/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
// 게시물 관리 > 게시물 상세관리 테이블
import { useState, useCallback, useMemo, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useModal from '../../../../hooks/useModal.js';

import Pagination from '../../../../components/common/Pagination/Pagination.jsx';
import Modal from '../../../../components/common/Modal/Modal.jsx';
import Dropdown from '../../../../components/common/DropDown/DropDown.jsx';
import CustomButton from '../../../../components/common/Button/Button.jsx';

import CommentVideoListTable from './CommentVideoListTable.jsx';
import CommentVideoListTableTitle from './CommentVideoListTableTitle.jsx';
import VideoDetailModal from '../../../../components/common/VideoDetailModal/VideoDetailModal.jsx';

import { PAGE_SIZE_OPTIONS } from '../../../../constants/constants.js';

import {
  StyledCommentVideoListContainer,
  StyledCommentVideoListWrapper,
  StyledCommentVideoListListWrapper,
  StyledCommentVideoListTableTitleContainer,
  StyledCommentVideoListButtonGroupWrapper,
  StyledPublishedVideoListBlueBtn,
  StyledPublishedVideoStatus,
} from './CommentVideoList.styles.js';
import {
  formatBirthDate,
  formatDateHHMM,
  formatEndTime,
  formatGroupFullCode,
  formatNumber,
  formatPhoneNumber,
  formatYYYYMMDDHHMMSS,
} from '../../../../utils/formatDate.js';
import CommentVideoDetailModal from '../../../../components/common/CommentVideoDetailModal/CommentVideoDetailModal.jsx';
import UserDetailModal from '../../../CustomerService/Inquiries/components/ReportList/UserDetailModal.jsx';
import xlsxDownload from '../../../../utils/xlsxDownload.js';
import {
  deleteVideoDetailLike,
  getVideoDetailCommentVideo,
  getVideoDetailComments,
  getVideoDetailFeelkyRequests,
  getVideoDetailLikes,
  getVideoDetailMainVideo,
  patchVideoDetailCommentStatus,
} from '../../../../apis/video/index.js';
import {
  getUserDetailActivity,
  getUserDetailBasicInfo,
} from '../../../../apis/user/index.js';
import { deleteVideos } from '../../../../apis/videos/index.js';
import UpdateActvieStatusModal from './UpdateActvieStatusModal.jsx';

const VIDEO_TYPES_FORM = {
  likeCount: '좋아요',
  videoCommentCount: '영상댓글',
  recvFeelkyRequestCount: '받은 필키요청',
  reportCount: '받은 신고 목록',
};

const CommentVideoList = ({ postId, postType, listType }) => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const navigate = useNavigate();
  const location = useLocation();

  const isVideoCommentCount = useMemo(() => {
    return listType === 'videoCommentCount';
  }, [listType]);

  const isLikeCount = useMemo(() => {
    return listType === 'likeCount';
  }, [listType]);

  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get('page')) || 1;
  const pageSize = parseInt(searchParams.get('size')) || PAGE_SIZE_OPTIONS[0];

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [posts, setPosts] = useState([]);
  const [openModal, setOpenModal] = useState('');
  const [modalData, setModalData] = useState(null);

  const [selectedId, setSelectedId] = useState('');

  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('DESC');

  const [isExcel, setIsExcel] = useState(false);

  const [updateVideoStatus, setUpdateVideoStatus] = useState();

  const handleSetSearchParams = ({ key, value }) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const handleSetSeatchParamsList = (list) => {
    const newSearchParams = new URLSearchParams(searchParams);
    list.forEach(({ key, value }) => newSearchParams.set(key, value));
    setSearchParams(newSearchParams);
  };

  const columnsLikeCountLabel = useMemo(
    () => ({
      createdAt: '좋아요 일자',
      nickname: '닉네임',
      gender: '성별',
      level: '회원유형',
      videoTitle: '관련 영상 타이틀',
    }),
    [],
  );
  const columnsLikeCountKey = useMemo(
    () => [
      {
        key: 'createdAt',
        width: '150px',
        sortable: true,
      },
      {
        key: 'nickname',
        width: '15%',
      },
      { key: 'gender', width: '60px' },
      { key: 'level', width: '80px' },
      {
        key: 'videoTitle',
        width: '35%',
      },
    ],
    [],
  );
  const columnsRecvFeelkyRequestCountLabel = useMemo(
    () => ({
      state: '응답',
      createdAt: '필키요청 일자',
      nickname: '요청자',
      videoTitle: '영상 타이틀',
      recvFeelkyRequestCount: '필키요청',
      likeCount: '좋아요',
      viewCount: '조회수',
      reportCount: '신고',
    }),
    [],
  );
  const columnsRecvFeelkyRequestCountKey = useMemo(
    () => [
      { key: 'state', width: '60px' },
      {
        key: 'createdAt',

        width: '180px',
        sortable: true,
      },
      { key: 'nickname', width: '15%' },
      { key: 'videoTitle', width: '40%' },
      {
        key: 'recvFeelkyRequestCount',

        width: '100px',
        sortable: true,
      },
      { key: 'likeCount', width: '90px', sortable: true },
      { key: 'viewCount', width: '90px', sortable: true },
      { key: 'reportCount', width: '80px', sortable: true },
    ],
    [],
  );
  const columnsVideoCommentCountLabel = useMemo(
    () => ({
      state: '상태',
      createdAt: '게시일자',
      commentVideoTitle: '영상 타이틀',
      timeline: '길이',
      nickname: '게시자',
      recvFeelkyRequestCount: '필키요청',
      likeCount: '좋아요',
      viewCount: '조회수',
      reportCount: '신고',
    }),
    [],
  );
  const columnsVideoCommentCountKey = useMemo(
    () => [
      {
        key: 'state',
        width: '50px',
      },
      {
        key: 'createdAt',
        width: '180px',
        sortable: true,
      },
      {
        key: 'commentVideoTitle',
        width: '35%',
      },
      { key: 'timeline', width: '65px' },
      {
        key: 'nickname',
        width: '150px',
      },
      {
        key: 'recvFeelkyRequestCount',
        width: '105px',
        sortable: true,
      },
      { key: 'likeCount', width: '95px', sortable: true },
      { key: 'viewCount', width: '95px', sortable: true },
      { key: 'reportCount', width: '85px', sortable: true },
    ],
    [],
  );

  const { fetch: fetchGetVideoDetailLikes } = getVideoDetailLikes({
    query: {
      isExcel,
      postId,
      postType,
      sortColumn,
      pageSize,
      sortDirection,
      currentPage,
    },
    isInitialRequest: false,
    onSuccess: (value) => {
      const newData = value.likeVideoDto.map(({ ...item }) => {
        return {
          ...item,
          id: item.likeId,
          gender: item.gender
            ? formatGroupFullCode(groupCodeList, item.gender).codeName
            : '-',
          level: item.level
            ? formatGroupFullCode(groupCodeList, item.level).codeName
            : '-',
          createdAt: formatYYYYMMDDHHMMSS(item.createdAt),
          videoTitle: item.videoTitle || '-',
        };
      });

      if (isExcel) {
        const excelData = newData
          .filter(({ uuid }) =>
            selectedUserIds.length ? selectedUserIds.includes(uuid) : true,
          )
          .map((data) => {
            const mappedData = {};
            Object.keys(columnsLikeCountLabel).forEach((key) => {
              mappedData[columnsLikeCountLabel[key]] = data[key];
            });
            return mappedData;
          });
        setIsExcel(false);
        handleExcelDownload(excelData, '좋아요');
      } else {
        setPosts(
          newData.map(({ ...item }) => ({
            ...item,
            videoTitle:
              item.videoTitle === '-' ? (
                item.videoTitle
              ) : (
                <StyledPublishedVideoListBlueBtn
                  onClick={() => handleOpenModal(item.videoId, item.videoType)}
                >
                  {item.videoTitle}
                </StyledPublishedVideoListBlueBtn>
              ),
          })),
        );
        setTotalCount(value.totalCount);
      }
    },
  });

  const { fetch: fetchGetVideoDetailFeelkyRequests } =
    getVideoDetailFeelkyRequests({
      query: {
        isExcel,
        postId,
        postType,
        sortColumn,
        pageSize,
        sortDirection,
        currentPage,
      },
      isInitialRequest: false,
      onSuccess: (value) => {
        const newData = value.feelkyRequestVideoDto.map((item) => ({
          ...item,
          id: item.videoId,
          createdAt: formatYYYYMMDDHHMMSS(item.createdAt),
          recvFeelkyRequestCount: formatNumber(item.recvFeelkyRequestCount),
          likeCount: formatNumber(item.likeCount),
          viewCount: formatNumber(item.viewCount),
          reportCount: formatNumber(item.reportCount),
          state: item.isActiveStatus,
        }));

        if (isExcel) {
          const excelData = newData
            .filter(({ uuid }) =>
              selectedUserIds.length ? selectedUserIds.includes(uuid) : true,
            )
            .map((data) => {
              const mappedData = {};
              Object.keys(columnsRecvFeelkyRequestCountLabel).forEach((key) => {
                mappedData[columnsRecvFeelkyRequestCountLabel[key]] = data[key];
              });
              return mappedData;
            });
          handleExcelDownload(excelData, '필키 요청');
          setIsExcel(false);
        } else {
          setPosts(
            newData.map((item) => ({
              ...item,
              videoTitle: (
                <StyledPublishedVideoListBlueBtn
                  onClick={() => handleOpenModal(item.videoId, item.videoType)}
                >
                  {item.videoTitle}
                </StyledPublishedVideoListBlueBtn>
              ),
              state: (
                <div
                  style={{
                    color: `${item.state !== '수락' && '#F76659'}`,
                  }}
                >
                  {item.state}
                </div>
              ),
            })),
          );
          setTotalCount(value.totalCount);
        }
      },
    });

  const { fetch: fetchGetVideoDetailComments } = getVideoDetailComments({
    query: {
      isExcel,
      postId,
      sortColumn,
      pageSize,
      sortDirection,
      currentPage,
    },
    isInitialRequest: false,
    onSuccess: (value) => {
      const newData = value.commentVideoDtos.map(({ ...item }) => ({
        ...item,
        id: item.commentVideoId,
        createdAt: formatYYYYMMDDHHMMSS(item.createdAt),
        timeline: `${formatNumber(item.timeline)} 초`,
        recvFeelkyRequestCount: formatNumber(item.recvFeelkyRequestCount),
        likeCount: formatNumber(item.likeCount),
        viewCount: formatNumber(item.viewCount),
        state: item.isActiveStatus,
      }));

      if (isExcel) {
        const excelData = newData
          .filter(({ uuid }) =>
            selectedUserIds.length ? selectedUserIds.includes(uuid) : true,
          )
          .map((data) => {
            const mappedData = {};
            Object.keys(columnsVideoCommentCountLabel).forEach((key) => {
              mappedData[columnsVideoCommentCountLabel[key]] = data[key];
            });
            return mappedData;
          });
        handleExcelDownload(excelData, '영상 댓글');
        setIsExcel(false);
      } else {
        setPosts(
          newData.map(({ ...item }) => ({
            ...item,
            commentVideoTitle: (
              <StyledPublishedVideoListBlueBtn
                onClick={() => handleOpenModal(item.commentVideoId, 'COMMENT')}
              >
                {item.commentVideoTitle}
              </StyledPublishedVideoListBlueBtn>
            ),
            state: (
              <StyledPublishedVideoStatus $isInquiry={item.state !== '게시'}>
                {item.state}
              </StyledPublishedVideoStatus>
            ),
          })),
        );
        setTotalCount(value.totalCount);
      }
    },
  });

  const { fetch: fetchGetVideoDetailMainVideo } = getVideoDetailMainVideo({
    query: selectedId,
    onSuccess: (value) => {
      const {
        timeline,
        meetingLocation,
        meetingCount,
        meetingTime,
        isActive,
        createdAt,
        videoEndTime,
        tags,
        ...data
      } = value;
      const formatedEndTime = formatEndTime(videoEndTime);
      setModalData({
        ...data,
        timeline: `${timeline}초`,
        meetingLocation: meetingLocation || '-',
        meetingCount: meetingCount ? `${meetingCount}명` : '-',
        meetingTime: meetingTime
          ? `${formatDateHHMM(new Date(meetingTime))}`
          : '-',
        tags: tags || '-',
        isActive: `${isActive ? '게시중' : '게시 종료'} ${
          formatedEndTime
            ? `( ${formatedEndTime.hour}시간${formatedEndTime.second}분 후 종료 )`
            : '( 종료 )'
        }`,
        createdAt: formatYYYYMMDDHHMMSS(createdAt),
      });
    },
  });

  const { fetch: fetchGetVideoDetailCommentVideo } = getVideoDetailCommentVideo(
    {
      query: selectedId,
      isInitialRequest: false,
      onSuccess: (value) => {
        const { createdAt, isActiveStatus, videoEndTime, ...data } = value;
        const formatedEndTime = formatEndTime(videoEndTime);
        setModalData({
          ...data,
          createdAt: formatYYYYMMDDHHMMSS(createdAt),
          isActive: `${isActiveStatus} ${
            formatedEndTime
              ? `( ${formatedEndTime.hour}시간${formatedEndTime.second}분 후 종료 )`
              : '( 종료 )'
          }`,
        });
      },
    },
  );

  const { fetch: fetchGetUserDetailBasicInfo } = getUserDetailBasicInfo({
    query: selectedId,
    isInitialRequest: false,
    onSuccess: (value) => {
      const { gender, birthDate, createdAt, phone, ...data } = value;
      const basic = {
        ...data,
        createdAt: formatYYYYMMDDHHMMSS(createdAt),
        birthDate: formatBirthDate(birthDate),
        phone: formatPhoneNumber(phone),
        gender: formatGroupFullCode(groupCodeList, gender).codeName,
      };
      setModalData((prev) => ({ ...prev, basic }));
    },
  });

  const { fetch: fetchGetUserDetailActivity } = getUserDetailActivity({
    query: selectedId,
    isInitialRequest: false,
    onSuccess: (value) => {
      const { blockedUserCount, recvUserVideoReportCount, ...data } = value;
      const activity = {
        ...data,
        blockedUserCount: `${blockedUserCount}회`,
        recvUserVideoReportCount: `${recvUserVideoReportCount}회`,
      };
      setModalData((prev) => ({ ...prev, activity }));
    },
  });

  const { fetch: fetchDeleteVideoDetailLike } = deleteVideoDetailLike({
    onSuccess: () => fetchGetVideoDetailLikes(),
  });

  const { fetch: fetchDeleteVideos } = deleteVideos({
    onSuccess: () => fetchGetVideoDetailComments(),
  });

  const { fetch: fetchPostVideoDetailCommentStatus, isLoading } =
    patchVideoDetailCommentStatus({
      query: {
        status: updateVideoStatus,
        type: selectedUserIds.length ? 'LIST' : 'ALL',
        mainVideoId: postId,
      },
      onSuccess: () => {
        setSelectedUserIds([]);
        fetchGetVideoDetailComments();
      },
    });

  const handleCommentVideoCreateClick = () => {
    const locationParams = new URLSearchParams(location.search.toString());
    const filterQuery = locationParams.toString();

    navigate(
      `/PostManagement/PostManagementDetail/${postId}/CreateCommentVideo?${filterQuery}`,
    );
  };

  const handleExcelDownload = (data, title) => {
    xlsxDownload(data, title, `${title} 리스트`);
    showModal('엑셀 다운로드가 완료되었습니다.', '', 'confirm');
  };

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  const handleSort = useCallback(
    (column) => {
      if (column === sortColumn) {
        setSortDirection((prevDirection) =>
          prevDirection === 'ASC' ? 'DESC' : 'ASC',
        );
      } else {
        setSortColumn(column);
        setSortDirection('ASC');
      }
    },
    [sortColumn],
  );

  const columns = useMemo(
    () => ({
      likeCount: columnsLikeCountKey.map(({ ...value }) => ({
        label: columnsLikeCountLabel[value.key],
        ...value,
      })),
      videoCommentCount: columnsVideoCommentCountKey.map(({ ...value }) => ({
        label: columnsVideoCommentCountLabel[value.key],
        ...value,
      })),
      recvFeelkyRequestCount: columnsRecvFeelkyRequestCountKey.map(
        ({ ...value }) => ({
          label: columnsRecvFeelkyRequestCountLabel[value.key],
          ...value,
        }),
      ),
    }),
    [listType],
  );

  const handleRowClick = (data) => {
    handleOpenModal(data.userId, 'USER');
  };

  const handleCloseModal = useCallback(() => {
    setModalData(null);
    setOpenModal('');
  }, []);

  const handleOpenModal = useCallback((id, openModalId) => {
    setOpenModal(openModalId);
    setSelectedId(id);
  }, []);

  const handleDownloadClick = useCallback(() => {
    if (selectedUserIds.length === 0) {
      showModal(
        '전체 다운로드',
        '전체 리스트를 엑셀로 다운로드 하시겠습니까?',
        'excelConfirm',
      );
    } else {
      showModal(
        '엑셀 다운로드 확인',
        '선택하신 항목 정보를 다운로드 하시겠습니까?',
        'excel',
      );
    }
  }, [selectedUserIds, showModal]);

  const handleDeleteConfirm = useCallback(() => {
    // selectedUserIds

    switch (listType) {
      case 'likeCount':
        fetchDeleteVideoDetailLike(
          selectedUserIds.map((value) => ({ likeId: value })),
        );
        break;
      case 'videoCommentCount':
        fetchDeleteVideos({
          data: selectedUserIds.map((value) => ({ videoId: value })),
          videoType: 'COMMENT',
        });
        break;
      case 'recvFeelkyRequestCount':
        console.log('필키 요청 삭제');
        break;
      default:
        // 처리
        break;
    }

    setSelectedUserIds([]);
    closeModal();
    showModal('삭제 완료 되었습니다.', '', 'confirm');
  }, [closeModal, showModal]);

  const handleDeleteClick = useCallback(() => {
    if (selectedUserIds.length === 0) {
      showModal('삭제 할 목록을 선택해 주세요.', '', 'confirm');
    } else {
      showModal('선택하신 목록을 삭제 하시겠습니까?', '', 'alert');
    }
  }, [selectedUserIds, showModal]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageUserIds = posts.map((user) => user.id);
      setSelectedUserIds(currentPageUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const handleSelectUser = useCallback((userId) => {
    setSelectedUserIds((prevSelectedUserIds) => {
      if (prevSelectedUserIds.includes(userId)) {
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
      return [...prevSelectedUserIds, userId];
    });
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      handleSetSearchParams({ key: 'page', value: page });
      setSelectedUserIds([]);
    },
    [searchParams],
  );

  const handlePageSizeChange = useCallback(
    (selectedOption) => {
      const selectedSize = PAGE_SIZE_OPTIONS.find(
        (size) => `${size}개씩 보기` === selectedOption,
      );
      if (selectedSize) {
        handleSetSeatchParamsList([
          { key: 'page', value: 1 },
          { key: 'size', value: selectedSize },
        ]);
        setSelectedUserIds([]);
      }
    },
    [searchParams],
  );

  const modalContents = useMemo(() => {
    return modalContent === 'updateActiveStatus' ? (
      <UpdateActvieStatusModal
        selectIds={selectedUserIds.length}
        closeModal={closeModal}
        handleChangeVideoStatus={(value) => setUpdateVideoStatus(value)}
      />
    ) : (
      modalContent
    );
  }, [modalContent]);

  const modalActions = useMemo(() => {
    return modalVariant === 'alert' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="삭제"
          $variant={modalVariant}
          onClick={handleDeleteConfirm}
        />
      </>
    ) : modalVariant === 'excelConfirm' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="다운로드"
          $variant="confirm"
          onClick={() => {
            setIsExcel(true);
            closeModal();
          }}
        />
      </>
    ) : modalVariant === 'updateActiveStatus' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="변경"
          $variant="confirm"
          disabled={!updateVideoStatus || isLoading}
          onClick={() => {
            fetchPostVideoDetailCommentStatus({ commentList: selectedUserIds });
            closeModal();
          }}
        />
      </>
    ) : (
      <CustomButton
        size="large"
        $state="default"
        text="확인"
        $variant={modalVariant}
        onClick={closeModal}
      />
    );
  }, [modalVariant, closeModal, handleDeleteConfirm]);

  useEffect(() => {
    if (!openModal || !selectedId) return;

    if (openModal === 'MAIN') fetchGetVideoDetailMainVideo();
    if (openModal === 'COMMENT') fetchGetVideoDetailCommentVideo();
    if (openModal === 'USER') {
      fetchGetUserDetailBasicInfo();
      fetchGetUserDetailActivity();
    }
  }, [openModal, selectedId]);

  useEffect(() => {
    setSelectedUserIds([]);
    switch (listType) {
      case 'likeCount':
        fetchGetVideoDetailLikes();
        break;
      case 'videoCommentCount':
        fetchGetVideoDetailComments();
        break;
      case 'recvFeelkyRequestCount':
        fetchGetVideoDetailFeelkyRequests();
        break;
      default:
        break;
    }
  }, [
    listType,
    postId,
    postType,
    sortColumn,
    sortDirection,
    pageSize,
    currentPage,
  ]);

  useEffect(() => {
    if (currentPage === 1 && pageSize === PAGE_SIZE_OPTIONS[0]) return;
    handleSetSeatchParamsList([
      { key: 'page', value: 1 },
      { key: 'size', value: PAGE_SIZE_OPTIONS[0] },
    ]);
  }, [listType]);

  useEffect(() => {
    if (isExcel)
      switch (listType) {
        case 'likeCount':
          fetchGetVideoDetailLikes();
          break;
        case 'videoCommentCount':
          fetchGetVideoDetailComments();
          break;
        case 'recvFeelkyRequestCount':
          fetchGetVideoDetailFeelkyRequests();
          break;
        default:
          // 처리
          break;
      }
  }, [isExcel]);

  return (
    <StyledCommentVideoListContainer>
      <StyledCommentVideoListWrapper>
        <StyledCommentVideoListListWrapper>
          <StyledCommentVideoListTableTitleContainer>
            <CommentVideoListTableTitle
              videoTitle={VIDEO_TYPES_FORM[listType]}
              totalUsers={totalCount}
            />
            <StyledCommentVideoListButtonGroupWrapper>
              <Dropdown
                options={PAGE_SIZE_OPTIONS.map((size) => `${size}개씩 보기`)}
                value={`${pageSize}개씩 보기`}
                selectedValue={`${pageSize}개씩 보기`}
                onOptionChange={handlePageSizeChange}
              />

              <CustomButton
                size="small"
                $state="default"
                text="엑셀 다운로드"
                $variant="exceldown"
                onClick={handleDownloadClick}
              />
              {isVideoCommentCount && (
                <>
                  <CustomButton
                    size="small"
                    $state="default"
                    text="상태 일괄 변경"
                    $variant="exceldown"
                    onClick={() =>
                      showModal(
                        <>
                          {selectedUserIds.length === 0 && '전체 '}
                          상태 일괄 변경
                        </>,
                        'updateActiveStatus',
                        'updateActiveStatus',
                      )
                    }
                  />
                  <CustomButton
                    size="small"
                    $state="default"
                    text="영상댓글 생성"
                    $variant="deleteBlack"
                    onClick={handleCommentVideoCreateClick}
                  />
                </>
              )}
              {isLikeCount && (
                <CustomButton
                  size="small"
                  $state="default"
                  text="좋아요 취소"
                  $variant="deleteBlack"
                  onClick={handleDeleteClick}
                />
              )}
            </StyledCommentVideoListButtonGroupWrapper>
          </StyledCommentVideoListTableTitleContainer>
          <CommentVideoListTable
            users={posts}
            columns={columns[listType]}
            selectedUserIds={selectedUserIds}
            onSelectAll={handleSelectAll}
            onSelectUser={handleSelectUser}
            onRowClick={handleRowClick}
            onSort={handleSort}
            onTitleClick={() => {}}
            onPublisherClick={() => {}}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            searchTitle={VIDEO_TYPES_FORM[listType]}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalCount / pageSize)}
            onPageChange={handlePageChange}
          />
        </StyledCommentVideoListListWrapper>
      </StyledCommentVideoListWrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContents}
        actions={modalActions}
      />
      <VideoDetailModal
        isOpen={openModal === 'MAIN'}
        onClose={handleCloseModal}
        videoData={modalData}
      />
      <CommentVideoDetailModal
        isOpen={openModal === 'COMMENT'}
        onClose={handleCloseModal}
        videoData={modalData}
      />
      <UserDetailModal
        userId={selectedId}
        isOpen={openModal === 'USER'}
        onClose={handleCloseModal}
        userData={modalData}
      />
    </StyledCommentVideoListContainer>
  );
};

export default CommentVideoList;
