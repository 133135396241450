// InputFields.jsx
import {
  StyledAppSettingTableRow,
  StyledAppSettingTableCell,
  StyledAppSettingTableCellLabel,
  StyledAppSettingInputFieldContainer,
  StyledAppSettingInputFieldWrapper,
  StyledAppSettingInputField,
  StyledAppSettingInputFieldText,
  StyledAppSettingInputFieldLabel,
  StyledAppSettingInputFixedText,
} from './AppSetting.styles';
import CustomButton from '../../../../components/common/Button/Button';

const InputFields = ({
  hour = false,
  minute = false,
  second = false,
  title,
  settings,
  onInputChange,
  modalTitleText,
  modalContentText,
  handleConfirm,
  showModal,
  disabled,
}) => {
  const distanceSubKey = ['minDistance', 'maxDistance'];
  const handleInputChange = (e, subKey) => {
    const value = e.target.value.replace(/\D/g, '');
    let limitedValue = value;

    if (subKey === 'hour') {
      limitedValue = Math.min(Math.max(value, 0), 23).toString();
    } else if (subKey === 'minute' || subKey === 'second') {
      limitedValue = Math.min(Math.max(value, 0), 59).toString();
    }

    onInputChange(limitedValue, subKey);
  };

  return (
    <StyledAppSettingTableRow>
      <StyledAppSettingTableCell $isHeader style={{ width: '15.80%' }}>
        <StyledAppSettingTableCellLabel $isHeader $disabled={disabled}>
          {title}
        </StyledAppSettingTableCellLabel>
      </StyledAppSettingTableCell>
      <StyledAppSettingTableCell
        style={{ width: '45.26%' }}
        $disabled={disabled}
      >
        <StyledAppSettingInputFieldContainer>
          {hour && (
            <StyledAppSettingInputFieldWrapper>
              <StyledAppSettingInputField>
                <StyledAppSettingInputFieldText
                  $disabled={disabled}
                  value={settings.hour}
                  onChange={(e) => handleInputChange(e, 'hour')}
                  placeholder="00"
                />
              </StyledAppSettingInputField>
              <StyledAppSettingInputFieldLabel $disabled={disabled}>
                시간
              </StyledAppSettingInputFieldLabel>
            </StyledAppSettingInputFieldWrapper>
          )}

          {minute && (
            <StyledAppSettingInputFieldWrapper>
              <StyledAppSettingInputField>
                <StyledAppSettingInputFieldText
                  $disabled={disabled}
                  value={settings.minute}
                  onChange={(e) => handleInputChange(e, 'minute')}
                  placeholder="00"
                />
              </StyledAppSettingInputField>
              <StyledAppSettingInputFieldLabel $disabled={disabled}>
                {title === '신고 영상 자동 숨김' ? '분 내에' : '분'}
              </StyledAppSettingInputFieldLabel>
            </StyledAppSettingInputFieldWrapper>
          )}
          {second && (
            <StyledAppSettingInputFieldWrapper>
              <StyledAppSettingInputField>
                <StyledAppSettingInputFieldText
                  $disabled={disabled}
                  value={settings.second}
                  onChange={(e) => handleInputChange(e, 'second')}
                  placeholder="00"
                />
              </StyledAppSettingInputField>
              <StyledAppSettingInputFieldLabel $disabled={disabled}>
                초
              </StyledAppSettingInputFieldLabel>
            </StyledAppSettingInputFieldWrapper>
          )}
          {title === '신고 영상 자동 숨김' && (
            <StyledAppSettingInputFieldWrapper>
              <StyledAppSettingInputField>
                <StyledAppSettingInputFieldText
                  value={settings.count}
                  onChange={(e) => handleInputChange(e, 'count')}
                  placeholder="00"
                />
              </StyledAppSettingInputField>
              <StyledAppSettingInputFieldLabel>
                회 연속 신고시 자동 숨김
              </StyledAppSettingInputFieldLabel>
            </StyledAppSettingInputFieldWrapper>
          )}
          {title === '최소/최대 거리' &&
            distanceSubKey.map((subkey) => (
              <StyledAppSettingInputFieldWrapper key={subkey}>
                <StyledAppSettingInputFieldLabel>
                  {subkey === 'minDistance' ? '최소거리' : '최대거리'}
                </StyledAppSettingInputFieldLabel>
                <StyledAppSettingInputField style={{ width: '81.5px' }}>
                  <StyledAppSettingInputFieldText
                    value={settings[subkey]}
                    onChange={(e) => handleInputChange(e, subkey)}
                    placeholder="00m"
                  />
                  <StyledAppSettingInputFixedText>
                    {settings[subkey] && 'm'}
                  </StyledAppSettingInputFixedText>
                </StyledAppSettingInputField>
              </StyledAppSettingInputFieldWrapper>
            ))}
          {title === "'내 주변' 영역" && (
            <StyledAppSettingInputFieldWrapper>
              <StyledAppSettingInputFieldLabel>
                반경 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </StyledAppSettingInputFieldLabel>
              <StyledAppSettingInputField style={{ width: '81.5px' }}>
                <StyledAppSettingInputFieldText
                  value={settings}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="00m"
                />
                <StyledAppSettingInputFixedText>
                  {settings && 'm'}
                </StyledAppSettingInputFixedText>
              </StyledAppSettingInputField>
            </StyledAppSettingInputFieldWrapper>
          )}
        </StyledAppSettingInputFieldContainer>
        <CustomButton
          size="small"
          $state="default"
          text="변경"
          $variant="change"
          disabled={disabled}
          onClick={() =>
            showModal(modalTitleText, modalContentText, 'alert', handleConfirm)
          }
        />
      </StyledAppSettingTableCell>
      <StyledAppSettingTableCell $isOdd style={{ width: '38.94%' }}>
        <StyledAppSettingTableCellLabel $disabled={disabled}>
          {title === '영상 촬영 시간' &&
            '*영상 업로드 가능한 최대 시간을 설정해 주세요.'}
          {title === '영상 게시 시간' &&
            '*영상 업로드부터 자동 삭제될때까지의 시간을 설정해 주세요.'}
          {title === '신고 영상 자동 숨김' &&
            '*연속 신고가 접수 될시 영상이 자동 숨김 처리가 되는 기능입니다.'}
          {title === '최소/최대 거리' &&
            '*영상 업로드시 표시되는 최소/최대 거리를 설정해 주세요.'}
          {title === "'내 주변' 영역" &&
            "*위치 카테고리 ‘내주변' 영역을 설정해 주세요."}
          {title === '추가 시간' &&
            '*영상댓글 1개당 추가될 시간을 설정해 주세요.'}
        </StyledAppSettingTableCellLabel>
      </StyledAppSettingTableCell>
    </StyledAppSettingTableRow>
  );
};

export default InputFields;
