/* eslint-disable consistent-return */

import { getAccount } from '../../apis/accountManagement';
import PageHeader from '../../components/common/PageHeader';
import { formatDateYYYYMMDD } from '../../utils/formatDate';
import MyInfo from './components/MyInfo/MyInfo';
import { StyledMyInfoContents } from './MyInfo.styles';

const MyInfoPage = () => {
  const formatAccount = (data) => {
    if (!data) return;
    const { myAccountInfoDto } = data;
    const { createdAt, updatedAt } = myAccountInfoDto;

    return {
      ...myAccountInfoDto,
      createdAt: formatDateYYYYMMDD(new Date(createdAt), '.'),
      updatedAt: formatDateYYYYMMDD(new Date(updatedAt), '.'),
    };
  };

  const account = formatAccount(getAccount());
  return (
    <div>
      <PageHeader title="마이페이지" />
      <StyledMyInfoContents>
        <MyInfo userData={account} />
      </StyledMyInfoContents>
    </div>
  );
};

export default MyInfoPage;
