// KpiStats.jsx
import { useState, useEffect } from 'react';
import StatusCard from '../../../../components/common/StatusCard/StatusCard';
import { kpiStatsData } from '../../../../data/dashboardData';
import {
  DASHBORD_OPTIONS,
  DASHBORD_OPTIONS_RENDER_ITEM,
} from '../../../../constants/constants';

const KpiStats = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState(1);
  const [kpiData, setKpiData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchData(selectedTimeRange);
  }, [selectedTimeRange]);

  const fetchData = (timeRange) => {
    // TODO: API 호출 시 해당 부분 수정해주세요!
    setLoading(true);
    setTimeout(() => {
      const data = kpiStatsData[timeRange];
      setKpiData(
        data.map(({ changeValue, ...value }) => ({
          ...value,
          changeValue: '전주 대비',
        })),
      );
      setLoading(false);
    }, 2000);
  };

  const handleOptionChange = (option) => {
    setSelectedTimeRange(option);
  };

  // 공통 컴포넌트에 전달할 props 준비
  const description = kpiData[1]?.change
    ? `지난 주 대비${kpiData[1].change} 성장했어요.`
    : '데이터를 불러오는 중입니다.';

  return (
    <StatusCard
      title="KPI 통계"
      description={description}
      data={kpiData}
      isLoading={isLoading}
      timeRangeOptions={DASHBORD_OPTIONS}
      timeRenderItem={(option) => DASHBORD_OPTIONS_RENDER_ITEM[option]}
      selectedTimeRange={selectedTimeRange}
      onTimeRangeChange={handleOptionChange}
      skeletonCount={3}
      arrow
    />
  );
};

export default KpiStats;
