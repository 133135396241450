// MemberInfo.jsx
import styled from 'styled-components';
import { PiX, PiSealCheckFill } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomButton from '../../../../../components/common/Button/Button';
import { getFullGroupCode } from '../../../../../utils/groupCode';

const UserDetailModal = ({ isOpen, onClose, userData, userId }) => {
  const navigate = useNavigate();
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const { basic, activity } = userData || false;

  if (!isOpen || !basic || !activity) return null;

  const handleSearchDetail = () => {
    navigate('/UserManagement/UserManagementDetail', {
      state: { userId },
    });
  };

  return (
    <StyledModalOverlay>
      <ModalWrapper>
        <Wrapper>
          <Container>
            <MainHeader>
              <MainTitle>회원 상세</MainTitle>
              <CloseButton onClick={onClose}>
                <CloseButtonVector />
              </CloseButton>
            </MainHeader>
            <Header>
              <Title>회원 기본 정보</Title>
            </Header>
            <Table>
              <TableRow>
                <TableCell $header>닉네임</TableCell>
                <TableCell>{basic.nickname}</TableCell>
                <TableCell $header>회원유형</TableCell>
                <TableCell>
                  {getFullGroupCode(groupCodeList, basic.level).codeName}
                  {basic.isCeleb && (
                    <Checkbox>
                      <CheckIcon type="checkbox" />
                      <CheckboxLabel>셀럽</CheckboxLabel>
                    </Checkbox>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell $header>가입일자</TableCell>
                <TableCell>{basic.createdAt}</TableCell>
                <TableCell $header>생년월일</TableCell>
                <TableCell>{basic.birthDate}</TableCell>
              </TableRow>
              <TableRow $isLast>
                <TableCell $header>성별</TableCell>
                <TableCell>{basic.gender}</TableCell>
                <TableCell $header>연락처</TableCell>
                <TableCell>{basic.phone}</TableCell>
              </TableRow>
            </Table>
          </Container>
          <Container>
            <Header>
              <Title>회원 활동</Title>
            </Header>
            <Table>
              <TableRow>
                <TableCell $header>게시물 영상</TableCell>
                <TableCell>서울시 강남구 압구정동</TableCell>
              </TableRow>
              <TableRow>
                <TableCell $header>주요 게시 위치</TableCell>
                <TableCell>서울시 강남구 압구정동</TableCell>
                <TableCell $header>평균 접속 시간</TableCell>
                <TableCell>일일 평균 21분 54초</TableCell>
              </TableRow>
              <TableRow $isLast>
                <TableCell $header>받은 신고 수</TableCell>
                <TableCell>메인영상 : 5회 | 영상댓글 : 2회</TableCell>
                <TableCell $header>신고한 횟수</TableCell>
                <TableCell>메인 영상 : 5회 | 영상댓글 : 2회</TableCell>
              </TableRow>
            </Table>
          </Container>
          <CustomButton
            size="medium"
            $state="default"
            text="자세히 보기"
            $variant="search"
            onClick={handleSearchDetail}
          />
        </Wrapper>
      </ModalWrapper>
    </StyledModalOverlay>
  );
};

export default UserDetailModal;

const ModalWrapper = styled.div`
  min-width: 703px;
  /* height: 464px; */
  padding: 40px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

const MainHeader = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const MainTitle = styled.div`
  color: #1e2024;
  font-size: 28px;
  font-family: 'Pretendard';
  font-weight: 700;
  line-height: 36px;
  word-wrap: break-word;
`;

const Header = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Title = styled.div`
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

const Table = styled.div`
  align-self: stretch;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 13px;
  line-height: 22px;
  /* overflow: visible; */
`;

const TableRow = styled.div`
  align-self: stretch;
  background: white;
  border-bottom: 1px solid #d9d9d9;
  border-bottom: ${({ $isLast }) => $isLast && 'none'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TableCell = styled.div`
  align-self: stretch;
  padding: 6px 12px;
  background: ${({ $header }) => ($header ? '#e9e9e9' : 'white')};
  /* border-right: 1px solid #d9d9d9; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  width: ${({ $header }) => ($header ? '110px' : 'auto')};
  flex: ${({ $header }) => ($header ? '0 0 auto' : '1 1 0')};
`;

const Checkbox = styled.div`
  width: 55px;
  height: 24px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e9e8ff;
  gap: 6px;
`;

const CheckIcon = styled(PiSealCheckFill)`
  width: 14px;
  height: 14px;
  color: #645dd1;
`;

const CheckboxLabel = styled.div`
  text-align: center;
  color: #7d7ae0;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
`;

const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButtonVector = styled(PiX)`
  font-size: 24px;
  color: #686b73;
`;
