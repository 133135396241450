/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/common/PageHeader';
import PostTemplate from './PostTemplate/PostTemplate';
import useModal from '../../../hooks/useModal';
import Modal from '../../../components/common/Modal/Modal';
import CustomButton from '../../../components/common/Button/Button';
import { formatDateTime } from '../../../utils/formatDate';
import Spinner from '../../../components/common/Spinner/Spinner';
import { postVideo } from '../../../apis/video';
import {
  VIDEO_ACTIVE_STATUS,
  VIDEO_HIDDEN_STATUS,
} from '../../../constants/groupCode';

const StyledPostManagementCreatePostContainer = styled.div``;
const StyledPostManagementCreatePostWrapper = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;

const PostManagementCreatePost = () => {
  const navigate = useNavigate();

  const defaultPostData = {
    isMute: false,
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    meetingLocation: '',
    meetingCount: 0,
    meetingTime: null,
    tags: '',
    isActive: true,
    videoAddress: '',
    createdBy: '',
    videoPath: '',
    timeline: 0,
    videoThumbnail: '',
    latLng: { lat: null, lng: null },
  };

  const [postData, setPostData] = useState(defaultPostData);
  const [fetchData, setFetchData] = useState('');

  const { fetch: fetchPostVideo } = postVideo({
    query: fetchData.queryString,
    onSuccess: () => {
      closeModal();
      showModal('등록 완료', '새 게시물이 등록되었습니다.', 'confirm');
    },
    onFailure: () => closeModal(),
  });

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  const handleCreateFetchData = (value) => {
    const {
      isMute,
      isActive,
      videoTitle,
      createdBy,
      // startDate,
      // startTime,
      endDate,
      endTime,
      videoAddress,
      latLng,
      meetingTime,
      meetingCount,
      meetingLocation,
      tags,
      videoPath,
      videoThumbnail,
      timeline,
    } = value;
    const formData = new FormData();

    formData.append('mainVideo', videoPath);
    formData.append('videoThumbnail', videoThumbnail);

    const videoTitlQuery = `videoTitle=${videoTitle}`;
    const latQuery = `lat=${latLng.lat}`;
    const lngQuery = `lng=${latLng.lng}`;
    const videoAddressQuery = `videoAddress=${videoAddress}`;
    const createdByQuery = `createdBy=${createdBy}`;
    const meetingCountQuery = meetingCount && `meetingCount=${meetingCount}`;
    const meetingLocationQuery =
      meetingLocation && `meetingLocation=${meetingLocation}`;
    const isMuteQuery = `isMute=${isMute}`;
    const isActiveQuery = `isActive=${isActive}`;
    const isActiveStatusQuery = `isActiveStatus=${
      isActive ? VIDEO_ACTIVE_STATUS : VIDEO_HIDDEN_STATUS
    }`;
    const tagsQuery = tags && `tags=${encodeURIComponent(tags)}`;
    const videoEndTimeQuery = `videoEndTime=${formatDateTime(
      endDate,
      endTime,
    )}`;
    // const videoStartTimeQuery = `videoStartTime=${formatDateTime(
    //   startDate,
    //   startTime,
    // )}`;
    const meetingTimeQuery =
      meetingTime?.hour != null &&
      meetingTime?.minute != null &&
      `meetingTime=${formatDateTime(new Date(), meetingTime)}`;

    const timelineQuery = `timeline=${timeline}`;

    const queryString = [
      videoTitlQuery,
      latQuery,
      lngQuery,
      videoAddressQuery,
      createdByQuery,
      meetingCountQuery,
      meetingLocationQuery,
      isMuteQuery,
      isActiveQuery,
      tagsQuery,
      // videoStartTimeQuery,
      videoEndTimeQuery,
      meetingTimeQuery,
      timelineQuery,
      isActiveStatusQuery,
    ]
      .filter(Boolean)
      .join('&');

    return { queryString, formData };
  };

  const handleRegister = (data) => {
    if (
      data.videoTitle.trim() === '' ||
      data.createdBy.trim() === '' ||
      data.endTime.hour === null ||
      data.endTime.minute === null ||
      // data.startTime.hour === null ||
      // data.startTime.minute === null ||
      data.videoAddress.trim() === '' ||
      !data.latLng.lat ||
      !data.latLng.lng ||
      !data.videoPath ||
      data.videoThumbnail === ''
    ) {
      showModal('내용을 입력해주세요', '', 'input');
    } else if (
      (data.meetingTime?.hour ||
        data.meetingTime?.minute ||
        data.meetingCount ||
        data.meetingLocation ||
        data.tags) &&
      (data.meetingTime?.hour === null ||
        data.meetingTime?.minute === null ||
        !data.meetingCount ||
        data.meetingLocation.trim() === '' ||
        data.tags.trim() === '')
    ) {
      showModal('내용을 입력해주세요', '', 'input');
    } else {
      setFetchData(handleCreateFetchData(data));
    }
  };

  const handleResetConfirm = () => {
    setPostData(defaultPostData);
    closeModal();
  };

  const handleReset = () => {
    showModal(
      '초기화 하시겠습니까?',
      '초기화 시 입력하신 모든 내용이 삭제됩니다.',
      'alert',
    );
  };

  const handleRegisterConfirm = () => {
    navigate('/PostManagement', { replace: true });
  };

  const handleReturnPageClick = () => {
    navigate('/PostManagement');
  };

  useEffect(() => {
    const { formData, queryString } = fetchData;
    if (!queryString) return;
    showModal('게시물 등록중', '', 'loading');
    fetchPostVideo(formData);
  }, [fetchData]);

  return (
    <StyledPostManagementCreatePostContainer>
      <PageHeader title="게시물 생성" returnPage={handleReturnPageClick} />
      <StyledPostManagementCreatePostWrapper>
        <PostTemplate
          isPost
          postData={postData}
          onCancel={handleReset}
          onSave={handleRegister}
          cancelButtonText="초기화"
          saveButtonText="등록"
        />
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalTitle}
          content={<div style={{ textAlign: 'center' }}>{modalContent}</div>}
          actions={
            modalVariant === 'alert' ? (
              <>
                <CustomButton
                  size="large"
                  $state="default"
                  text="취소"
                  $variant="cancel"
                  onClick={closeModal}
                />
                <CustomButton
                  size="large"
                  $state="default"
                  text="초기화"
                  $variant="alert"
                  onClick={handleResetConfirm}
                />
              </>
            ) : modalVariant === 'confirm' ? (
              <CustomButton
                size="large"
                $state="default"
                $variant="confirm"
                text="확인"
                onClick={handleRegisterConfirm}
              />
            ) : modalVariant === 'loading' ? (
              <Spinner />
            ) : (
              <CustomButton
                size="large"
                $state="default"
                text="확인"
                $variant="alert"
                onClick={closeModal}
              />
            )
          }
        />
      </StyledPostManagementCreatePostWrapper>
    </StyledPostManagementCreatePostContainer>
  );
};

export default PostManagementCreatePost;
