import {
  PiChartBarFill,
  PiAddressBookFill,
  PiMonitorPlayFill,
  PiDevicesFill,
  PiHeadsetFill,
  PiShieldCheckeredFill,
} from 'react-icons/pi';

export const ChangeAccessItemConstans = [
  { id: 'dashboard', icon: <PiChartBarFill />, menuText: '대시보드' },
  { id: 'userManagement', icon: <PiAddressBookFill />, menuText: '회원 관리' },
  {
    id: 'postManagement',
    icon: <PiMonitorPlayFill />,
    menuText: '게시물 관리',
  },
  { id: 'systemManagement', icon: <PiDevicesFill />, menuText: '시스템 관리' },
  { id: 'customerCenter', icon: <PiHeadsetFill />, menuText: '고객센터' },
  {
    id: 'accountManagement',
    icon: <PiShieldCheckeredFill />,
    menuText: '계정 관리',
  },
];

export default ChangeAccessItemConstans;
