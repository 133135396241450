/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { PiCheckBold } from 'react-icons/pi';

export const StyledContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const StyledTable = styled.div`
  width: 596px;
  height: 100%;
  overflow: hidden;
  align-self: stretch;
  border-radius: 8px;
  border: 1px #d9d9d9 solid;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Pretendard';
`;

export const StyledIsCommentVideoContainer = styled.div`
  width: 596px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledNoticeTemplateTableRow = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px #d9d9d9 solid;
  &:last-child {
    border-bottom: none;
  }
`;

export const StyledNoticeTemplateTableCell = styled.div`
  height: ${({ $height }) => $height || '40px'};
  align-self: stretch;
  padding: ${({ $padding }) => $padding || '6px 12px'};
  border-right: 1px #d9d9d9 solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  min-width: ${({ $minWidth }) => $minWidth || ''};
  &:last-child {
    border-right: none;
  }
  &:first-child {
    border-left: none;
    border-top-left-radius: ${({ $isFirstRow }) => ($isFirstRow ? '8px' : '0')};
  }
`;

export const StyledNoticeTemplateTableHeaderCell = styled(
  StyledNoticeTemplateTableCell,
)`
  background: #f5f5f5;
  color: #434343;
  justify-content: flex-start;
  min-width: 110px;
  padding: 6px 8px 6px 12px;
`;

export const StyledDropdownWrapper = styled.div`
  position: relative;
`;

export const StyledInputsWrapper = styled.div`
  width: 484px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

export const StyledInputsBorderWrapper = styled.div`
  border-top: ${({ $borderTop }) => $borderTop && '0.5px'};
  border-left: ${({ $borderLeft }) => $borderLeft && '0.5px'};
  border-right: ${({ $borderRight }) => $borderRight && '0.5px'};
  border-bottom: ${({ $borderBottom }) => $borderBottom && '0.5px'};
  border-style: solid;
  border-color: #d9d9d9;

  display: flex;
  align-items: center;
  padding: 9px 12px;
`;

export const StyledTagInputField = styled.div`
  padding: 0 12px;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
`;

export const StyledTimeInputFiled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 0;
  color: #1e2024;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &::placeholder {
    color: #9d9d9d;
  }
`;

export const StyledInputField = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  padding: 0 12px;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
`;

export const StyledDayPickContainer = styled.div`
  position: relative;
  left: -135px;
`;

export const StyledInputFieldWithHeight = styled(StyledInputField)`
  height: 100%;
  align-items: flex-start;
`;

export const StyledFilterInputFieldText = styled.input`
  /* width: 100%; */
  border: none;
  outline: none;
  border-radius: 0;
  padding: 0;
  color: #1e2024;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &::placeholder {
    color: #9d9d9d;
  }
`;

export const StyledFilterTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 0;
  color: #1e2024;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  resize: none;

  &::placeholder {
    color: #9d9d9d;
  }
`;

export const StyledInputFieldText = styled.div`
  min-width: 87px;
  color: #1e2024;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

export const StyledRadioOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  border-radius: 50%;
`;

export const StyledRadioWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`;

export const StyledRadioBtnContainer = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
`;

export const StyledSelectedRadioBackground = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  background: #412cab;
  border-radius: 50%;
`;

export const StyledSelectedRadioIcon = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 50%;
`;

export const StyledUnSelectedRadioBackground = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
`;

export const StyledRadioLabel = styled.div`
  text-align: center;
  color: black;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 16.9px;
  word-wrap: break-word;
`;

export const StyledNoticeTemplateDatePickerWrapper = styled.div`
  position: relative;
  top: 2px;
  left: 120px;
`;

export const StyledCheckbox = styled.div`
  width: 79px;
  height: 28px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #efefef;
  gap: 6px;
  position: relative;
  cursor: pointer;
`;

export const StyledCheckboxInput = styled.input`
  appearance: none;
  width: 14px;
  height: 14px;
  padding: 0px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  &:checked {
    background-color: #412cab;
    border-color: #412cab;
  }
`;

export const StyledCheckboxIcon = styled(PiCheckBold)`
  position: absolute;
  top: 50%;
  left: 18%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
  visibility: ${({ $isChecked }) => ($isChecked ? 'visible' : 'hidden')};
  cursor: pointer;
`;

export const StyledCheckboxLabel = styled.div`
  text-align: center;
  color: black;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 15.6px;
  word-wrap: break-word;
`;

// FileInfo
export const StyledFileInfo = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: inline-flex;
`;

export const StyledFileInfoText = styled.div`
  width: 180px;
  color: black;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 15.6px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledFileInfoSubText = styled.div`
  text-align: center;
  color: #7b7b7b;
  font-size: 9px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 11.7px;
  word-wrap: break-word;
`;

export const StyledFileUploadCell = styled(StyledNoticeTemplateTableCell)`
  padding: 9px 12px;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
`;

export const StyledFileUploadWrapper = styled.div`
  height: 28px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
`;

export const StyledFileInputWrapper = styled.div`
  display: none;
`;

export const StyledButtonGroup = styled.div`
  width: 886px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledMappWrapper = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

export const StyledLatLngWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ $isNull }) => $isNull && '#9d9d9d'};
  /* justify-content: center; */
  /* overflow: scroll; */
`;
