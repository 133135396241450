/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../../components/common/PageHeader';
import PostTemplate from './PostTemplate/PostTemplate';
import useModal from '../../../hooks/useModal';
import Modal from '../../../components/common/Modal/Modal';
import CustomButton from '../../../components/common/Button/Button';
import Spinner from '../../../components/common/Spinner/Spinner';
import { postVideoComment } from '../../../apis/video';
import {
  VIDEO_ACTIVE_STATUS,
  VIDEO_HIDDEN_STATUS,
} from '../../../constants/groupCode';

const StyledPostManagementCreatePostContainer = styled.div``;
const StyledPostManagementCreatePostWrapper = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;

const PostManagementCreateCommentVideo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const defaultPostData = {
    isMute: false,
    videoTitle: '',
    isActive: true,
    videoAddress: '',
    createdBy: '',
    videoPath: '',
    timeline: 0,
    videoThumbnail: '',
    latLng: { lat: null, lng: null },
  };

  const [postData, setPostData] = useState(defaultPostData);
  const [fetchData, setFetchData] = useState('');

  const { fetch: fetchPostVideoComment } = postVideoComment({
    query: fetchData.queryString,
    onSuccess: () => {
      closeModal();
      showModal('등록 완료', '새 게시물이 등록되었습니다.', 'confirm');
    },
    onFailure: () => closeModal(),
  });

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  const handleCreateFetchData = (value) => {
    const {
      isMute,
      isActive,
      videoTitle,
      createdBy,
      videoAddress,
      latLng,
      videoPath,
      videoThumbnail,
      timeline,
    } = value;
    const formData = new FormData();

    formData.append('commentVideoFile', videoPath);
    formData.append('videoThumbnail', videoThumbnail);

    const mainVideoIdQuery = `mainVideoId=${id}`;
    const videoTitlQuery = `videoTitle=${videoTitle}`;
    const latQuery = latLng?.lat && `lat=${latLng.lat}`;
    const lngQuery = latLng?.lng && `lng=${latLng.lng}`;
    const videoAddressQuery = `videoAddress=${videoAddress}`;
    const createdByQuery = `createdBy=${createdBy}`;
    const isMuteQuery = `isMute=${isMute}`;
    const isActiveQuery = `isActive=${isActive}`;
    const isActiveStatusQuery = `isActiveStatus=${
      isActive ? VIDEO_ACTIVE_STATUS : VIDEO_HIDDEN_STATUS
    }`;

    const timelineQuery = `timeline=${timeline}`;

    const queryString = [
      mainVideoIdQuery,
      videoTitlQuery,
      latQuery,
      lngQuery,
      videoAddressQuery,
      createdByQuery,
      isMuteQuery,
      isActiveQuery,
      timelineQuery,
      isActiveStatusQuery,
    ]
      .filter(Boolean)
      .join('&');

    return { queryString, formData };
  };

  const handleRegister = (data) => {
    if (
      data.videoTitle.trim() === '' ||
      data.createdBy.trim() === '' ||
      data.videoAddress.trim() === '' ||
      !data.videoPath ||
      data.videoThumbnail === ''
    ) {
      showModal('내용을 입력해주세요', '', 'input');
    } else {
      setFetchData(handleCreateFetchData(data));
    }
  };

  const handleResetConfirm = () => {
    setPostData(defaultPostData);
    closeModal();
  };

  const handleReset = () => {
    showModal(
      '초기화 하시겠습니까?',
      '초기화 시 입력하신 모든 내용이 삭제됩니다.',
      'alert',
    );
  };

  const returnPage = useCallback(() => {
    const locationParams = new URLSearchParams(location.search.toString());
    const filterQuery = locationParams.toString();

    navigate(`/PostManagement/PostManagementDetail/${id}?${filterQuery}`);
  }, []);

  const handleRegisterConfirm = () => returnPage();

  const handleReturnPageClick = () => returnPage();

  useEffect(() => {
    const { formData, queryString } = fetchData;
    if (!queryString) return;
    showModal('게시물 등록중', '', 'loading');
    fetchPostVideoComment(formData);
  }, [fetchData]);

  return (
    <StyledPostManagementCreatePostContainer>
      <PageHeader title="게시물 생성" returnPage={handleReturnPageClick} />
      <StyledPostManagementCreatePostWrapper>
        <PostTemplate
          postData={postData}
          onCancel={handleReset}
          onSave={handleRegister}
          cancelButtonText="초기화"
          saveButtonText="등록"
        />
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalTitle}
          content={<div style={{ textAlign: 'center' }}>{modalContent}</div>}
          actions={
            modalVariant === 'alert' ? (
              <>
                <CustomButton
                  size="large"
                  $state="default"
                  text="취소"
                  $variant="cancel"
                  onClick={closeModal}
                />
                <CustomButton
                  size="large"
                  $state="default"
                  text="초기화"
                  $variant="alert"
                  onClick={handleResetConfirm}
                />
              </>
            ) : modalVariant === 'confirm' ? (
              <CustomButton
                size="large"
                $state="default"
                $variant="confirm"
                text="확인"
                onClick={handleRegisterConfirm}
              />
            ) : modalVariant === 'loading' ? (
              <Spinner />
            ) : (
              <CustomButton
                size="large"
                $state="default"
                text="확인"
                $variant="alert"
                onClick={closeModal}
              />
            )
          }
        />
      </StyledPostManagementCreatePostWrapper>
    </StyledPostManagementCreatePostContainer>
  );
};

export default PostManagementCreateCommentVideo;
