/* eslint-disable no-unused-vars */
import CustomButton from '../../../../../components/common/Button/Button';
import {
  StyledFileUploadCell,
  StyledFileUploadWrapper,
  StyledFileInfo,
  StyledFileInfoText,
  StyledFileInfoSubText,
} from '../AlarmTemplate.styles';

const AlarmTemplateSetLink = ({
  noticeTitle,
  handleDeleteConectedId,
  handleOpenModal,
}) => {
  return (
    <StyledFileUploadCell>
      <StyledFileUploadWrapper>
        <CustomButton
          size="small"
          $state="default"
          text="연결"
          $variant="fileSelect"
          onClick={handleOpenModal}
        />
        <StyledFileInfo>
          <StyledFileInfoText $width="405px">
            {noticeTitle ? `${noticeTitle}` : '선택된 내용 없음'}
          </StyledFileInfoText>
          <StyledFileInfoSubText>
            클릭시 이동할 상세페이지를 연결해 주세요.
          </StyledFileInfoSubText>
        </StyledFileInfo>
      </StyledFileUploadWrapper>
      <CustomButton
        size="small"
        $state="default"
        text="삭제"
        $variant="mediaDelete"
        onClick={handleDeleteConectedId}
      />
    </StyledFileUploadCell>
  );
};

export default AlarmTemplateSetLink;
