/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categoryId: null,
  categoryName: '',
  isAdding: null,
};

const regionCategorySlice = createSlice({
  name: 'regionCategory',
  initialState,
  reducers: {
    selectCategory: (state, action) => {
      state.categoryId = action.payload;
    },
    setAddMode: (state, action) => {
      state.isAdding = action.payload;
    },
    setCategoryName: (state, action) => {
      state.categoryName = action.payload;
    },
    regionCategoryReset: () => initialState,
  },
});

export const {
  selectCategory,
  setAddMode,
  setCategoryName,
  regionCategoryReset,
} = regionCategorySlice.actions;

export default regionCategorySlice.reducer;
