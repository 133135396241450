/* eslint-disable no-nested-ternary */

import styled from 'styled-components';

export const StyledCommonInputContainer = styled.div`
  position: relative;
`;

export const StyledInputFieldInput = styled.input`
  border: 1px solid
    ${({ $state }) =>
      $state === 'error'
        ? '#F76659'
        : $state === 'focus'
        ? '#1A1A1A'
        : '#DDDFE3'};
  color: ${({ $state }) => ($state === 'error' ? '#F76659' : '#1e2024')};

  &:focus {
    border-color: #1a1a1a;
    outline: none;
  }

  &::placeholder {
    color: #9d9d9d;
    word-wrap: break-word;
  }
`;

export const StyledCountMaxLength = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;
