import { StyledUserTypeFilterContainer } from './UserSearchFilter.styles';
import Checkbox from './Checkbox';

const UserTypeFilter = ({
  userTypeValue,
  userTypeOptions,
  handleUserTypeChange,
}) => {
  return (
    <StyledUserTypeFilterContainer>
      <Checkbox
        label="유형"
        options={userTypeOptions}
        value={userTypeValue}
        handleChange={handleUserTypeChange}
      />
    </StyledUserTypeFilterContainer>
  );
};

export default UserTypeFilter;
