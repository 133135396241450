/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputField from '../../../../components/common/InputField/InputField';
import CustomButton from '../../../../components/common/Button/Button';
import Dropdown from '../../../../components/common/DropDown/DropDown';
import { getGroupCode } from '../../../../utils/groupCode';
import {
  VIDEO_ACTIVE_STATUS,
  VIDEO_HIDDEN_STATUS,
  VIDEO_STATUS,
} from '../../../../constants/groupCode';

const UpdateActvieStatusModal = ({ selectIds, handleChangeVideoStatus }) => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const groupCodeValue = getGroupCode(groupCodeList, VIDEO_STATUS).codes.filter(
    ({ fullCode }) =>
      Number(fullCode) === VIDEO_ACTIVE_STATUS ||
      Number(fullCode) === VIDEO_HIDDEN_STATUS,
  );
  const statusOptions = groupCodeValue.map(({ fullCode }) => Number(fullCode));
  const statusOptionsValue = groupCodeValue.reduce((acc, item) => {
    acc[Number(item.fullCode)] = item.codeName;
    return acc;
  }, {});

  return (
    <StyledDeleteAccountModalContainer>
      <StyledDeleteAccountModalSubTitle>
        {!selectIds
          ? '전체 리스트의 영상 게시 상태가 변경됩니다.'
          : `선택하신 (${selectIds}개) 영상 게시 상태가 변경됩니다.`}
      </StyledDeleteAccountModalSubTitle>
      <StyledDeleteAccountModalInput>
        <Dropdown
          minWidth="100%"
          height="48px"
          options={statusOptions}
          renderItem={(value) => statusOptionsValue[value] || value}
          selectedValue="영상 게시 상태를 선택해주세요."
          onOptionChange={handleChangeVideoStatus}
        />
      </StyledDeleteAccountModalInput>
    </StyledDeleteAccountModalContainer>
  );
};

const StyledDeleteAccountModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledDeleteAccountModalSubTitle = styled.div`
  text-align: center;
`;

const StyledDeleteAccountModalInput = styled.div`
  margin: 10px 0;
`;
const StyledDeleteAccountModalButtons = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 12px;
`;
const StyledInputFieldErrorMessage = styled.div`
  color: #f76659;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
`;

export default UpdateActvieStatusModal;
