/* eslint-disable import/prefer-default-export */
import useFetch from '../../hooks/useFetch';

export const postAuthLogin = ({ onSuccess, onFailure }) => {
  const { fetch } = useFetch({
    url: '/auth/login',
    method: 'post',
    onSuccess,
    onFailure,
  });

  return { fetch };
};
