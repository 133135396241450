/* eslint-disable consistent-return */
import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

const getUserMy = () => {
  const { data, fetch, isLoading } = useFetch({
    url: '/user/my',
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  if (!data || isLoading) return null;

  if (!data)
    console.log('요청은 성공했는데 유저 데이터가 없는 경우 처리 과정 필요');

  return data;
};

export default getUserMy;
