/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-catch */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TokenManager from '../../api/tokenManger';
import API from '../../api';

const initialState = {
  regionCategoryList: [],
  initialSort: 0,
  error: null,
};

export const asyncGetRegionCategoryList = createAsyncThunk(
  'asyncThunk/asyncGetRegionCategoryList',
  async () => {
    const tokenManager = new TokenManager();
    try {
      const response = await API('/system/region/category', {
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization:
            tokenManager.accessToken && `Bearer ${tokenManager.accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const regionCategoryListSlice = createSlice({
  name: 'regionCategoryList',
  initialState,
  reducers: {
    addRegionCategoryList: (state) => {
      const ADD_CATEGORY_ID = 'ADD_CATEGORY_ID';
      const newCategory = {
        categoryId: ADD_CATEGORY_ID,
        sort: state.regionCategoryList.length,
        categoryName: '',
      };
      state.regionCategoryList.push(newCategory);
    },
    deleteRegionCategoryList: (state, action) => {
      const deletedCategoryId = action.payload;
      const updatedList = state.regionCategoryList.filter(
        (category) => category.categoryId !== deletedCategoryId,
      );
      state.regionCategoryList = updatedList;
    },

    updateRegionCategoryOrderList: (state, action) => {
      state.regionCategoryList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetRegionCategoryList.fulfilled, (state, action) => {
        const { locationCategoryListDto, initialValue } = action.payload;

        state.regionCategoryList = locationCategoryListDto;
        state.initialSort = initialValue;
      })
      .addCase(asyncGetRegionCategoryList.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const {
  addRegionCategoryList,
  deleteRegionCategoryList,
  setReginCategoryOrderList,
  updateRegionCategoryOrderList,
} = regionCategoryListSlice.actions;

export default regionCategoryListSlice.reducer;
