import {
  StyledPostListTableTitleWrapper,
  StyledPostListTableTitleText,
} from './PostList.styles';

const PostListTableTitle = ({ totalPosts }) => {
  return (
    <StyledPostListTableTitleWrapper>
      <StyledPostListTableTitleText>게시물 목록</StyledPostListTableTitleText>
      <StyledPostListTableTitleText>{totalPosts}</StyledPostListTableTitleText>
    </StyledPostListTableTitleWrapper>
  );
};

export default PostListTableTitle;
