import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import CustomButton from '../../../../components/common/Button/Button';
import Modal from '../../../../components/common/Modal/Modal';

import useModal from '../../../../hooks/useModal';

import ChangeAccessItem from './components/ChangeAccessItem';
import AddNewAdminHeader from './components/AddNewAdminHeader';
import AddNewAdminBadgeDropdown from './components/AddNewAdminBadgeDropdown';
import AddNewAdminItem from './components/AddNewAdminItem';

import { validatePassword, validateEmail } from '../../../../utils/validate';

import {
  StyledAddNewAdminContainer,
  StyledAddNewAdminContentsWrapper,
  StyledAddNewAdminInfoLabel,
  StyledAddNewAdminAccessRow,
  StyledAddNewAdminAccessValue,
  StyledPasswordChangeWrapper,
  StyledPasswordInput,
  StyledInput,
  StyledEmailInput,
} from './AddNewAdmin.styles';

import { adminLevelList } from '../../../../constants/adminLevel';
import {
  getAccountAccessPermissions,
  postAccunt,
} from '../../../../apis/accountManagement';

const AddNewAdmin = () => {
  // const location = useLocation();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.user.user);

  const [formData, setFormData] = useState({
    userName: '',
    selectedType: '',
    position: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [formErrors, setFormErrors] = useState({
    userName: '',
    selectedType: '',
    email: '',
    password: '',
  });

  const [accessRanges, setAccessRanges] = useState([]);
  const [accessRangesError, setAccessRangesError] = useState('');

  const [isFormValid, setIsFormValid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const accountAccessPermissions = getAccountAccessPermissions(null);

  useEffect(() => {
    if (!accountAccessPermissions) return;

    setAccessRanges(
      accountAccessPermissions.map((item) => ({ ...item, isActive: false })),
    );
  }, [accountAccessPermissions]);

  useEffect(() => {
    if (!accountAccessPermissions) return;

    setAccessRanges(
      accountAccessPermissions
        .filter(
          (item) =>
            formData.selectedType !== 100993 ||
            item.routerPath !== 'accountmanagement',
        )
        .map((item) => ({ ...item, isActive: false })),
    );
  }, [formData.selectedType]);

  const createAccount = postAccunt({
    onSuccess: () => {
      setIsSuccess(false);
      showModal('새로운 관리자 생성이 완료되었습니다.', '', 'confirm');
      setIsSuccess(true);
    },
    onFailure: () => {
      setIsSuccess(false);
      showModal(
        '오류',
        '관리자 생성에 실패하였습니다. 다시 시도해주세요.',
        'alert',
      );
    },
  });

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  useEffect(() => {
    checkFormValidity();
  }, [formData, accessRanges]);

  const checkFormValidity = () => {
    const { userName, selectedType, email, password, confirmPassword } =
      formData;
    const isEmailValid = validateEmail(email);
    const { isValid: isPasswordValid } = validatePassword(
      password,
      confirmPassword,
    );

    setIsFormValid(
      userName &&
        selectedType &&
        isEmailValid &&
        isPasswordValid &&
        accessRanges.filter((range) => range.isActive).length > 0 &&
        Object.values(formErrors).every((error) => error === ''),
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === 'userName') {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        userName: value ? '' : '이름을 입력하여 주세요.',
      }));
    } else if (name === 'email') {
      if (!value) {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          email: '이메일을 입력하여 주세요.',
        }));
      } else if (!validateEmail(value)) {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          email: '정상적인 이메일 형식이 아닙니다.',
        }));
      } else {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          email: '',
        }));
      }
    } else if (name === 'password' || name === 'confirmPassword') {
      const { isValid, message } = validatePassword(
        value,
        name === 'password' ? formData.confirmPassword : formData.password,
      );
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        password: isValid ? '' : message,
      }));
    }
  };

  const handleSelectedTypeChange = (option) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedType: option,
    }));
  };

  const handleAccessRangeChange = (id) => {
    const updatedAccessRanges = accessRanges.map((range) =>
      range.featureUuid === id
        ? { ...range, isActive: !range.isActive }
        : range,
    );

    setAccessRanges(updatedAccessRanges);
    setAccessRangesError(
      updatedAccessRanges.filter((range) => range.isActive).length === 0
        ? '접근 허용 범위를 최소 1개 이상 체크 하여 주세요.'
        : '',
    );
  };

  const handleCreate = () => {
    const newAdminData = {
      name: formData.userName,
      level: formData.selectedType,
      department: formData.position,
      email: formData.email,
      password: formData.password,
      accessPermissionScopeDtoList: accessRanges.map(
        ({ featureUuid, isActive }) => ({
          accessPermissionFeatureId: featureUuid,
          isActive,
        }),
      ),
    };
    createAccount(newAdminData);
  };

  const createActions = (
    <CustomButton
      size="large"
      $state="default"
      text="확인"
      $variant={modalVariant}
      onClick={() => {
        closeModal();
        if (isSuccess) {
          navigate('/AccountManagement', {
            state: { activeTab: 'adminInfo' },
          });
        }
      }}
    />
  );

  const infoItems = [
    {
      label: '이름',
      value: (
        <>
          <StyledInput
            type="text"
            name="userName"
            value={formData.userName}
            onChange={handleInputChange}
            placeholder="이름 입력"
            style={{
              borderColor: formErrors.userName ? 'red' : '',
            }}
          />
          {formErrors.userName && (
            <div style={{ color: 'red', fontSize: '13px' }}>
              {formErrors.userName}
            </div>
          )}
        </>
      ),
    },
    {
      label: '관리자 권한',
      value: (
        <StyledPasswordChangeWrapper>
          <>
            <AddNewAdminBadgeDropdown
              options={adminLevelList.filter((item) => item > userInfo.role)}
              initialOption={formData.selectedType}
              onOptionChange={handleSelectedTypeChange}
              minWidth="143px"
              style={{
                borderColor: formErrors.selectedType ? 'red' : '',
              }}
            />
            <StyledInput
              type="text"
              name="position"
              value={formData.position}
              onChange={handleInputChange}
              placeholder="담당파트 입력"
            />
            {formErrors.selectedType && (
              <div style={{ color: 'red', fontSize: '13px' }}>
                {formErrors.selectedType}
              </div>
            )}
          </>
        </StyledPasswordChangeWrapper>
      ),
    },
    {
      label: '이메일',
      value: (
        <>
          <StyledEmailInput
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="이메일 입력"
            style={{
              borderColor: formErrors.email ? 'red' : '',
            }}
          />
          {formErrors.email && (
            <div style={{ color: 'red', fontSize: '13px' }}>
              {formErrors.email}
            </div>
          )}
        </>
      ),
    },
    {
      label: '비밀번호',
      value: (
        <StyledPasswordChangeWrapper>
          <StyledPasswordInput
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="비밀번호 입력"
            style={{
              borderColor: formErrors.password ? 'red' : '',
            }}
          />
          <>
            <StyledPasswordInput
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="비밀번호 재입력"
              style={{
                borderColor: formErrors.password ? 'red' : '',
              }}
            />
            {formErrors.password && (
              <div style={{ color: 'red', fontSize: '13px' }}>
                {formErrors.password}
              </div>
            )}
          </>
        </StyledPasswordChangeWrapper>
      ),
    },
  ];

  return (
    <div>
      <AddNewAdminHeader />
      <StyledAddNewAdminContainer>
        <StyledAddNewAdminContentsWrapper>
          {infoItems.map((item) => (
            <AddNewAdminItem
              key={item.label}
              label={item.label}
              value={item.value}
            />
          ))}
          <StyledAddNewAdminAccessRow>
            <StyledAddNewAdminInfoLabel>
              접근 허용 범위
            </StyledAddNewAdminInfoLabel>
            <StyledAddNewAdminAccessValue>
              <ChangeAccessItem
                accessRanges={accessRanges}
                onAccessRangeChange={handleAccessRangeChange}
              />
              {accessRangesError && (
                <div style={{ color: 'red', fontSize: '13px' }}>
                  {accessRangesError}
                </div>
              )}
            </StyledAddNewAdminAccessValue>
          </StyledAddNewAdminAccessRow>
        </StyledAddNewAdminContentsWrapper>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <CustomButton
            $variant="save"
            $state="default"
            size="medium"
            text="생성"
            onClick={handleCreate}
            disabled={!isFormValid}
          />
        </div>
      </StyledAddNewAdminContainer>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={createActions}
      />
    </div>
  );
};

export default AddNewAdmin;
