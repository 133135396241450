import { useRef, useEffect } from 'react';
import CustomDateRangePicker from '../../../../components/common/DateRangePicker/CustomDateRangePicker';
import { formatDateYYYYMMDD } from '../../../../utils/formatDate';
import {
  StyledJoinPeriodContainer,
  StyledJoinPeriodInput,
  StyledJoinPeriodInputText,
  StyledJoinPeriodToggle,
  StyledJoinPeriodToggleButton,
  StyledJoinPeriodToggleButtonLabel,
  StyledLabel,
  StyledDatePickerWrapper,
  StyledLabelWrapper,
  StyledJoinPeriodWrapper,
} from './PostSearchFilter.styles';

const JoinPeriod = ({
  isAllPeriod,
  showDatePicker,
  startDate,
  endDate,
  toggleAllPeriod,
  handleDirectSettingClick,
  handleDateChange,
  handleCancel,
  handleConfirm,
}) => {
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        handleCancel();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleCancel]);

  const getFormattedDate = () => {
    if (isAllPeriod) {
      return `2024/07/01 - ${formatDateYYYYMMDD(new Date())}`;
    }
    if (startDate && endDate) {
      return `${formatDateYYYYMMDD(startDate)} - ${formatDateYYYYMMDD(
        endDate,
      )}`;
    }
    return '';
  };

  return (
    <StyledJoinPeriodContainer>
      <StyledLabelWrapper>
        <StyledLabel>게시일자</StyledLabel>
      </StyledLabelWrapper>
      <StyledJoinPeriodWrapper>
        <StyledJoinPeriodInput
          $active={isAllPeriod}
          onClick={handleDirectSettingClick}
        >
          <StyledJoinPeriodInputText $active={isAllPeriod}>
            {getFormattedDate()}
          </StyledJoinPeriodInputText>
        </StyledJoinPeriodInput>

        <StyledJoinPeriodToggle>
          <StyledJoinPeriodToggleButton
            $active={isAllPeriod}
            onClick={toggleAllPeriod}
          >
            <StyledJoinPeriodToggleButtonLabel $active={isAllPeriod}>
              전체기간
            </StyledJoinPeriodToggleButtonLabel>
          </StyledJoinPeriodToggleButton>

          <StyledJoinPeriodToggleButton
            $active={!isAllPeriod}
            onClick={handleDirectSettingClick}
          >
            <StyledJoinPeriodToggleButtonLabel $active={!isAllPeriod}>
              직접설정
            </StyledJoinPeriodToggleButtonLabel>
          </StyledJoinPeriodToggleButton>
        </StyledJoinPeriodToggle>
        {showDatePicker && (
          <StyledDatePickerWrapper ref={datePickerRef}>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              onCancel={handleCancel}
              onConfirm={handleConfirm}
              selectsRange
            />
          </StyledDatePickerWrapper>
        )}
      </StyledJoinPeriodWrapper>
    </StyledJoinPeriodContainer>
  );
};

export default JoinPeriod;
