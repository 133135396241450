import {
  StyledPublishedVideoListTableTitleWrapper,
  StyledPublishedVideoListTableTitleText,
} from './PublishedVideoList.styles';

const PublishedVideoListTableTitle = ({ totalVideos }) => {
  return (
    <StyledPublishedVideoListTableTitleWrapper>
      <StyledPublishedVideoListTableTitleText>
        영상 목록
      </StyledPublishedVideoListTableTitleText>
      <StyledPublishedVideoListTableTitleText>
        {totalVideos}
      </StyledPublishedVideoListTableTitleText>
    </StyledPublishedVideoListTableTitleWrapper>
  );
};

export default PublishedVideoListTableTitle;
