/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import { useMemo } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import {
  StyledPaginationWrapper,
  StyledPaginationPageButton,
  StyledPaginationPageNumber,
  StyledPaginationEllipsisButton,
} from './Pagination.styles';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  if (totalPages === 1 || !totalPages) {
    return null; // 토탈 페이지가 1인 경우 페이지네이션 컴포넌트를 렌더링하지 않음
  }

  // TODO: 해당 부분 추후 수정해서 사용해 주세요.
  const pageNumbers = useMemo(() => {
    const maxVisiblePages = 5;
    const middlePage = Math.floor(maxVisiblePages / 2) + 1;
    let startPage = Math.max(1, currentPage - middlePage + 1);
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    const pages = [];

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) {
        pages.push('...');
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push('...');
      }
      pages.push(totalPages);
    }

    return pages;
  }, [currentPage, totalPages]);

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  return (
    <StyledPaginationWrapper>
      <StyledPaginationPageButton
        onClick={handlePrevPage}
        disabled={isFirstPage}
      >
        <MdChevronLeft size={16} />
      </StyledPaginationPageButton>
      {pageNumbers.map((number, index) =>
        number === '...' ? (
          <StyledPaginationEllipsisButton key={`ellipsis-${index}`}>
            ...
          </StyledPaginationEllipsisButton>
        ) : (
          <StyledPaginationPageNumber
            key={number}
            $active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </StyledPaginationPageNumber>
        ),
      )}
      <StyledPaginationPageButton
        onClick={handleNextPage}
        disabled={isLastPage}
      >
        <MdChevronRight size={16} />
      </StyledPaginationPageButton>
    </StyledPaginationWrapper>
  );
};

export default Pagination;

/* 
currentPage: 현재 활성화된 페이지 번호.
totalPages: 전체 페이지 수. 전체 데이터셋의 크기와 각 페이지에 표시될 항목의 수에 따라 계산됩니다.
onPageChange: 페이지 번호가 클릭될 때 호출될 콜백 함수.

상위 컴포넌트에서 적용 예시
const data = [...]; 
const pageSize = 10; 

const exampleComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / pageSize)); // 데이터셋 크기와 페이지 사이즈를 기반으로 총 페이지 수를 계산. 나중에 편하신대로 변경하시면..
    const start = (currentPage - 1) * pageSize; // 현재 페이지에 해당하는 데이터 부분을 계산
    const end = start + pageSize;
    setCurrentData(data.slice(start, end));
  }, [currentPage]); // currentPage가 변경될 때마다 이 로직 실행.

  const onPageChange = (page) => {
    setCurrentPage(page); // 선택된 페이지로 현재 페이지 상태를 업데이트.
  };
  return (
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
  );
};  

*/

// 1.   Pagination 컴포넌트:
//     - 상위 컴포넌트로부터 `currentPage`(현재 페이지), `totalPages`(전체 페이지 수), `onPageChange`(페이지 변경 콜백 함수)를 props로 받아옵니다.
//     - 전체 페이지 수가 1인 경우에는 페이지네이션 컴포넌트를 렌더링하지 않고 `null`을 반환합니다.
// 2. `pageNumbers` 계산:
//     - `useMemo` 훅을 사용하여 페이지 번호 배열을 계산합니다. 이는 `currentPage`와 `totalPages`가 변경될 때만 다시 계산됩니다.
//     - `maxVisiblePages` 변수는 한 번에 표시할 최대 페이지 번호 개수를 나타냅니다.
//     - `startPage`와 `endPage`를 계산하여 표시할 페이지 번호 범위를 결정합니다.
//     - 페이지 번호 배열에는 시작 페이지, 끝 페이지, 그리고 필요한 경우 줄임표(...)를 추가합니다.
// 3. 페이지 변경 핸들러:
//     - `handlePageChange` 함수는 클릭된 페이지 번호를 인자로 받아 `onPageChange` 콜백 함수를 호출합니다.
//     - `handlePrevPage` 함수는 이전 페이지로 이동하는 버튼 클릭 시 호출되며, 현재 페이지가 1보다 큰 경우에만 `onPageChange` 콜백 함수를 호출합니다.
//     - `handleNextPage` 함수는 다음 페이지로 이동하는 버튼 클릭 시 호출되며, 현재 페이지가 전체 페이지 수보다 작은 경우에만 `onPageChange` 콜백 함수를 호출합니다.
// 4. 페이지네이션 렌더링:
//     - `StyledPaginationWrapper` 컴포넌트로 감싸진 페이지네이션을 렌더링합니다.
//     - 이전 페이지 버튼은 `StyledPaginationPageButton` 컴포넌트로 렌더링되며, `handlePrevPage` 함수와 연결되어 있습니다. 현재 페이지가 1인 경우 비활성화됩니다.
//     - `pageNumbers` 배열을 매핑하여 페이지 번호 버튼 또는 줄임표를 렌더링합니다.
//         - 페이지 번호는 `StyledPaginationPageNumber` 컴포넌트로 렌더링되며, 현재 페이지와 일치하는 경우 활성화된 스타일이 적용됩니다. 클릭 시 `handlePageChange` 함수를 호출합니다.
//         - 줄임표는 `StyledPaginationEllipsisButton` 컴포넌트로 렌더링됩니다.
//     - 다음 페이지 버튼은 `StyledPaginationPageButton` 컴포넌트로 렌더링되며, `handleNextPage` 함수와 연결되어 있습니다. 현재 페이지가 전체 페이지 수와 같은 경우 비활성화됩니다.

// 이 페이지네이션 컴포넌트는 현재 페이지, 전체 페이지 수, 페이지 변경 콜백 함수를 받아 동적으로 페이지 번호를 계산하고 렌더링합니다.
// 사용자는 페이지 번호 버튼을 클릭하여 해당 페이지로 이동할 수 있으며, 이전/다음 페이지 버튼을 사용하여 페이지를 탐색할 수 있습니다.
