import { useRef, useState } from 'react';
import CustomButton from '../../../../components/common/Button/Button';
import {
  StyledFileInfo,
  StyledFileInfoSubText,
  StyledFileInfoText,
  StyledFileInputWrapper,
  StyledFileUploadCell,
  StyledFileUploadWrapper,
} from './PostTemplate.styles';

const PostTemplateFileUpload = ({
  selectedFile,
  onFileChange,
  onFileDelete,
  disabled,
  accept,
  fileInfo,
}) => {
  const handleFileDelete = () => {
    onFileDelete();
    document.getElementById('fileInput').value = ''; // 파일 입력 필드 초기화
  };
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileDownload = () => {
    if (!file) return;

    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const inputRef = useRef(null);

  return (
    <StyledFileUploadCell>
      <StyledFileUploadWrapper>
        <CustomButton
          size="small"
          $state="default"
          text="파일선택"
          $variant="fileSelect"
          onClick={() => inputRef.current.click()}
          disabled={disabled}
        />
        <StyledFileInputWrapper>
          <input
            id="fileInput"
            ref={inputRef}
            type="file"
            accept={accept}
            onChange={(e) => {
              onFileChange(e);
              handleFileChange(e);
            }}
            disabled={disabled}
          />
        </StyledFileInputWrapper>
        <StyledFileInfo>
          <StyledFileInfoText>
            {selectedFile ? `${selectedFile.name}` : '선택된 파일 없음'}
          </StyledFileInfoText>
          {fileInfo && (
            <StyledFileInfoSubText>
              {fileInfo}
              {accept && ` / ${accept}`}
            </StyledFileInfoSubText>
          )}
        </StyledFileInfo>
      </StyledFileUploadWrapper>

      <CustomButton
        size="small"
        $state="default"
        text="다운로드"
        $variant="fileDelete"
        onClick={handleFileDownload}
        disabled={disabled}
      />
      <CustomButton
        size="small"
        $state="default"
        text="삭제"
        $variant="mediaDelete"
        onClick={handleFileDelete}
        disabled={disabled}
      />
    </StyledFileUploadCell>
  );
};

export default PostTemplateFileUpload;
