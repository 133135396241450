// 일(DD) 형식으로 날짜 포맷팅 함수
export const formatDateDD = (date) => {
  if (!date) {
    return '';
  }
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}일`;
};

// 월.일(MM.DD) 형식으로 날짜 포맷팅 함수
export const formatDateMMDD = (date) => {
  if (!date) {
    return '';
  }
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${month}.${day}`;
};

// 년 월.일(YYYY.MM.DD) 형식으로 날짜 포맷팅 함수
export const formatDateYYYYMMDD = (inputDate, formatType = '/') => {
  const date = new Date(inputDate);
  if (!date) {
    return '';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${formatType}${month}${formatType}${day}`;
};

export const formatDateYYMMDD = (inputDate, formatType = '/') => {
  const date = new Date(inputDate);
  if (!date) {
    return '';
  }
  const year = date.getFullYear().toString().slice(-2);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${formatType}${month}${formatType}${day}`;
};

export const formatDataHHMMSS = (inputDate) => {
  const date = new Date(inputDate);
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return { hour, minute, second };
};

export const formatDataTotalHHMMSS = (data) => {
  const hour = Math.floor(data / 3600);
  const minute = Math.floor((data % 3600) / 60);
  const second = Math.round(data % 60);

  return { hour, minute, second };
};

export const formatDataHHMMSStoSS = (data) => {
  const { hour, minute, second } = data;
  const hourToSecond = hour * 60 ** 2;
  const minuteToSecond = minute * 60;

  return hourToSecond + minuteToSecond + second;
};

export const formatGroupFullCode = (groupCodes, fullCode) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const group of groupCodes) {
    const { codes } = group;
    const foundCode = codes.find(
      (code) => code.fullCode === fullCode.toString(),
    );
    if (foundCode) {
      return { group, codes, foundCode, codeName: foundCode.codeName };
    }
  }

  return null;
};

export const formatPhoneNumber = (number) =>
  number.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');

export const formatGroupCode = (groupCodes, groupCode) => {
  const foundCode = groupCodes.find(
    (code) => code.groupCode === groupCode.toString(),
  );
  return foundCode;
};

export const formatBirthDate = (birthday) => {
  const birthDate = new Date(
    birthday.substring(0, 4),
    // eslint-disable-next-line radix
    parseInt(birthday.substring(4, 6)) - 1,
    birthday.substring(6, 8),
  );

  const currentDate = new Date();

  const age = currentDate.getFullYear() - birthDate.getFullYear();
  const isBirthdayPassed =
    currentDate.getMonth() > birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() >= birthDate.getDate());

  const adjustedAge = isBirthdayPassed ? age + 1 : age;

  const formattedDate = `${birthDate.getFullYear()}.${(birthDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}.${birthDate
    .getDate()
    .toString()
    .padStart(2, '0')} (만 ${adjustedAge}세)`;

  return formattedDate;
};

export const formatDateYYYYMMDDWHHMMSS = (inputDate) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
  const weekdayIndex = date.getDay();
  const weekday = weekdays[weekdayIndex];

  const formattedDate = `${year}/${month}/${day} (${weekday}) 오후 ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

export const formatYYYYMMDDHHMMSS = (inputDate) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};
export const formatYYYYMMDDHHMM = (inputDate) => {
  const date = new Date(inputDate);

  const year = String(date.getFullYear()).slice(-2);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}`;

  return formattedDate;
};

export const formatEndTime = (inputDate) => {
  const endDate = new Date(inputDate);
  const currentTime = new Date().getTime();
  const endTime = endDate.getTime();

  if (currentTime >= endTime) return 0;
  const year = endDate.getFullYear();
  const month = String(endDate.getMonth() + 1).padStart(2, '0');
  const day = String(endDate.getDate()).padStart(2, '0');
  const { hour, minute, second } = Object.entries(
    formatDataTotalHHMMSS((endTime - currentTime) / 1000),
  ).reduce((acc, [key, value]) => {
    acc[key] = String(value).padStart(2, '0');
    return acc;
  }, {});

  return { year, month, day, hour, minute, second };
};

export const formatDateHHMM = (inputDate) => {
  const date = new Date(inputDate);
  const minute = String(date.getMinutes()).padStart(2, '0');
  const hour = String(date.getHours()).padStart(2, '0');
  return `${hour}:${minute}`;
};

export const formatNumber = (props) => {
  return props.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatDateTime = (dateString, timeObj) => {
  const date = new Date(dateString);
  const { hour, minute, second } = timeObj;

  // 새로운 날짜 객체 생성
  const formattedDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hour ? parseInt(timeObj.hour, 10) : 0,
    minute ? parseInt(timeObj.minute, 10) : 0,
    second ? parseInt(timeObj.second, 10) : 0,
  );

  const year = formattedDate.getFullYear();
  const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const day = String(formattedDate.getDate()).padStart(2, '0');
  const setHour = String(formattedDate.getHours()).padStart(2, '0');
  const setMinute = String(formattedDate.getMinutes()).padStart(2, '0');
  const setSecond = String(formattedDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${setHour}:${setMinute}:${setSecond}`;
};
