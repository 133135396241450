import DashboardHeader from './components/DashboardHeader/DashboardHeader';
import MemberStatus from './components/MemberStatus/MemberStatus';
import PostStatus from './components/PostStatus/PostStatus';

import {
  StyledDashboardContents,
  StyledDashboardTabWrapper,
  StyledDashboardTab,
} from './Dashboard.styles';
import DASHBORD_TABS from '../../constants/dashbordTabs';
import useParams from '../../hooks/useParams';

const Dashboard = () => {
  const tabKey = 'tab';

  const { searchParams, handleChangeParams } = useParams();

  const activeTab = searchParams.get(tabKey) || DASHBORD_TABS[0].type;

  const handleChangeTab = (tab) =>
    handleChangeParams({ key: tabKey, value: tab });

  return (
    <>
      <DashboardHeader />
      <StyledDashboardContents>
        <StyledDashboardTabWrapper>
          {DASHBORD_TABS.map(({ title, type }) => (
            <StyledDashboardTab
              key={type}
              $isActive={activeTab === type}
              onClick={() => handleChangeTab(type)}
            >
              {title}
            </StyledDashboardTab>
          ))}
        </StyledDashboardTabWrapper>
        {activeTab === 'member' && <MemberStatus />}
        {activeTab === 'post' && <PostStatus />}
      </StyledDashboardContents>
    </>
  );
};

export default Dashboard;
