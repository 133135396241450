/* eslint-disable import/no-extraneous-dependencies */
import * as XLSX from 'xlsx';

const xlsxDownload = (
  data,
  fileName = 'Feelky 엑셀 정보',
  sheetName = 'feelky excel data',
) => {
  const excelHandler = {
    getExcelFileName: () => {
      return `${fileName}.xlsx`;
    },
    getSheetName: () => {
      return sheetName;
    },
    getExcelData: () => {
      return data;
    },
    getWorksheet: () => {
      return XLSX.utils.json_to_sheet(excelHandler.getExcelData());
    },
  };

  const datas = excelHandler.getWorksheet();
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, datas, excelHandler.getSheetName());
  XLSX.writeFile(workbook, excelHandler.getExcelFileName());
};

export default xlsxDownload;
