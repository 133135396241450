import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useModal from '../../../../../../hooks/useModal';
import Modal from '../../../../../../components/common/Modal/Modal';
import CustomButton from '../../../../../../components/common/Button/Button';

import {
  getSupportFaqCategory,
  getSupportFaqDetail,
  postSupportFaq,
  postSupportFaqCategory,
  putSupportFaq,
  putSupportFaqCategory,
} from '../../../../../../apis/support';

const RegionalFilter = ({ focusNode, setFocusNode, handleGetCategory }) => {
  const [selectedStatus, setSelectedStatus] = useState('게시 안함');
  const [titleValue, setTitleValue] = useState('');
  const [content, setContent] = useState('');
  const [focusId, isAddItem] = focusNode.id.split(':');

  const disabled = !focusId;

  const isFocuseNode = useMemo(() => focusNode.target === 'node', [focusNode]);

  const { fetch: fetchGetSupportFaqCategory } = getSupportFaqCategory({
    query: focusNode.id,
    isInitialRequest: false,
    onSuccess: (value) => {
      const { title, contents, isActive } = value;
      setTitleValue(title);
      setSelectedStatus(isActive ? '게시' : '게시 안함');
      setContent(contents);
    },
  });

  const { fetch: fetchGetSupportFaqDetail } = getSupportFaqDetail({
    query: focusNode.id,
    isInitialRequest: false,
    onSuccess: (value) => {
      const { title, contents, isActive } = value;
      setTitleValue(title);
      setSelectedStatus(isActive ? '게시' : '게시 안함');
      setContent(contents);
    },
  });

  const { fetch: fetchPostSupportFaqCategory } = postSupportFaqCategory({
    query: {
      titleValue,
      selectedStatus,
      content,
    },
    onSuccess: () => handleGetCategory(),
  });

  const { fetch: fetchPutSupportFaqCategory } = putSupportFaqCategory({
    query: {
      focusId,
      titleValue,
      selectedStatus,
      content,
    },
    onSuccess: () => {
      showModal('입력하신 내용이 저장되었습니다.', '', 'confirm');
      handleGetCategory();
    },
  });

  const { fetch: fetchPostSupportFaq } = postSupportFaq({
    query: {
      focusId,
      titleValue,
      content,
      selectedStatus,
    },
    onSuccess: () => handleGetCategory(),
  });

  const { fetch: fetchPutSupportFaq } = putSupportFaq({
    query: {
      focusId,
      titleValue,
      content,
      selectedStatus,
    },
    onSuccess: () => {
      showModal('입력하신 내용이 저장되었습니다.', '', 'confirm');
      handleGetCategory();
    },
  });

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    setTitleValue(newTitle);
  };

  const handleContentChange = (event) => {
    const newContent = event.target.value;
    setContent(newContent);
  };

  const handleReset = () => {
    showModal(
      '초기화 하시겠습니까?',
      '초기화 시 입력하신 모든 내용이 삭제됩니다.',
      'alert',
    );
  };

  const handleResetConfirm = () => {
    setSelectedStatus('게시 안함');
    setTitleValue('');
    setContent('');
    closeModal();
  };

  const handleSave = () => {
    if (!titleValue) {
      showModal('타이틀을 입력하여 주세요.', '', 'confirm');
      return;
    }

    if (focusNode.target === 'item' && !content) {
      showModal('내용을 입력하여 주세요.', '', 'confirm');
      return;
    }
    handleFetchGateWay();
    handleResetConfirm();
    setFocusNode({ target: '', id: '' });
    showModal('입력하신 내용이 저장되었습니다.', '', 'confirm');
  };

  const handleFetchGateWay = () => {
    const isAddNode = focusNode.id === 'add';
    const isNode = focusNode.target === 'node';
    const isItem = focusNode.target === 'item';

    if (isNode) {
      if (isAddNode) fetchPostSupportFaqCategory();
      else fetchPutSupportFaqCategory();
    } else if (isItem) {
      if (isAddItem) fetchPostSupportFaq();
      else fetchPutSupportFaq();
    }
  };

  useEffect(() => {
    if (isAddItem || focusId === 'add' || !focusId) {
      handleResetConfirm();
      return;
    }
    const isNode = focusNode.target === 'node';
    if (isNode) fetchGetSupportFaqCategory();
    else fetchGetSupportFaqDetail();
  }, [focusNode]);

  return (
    <StyledRegionalFilterContainer>
      <StyledRegionalFilterTable>
        <StyledRegionalFilterHeader>
          <StyledRegionalFilterHeaderCell>
            <StyledRegionalFilterHeaderText>
              게시 상태
            </StyledRegionalFilterHeaderText>
          </StyledRegionalFilterHeaderCell>

          <StyledRegionalFilterBodyCell>
            <StyledStatusContainer
              onClick={() => handleStatusChange('게시')}
              disabled={disabled}
            >
              <StyledRadioButton>
                {selectedStatus === '게시' ? (
                  <>
                    <StyledSelectedBackground />
                    <StyledSelectedIcon />
                  </>
                ) : (
                  <StyledUnselectedBackground />
                )}
              </StyledRadioButton>

              <StyledRadioLabel>게시</StyledRadioLabel>
            </StyledStatusContainer>

            <StyledStatusContainer
              onClick={() => handleStatusChange('게시 안함')}
              disabled={disabled}
            >
              <StyledRadioButton>
                {selectedStatus === '게시 안함' ? (
                  <>
                    <StyledSelectedBackground />
                    <StyledSelectedIcon />
                  </>
                ) : (
                  <StyledUnselectedBackground />
                )}
              </StyledRadioButton>
              <StyledRadioLabel>게시 안함</StyledRadioLabel>
            </StyledStatusContainer>
          </StyledRegionalFilterBodyCell>
        </StyledRegionalFilterHeader>

        <StyledRegionalFilterHeader>
          <StyledRegionalFilterHeaderCell>
            <StyledRegionalFilterHeaderText>
              타이틀
            </StyledRegionalFilterHeaderText>
          </StyledRegionalFilterHeaderCell>
          <StyledRegionalFilterBodyCell>
            <StyledRegionalFilterInputField>
              <StyledRegionalFilterInputFieldText
                value={titleValue}
                onChange={(e) => handleTitleChange(e)}
                disabled={disabled}
                placeholder="타이틀을 입력하여 주세요."
              />
            </StyledRegionalFilterInputField>
          </StyledRegionalFilterBodyCell>
        </StyledRegionalFilterHeader>
        <StyledRegionalFilterHeader>
          <StyledRegionalFilterHeaderCell
            style={{ minHeight: 410, alignItems: 'start' }}
          >
            <StyledRegionalFilterHeaderText $disable={isFocuseNode}>
              내용
            </StyledRegionalFilterHeaderText>
          </StyledRegionalFilterHeaderCell>
          <StyledRegionalFilterBodyCell $disable={isFocuseNode}>
            <StyledRegionalFilterInputField
              style={{ height: '100%', alignItems: 'flex-start' }}
            >
              <StyledFaqInputFieldText
                disabled={disabled}
                value={content || ''}
                onChange={(e) => handleContentChange(e)}
                placeholder="내용을 입력해주세요."
              />
            </StyledRegionalFilterInputField>
          </StyledRegionalFilterBodyCell>
        </StyledRegionalFilterHeader>
      </StyledRegionalFilterTable>
      <StyledButtonContainer>
        <CustomButton
          size="large"
          $state="default"
          $variant="systemReset"
          text="초기화"
          onClick={handleReset}
          disabled={disabled}
        />
        <CustomButton
          size="medium"
          $state="default"
          $variant="systemSave"
          text="저장"
          onClick={handleSave}
          disabled={disabled}
        />
      </StyledButtonContainer>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={<div style={{ textAlign: 'center' }}>{modalContent}</div>}
        actions={
          modalVariant === 'alert' ? (
            <>
              <CustomButton
                size="large"
                $state="default"
                text="취소"
                $variant="cancel"
                onClick={closeModal}
              />
              <CustomButton
                size="large"
                $state="default"
                text="초기화"
                $variant="alert"
                onClick={handleResetConfirm}
              />
            </>
          ) : (
            <CustomButton
              size="large"
              $state="default"
              text="확인"
              $variant="confirm"
              onClick={closeModal}
            />
          )
        }
      />
    </StyledRegionalFilterContainer>
  );
};

export default RegionalFilter;

const StyledRegionalFilterContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: inline-flex;
`;

const StyledRegionalFilterTable = styled.div`
  height: 496px;
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  border: 1px #d9d9d9 solid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const StyledRegionalFilterHeader = styled.div`
  align-self: stretch;
  background: white;
  border-bottom: 1px #d9d9d9 solid;
  justify-content: flex-start;
  align-items: center;

  display: inline-flex;
  &:last-child {
    border-bottom: none;
  }
`;

const StyledRegionalFilterHeaderCell = styled.div`
  width: 140px;
  align-self: stretch;
  padding: 9px 12px;
  opacity: 0.9;
  background: #f5f5f5;
  border-right: 1px #d9d9d9 solid;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
`;

const StyledRegionalFilterHeaderText = styled.div`
  color: ${({ $disable }) => ($disable ? '#9D9D9D' : '#434343')};
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

const StyledRegionalFilterBodyCell = styled.div`
  flex: 1 1 0;
  align-self: stretch;
  padding: 9px 12px;
  background: ${({ $disable }) => ($disable ? '#F5F5F5' : 'white')};
  pointer-events: ${({ $disable }) => $disable && 'none'};
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
`;

const StyledRegionalFilterInputField = styled.div`
  flex: 1 1 0;
  height: 28px;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
`;

const StyledRegionalFilterInputFieldText = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 0;
  color: #9d9d9d;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  color: #1e2024;
  border-radius: 0;

  &::placeholder {
    color: #9d9d9d;
  }
`;

const StyledFaqInputFieldText = styled.textarea`
  height: 100%;
  flex: 1;
  border: none;
  outline: none;
  padding: 0;
  color: #9d9d9d;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  color: #1e2024;
  border-radius: 0;

  &::placeholder {
    color: #9d9d9d;
  }
`;

// 라디오

const StyledStatusContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

const StyledRadioButton = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  cursor: pointer;
`;

const StyledSelectedBackground = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  background: #412cab;
  border-radius: 50%;
`;

const StyledSelectedIcon = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 50%;
`;

const StyledUnselectedBackground = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
`;

const StyledRadioLabel = styled.div`
  text-align: center;
  color: black;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 16.9px;
  word-wrap: break-word;
`;

//

const StyledButtonContainer = styled.div`
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
`;
