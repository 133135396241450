import { useState, useEffect } from 'react';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../../../../../components/common/Button/Button';
import {
  StyledRegionalFilterContainer,
  StyledRegionalFilterTable,
  StyledRegionalFilterHeader,
  StyledRegionalFilterHeaderCell,
  StyledRegionalFilterHeaderText,
  StyledRegionalFilterBodyCell,
  StyledRegionalFilterInputField,
  StyledRegionalFilterInputFieldText,
  StyledRegionalFilterSearchbar,
  StyledRegionalFilterAreaList,
  StyledRegionalFilterAreaItem,
  StyledRegionalFilterAreaText,
  StyledRegionalFilterCheckbox,
  StyledRegionalFilterCheckboxInput,
  StyledRegionalFilterCheckboxIcon,
  StyledRegionalFilterButtonGroup,
} from './RegionalFilter.styles';
import {
  selectCategory,
  setAddMode,
} from '../../../../../store/redux/regionCategorySlice';
import { asyncGetRegionCategoryList } from '../../../../../store/redux/regionCategoryListSlice';
import {
  getSystemRegionCategory,
  getSystemRegionCategoryLocation,
  postSystemRegionCategory,
  putSystemRegionCategory,
} from '../../../../../apis/system';

const RegionalFilter = ({ showModal, setCreateButton, closeModal }) => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector(
    (state) => state.regionCategory.categoryId,
  );
  const selectedCategoryName = useSelector(
    (state) => state.regionCategory.categoryName,
  );
  const isAdding = useSelector((state) => state.regionCategory.isAdding);

  const disabled = !selectedCategory;

  const [categoryName, setCategoryName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchedAreas, setSearchedAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const { fetch: fetchGetSystemRegionCategory } = getSystemRegionCategory({
    query: selectedCategory,
    isInitialRequest: false,
    onSuccess: (data) => {
      const locationLost = data.locationList || [];
      setSelectedAreas(locationLost);
    },
  });
  const { fetch: fetchPostSystemRegionCategory } = postSystemRegionCategory();

  const { fetch: fetchGetSystemRegionCategoryLocation } =
    getSystemRegionCategoryLocation({
      query: searchQuery,
      isInitialRequest: false,
      onSuccess: (data) => {
        setSearchedAreas(data);
      },
    });

  const { fetch: fetchPutSystemRegionCategory } = putSystemRegionCategory();

  const initState = () => {
    setCategoryName('');
    setSelectedAreas([]);
    setSearchQuery('');
    setSearchedAreas([]);
  };

  const handleAreaToggle = (area) => {
    setSelectedAreas((prevSelectedAreas) =>
      prevSelectedAreas.some((selected) => selected === area)
        ? prevSelectedAreas.filter((selected) => selected !== area)
        : [...prevSelectedAreas, area],
    );
  };

  const handleSearch = () => {
    if (!searchQuery) return;
    fetchGetSystemRegionCategoryLocation();
  };

  const handleCategorySave = () => {
    if (!categoryName) {
      showModal('카테고리 명 입력', '카테고리 명을 입력해주세요.', 'confirm');
      setCreateButton([
        {
          size: 'large',
          state: 'default',
          variant: 'confirm',
          text: '확인',
          onClick: () => {
            closeModal();
          },
        },
      ]);
      return;
    }
    showModal('입력하신 내용이 저장되었습니다', '', 'confirm');
    setCreateButton([
      {
        size: 'large',
        state: 'default',
        variant: 'confirm',
        text: '확인',
        onClick: async () => {
          const isCreate = selectedCategory === isAdding;

          if (isCreate) {
            await fetchPostSystemRegionCategory({
              categoryName,
              locationList: selectedAreas,
            });
            dispatch(setAddMode(null));
          } else {
            await fetchPutSystemRegionCategory({
              categoryId: selectedCategory,
              categoryName,
              locationList: selectedAreas,
            });
          }
          dispatch(selectCategory(null));
          dispatch(asyncGetRegionCategoryList());
          initState();
          closeModal();
        },
      },
    ]);
  };

  const handleCategoryReset = () => {
    showModal(
      '초기화 하시겠습니까?',
      '초기화시 입력하신 모든 내용이 삭제됩니다.',
      'reset',
    );
    setCreateButton([
      {
        size: 'large',
        state: 'default',
        text: '취소',
        variant: 'cancel',
        onClick: () => {
          closeModal();
        },
      },
      {
        size: 'large',
        state: 'default',
        text: '확인',
        variant: 'alert',
        onClick: () => {
          initState();
          closeModal();
        },
      },
    ]);
  };

  useEffect(() => {
    if (selectedCategory === null || selectedCategory === isAdding) {
      initState();
    } else {
      fetchGetSystemRegionCategory();
      setCategoryName(selectedCategoryName);
      setSearchedAreas([]);
      setSearchQuery('');
    }
  }, [selectedCategory]);

  return (
    <StyledRegionalFilterContainer>
      <StyledRegionalFilterTable>
        <StyledRegionalFilterHeader>
          <StyledRegionalFilterHeaderCell>
            <StyledRegionalFilterHeaderText>
              카테고리명
            </StyledRegionalFilterHeaderText>
          </StyledRegionalFilterHeaderCell>
          <StyledRegionalFilterBodyCell>
            <StyledRegionalFilterInputField>
              <StyledRegionalFilterInputFieldText
                value={categoryName || ''}
                onChange={(e) => setCategoryName(e.target.value)}
                placeholder="설정하실 지역의 지역명을 입력하여 주세요."
                disabled={disabled}
                maxLength={10}
                style={{
                  color: disabled ? '#9d9d9d' : '#000000',
                }}
              />
            </StyledRegionalFilterInputField>
          </StyledRegionalFilterBodyCell>
        </StyledRegionalFilterHeader>
        <StyledRegionalFilterHeader>
          <StyledRegionalFilterHeaderCell
            style={{ minHeight: 452, alignItems: 'start' }}
          >
            <StyledRegionalFilterHeaderText>
              포함 지역 설정
            </StyledRegionalFilterHeaderText>
          </StyledRegionalFilterHeaderCell>
          <StyledRegionalFilterBodyCell>
            <StyledRegionalFilterSearchbar>
              <StyledRegionalFilterInputField>
                <PiMagnifyingGlass size={20} />
                <StyledRegionalFilterInputFieldText
                  value={searchQuery || ''}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="찾으시는 지역을 입력해주세요. (예. OO시, OO구, OO동)"
                  disabled={disabled}
                />
              </StyledRegionalFilterInputField>
              <CustomButton
                size="medium"
                $variant="change"
                text="검색"
                onClick={handleSearch}
                disabled={disabled}
              />
            </StyledRegionalFilterSearchbar>
            <StyledRegionalFilterAreaList style={{ maxHeight: '149px' }}>
              {selectedAreas.map((area) => (
                <StyledRegionalFilterAreaItem
                  key={area}
                  onClick={() => handleAreaToggle(area)}
                >
                  <StyledRegionalFilterAreaText>
                    <StyledRegionalFilterCheckbox>
                      <StyledRegionalFilterCheckboxInput
                        type="checkbox"
                        checked
                        readOnly
                      />
                      <StyledRegionalFilterCheckboxIcon $isChecked />
                    </StyledRegionalFilterCheckbox>
                    {area}
                  </StyledRegionalFilterAreaText>
                </StyledRegionalFilterAreaItem>
              ))}
            </StyledRegionalFilterAreaList>
            <StyledRegionalFilterAreaList
              style={{ maxHeight: '251px', borderBottom: 'none' }}
            >
              {/* 검색된 지역 목록 */}
              {searchedAreas.map((area) => (
                <StyledRegionalFilterAreaItem
                  key={area}
                  onClick={() => !disabled && handleAreaToggle(area)}
                  disabled={disabled}
                >
                  <StyledRegionalFilterAreaText>
                    <StyledRegionalFilterCheckbox>
                      <StyledRegionalFilterCheckboxInput
                        type="checkbox"
                        checked={selectedAreas.some(
                          (selected) => selected === area,
                        )}
                        readOnly
                      />
                      <StyledRegionalFilterCheckboxIcon
                        $isChecked={selectedAreas.some(
                          (selected) => selected === area,
                        )}
                      />
                    </StyledRegionalFilterCheckbox>
                    {area}
                  </StyledRegionalFilterAreaText>
                </StyledRegionalFilterAreaItem>
              ))}
            </StyledRegionalFilterAreaList>
          </StyledRegionalFilterBodyCell>
        </StyledRegionalFilterHeader>
      </StyledRegionalFilterTable>
      <StyledRegionalFilterButtonGroup>
        <CustomButton
          size="large"
          $state="default"
          $variant="systemReset"
          text="초기화"
          onClick={handleCategoryReset}
          disabled={disabled}
        />
        <CustomButton
          size="medium"
          $state="default"
          $variant="systemSave"
          text="저장"
          onClick={handleCategorySave}
          disabled={disabled}
        />
      </StyledRegionalFilterButtonGroup>
    </StyledRegionalFilterContainer>
  );
};

export default RegionalFilter;
