// BadgeDropdown.js
import Dropdown from '../../../../../components/common/DropDown/DropDown';
import Badge from '../../../../../components/common/Badge';

const AddNewAdminBadgeDropdown = ({
  options,
  initialOption,
  onOptionChange,
  minWidth,
}) => {
  const renderBadge = (option) =>
    option ? <Badge type={option} /> : '관리자 권한';

  return (
    <Dropdown
      options={options}
      selectedValue={initialOption}
      onOptionChange={onOptionChange}
      renderItem={renderBadge}
      minWidth={minWidth}
    />
  );
};

export default AddNewAdminBadgeDropdown;
