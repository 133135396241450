// Dropdown.jsx
import { useState, useEffect } from 'react';

import { PiCaretDown } from 'react-icons/pi';

import {
  StyledDropDownWrapper,
  StyledDropDownButton,
  StyledDropDownContent,
  StyledDropDownPopoverCell,
  StyledDropDownPopoverText,
  StyledDropDownCheckIcon,
} from './DropDown.styles';

const Dropdown = ({
  options,
  selectedValue,
  onOptionChange,
  renderItem,
  minWidth,
  maxHeight,
  height,
  fontSize,
  disabled,
  error = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    selectedValue || options[0],
  );

  useEffect(() => {
    setSelectedOption(selectedValue);
  }, [selectedValue]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onOptionChange(option);
  };

  const handleKeyDown = (event, option) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      handleOptionClick(option);
    }
  };

  return (
    <StyledDropDownWrapper
      $minWidth={minWidth}
      onMouseLeave={() => setIsOpen(false)}
    >
      <StyledDropDownButton
        onClick={() => !disabled && setIsOpen(!isOpen)}
        aria-haspopup="true"
        aria-expanded={isOpen}
        $minWidth={minWidth}
        $height={height}
        $fontSize={fontSize}
        $error={error}
      >
        {renderItem ? renderItem(selectedOption) : selectedOption}
        <PiCaretDown size={16} color={error ? '#f76659' : '#262626'} />
      </StyledDropDownButton>
      {isOpen && !disabled && (
        <StyledDropDownContent
          role="listbox"
          $minWidth={minWidth}
          $maxHeight={maxHeight}
        >
          {options.map((option) => (
            <StyledDropDownPopoverCell
              key={option}
              role="option"
              tabIndex={0}
              aria-selected={selectedOption === option ? 'true' : 'false'}
              onClick={() => handleOptionClick(option)}
              onKeyDown={(event) => handleKeyDown(event, option)}
              $fontSize={fontSize}
            >
              {renderItem ? (
                renderItem(option)
              ) : (
                <StyledDropDownPopoverText>{option}</StyledDropDownPopoverText>
              )}
              {selectedOption === option && <StyledDropDownCheckIcon />}
            </StyledDropDownPopoverCell>
          ))}
        </StyledDropDownContent>
      )}
    </StyledDropDownWrapper>
  );
};

export default Dropdown;
