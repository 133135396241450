/* eslint-disable no-shadow */
import { useCallback, useState } from 'react';
// import { isAxiosError } from 'axios';
import API from '../api';

const useFetch = ({
  url,
  method,
  onSuccess,
  onFailure,
  onFinaly,
  successMessage,
  errorMessage,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const fetch = useCallback(
    // eslint-disable-next-line consistent-return
    async (body) => {
      setLoading(true);
      try {
        const { data } = await API({
          url,
          method,
          data: body,
        });
        setData(data);

        // if (successMessage) console.log('성공');
        if (onSuccess) await onSuccess(data);
      } catch (e) {
        // if (!isAxiosError(e)) return console.log('알수 없는 에러');
        if (onFailure) await onFailure(e);
      } finally {
        if (onFinaly) onFinaly();
        setLoading(false);
      }
    },
    [url, method, onSuccess, onFailure, successMessage, errorMessage],
  );

  return { data, isLoading, fetch };
};

export default useFetch;
