/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import styled from 'styled-components';

const InputNumber = ({ value, onChange, min, max, ...rest }) => {
  const changeNumber = (data) => {
    if (!data) return onChange(data);
    if (data < min) return;
    if (data > max) return;
    onChange(Number(data));
  };
  return (
    <StyledCommonInputContainer>
      <StyledCommonInputButton
        $borderRight
        onClick={() => changeNumber(--value)}
      >
        -
      </StyledCommonInputButton>
      <StyledInputFieldInput
        value={value}
        onChange={(e) => {
          function removeLeadingZero(str) {
            if (str.length > 1 && str[0] === '0') {
              return str.slice(1);
            }
            return str;
          }
          const changeValue = removeLeadingZero(e.target.value);

          changeNumber(changeValue);
        }}
        autoComplete="off"
        type="number"
        min={min}
        max={max}
        $isNull={!value}
        {...rest}
      />
      <StyledCommonInputButton
        $borderLeft
        onClick={() => changeNumber(++value)}
      >
        +
      </StyledCommonInputButton>
    </StyledCommonInputContainer>
  );
};
export default InputNumber;

const StyledCommonInputContainer = styled.div`
  width: 55%;
  height: 100%;
  position: relative;
  display: flex;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
  overflow: hidden;
`;

const StyledCommonInputButton = styled.div`
  width: 36px;
  background: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9d9d9d;
  padding: 0 14px;
  cursor: pointer;
  font-weight: 400;
  font-size: 20px;

  border-left: ${({ $borderLeft }) => $borderLeft && '1px'};
  border-right: ${({ $borderRight }) => $borderRight && '1px'};
  border-style: solid;
  border-color: #d9d9d9;
`;

const StyledInputFieldInput = styled.input`
  border: none;
  outline: none;
  text-align: center;
  border-radius: 0;
  padding: 0;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  transition: 0.3s;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: #9d9d9d;
  }
  color: ${({ $isNull, $state }) =>
    $isNull ? '#9D9D9D' : $state === 'error' ? '#F76659' : '#1e2024'};

  &:focus {
    border-color: #1a1a1a;
    outline: none;
  }

  &::placeholder {
    color: #9d9d9d;
    word-wrap: break-word;
  }
`;
