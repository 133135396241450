// 회원 관리 헤더
import PageHeader from '../../../../components/common/PageHeader';
import UserStats from './UserStats';

const UserManagementHeader = () => {
  return (
    <>
      <PageHeader title="회원 관리" />
      <UserStats />
    </>
  );
};

export default UserManagementHeader;
