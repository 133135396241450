/* eslint-disable no-param-reassign */

import axios from 'axios';
import TokenManager from './tokenManger';

const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

API.interceptors.request.use(async (config) => {
  const tokenManager = new TokenManager();
  config.headers.Authorization = tokenManager.accessToken
    ? `Bearer ${tokenManager.accessToken}`
    : undefined;
  return config;
});

const MAX_RETRY_COUNT = 3;
const retryCounts = new Map();

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const tokenManager = new TokenManager();
    if (error.response && error.response.status === 401) {
      const requestConfig = error.config;
      const requestUrl = requestConfig.url;

      let retryCount = retryCounts.get(requestUrl) || 0;
      if (retryCount < MAX_RETRY_COUNT) {
        retryCount += 1;
        retryCounts.set(requestUrl, retryCount);

        try {
          await tokenManager.reissueToken();
          requestConfig.headers.Authorization = tokenManager.accessToken
            ? `Bearer ${tokenManager.accessToken}`
            : undefined;
          return API(requestConfig);
        } catch (err) {
          tokenManager.deleteToken();
        }
      } else {
        retryCounts.delete(requestUrl);
      }
    }
    if (!tokenManager.isSkipUrl) window.location = '/';
    return Promise.reject(error);
  },
);

export default API;
