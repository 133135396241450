/* eslint-disable radix */
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PAGE_SIZE_OPTIONS } from '../../../../../constants/constants';
import useModal from '../../../../../hooks/useModal';
import CustomButton from '../../../../../components/common/Button/Button';
import {
  StyledNoticeListButtonGroupWrapper,
  StyledNoticeListContainer,
  StyledNoticeListListWrapper,
  StyledNoticeListTableTitleContainer,
  StyledNoticeListWrapper,
  StyledPublishedVideoListInquiryButton,
} from './NoticeList.styles';
import NoticeListTable from './NoticeListTable';
import Pagination from '../../../../../components/common/Pagination/Pagination';
import Modal from '../../../../../components/common/Modal/Modal';
import NoticeListTableTitle from './NoticeListTableTitle';
import { formatDateYYMMDD } from '../../../../../utils/formatDate';
import { delteNotice, getNoticeList } from '../../../../../apis/notice';

const NoticeList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get('page')) || 1;
  const pageSize = parseInt(searchParams.get('size')) || PAGE_SIZE_OPTIONS[0];
  const [posts, setPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [selectedPostIds, setSelectedPostIds] = useState([]);

  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('DESC');

  const handleSetSearchParams = ({ key, value }) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const { fetch: fetchGetNoticeList } = getNoticeList({
    query: {
      currentPage,
      pageSize,
      sortColumn,
      sortDirection,
    },
    onSuccess: (value) => {
      setPosts(
        value.list.map(({ ...item }) => {
          const startDateValue = formatDateYYMMDD(new Date(item.startDate));
          const endDateValue = item.endDate
            ? formatDateYYMMDD(new Date(item.endDate))
            : '종료';

          return {
            ...item,
            id: item.noticeId,
            noticePeriod: `${startDateValue} ~ ${endDateValue}`,
            boardStatus: (
              <StyledPublishedVideoListInquiryButton
                $isInquiry={item.boardStatus !== '게시'}
              >
                {item.boardStatus}
              </StyledPublishedVideoListInquiryButton>
            ),
          };
        }),
      );
      setTotalPosts(value.totalCount);
    },
  });

  const { fetch: fetchDeleteNotice } = delteNotice({
    query: selectedPostIds.length ? 'LIST' : 'ALL',
    onSuccess: () => {
      setSelectedPostIds([]);
      fetchGetNoticeList();
    },
  });

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    handleConfirm,
    closeModal,
  } = useModal();

  const navigate = useNavigate();
  const location = useLocation();

  const handleCreateNoticeClick = () => {
    navigate(`/CustomerService/Notices/CreateNotices${location.search}`);
  };
  const handleSort = useCallback(
    (column) => {
      if (column === sortColumn) {
        setSortDirection((prevDirection) =>
          prevDirection === 'ASC' ? 'DESC' : 'ASC',
        );
      } else {
        setSortColumn(column);
        setSortDirection('ASC');
      }
    },
    [sortColumn],
  );

  const columnsLabel = useMemo(
    () => ({
      boardStatus: '상태',
      boardType: '구분',
      noticePeriod: '공지 기간',
      title: '공지 타이틀',
      createdByName: '생성자',
      viewTargetName: '공지 대상',
    }),
    [],
  );

  const columnsKey = useMemo(
    () => [
      { key: 'boardStatus', width: '60px' },
      { key: 'boardType', width: '80px' },
      { key: 'noticePeriod', width: '180px' },
      { key: 'title', width: '45.60%' },
      { key: 'createdByName', width: '11.29%' },
      { key: 'viewTargetName', width: '11.29%' },
    ],
    [],
  );

  const columns = useMemo(
    () =>
      columnsKey.map(({ ...value }) => ({
        label: columnsLabel[value.key],
        ...value,
      })),
    [],
  );

  const handleRowClick = useCallback(
    (post) => {
      navigate(`/CustomerService/Notices/${post}${location.search}`);
    },
    [navigate, location],
  );

  const handleDeleteClick = useCallback(() => {
    if (selectedPostIds.length === 0) {
      showModal('전체 알림을 삭제 하시겠습니까?', '', 'alert', () =>
        fetchDeleteNotice(),
      );
    } else {
      showModal(
        '삭제 하시겠습니까?',
        '선택하신 알림을 삭제됩니다.',
        'alert',
        () =>
          fetchDeleteNotice({
            noticeList: selectedPostIds,
          }),
      );
    }
  }, [selectedPostIds, showModal]);

  const handleDeleteConfirm = useCallback(() => {
    setSelectedPostIds([]);
    closeModal();
    showModal('삭제 완료 되었습니다.', '', 'confirm');
  }, [closeModal, showModal]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPagePostIds = posts.map((post) => post.id);
      setSelectedPostIds(currentPagePostIds);
    } else {
      setSelectedPostIds([]);
    }
  };

  const handleSelectPost = useCallback((postId) => {
    setSelectedPostIds((prevselectedPostIds) => {
      if (prevselectedPostIds.includes(postId)) {
        return prevselectedPostIds.filter((id) => id !== postId);
      }
      return [...prevselectedPostIds, postId];
    });
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      handleSetSearchParams({ key: 'page', value: page });
      setSelectedPostIds([]);
    },
    [searchParams],
  );

  //   const handlePageSizeChange = useCallback(
  //     (selectedOption) => {
  //       const selectedSize = PAGE_SIZE_OPTIONS.find(
  //         (size) => `${size}개씩 보기` === selectedOption,
  //       );
  //       if (selectedSize) {
  //         handleSetSearchParams({ key: 'page', value: 1 });
  //         handleSetSearchParams({ key: 'size', value: selectedSize });
  //         setSelectedPostIds([]);
  //       }
  //     },
  //     [searchParams],
  //   );

  const modalActions = useMemo(() => {
    return modalVariant === 'alert' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="삭제"
          $variant={modalVariant}
          onClick={() => {
            handleConfirm();
            closeModal();
          }}
        />
      </>
    ) : (
      <CustomButton
        size="large"
        $state="default"
        text="확인"
        $variant={modalVariant}
        onClick={closeModal}
      />
    );
  }, [modalVariant, closeModal, handleDeleteConfirm]);

  return (
    <StyledNoticeListContainer>
      <StyledNoticeListWrapper>
        <StyledNoticeListListWrapper>
          <StyledNoticeListTableTitleContainer>
            <NoticeListTableTitle totalPosts={totalPosts} />
            <StyledNoticeListButtonGroupWrapper>
              {/* <Dropdown
                options={PAGE_SIZE_OPTIONS.map((size) => `${size}개씩 보기`)}
                value={`${pageSize}개씩 보기`}
                selectedValue={`${pageSize}개씩 보기`}
                onOptionChange={handlePageSizeChange}
              /> */}
              <CustomButton
                size="small"
                $state="default"
                text="글쓰기"
                $variant="post"
                onClick={handleCreateNoticeClick}
              />
              <CustomButton
                size="small"
                $state="default"
                text="삭제"
                $variant="delete"
                onClick={handleDeleteClick}
              />
            </StyledNoticeListButtonGroupWrapper>
          </StyledNoticeListTableTitleContainer>
          <NoticeListTable
            posts={posts}
            columns={columns}
            selectedPostIds={selectedPostIds}
            onSelectAll={handleSelectAll}
            onSelectPost={handleSelectPost}
            onRowClick={(id) => handleRowClick(id)}
            onSort={handleSort}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalPosts / pageSize)}
            onPageChange={handlePageChange}
          />
        </StyledNoticeListListWrapper>
      </StyledNoticeListWrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={modalActions}
      />
    </StyledNoticeListContainer>
  );
};

export default NoticeList;
