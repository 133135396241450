export const getFullGroupCode = (groupCodes, fullCode) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const group of groupCodes) {
    const { codes } = group;
    const foundCode = codes.find(
      (code) => code.fullCode === fullCode.toString(),
    );
    if (foundCode) {
      return { group, codes, foundCode, codeName: foundCode.codeName };
    }
  }

  return null;
};

export const getGroupCode = (groupCodes, groupCode) => {
  const foundCode = groupCodes.find(
    (code) => code.groupCode === groupCode.toString(),
  );
  return foundCode;
};
