import { useRef } from 'react';
import CustomButton from '../../../../../components/common/Button/Button';
import {
  StyledFileUploadCell,
  StyledFileUploadWrapper,
  StyledFileInputWrapper,
  StyledFileInfo,
  StyledFileInfoText,
  StyledFileInfoSubText,
} from '../NoticeTemplate.styles';

const NoticeTemplateFileUpload = ({
  selectedFile,
  onFileChange,
  onFileDelete,
  fileAspectRatio,
  disabled,
  height,
}) => {
  const handleFileDelete = () => {
    onFileDelete();
    document.getElementById('fileInput').value = ''; // 파일 입력 필드 초기화
  };

  const handleFileDownload = () => {
    if (!selectedFile) return;
    if (selectedFile.url) {
      window.location.href = selectedFile.url;
    } else {
      const url = URL.createObjectURL(selectedFile);
      const link = document.createElement('a');
      link.href = url;
      link.download = selectedFile.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const inputRef = useRef(null);

  return (
    <StyledFileUploadCell $height={height}>
      <StyledFileUploadWrapper>
        <CustomButton
          size="small"
          $state="default"
          text="파일선택"
          $variant="fileSelect"
          onClick={() => inputRef.current.click()}
          disabled={disabled}
        />
        <StyledFileInputWrapper>
          <input
            id="fileInput"
            ref={inputRef}
            type="file"
            accept=".gif, .png, .jpg"
            onChange={(e) => {
              console.log('file');
              onFileChange(e, fileAspectRatio);
            }}
            disabled={disabled}
          />
        </StyledFileInputWrapper>
        <StyledFileInfo>
          <StyledFileInfoText>
            {selectedFile ? `${selectedFile.name}` : '선택된 파일 없음'}
          </StyledFileInfoText>
          <StyledFileInfoSubText>
            -권장 이미지 : gif, png, jpg
          </StyledFileInfoSubText>
        </StyledFileInfo>
      </StyledFileUploadWrapper>
      <CustomButton
        size="small"
        $state="default"
        text="다운로드"
        $variant="fileDelete"
        onClick={handleFileDownload}
        disabled={disabled}
      />
      <CustomButton
        size="small"
        $state="default"
        text="삭제"
        $variant="mediaDelete"
        onClick={handleFileDelete}
        disabled={disabled}
      />
    </StyledFileUploadCell>
  );
};

export default NoticeTemplateFileUpload;
