/* eslint-disable no-unused-vars */
// AccessRangeItem.jsx

import {
  StyledChangeAccessGridContainer,
  StyledChangeAccessItemWrapper,
  StyledChangeAccessItemContent,
  StyledChangeAccessItemInfo,
  StyledChangeAccessItemIconWrapper,
  StyledChangeAccessItemText,
  StyledChangeAccessItemCheckboxWrapper,
  StyledChangeAccessItemCheckboxInput,
  StyledChangeAccessItemCheckboxIcon,
} from './ChangeAccessItem.styles';

import { ChangeAccessItemConstans } from './ChangeAccessItemConstans';

const AccessRangeItem = ({ accessRanges, onAccessRangeChange }) => {
  // const items = ChangeAccessItemConstans.map((range) => ({
  //   ...range,
  //   isAvailable: accessRanges.includes(range.id),
  // }));

  const handleCheckboxChange = (id) => {
    onAccessRangeChange(id);
  };

  return (
    <StyledChangeAccessGridContainer>
      {accessRanges?.map((item) => {
        const { featureUuid, title, iconUrl, isActive } = item;

        return (
          <StyledChangeAccessItemWrapper
            key={featureUuid + title}
            onClick={() => handleCheckboxChange(featureUuid)}
          >
            <StyledChangeAccessItemContent>
              <StyledChangeAccessItemInfo $isFullWidth>
                <StyledChangeAccessItemIconWrapper>
                  {/* {icon} */}
                  <img src={iconUrl} alt={title} />
                </StyledChangeAccessItemIconWrapper>
                <StyledChangeAccessItemText>{title}</StyledChangeAccessItemText>
              </StyledChangeAccessItemInfo>
              <StyledChangeAccessItemCheckboxWrapper>
                <StyledChangeAccessItemCheckboxInput
                  type="checkbox"
                  checked={isActive}
                  onChange={() => handleCheckboxChange(featureUuid)}
                />
                <StyledChangeAccessItemCheckboxIcon $isChecked={isActive} />
              </StyledChangeAccessItemCheckboxWrapper>
            </StyledChangeAccessItemContent>
          </StyledChangeAccessItemWrapper>
        );
      })}
    </StyledChangeAccessGridContainer>
  );
};

export default AccessRangeItem;
