import styled from 'styled-components';

export const StyledReportListContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledReportListWrapper = styled.div`
  width: 100%;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
`;

export const StyledReportListListWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledReportListTableTitleContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  margin-bottom: 10px;
`;

export const StyledReportListButtonGroupWrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: flex;
`;

export const StyledPublishedVideoListBlueBtn = styled.a`
  color: ${({ $isInquiry }) => ($isInquiry ? '#F76659' : '#0070f3')};
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledPublishedVideoListLinkBtn = styled.a`
  color: '#1A1A1A';
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledPublishedVideoListState = styled.a`
  color: ${({ $isInquiry }) => ($isInquiry ? '#F76659' : '#1A1A1A')};
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

// ReportListTableTitle

export const StyledReportListTableTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledReportListTableTitleText = styled.div`
  color: #262626;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

// ReportListTable
export const StyledReportListTableWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  box-sizing: border-box;
`;
