import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

const getDashboardUsersAge = () => {
  const { data, fetch, isLoading } = useFetch({
    url: `/dashboard/users/age`,
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  if (!data || isLoading) return null;

  return data;
};

export default getDashboardUsersAge;
