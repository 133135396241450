export const USER_TYPE_PRIORITY = {
  master: 4,
  super: 3,
  serviceManager: 2,
  serviceAdmin: 1,
};

export const PAGE_SIZE_OPTIONS = [10, 20, 50];

export const PAGE_SIZE_OPTIONS_DEPTH = [5, 10, 15];

export const DASHBORD_OPTIONS = [1, 7, 30, 60, 180];

export const DASHBORD_OPTIONS_RENDER_ITEM = {
  1: '오늘',
  7: '최근7일',
  30: '최근 30일',
  60: '최근 60일',
  180: '최근 6개월',
};

export const CHART_OPTIONS = [7, 30, 60, 180];

export const CHART_OPTIONS_RENDER_ITEM = {
  7: '최근 7일',
  30: '최근 30일',
  60: '최근 60일',
  180: '최근 6개월',
};

export const AGE_RANGE_OPTIONS = [
  '전체',
  '19 - 24세',
  '25 - 29세',
  '30 - 34세',
  '35 - 39세',
  '40 - 100세',
];
