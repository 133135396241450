/* eslint-disable no-unused-vars */
// Shutdown.jsx
import { useState, useRef, useEffect, useCallback } from 'react';
import AlarmTemplateForm from './AlarmTemplateForm';

const AlarmTemplate = ({
  alarmData,
  isEditable,
  onCancel,
  onSave,
  cancelButtonText = '나가기',
  saveButtonText = '수정',
}) => {
  const [viewTarget, setViewTarget] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [viewType, setViewType] = useState(['알림']);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isPush, setIsPush] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState('');
  const [shortContents, setShortContents] = useState('');
  const [noticeId, setNoticeId] = useState('');
  const [noticeTitle, setNoticeTitle] = useState('');

  useEffect(() => {
    setViewTarget(alarmData.viewTarget || '회원');
    setStartTime(alarmData.startTime || null);
    setEndTime(alarmData.endTime || null);
    setViewType(alarmData.viewType[0] || ['알림']);
    setStartDate(
      alarmData.startDate ? new Date(alarmData.startDate) : new Date(),
    );
    setEndDate(alarmData.endDate ? new Date(alarmData.endDate) : new Date());
    setTitle(alarmData.title || '');
    setSelectedFile(
      alarmData.fileUrl
        ? { name: alarmData.originalFileName, url: alarmData.fileUrl }
        : null,
    );
    setIsPush(alarmData.isPush);
    setShortContents(alarmData.shortContents || '');
    setNoticeId(alarmData.noticeId || '');
    setNoticeTitle(alarmData.noticeTitle || '');
  }, [alarmData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleShortContentsChange = (event) => {
    setShortContents(event.target.value);
  };

  const datePickerRef = useRef(null);

  const handleChangeIsPush = (value) => setIsPush(value);

  const handleUpdateNoticeId = (id) => setNoticeId(id);

  const handleUpdateNoticeTitle = (value) => setNoticeTitle(value);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChangeViewType = (reason) => {
    setViewType([reason]);
  };

  const handleDateChange = (dates) => {
    if (dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  const hanldeDayChange = (date) => {
    setStartDate(date);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
  };

  const handleConfirm = () => {
    setShowDatePicker(false);
  };

  return (
    <AlarmTemplateForm
      viewType={viewType}
      handleChangeViewType={handleChangeViewType}
      viewTarget={viewTarget}
      setViewTarget={setViewTarget}
      title={title}
      handleTitleChange={handleTitleChange}
      shortContents={shortContents}
      handleShortContentsChange={handleShortContentsChange}
      startDate={startDate}
      setStartDate={setStartDate}
      startTime={startTime}
      setStartTime={setStartTime}
      endDate={endDate}
      setEndDate={setEndDate}
      endTime={endTime}
      setEndTime={setEndTime}
      isPush={isPush}
      handleChangeIsPush={handleChangeIsPush}
      noticeId={noticeId}
      handleUpdateNoticeId={handleUpdateNoticeId}
      noticeTitle={noticeTitle}
      handleUpdateNoticeTitle={handleUpdateNoticeTitle}
      selectedFile={selectedFile}
      handleFileChange={handleFileChange}
      handleFileDelete={handleFileDelete}
      isEditable={isEditable}
      onCancel={onCancel}
      cancelButtonText={cancelButtonText}
      handleCancel={handleCancel}
      onSave={() =>
        onSave({
          viewType,
          viewTarget,
          title,
          shortContents,
          startDate,
          startTime,
          endDate,
          endTime,
          isPush,
          noticeId,
          fileUrl: selectedFile,
        })
      }
      saveButtonText={saveButtonText}
      showDatePicker={showDatePicker}
      setShowDatePicker={setShowDatePicker}
      hanldeDayChange={hanldeDayChange}
      handleDateChange={handleDateChange}
      handleConfirm={handleConfirm}
    />
  );
};

export default AlarmTemplate;
