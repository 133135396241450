import styled from 'styled-components';
import RegionalSetting from './components/RegionalSetting/RegionalSetting';
import PageHeader from '../../../components/common/PageHeader';

const StyledPostManagementContainer = styled.div``;
const StyledPostManagementWrapper = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;

const StyledPostManagementContentsWrapper = styled(
  StyledPostManagementWrapper,
)``;

const Faq = () => {
  return (
    <StyledPostManagementContainer>
      <PageHeader title=" 자주 묻는 질문" />
      <StyledPostManagementContentsWrapper>
        <RegionalSetting />
      </StyledPostManagementContentsWrapper>
    </StyledPostManagementContainer>
  );
};

export default Faq;
