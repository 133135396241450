// StyledComponents.js
import styled from 'styled-components';

// index
export const StyledDashboardContents = styled.div``;

export const StyledDashboardTabWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-right: 10px;
  padding-bottom: 21px;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
`;

export const StyledDashboardTab = styled.div`
  flex: 1;
  padding: ${(props) => (props.$isActive ? '10px' : '12px')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => (props.$isActive ? '19px' : '16px')};
  font-weight: ${(props) => (props.$isActive ? '600' : '500')};
  color: ${(props) => (props.$isActive ? '#1E2024' : '#555555')};
  border-bottom: 2px solid
    ${(props) => (props.$isActive ? '#1E2024' : '#C4C4C4')};
`;
