import styled from 'styled-components';

const Spinner = () => {
  return (
    <SpinnerWrapper>
      <span className="loader" />
    </SpinnerWrapper>
  );
};

export default Spinner;

const SpinnerWrapper = styled.div`
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #d9d9d9;
    border-bottom-color: #412cab;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
