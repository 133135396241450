import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

const getUserCount = () => {
  const { data, fetch } = useFetch({
    url: '/user/count',
    method: 'get',
  });
  useEffect(() => {
    fetch();
  }, []);

  return { data, fetch };
};

export default getUserCount;
