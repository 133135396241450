// src/pages/LoginPage/LoginPage.styles.js
import styled from 'styled-components';

export const StyledLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 80px;
  background-color: #f9fafa;
`;

export const StyledLoginWrapper = styled.div`
  padding: 80px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
`;

export const StyledLoginTextWrapper = styled.div`
  color: #1e2024;
  font-size: 28px;
  font-family: 'Pretendard';
  font-weight: 700;
  line-height: 36px;
  word-wrap: break-word;
`;

export const StyledLoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
`;

export const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const StyledLoginInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
`;

export const StyledTopBarLogoWrapper = styled.div`
  /* height: 32px; */
  height: 44px;
  padding-top: 2.14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTopBarLogoIcon = styled.div`
  margin-bottom: 3px;
  margin-left: 10px;
  background: #1a1a1a;
  height: 100%;
  aspect-ratio: 1 / 1;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledTopBarLogoText = styled.div`
  width: 120px;
  height: 100%;

  margin-left: 7.21px;
  filter: invert(100%);
  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledLoginInputErrorMessage = styled.div`
  color: #f76659;
  font-size: 14px;
`;
