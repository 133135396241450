export const adminLevel = {
  100990: 'master',
  100991: 'super',
  // 100992: 'serviceManager',
  100993: 'serviceAdmin',
};

export const adminLevelList = [
  100990, 100991,
  //  100992,
  100993,
];
