/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
// 고객센터 > 문의 및 신고 관리
import { useState, useCallback, useMemo, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useModal from '../../../../../hooks/useModal.js';

import Pagination from '../../../../../components/common/Pagination/Pagination.jsx';
import Modal from '../../../../../components/common/Modal/Modal.jsx';
import Dropdown from '../../../../../components/common/DropDown/DropDown.jsx';
import CustomButton from '../../../../../components/common/Button/Button.jsx';

import ReportListTable from './ReportListTable.jsx';
import ReportListTableTitle from './ReportListTableTitle.jsx';
import VideoDetailModal from '../../../../../components/common/VideoDetailModal/VideoDetailModal.jsx';
import UserDetailModal from './UserDetailModal.jsx';

import { PAGE_SIZE_OPTIONS } from '../../../../../constants/constants.js';

import {
  StyledReportListContainer,
  StyledReportListWrapper,
  StyledReportListListWrapper,
  StyledReportListTableTitleContainer,
  StyledReportListButtonGroupWrapper,
  StyledPublishedVideoListBlueBtn,
  StyledPublishedVideoListState,
  StyledPublishedVideoListLinkBtn,
} from './ReportList.styles.js';

import {
  formatBirthDate,
  formatDateHHMM,
  formatEndTime,
  formatGroupFullCode,
  formatPhoneNumber,
  formatYYYYMMDDHHMMSS,
} from '../../../../../utils/formatDate.js';
import InquiryDetailModal from './InquiryDetailModal.jsx';
import CommentVideoDetailModal from '../../../../../components/common/CommentVideoDetailModal/CommentVideoDetailModal.jsx';
import xlsxDownload from '../../../../../utils/xlsxDownload.js';
import {
  getUserDetailActivity,
  getUserDetailBasicInfo,
} from '../../../../../apis/user/index.js';
import {
  getSupportInquiryDeltail,
  getSupportInquiryList,
} from '../../../../../apis/support/index.js';
import {
  getVideoDetailCommentVideo,
  getVideoDetailMainVideo,
} from '../../../../../apis/video/index.js';

const ReportList = ({ activeTab }) => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get('page')) || 1;
  const pageSize = parseInt(searchParams.get('size')) || PAGE_SIZE_OPTIONS[0];
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const [openModal, setOpenModal] = useState('');
  const [modalData, setModalData] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);

  const [filteredData, setFilteredInquiryList] = useState([]);

  const [sortColumn, setSortColumn] = useState('lastReportDate');
  const [sortDirection, setSortDirection] = useState('ASC');

  const [selectedId, setSelectedId] = useState('');
  const [isExcel, setIsExcel] = useState(false);

  const handleSetSearchParams = ({ key, value }) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const handleSetSeatchParamsList = (list) => {
    const newSearchParams = new URLSearchParams(searchParams);
    list.forEach(({ key, value }) => newSearchParams.set(key, value));
    setSearchParams(newSearchParams);
  };

  const columnsLabel = useMemo(
    () => ({
      reportTypeName: '구분',
      lastReportDate: '신고 일자',
      videoActive: '상태',
      videoTitle: '영상타이틀',
      videoCreatorNickname: '게시자',
      commentsCount: '영상댓글',
      requestCount: '필키요청',
      likeCount: '좋아요',
      viewCount: '조회수',
      reportCount: '신고건수',
      reportStatusName: '신고처리',
    }),
    [],
  );

  const columnsKey = useMemo(
    () => [
      { key: 'reportTypeName', width: '55px' },
      {
        key: 'lastReportDate',

        width: '180px',
        sortable: true,
      },
      { key: 'videoActive', width: '65px' },
      { key: 'videoTitle', width: '25%' },
      { key: 'videoCreatorNickname', width: '10%' },
      {
        key: 'commentsCount',

        width: '110px',
        sortable: true,
      },
      {
        key: 'requestCount',

        width: '110px',
        sortable: true,
      },
      { key: 'likeCount', width: '90px', sortable: true },
      { key: 'viewCount', width: '90px', sortable: true },
      { key: 'reportCount', width: '100px', sortable: true },
      { key: 'reportStatusName', width: '100px' },
    ],
    [],
  );

  const columns = useMemo(
    () =>
      columnsKey.map(({ ...value }) => ({
        label: columnsLabel[value.key],
        ...value,
      })),
    [],
  );

  const { fetch: fetchGetSupportInquiryList } = getSupportInquiryList({
    query: {
      isExcel,
      currentPage,
      sortColumn,
      sortDirection,
      pageSize,
      activeTab,
    },
    onSuccess: (value) => {
      const newData = value.list.map(({ ...item }) => {
        return {
          ...item,
          id: item.targetId,
          lastReportDate: formatYYYYMMDDHHMMSS(item.lastReportDate),
        };
      });
      if (isExcel) {
        const excelData = newData
          .filter(({ uuid }) =>
            selectedId.length ? selectedId.includes(uuid) : true,
          )
          .map((data) => {
            const mappedData = {};
            Object.keys(columnsLabel).forEach((key) => {
              mappedData[columnsLabel[key]] = data[key];
            });
            return mappedData;
          });
        handleExcelDownload(excelData);
        setIsExcel(false);
      } else {
        setFilteredInquiryList(
          newData.map(({ ...item }) => {
            const isMainVideo = item.reportTypeName === '메인';
            return {
              ...item,
              videoActive: (
                <StyledPublishedVideoListState
                  $isInquiry={item.videoActive !== '게시중'}
                >
                  {item.videoActive}
                </StyledPublishedVideoListState>
              ),
              videoTitle: (
                <StyledPublishedVideoListLinkBtn
                  onClick={() => {
                    handleOpenModal(
                      item.targetId,
                      isMainVideo ? 'MAIN' : 'COMMENT',
                    );
                  }}
                >
                  {item.videoTitle}
                </StyledPublishedVideoListLinkBtn>
              ),
              videoCreatorNickname: (
                <StyledPublishedVideoListLinkBtn
                  onClick={() => handleOpenModal(item.videoCreator, 'USER')}
                >
                  {item.videoCreatorNickname}
                </StyledPublishedVideoListLinkBtn>
              ),
              reportStatusName: (
                <StyledPublishedVideoListBlueBtn
                  $isInquiry={
                    item.reportStatusName === '미처리' ||
                    item.reportStatusName === '보류'
                  }
                  onClick={() => {
                    handleOpenModal(item.targetId, 'INQUIRY');
                  }}
                >
                  {item.reportStatusName}
                </StyledPublishedVideoListBlueBtn>
              ),
            };
          }),
        );
        setTotalUsers(value.totalCount);
      }
    },
  });

  const { fetch: fetchGetSupportInquiryDeltail } = getSupportInquiryDeltail({
    query: selectedId,
    isInitialRequest: false,
    onSuccess: (value) => setModalData(value),
  });

  const { fetch: fetchGetVideoDetailMainVideo } = getVideoDetailMainVideo({
    query: selectedId,
    onSuccess: (value) => {
      const {
        timeline,
        meetingLocation,
        meetingCount,
        meetingTime,
        isActive,
        createdAt,
        videoEndTime,
        tags,
        ...data
      } = value;
      const formatedEndTime = formatEndTime(videoEndTime);

      setModalData({
        ...data,
        timeline: `${timeline}초`,
        meetingLocation: meetingLocation || '-',
        meetingCount: meetingCount ? `${meetingCount}명` : '-',
        meetingTime: meetingTime
          ? `${formatDateHHMM(new Date(meetingTime))}에 만나요`
          : '-',
        tags: tags || '-',
        isActive: `${isActive ? '게시중' : '게시 종료'} ${
          formatedEndTime
            ? `( ${formatedEndTime.hour}시간${formatedEndTime.second}분 후 종료 )`
            : '( 종료 )'
        }`,
        createdAt: formatYYYYMMDDHHMMSS(createdAt),
      });
    },
  });

  const { fetch: fetchGetVideoDetailCommentVideo } = getVideoDetailCommentVideo(
    {
      query: selectedId,
      isInitialRequest: false,
      onSuccess: (value) => {
        const { createdAt, isActive, videoEndTime, ...data } = value;

        const formatedEndTime = formatEndTime(videoEndTime);

        setModalData({
          ...data,
          createdAt: formatYYYYMMDDHHMMSS(createdAt),
          isActive: `${isActive ? '게시중' : '게시 종료'} ${
            formatedEndTime
              ? `( ${formatedEndTime.hour}시간${formatedEndTime.second}분 후 종료 )`
              : '( 종료 )'
          }`,
        });
      },
    },
  );

  const { fetch: fetchGetUserDetailBasicInfo } = getUserDetailBasicInfo({
    query: selectedId,
    isInitialRequest: false,
    onSuccess: (value) => {
      const basic = {
        ...value,
        createdAt: formatYYYYMMDDHHMMSS(value.createdAt),
        birthDate: formatBirthDate(value.birthDate),
        phone: formatPhoneNumber(value.phone),
        gender: formatGroupFullCode(groupCodeList, value.gender).codeName,
      };
      setModalData((prev) => ({ ...prev, basic }));
    },
  });

  const { fetch: fetchGetUserDetailActivity } = getUserDetailActivity({
    query: selectedId,
    isInitialRequest: false,
    onSuccess: (value) => {
      const activity = {
        ...value,
        blockedUserCount: `${value.blockedUserCount}회`,
        recvUserVideoReportCount: `${value.recvUserVideoReportCount}회`,
      };
      setModalData((prev) => ({ ...prev, activity }));
    },
  });

  const handleExcelDownload = (data) => {
    xlsxDownload(data, '신고 목록', '신고 목록 리스트');
    showModal('엑셀 다운로드가 완료되었습니다..', '', 'confirm');
  };
  const handleOpenModal = useCallback((id, openModalId) => {
    setOpenModal(openModalId);
    setSelectedId(id);
  }, []);

  const handleRowClick = useCallback(() => {}, []);

  const handleCloseModal = useCallback(() => {
    setModalData(null);
    setOpenModal('');
  }, []);

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  const handleSort = useCallback(
    (column) => {
      if (column === sortColumn) {
        setSortDirection((prevDirection) =>
          prevDirection === 'ASC' ? 'DESC' : 'ASC',
        );
      } else {
        setSortColumn(column);
        setSortDirection('ASC');
      }
    },
    [sortColumn],
  );

  const handleDownloadClick = useCallback(() => {
    if (selectedUserIds.length === 0) {
      showModal(
        '전체 다운로드',
        '전체 리스트를 엑셀로 다운로드 하시겠습니까?',
        'excelConfirm',
      );
    } else {
      showModal(
        '엑셀 다운로드 확인',
        '선택하신 항목 정보를 다운로드 하시겠습니까?',
        'excel',
      );
    }
  }, [selectedUserIds, showModal]);

  const handleDownloadConfirm = useCallback(() => {
    setSelectedUserIds([]);
    closeModal();
  }, [selectedUserIds, closeModal]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageUserIds = filteredData.map((user) => user.id);
      setSelectedUserIds(currentPageUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const handleSelectUser = useCallback((userId) => {
    setSelectedUserIds((prevSelectedUserIds) => {
      if (prevSelectedUserIds.includes(userId)) {
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
      return [...prevSelectedUserIds, userId];
    });
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      handleSetSearchParams({ key: 'page', value: page });
      setSelectedUserIds([]);
      // TODO: 백엔드 API 호출 시 페이지 변경에 따른 데이터 요청 로직 추가해주세요.
      // 예: fetchUsers(page, pageSize);
    },
    [filteredData, searchParams],
  );

  const handlePageSizeChange = useCallback(
    (selectedOption) => {
      const selectedSize = PAGE_SIZE_OPTIONS.find(
        (size) => `${size}개씩 보기` === selectedOption,
      );
      if (selectedSize) {
        handleSetSeatchParamsList([
          { key: 'page', value: 1 },
          { key: 'size', value: selectedSize },
        ]);
        setSelectedUserIds([]);
      }
    },
    [filteredData, searchParams],
  );

  useEffect(() => {
    if (!openModal || !selectedId) return;

    if (openModal === 'INQUIRY') fetchGetSupportInquiryDeltail();
    if (openModal === 'MAIN') fetchGetVideoDetailMainVideo();
    if (openModal === 'COMMENT') fetchGetVideoDetailCommentVideo();
    if (openModal === 'USER') {
      fetchGetUserDetailBasicInfo();
      fetchGetUserDetailActivity();
    }
  }, [openModal, selectedId]);

  const modalActions = useMemo(() => {
    return modalVariant === 'alert' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="다운로드"
          $variant={modalVariant}
          onClick={handleDownloadConfirm}
        />
      </>
    ) : modalVariant === 'excel' ? (
      <CustomButton
        size="large"
        $state="default"
        text="확인"
        $variant={modalVariant}
        onClick={() => {
          setIsExcel(true);
          closeModal();
        }}
      />
    ) : modalVariant === 'excelConfirm' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="다운로드"
          $variant="confirm"
          onClick={() => {
            setIsExcel(true);
            closeModal();
          }}
        />
      </>
    ) : (
      <CustomButton
        size="large"
        $state="default"
        text="확인"
        $variant={modalVariant}
        onClick={closeModal}
      />
    );
  }, [modalVariant, closeModal, handleDownloadConfirm]);

  useEffect(() => {
    if (isExcel) fetchGetSupportInquiryList();
  }, [isExcel]);

  return (
    <StyledReportListContainer>
      <StyledReportListWrapper>
        <StyledReportListListWrapper>
          <StyledReportListTableTitleContainer>
            <ReportListTableTitle totalUsers={totalUsers} />
            <StyledReportListButtonGroupWrapper>
              <Dropdown
                options={PAGE_SIZE_OPTIONS.map((size) => `${size}개씩 보기`)}
                value={`${pageSize}개씩 보기`}
                selectedValue={`${pageSize}개씩 보기`}
                onOptionChange={handlePageSizeChange}
              />
              <CustomButton
                size="small"
                $state="default"
                text="엑셀 다운로드"
                $variant="exceldown"
                onClick={handleDownloadClick}
              />
            </StyledReportListButtonGroupWrapper>
          </StyledReportListTableTitleContainer>
          <ReportListTable
            keyValue="targetId"
            title={
              activeTab
                ? formatGroupFullCode(groupCodeList, activeTab).codeName
                : '전체 신고'
            }
            users={filteredData}
            columns={columns}
            selectedUserIds={selectedUserIds}
            onSelectAll={handleSelectAll}
            onSelectUser={handleSelectUser}
            onSort={handleSort}
            onRowClick={handleRowClick}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalUsers / pageSize)}
            onPageChange={handlePageChange}
          />
        </StyledReportListListWrapper>
      </StyledReportListWrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={modalActions}
      />
      <VideoDetailModal
        isOpen={openModal === 'MAIN'}
        onClose={handleCloseModal}
        videoData={modalData}
      />
      <CommentVideoDetailModal
        isOpen={openModal === 'COMMENT'}
        onClose={handleCloseModal}
        videoData={modalData}
      />
      <UserDetailModal
        userId={selectedId}
        isOpen={openModal === 'USER'}
        onClose={handleCloseModal}
        userData={modalData}
      />
      <InquiryDetailModal
        targetId={selectedId}
        isOpen={openModal === 'INQUIRY'}
        onClose={handleCloseModal}
        onSuccess={fetchGetSupportInquiryList}
        reportData={modalData}
      />
    </StyledReportListContainer>
  );
};

export default ReportList;
