import {
  StyledUserStatsSection,
  StyledUserStatsContent,
  StyledUserStatsTotalUsersItem,
  StyledUserStatsItem,
  StyledUserStatsItemSection,
  StyledUserStatsItemLabel,
  StyledUserStatsItemValue,
} from './UserStats.styles';
import { formatNumber } from '../../../../utils/formatDate';
import getUserCount from '../../../../apis/userManagement/getUserCount';
import StatusHeader from '../../../../components/common/StatusHeadr';

const UserStatsItemSection = ({ item, index, length }) => (
  <StyledUserStatsItemSection
    key={item.label}
    style={{ borderRight: index < length - 1 ? '1px solid #d9d9d9' : 'none' }}
  >
    <StyledUserStatsItemLabel>{item.label}</StyledUserStatsItemLabel>
    <StyledUserStatsItemValue>{item.count}</StyledUserStatsItemValue>
  </StyledUserStatsItemSection>
);

const UserStats = () => {
  const { data: usrCountData, fetch: fetchGetUserCount } = getUserCount();

  const formatUserCount = (data) => {
    if (!data) return null;

    const {
      totalMembers,
      maleMembers,
      femaleMembers,
      under24ageCount,
      age25to29Count,
      age30to34Count,
      age35to39Count,
      over40ageCount,
    } = Object.entries(data).reduce((acc, [key, item]) => {
      acc[key] = formatNumber(item);
      return acc;
    }, {});
    return {
      totalMemberStatus: {
        label: '총 회원 수',
        count: `${totalMembers}명`,
      },
      genderStatus: [
        { label: '남자 회원 수', count: `${maleMembers}명` },
        { label: '여자 회원 수', count: `${femaleMembers}명` },
      ],
      ageStatus: [
        { label: '19-24세', count: `${under24ageCount}명` },
        { label: '25-29세', count: `${age25to29Count}명` },
        { label: '30-34세', count: `${age30to34Count}명` },
        { label: '35-39세', count: `${age35to39Count}명` },
        { label: '40-100세', count: `${over40ageCount}명` },
      ],
    };
  };

  const userCount = formatUserCount(usrCountData);

  return (
    <StyledUserStatsSection>
      <StatusHeader title="회원 현황" onReload={() => fetchGetUserCount()} />
      <StyledUserStatsContent>
        <StyledUserStatsTotalUsersItem>
          <StyledUserStatsItemLabel>
            {userCount?.totalMemberStatus.label}
          </StyledUserStatsItemLabel>
          <StyledUserStatsItemValue>
            {userCount?.totalMemberStatus.count}
          </StyledUserStatsItemValue>
        </StyledUserStatsTotalUsersItem>
        <StyledUserStatsItem>
          {userCount?.genderStatus.map((item, index) => (
            <UserStatsItemSection
              key={item.label}
              item={item}
              index={index}
              length={userCount?.genderStatus.length}
            />
          ))}
        </StyledUserStatsItem>
        <StyledUserStatsItem>
          {userCount?.ageStatus.map((item, index) => (
            <UserStatsItemSection
              key={item.label}
              item={item}
              index={index}
              length={userCount?.ageStatus.length}
            />
          ))}
        </StyledUserStatsItem>
      </StyledUserStatsContent>
    </StyledUserStatsSection>
  );
};

export default UserStats;
