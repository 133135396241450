/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import PageHeader from '../../../../components/common/PageHeader';
import CustomButton from '../../../../components/common/Button/Button';
import Modal from '../../../../components/common/Modal/Modal';

import useModal from '../../../../hooks/useModal';

import NoticeTemplate from './components/NoticeTemplate';
import { formatDateTime, formatGroupCode } from '../../../../utils/formatDate';
import { postNotice } from '../../../../apis/notice';
import { BOARD_TYPE_NOTICE } from '../../../../constants/groupCode';

const CreateNotices = () => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const viewTargetList = formatGroupCode(groupCodeList, 7021);

  const location = useLocation();
  const navigate = useNavigate();
  const defaultNoticeData = {
    boardType: BOARD_TYPE_NOTICE,
    viewTarget: '회원',
    startTime: null,
    endTime: null,
    viewType: ['팝업'],
    startDate: new Date(),
    endDate: new Date(),
    title: '',
    summary: '',
    contents: '',
    file: '',
    popUpFileUrl: '',
    isPush: false,
  };
  const [noticeData, setNoticeData] = useState(defaultNoticeData);
  const [fetchData, setFetchData] = useState('');
  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
    handleConfirm,
  } = useModal();

  const [isEditable] = useState(true); // Always editable for creation

  const { fetch: fetchPostNotice } = postNotice({
    query: fetchData.queryString,
    onSuccess: () => {
      showModal('등록 완료', '새 공지사항이 등록되었습니다.', 'confirm');
    },
  });

  const handleResetConfirm = () => {
    setNoticeData(defaultNoticeData);
    closeModal();
  };

  const handleReset = () => {
    showModal(
      '초기화 하시겠습니까?',
      '초기화 하시면 입력하신 정보들을 삭제됩니다.',
      'alert',
    );
  };

  useEffect(() => {
    const { formData, queryString } = fetchData;
    if (!queryString) return;
    fetchPostNotice(formData);
  }, [fetchData]);

  const handleRegister = (data) => {
    if (
      data.title.trim() === '' ||
      data.contents.trim() === '' ||
      !data.startDate ||
      !data.startTime ||
      !data.endDate ||
      !data.endTime
    ) {
      showModal('내용을 입력해주세요', '', 'input');
    } else {
      showModal(
        '새 이벤트/공지사항을 생성하시겠습니까?',
        '작성하신 내용으로 새 이벤트/공지사항이 생성됩니다.',
        'checkCreate',
        () => setFetchData(handleCreateFetchData(data)),
      );
    }
  };

  const handleCreateFetchData = (value) => {
    const {
      isActiveStatus,
      boardType,
      viewType,
      viewTarget,
      title,
      contents,
      startDate,
      startTime,
      endDate,
      endTime,
      fileUrl,
      popUpFile,
      bannerFile,
    } = value;

    const formData = new FormData();

    formData.append('file', fileUrl);
    formData.append('popUpFile', popUpFile);
    formData.append('bannerFile', bannerFile);

    const isActiveStatusQuery = `isActiveStatus=${isActiveStatus}`;

    const boardTypeQuery = `boardType=${boardType}`;

    const viewTypeCode = viewType[0] === '팝업' ? 702001 : 702002;
    const viewTypeQuery = `viewType=${viewTypeCode}`;

    const viewTargetCode = viewTargetList.codes.find(
      ({ codeName }) =>
        codeName.replace(/ /g, '') === viewTarget.replace(/ /g, ''),
    ).fullCode;
    const viewTargetQuery = `viewTarget=${viewTargetCode}`;

    const titleQuery = `title=${title}`;

    const endDateQuery = `endDate=${formatDateTime(endDate, endTime)}`;
    const startDateQuery = `startDate=${formatDateTime(startDate, startTime)}`;

    const contentsQuery = `contents=${contents}`;

    const queryString = [
      viewTypeQuery,
      viewTargetQuery,
      titleQuery,
      contentsQuery,
      startDateQuery,
      endDateQuery,
      isActiveStatusQuery,
      boardTypeQuery,
    ]
      .filter(Boolean)
      .join('&');

    // if (!fileUrl) return { queryString };
    return { queryString, formData };
  };

  const handleRegisterConfirm = () => {
    navigate(`/CustomerService/Notices${location.search}`, { replace: true });
  };

  return (
    <StyledManagementContainer>
      <div>
        <PageHeader
          title="이벤트/공지사항 생성"
          returnPage={handleRegisterConfirm}
        />
        <StyledManagementWrapper>
          <NoticeTemplate
            noticeData={noticeData}
            isEditable={isEditable}
            onCancel={handleReset}
            onSave={handleRegister}
            cancelButtonText="초기화"
            saveButtonText="등록"
          />
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            title={modalTitle}
            content={<div style={{ textAlign: 'center' }}>{modalContent}</div>}
            actions={
              modalVariant === 'alert' ? (
                <>
                  <CustomButton
                    size="large"
                    $state="default"
                    text="취소"
                    $variant="cancel"
                    onClick={closeModal}
                  />
                  <CustomButton
                    size="large"
                    $state="default"
                    text="초기화"
                    $variant="alert"
                    onClick={handleResetConfirm}
                  />
                </>
              ) : modalVariant === 'confirm' ? (
                <CustomButton
                  size="large"
                  $state="default"
                  $variant="confirm"
                  text="확인"
                  onClick={handleRegisterConfirm}
                />
              ) : modalVariant === 'checkCreate' ? (
                <>
                  <CustomButton
                    size="large"
                    $state="default"
                    text="취소"
                    $variant="cancel"
                    onClick={closeModal}
                  />
                  <CustomButton
                    size="large"
                    $state="default"
                    $variant="confirm"
                    text="확인"
                    onClick={handleConfirm}
                  />
                </>
              ) : (
                <CustomButton
                  size="large"
                  $state="default"
                  text="확인"
                  $variant="alert"
                  onClick={closeModal}
                />
              )
            }
          />
        </StyledManagementWrapper>
      </div>
    </StyledManagementContainer>
  );
};

export default CreateNotices;

const StyledManagementContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledManagementWrapper = styled.div`
  /* min-width: 926px; */
  width: auto;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  /* border: 1px solid #f1f2f4; */
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;
