/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
import { useEffect, useMemo, useState } from 'react';
// 회원 기본 정보 컴포넌트
import { useSelector } from 'react-redux';
import { PiSealCheckFill } from 'react-icons/pi';
import CustomButton from '../../../../../../components/common/Button/Button';
import Dropdown from '../../../../../../components/common/DropDown/DropDown';

import {
  StyledUserBasicInfoContainer,
  StyledUserBasicInfoHeader,
  StyledUserBasicInfoTitle,
  StyledUserBasicInfoTable,
  StyledUserBasicInfoTableRow,
  StyledUserBasicInfoTableCell,
  StyledUserBasicInfoCheckbox,
  StyledUserBasicInfoCheckboxInput,
  StyledUserBasicInfoCheckboxIcon,
  StyledUserBasicInfoCheckboxLabel,
  StyledSeparator,
  StyledBadgeWrapper,
  StyledBadgeIconWrapper,
  StyledUserBasicInfoNicknameInput,
  StyledUserBasicInfoNicknameContainer,
} from './UserBasicInfo.styles';

import {
  formatBirthDate,
  formatDataTotalHHMMSS,
  formatDateYYYYMMDD,
  formatGroupFullCode,
  formatPhoneNumber,
} from '../../../../../../utils/formatDate';
import {
  getUserLevels,
  patchUserDetailCelb,
  patchUserDetailLevel,
  patchUserNickName,
} from '../../../../../../apis/userManagement';
import {
  getUserDetailActivity,
  getUserDetailBasicInfo,
} from '../../../../../../apis/user';
import CommonInput from '../../../../../../components/common/CommonInput/input';
import useModal from '../../../../../../hooks/useModal';
import Modal from '../../../../../../components/common/Modal/Modal';

const UserBasicInfo = ({ userId }) => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const [basicInfo, setBasicInfo] = useState({});
  const [activity, setActivity] = useState({});
  const [isCelebChecked, setIsCelebChecked] = useState(false);
  const [nickname, setNickname] = useState('');
  const [isUserTypeEditing, setIsUserTypeEditing] = useState(false);
  const [isNicknameEditing, setIsNicknameEditing] = useState(false);
  const [memberType, setMemberType] = useState();

  const { fetch: fetchGetUserDetailBasicInfo } = getUserDetailBasicInfo({
    query: userId,
    onSuccess: (value) => {
      const { ...data } = value;

      setMemberType(data.level);
      setIsCelebChecked(data.isCeleb);
      setNickname(data.nickname);

      setBasicInfo({
        ...data,
        createdAt: formatDateYYYYMMDD(data.createdAt),
        birthDate: formatBirthDate(data.birthDate),
        phone: formatPhoneNumber(data.phone),
        gender: formatGroupFullCode(groupCodeList, data.gender).codeName,
      });
    },
  });

  getUserDetailActivity({
    query: userId,
    onSuccess: (value) => {
      const { ...data } = value;
      const { hour, minute, second } = formatDataTotalHHMMSS(
        data.averageAccessTime,
      );
      setActivity({
        ...data,
        accUploadCount: `메인영상 : ${data.mainActivityCount}개 | 댓글영상 : ${data.commentActivityCount}개`,
        accFeelkyRequestCount: `받은 필키요청 : ${data.recvFeelkyRequestCount}개 | 보낸 필키요청 : ${data.sentFeelkyRequestCount}개`,
        averageAccessTime: `일일 평균 ${hour}시간 ${minute}분 ${second}초`,
      });
    },
  });

  const userLevel = getUserLevels();

  const fetchUserDetailLevel = patchUserDetailLevel({ userId, memberType });

  const fetchUserDetailCelb = patchUserDetailCelb({ userId, isCelebChecked });

  const fetchUserNickName = patchUserNickName({ userId });

  const handleOptionChange = (codeName) => {
    setMemberType(
      userLevel?.find((value) => value?.codeName === codeName).fullCode,
    );
  };

  const handleCelebCheckboxChange = () =>
    setIsCelebChecked((prevIsCelebChecked) => !prevIsCelebChecked);

  const handleEditClick = () =>
    setIsUserTypeEditing((prevIsEditing) => !prevIsEditing);

  const handleNicknameEditing = () =>
    setIsNicknameEditing((prevIsEditing) => !prevIsEditing);

  const handleSubmitChangeNickname = () => {
    if (basicInfo.nickname === nickname) return handleNicknameEditing();
    showModal('수정한 회원 정보를 저장하시겠습니까?', '', '');
  };

  const handleChangeNickname = (value) => {
    setNickname(value.target.value);
  };

  const memberTypes = userLevel?.map((value) => value?.codeName);

  const isChangeNickName = useMemo(() => {
    return nickname !== basicInfo.nickname;
  }, [basicInfo, nickname]);

  const isChangeMemberType = useMemo(() => {
    return memberType !== basicInfo.level;
  }, [basicInfo, memberType]);

  const isChangeCelebChecked = useMemo(() => {
    return isCelebChecked !== basicInfo.isCeleb;
  }, [basicInfo, isCelebChecked]);

  const handleUpdateUser = () => {
    if (!isChangeMemberType && !isChangeCelebChecked) return handleEditClick();

    showModal('수정한 회원 정보를 저장하시겠습니까?', '', 'userType');
  };

  const fetchAndUpdateUser = async () => {
    if (isChangeMemberType) await fetchUserDetailLevel();
    if (isChangeCelebChecked) await fetchUserDetailCelb();
    await fetchGetUserDetailBasicInfo();
  };

  const fetchAndUpdateNickName = async () => {
    if (isChangeNickName) await fetchUserNickName({ nickname });
    await fetchGetUserDetailBasicInfo();
  };

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();
  useEffect(() => {
    console.log(
      'modalVariant :',
      modalVariant,
      '.',
      modalVariant === 'userType',
    );
  }, [modalVariant]);

  const modalActions = useMemo(() => {
    return modalVariant === 'userType' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="확인"
          $variant="confirm"
          onClick={() => {
            fetchAndUpdateUser();
            closeModal();
            handleEditClick();
          }}
        />
      </>
    ) : (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="확인"
          $variant="confirm"
          onClick={() => {
            fetchAndUpdateNickName();
            closeModal();
            handleNicknameEditing();
          }}
        />
      </>
    );
  }, [modalVariant, closeModal]);

  const renderTableRow = (
    header1,
    content1,
    header2,
    content2,
    topLeft = false,
    bottomLeft = false,
  ) => (
    <StyledUserBasicInfoTableRow>
      <StyledUserBasicInfoTableCell
        $header
        $topLeft={topLeft}
        $bottomLeft={bottomLeft}
      >
        {header1}
      </StyledUserBasicInfoTableCell>
      <StyledUserBasicInfoTableCell>
        {Array.isArray(content1) ? (
          <StyledSeparator>
            {content1.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </StyledSeparator>
        ) : (
          content1
        )}
      </StyledUserBasicInfoTableCell>
      <StyledUserBasicInfoTableCell $header>
        {header2}
      </StyledUserBasicInfoTableCell>
      <StyledUserBasicInfoTableCell>
        {Array.isArray(content2) ? (
          <StyledSeparator>
            {content2.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </StyledSeparator>
        ) : (
          content2
        )}
      </StyledUserBasicInfoTableCell>
    </StyledUserBasicInfoTableRow>
  );

  return (
    <>
      <StyledUserBasicInfoContainer>
        <StyledUserBasicInfoHeader>
          <StyledUserBasicInfoTitle>회원 기본 정보</StyledUserBasicInfoTitle>
        </StyledUserBasicInfoHeader>

        <StyledUserBasicInfoTable>
          {renderTableRow(
            '닉네임',
            <StyledUserBasicInfoNicknameContainer
              onSubmit={(e) => {
                e.preventDefault();
                if (!isNicknameEditing) return handleNicknameEditing();
                handleSubmitChangeNickname();
              }}
            >
              {isNicknameEditing ? (
                <StyledUserBasicInfoNicknameInput>
                  <CommonInput
                    value={nickname}
                    onChange={handleChangeNickname}
                    maxLength={9}
                    pattern="^[a-zA-Z0-9가-힣]*$"
                    title="영문, 국문, 숫자만 입력 가능해요."
                  />
                </StyledUserBasicInfoNicknameInput>
              ) : (
                nickname
              )}

              <CustomButton
                type="submit"
                size="small"
                $state="default"
                text={isNicknameEditing ? '저장' : '수정'}
                $variant="edit"
              />
            </StyledUserBasicInfoNicknameContainer>,
            '회원유형',
            <>
              {isUserTypeEditing ? (
                <>
                  <Dropdown
                    options={memberTypes}
                    selectedValue={
                      userLevel?.find((value) => value.fullCode === memberType)
                        ?.codeName
                    }
                    onOptionChange={handleOptionChange}
                    minWidth="141px"
                    height="24px"
                  />
                  <StyledUserBasicInfoCheckbox
                    onClick={handleCelebCheckboxChange}
                  >
                    <StyledUserBasicInfoCheckboxInput
                      type="checkbox"
                      checked={isCelebChecked}
                      readOnly
                    />
                    <StyledUserBasicInfoCheckboxIcon
                      $isChecked={isCelebChecked}
                    />
                    <StyledUserBasicInfoCheckboxLabel>
                      셀럽
                    </StyledUserBasicInfoCheckboxLabel>
                  </StyledUserBasicInfoCheckbox>
                </>
              ) : (
                <>
                  {
                    userLevel?.find((value) => value.fullCode === memberType)
                      ?.codeName
                  }
                  {isCelebChecked && (
                    <StyledBadgeWrapper>
                      <StyledBadgeIconWrapper>
                        <PiSealCheckFill size="14px" />
                      </StyledBadgeIconWrapper>
                      셀럽
                    </StyledBadgeWrapper>
                  )}
                </>
              )}
              <CustomButton
                size="small"
                $state="default"
                text={isUserTypeEditing ? '저장' : '수정'}
                $variant="edit"
                onClick={() => {
                  if (!isUserTypeEditing) return handleEditClick();
                  handleUpdateUser();
                }}
              />
            </>,
            true,
          )}
          {renderTableRow(
            '가입일자',
            basicInfo.createdAt,
            '생년월일',
            basicInfo.birthDate,
          )}
          {renderTableRow(
            '성별',
            basicInfo.gender,
            '연락처',
            basicInfo.phone,
            false,
            true,
          )}
        </StyledUserBasicInfoTable>
      </StyledUserBasicInfoContainer>

      <StyledUserBasicInfoContainer>
        <StyledUserBasicInfoHeader>
          <StyledUserBasicInfoTitle>회원 활동</StyledUserBasicInfoTitle>
        </StyledUserBasicInfoHeader>
        <StyledUserBasicInfoTable>
          {renderTableRow(
            '주요 게시 위치',
            activity.majorAccessLocations,
            '평균 접속 시간',
            activity.averageAccessTime,
            true,
          )}
          {renderTableRow(
            '누적 업로드 수',
            activity.accUploadCount,
            '누적 필기요청 수',
            activity.accFeelkyRequestCount,
            false,
            true,
          )}
        </StyledUserBasicInfoTable>
      </StyledUserBasicInfoContainer>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={modalActions}
      />
    </>
  );
};

export default UserBasicInfo;
