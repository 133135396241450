import {
  StyledNoticeListTableTitleWrapper,
  StyledNoticeListTableTitleText,
} from './AlarmList.styles';

const AlarmListTableTitle = ({ totalPosts }) => {
  return (
    <StyledNoticeListTableTitleWrapper>
      <StyledNoticeListTableTitleText>알림 목록</StyledNoticeListTableTitleText>
      <StyledNoticeListTableTitleText>
        {totalPosts}
      </StyledNoticeListTableTitleText>
    </StyledNoticeListTableTitleWrapper>
  );
};

export default AlarmListTableTitle;
