// TodayTasks.jsx
import { useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import StatusCard from '../../../../components/common/StatusCard/StatusCard';
import getSupportInquiryCount from '../../../../apis/dashbord/getSupportInquiryCount';

const StyledLocationChangeBtn = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  text-align: center;
  color: #412cab;
  text-decoration: underline;
  text-decoration-color: #412cab;
  text-underline-offset: 2px;
  cursor: pointer;
`;

const StyleLocationChangeText = styled.div``;

const InquiryAndReportStats = () => {
  const navigate = useNavigate();
  const { data, isLoading } = getSupportInquiryCount();

  const handleLocationChange = (url) => {
    window.open(`${url}`, '_blank');
  };

  const handleChannelTalkClick = () => {
    handleLocationChange(process.env.REACT_APP_CHANNEL_TALK_URL);
  };

  const inquiryData = useMemo(() => {
    if (!data) return [];

    const newInquiryData = data.map(({ count, ...value }) => ({
      ...value,
      count: (
        <StyleLocationChangeText
          onClick={() => navigate('/CustomerService/Inquiries')}
          style={{ cursor: 'pointer' }}
        >
          {count}
        </StyleLocationChangeText>
      ),
    }));

    return [
      ...newInquiryData,
      {
        label: '신규 문의',
        count: (
          <StyledLocationChangeBtn onClick={handleChannelTalkClick}>
            채널톡 이동
          </StyledLocationChangeBtn>
        ),
      },
    ];
  }, [data]);

  return (
    <StatusCard
      title="신규 신고 및 문의"
      description="신규 신고는 하루가 지나면 미처리로 표시됩니다."
      data={inquiryData}
      isLoading={isLoading}
      skeletonCount={2}
    />
  );
};

export default InquiryAndReportStats;
