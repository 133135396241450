// 차트 날짜 포맷팅 함수
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}월 ${date.getDate()}일`;
};

// 회원수 차트를 위한 포맷터 함수
export const tooltipFormatterForMembership = (date, value) => {
  const formattedDate = formatDate(date);
  return `${formattedDate} 회원수\n${value}명`;
};

// 신규가입/탈퇴 차트를 위한 포맷터 함수
export const tooltipFormatterForSignup = (
  label,
  signupValue,
  withdrawalValue,
) => {
  return `신규가입: ${signupValue}명 \n탈퇴: ${withdrawalValue || 0}명 `;
};

// 신규 영상/ 댓글 영상 차트를 위한 포맷터 함수
export const tooltipFormatterForNewPosts = (
  label,
  mainVideoCount,
  commentVideoCount,
  data,
) => {
  const { valuePercent, value2Percent } = data;
  return ` 메인영상: ${mainVideoCount}건 (${valuePercent})\n댓글영상: ${
    commentVideoCount || 0
  }건 (${value2Percent})`;
};

// 연령대별 사용자 현황 차트를 위한 포맷터 함수
export const tooltipFormatterForAge = (age, value) => {
  return `${age}세 : ${value} 명`;
};
