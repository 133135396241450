/* eslint-disable no-nested-ternary */
import {
  StyledTableRow,
  StyledTableCheckboxWrapper,
  StyledTableCell,
  StyledTableText,
  // StyledTableCheckbox,
  StyledTableLink,
  StyledTableCheckboxInput,
  StyledTableCheckboxIcon,
} from './Table.styles';

const TableDataRow = ({
  data,
  columns,
  onSelect,
  isSelected,
  onRowClick,
  onTitleClick,
  onPublisherClick,
}) => {
  const handleCheckboxClick = (e) => {
    e.stopPropagation(); // 이벤트 버블링 방지
    onSelect(data.id);
  };

  const handleCellClick = (e, key) => {
    e.stopPropagation();
    if (key === 'videotitle' && onTitleClick) {
      onTitleClick(data);
    } else if (key === 'postpublisher' && onPublisherClick) {
      onPublisherClick(data);
    }
  };

  return (
    <StyledTableRow onClick={() => onRowClick(data)}>
      {onSelect && (
        <StyledTableCheckboxWrapper onClick={handleCheckboxClick}>
          <StyledTableCheckboxInput
            type="checkbox"
            checked={isSelected}
            readOnly
          />
          <StyledTableCheckboxIcon
            $isChecked={isSelected}
            onClick={handleCheckboxClick}
          />
        </StyledTableCheckboxWrapper>
      )}
      {columns.map(({ key, width, format, component: Component, $flex }) => (
        <StyledTableCell key={key} width={width} $flex={$flex}>
          {Component ? (
            <Component value={data[key]} />
          ) : (key === 'videotitle' && onTitleClick) ||
            (key === 'postpublisher' && onPublisherClick) ? (
            <StyledTableLink onClick={(e) => handleCellClick(e, key)}>
              {format ? format(data[key]) : data[key]}
            </StyledTableLink>
          ) : (
            <StyledTableText>
              {format ? format(data[key]) : data[key]}
            </StyledTableText>
          )}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

export default TableDataRow;
