import { useState } from 'react';
import { FaRotate } from 'react-icons/fa6';
import styled from 'styled-components';
import { formatYYYYMMDDHHMMSS } from '../../utils/formatDate';

const StatusHeader = ({ title, onReload }) => {
  const [currentTime, setCurrentTime] = useState(
    formatYYYYMMDDHHMMSS(new Date()),
  );
  const handleReload = () => {
    onReload();
    setCurrentTime(formatYYYYMMDDHHMMSS(new Date()));
  };
  return (
    <StyledUserStatsHeader>
      <StyledUserStatsTitle>{title}</StyledUserStatsTitle>
      <StyledUserStatsReloadButton onClick={handleReload}>
        <div>
          <FaRotate />
        </div>
        {currentTime}
      </StyledUserStatsReloadButton>
    </StyledUserStatsHeader>
  );
};

export default StatusHeader;

export const TextBase = styled.div`
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 500;
  word-wrap: break-word;
`;

export const StyledUserStatsHeader = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* justify-content: center; */
  /* align-items: flex-start; */
  gap: 10px;
`;

export const StyledUserStatsTitle = styled(TextBase)`
  color: #1e2024;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`;

export const StyledUserStatsReloadButton = styled.div`
  width: auto;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px 0px 8px;
  gap: 4px;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
  opacity: 0px;

  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  color: #9d9d9d;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #262626;
  }
`;
