// InfoItem.js
import {
  StyledAdminInfoDetailInfoRow,
  StyledAdminInfoDetailInfoLabel,
  StyledAdminInfoDetailInfoValue,
  StyledAdminInfoDetailInfoText,
} from '../AdminInfoDetail.style';

const AdminInfoDetailItem = ({ label, value, size, weight }) => (
  <StyledAdminInfoDetailInfoRow>
    <StyledAdminInfoDetailInfoLabel>{label}</StyledAdminInfoDetailInfoLabel>
    <StyledAdminInfoDetailInfoValue>
      {typeof value === 'string' ? (
        <StyledAdminInfoDetailInfoText size={size} weight={weight}>
          {value}
        </StyledAdminInfoDetailInfoText>
      ) : (
        value
      )}
    </StyledAdminInfoDetailInfoValue>
  </StyledAdminInfoDetailInfoRow>
);

export default AdminInfoDetailItem;
