import styled from 'styled-components';

import PageHeader from '../../../components/common/PageHeader';

import NoticeList from './componets/NoticeList/NoticeList';

const StyledNoticeListContainer = styled.div``;
const StyledNoticeListWrapper = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;

const StyledNoticeListContentsWrapper = styled(StyledNoticeListWrapper)`
  margin-top: 20px;
`;

const Notice = () => {
  return (
    <StyledNoticeListContainer>
      <PageHeader title="이벤트/공지사항" />
      <StyledNoticeListContentsWrapper>
        <NoticeList />
      </StyledNoticeListContentsWrapper>
    </StyledNoticeListContainer>
  );
};
export default Notice;
