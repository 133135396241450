import styled from 'styled-components';

export const StyledMyInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
// same as Item
export const StyledMyInfoLabel = styled.div`
  width: 120px;
  font-size: 13px;
  font-weight: 500;
  color: #434343;
`;
// same as Item
export const StyledMyInfoText = styled.div`
  font-size: ${({ size }) => (size === 'small' ? '13px' : '16px')};
  font-weight: ${({ weight }) => (weight === 'bold' ? '500' : '400')};
  color: #434343;
`;
// Item
export const StyledMyInfoRow = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px;
  border-bottom: 1px solid #e9e9e9;
`;

export const StyledMyInfoValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledMyInfoAccessRangeRow = styled(StyledMyInfoRow)`
  height: 210px;
  align-items: flex-start;
  border-bottom: none;
`;

export const StyledMyInfoAccessRangeValue = styled.div`
  flex: 1;
  display: flex;
  gap: 12px;
`;

export const StyledMyInfoSeparator = styled.div`
  color: #c4c4c4;
`;

export const StyledPasswordChangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledPasswordInput = styled.input`
  max-width: 143px;
  padding: 6px 12px;
  border: 1px solid #dddfe3;
  border-radius: 4px;
  font-size: 14px;
`;

// Loading
export const StyledLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; // 컨테이너가 전체 너비를 차지하도록 설정
  min-height: 50vh; // 컨테이너의 최소 높이 설정, 실제 상황에 맞게 조절 필요
`;
