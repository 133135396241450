import styled from 'styled-components';
import { PiX } from 'react-icons/pi';

export const StyledVideoDetailModalWrapper = styled.div`
  width: 703px;
  padding: 40px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  z-index: 1001;
`;

export const StyledVideoDetailModalHeader = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledVideoDetailModalTitle = styled.div`
  color: #1e2024;
  font-size: 28px;
  font-family: 'Pretendard';
  font-weight: 700;
  line-height: 36px;
  word-wrap: break-word;
`;

export const StyledVideoDetailModalCloseButton = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StyledVideoDetailModalCloseButtonVector = styled(PiX)`
  width: 24px;
  height: 24px;
  color: #686b73;
`;

export const StyledVideoDetailModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

export const StyledVideoDetailModalThumbnailWrapper = styled.div`
  width: 200px;
  height: 355px;
  /* height: 100%; */
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  background-color: #e9e9e9;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const StyledVideoDetailModalInfoWrapper = styled.div`
  width: 403px;
  height: 355px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const StyledVideoDetailModalInfoLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`;

export const StyledVideoDetailModalInfoTable = styled.div`
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledVideoDetailModalInfoRow = styled.div`
  align-self: stretch;
  background: white;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`;

export const StyledVideoDetailModalInfoHeader = styled.div`
  width: 100px;
  align-self: stretch;
  padding: 6px 12px;
  background: #e9e9e9;
  border-right: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

export const StyledVideoDetailModalInfoCell = styled.div`
  flex: 1;
  align-self: stretch;
  padding: 6px 12px;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

export const StyledVideoDetailModalDescriptionCell = styled.div`
  flex: 1;
  align-self: stretch;
  padding: 6px 12px;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

export const StyledVideoDetailModalDescriptionTable = styled.div`
  flex: 1;
  align-self: stretch;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledVideoDetailModalDescriptionRow = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  &:last-child {
    border-bottom: none;
  }
`;

export const StyledVideoDetailModalTagCell = styled.div`
  flex: 1;
  align-self: stretch;
  padding: 6px 12px;
  background: white;
  display: flex;
  align-items: center;
  border-left: 1px solid #d9d9d9;
  gap: 12px;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

export const StyledVideoDetailModalStatsTable = styled.div`
  align-self: stretch;
  height: 68px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledVideoDetailModalStatsHeader = styled.div`
  align-self: stretch;
  background: white;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledVideoDetailModalStatsHeaderCell = styled.div`
  flex: 1;
  align-self: stretch;
  padding: 6px 12px;
  background: #e9e9e9;
  border-right: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &:last-child {
    border-right: none;
  }
`;

export const StyledVideoDetailModalStatsRow = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledVideoDetailModalStatsCell = styled.div`
  flex: 1;
  height: 34px;
  background: white;
  border-right: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &:last-child {
    border-right: none;
  }
`;

export const StyledVideoDetailModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledVideoDetailModalThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export const StyledVideoPlayer = styled.video`
  width: 200px;
  /* height: 355px; */
  height: 100%;
  border-radius: 8px;
`;
