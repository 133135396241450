/* eslint-disable no-nested-ternary */
// StatusCard.jsx
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import Dropdown from '../DropDown/DropDown';
import {
  StyledStatusCardContainer,
  StyledStatusCardHeader,
  StyledStatusCardTitle,
  StyledStatusCardDescription,
  StyledStatusCardItemsContainer,
  StyledStatusCardItemWrapper,
  StyledStatusCardItem,
  StyledStatusCardLabel,
  StyledStatusCardCount,
  StyledStatusCardChange,
  StyledStatusCardChangeIndicator,
  StyledStatusCardChangeValue,
  StyledStatusCardItemSkeleton,
} from './StatusCard.styles';

const StatusCard = ({
  title,
  description,
  data,
  isLoading,
  timeRangeOptions,
  selectedTimeRange,
  onTimeRangeChange,
  skeletonCount,
  timeRenderItem,
  arrow = false,
}) => {
  return (
    <StyledStatusCardContainer>
      {(title || description || timeRangeOptions) && (
        <StyledStatusCardHeader>
          <div>
            {title && <StyledStatusCardTitle>{title}</StyledStatusCardTitle>}
            {description && (
              <StyledStatusCardDescription>
                {description}
              </StyledStatusCardDescription>
            )}
          </div>
          {timeRangeOptions && (
            <Dropdown
              minWidth="100px"
              options={timeRangeOptions}
              renderItem={timeRenderItem}
              selectedValue={selectedTimeRange}
              onOptionChange={onTimeRangeChange}
            />
          )}
        </StyledStatusCardHeader>
      )}
      <StyledStatusCardItemsContainer>
        {isLoading
          ? Array.from({ length: skeletonCount }, (_, index) => (
              <StyledStatusCardItemSkeleton key={index} />
            ))
          : data.map((item) => (
              <StyledStatusCardItemWrapper key={item.label}>
                <StyledStatusCardItem>
                  <StyledStatusCardLabel>{item.label}</StyledStatusCardLabel>
                  <StyledStatusCardCount>{item.count}</StyledStatusCardCount>
                  {(item.change || item.changeValue) && (
                    <StyledStatusCardChange>
                      {item.changeValue && (
                        <StyledStatusCardChangeValue>
                          {item.changeValue}
                        </StyledStatusCardChangeValue>
                      )}
                      {item.change && (
                        <StyledStatusCardChangeIndicator
                          $isPositive={{
                            change: !item.change.includes('-'),
                            rateOfChange: item.change.replace('%', '') !== '0',
                          }}
                        >
                          {item.change}

                          {arrow &&
                            item.change.replace('%', '') !== '0' &&
                            (!item.change.includes('-') ? (
                              <FaArrowUp size={8} />
                            ) : (
                              <FaArrowDown size={8} />
                            ))}
                        </StyledStatusCardChangeIndicator>
                      )}
                    </StyledStatusCardChange>
                  )}
                </StyledStatusCardItem>
              </StyledStatusCardItemWrapper>
            ))}
      </StyledStatusCardItemsContainer>
    </StyledStatusCardContainer>
  );
};

export default StatusCard;
