/* eslint-disable no-unused-vars */
// CheckboxOptions.jsx
import { useSelector } from 'react-redux';
import {
  StyledAppSettingTableRow,
  StyledAppSettingTableCell,
  StyledAppSettingTableCellLabel,
  StyledAppSettingInputFieldContainer,
  StyledAppSettingCheckboxOption,
  StyledAppSettingCheckboxWrapper,
  StyledAppSettingCheckboxInput,
  StyledAppSettingCheckboxIcon,
  StyledAppSettingCheckboxLabel,
} from './AppSetting.styles';
import CustomButton from '../../../../components/common/Button/Button';
import { formatGroupCode } from '../../../../utils/formatDate';

const CheckboxOptions = ({
  title,
  checked = 970001,
  onChange,
  modalTitleText,
  modalContentText,
  handleConfirm,
  showModal,
  disabled,
}) => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const { codes: options } = formatGroupCode(groupCodeList, '9700');

  return (
    <StyledAppSettingTableRow>
      <StyledAppSettingTableCell $isHeader style={{ width: '15.80%' }}>
        <StyledAppSettingTableCellLabel $isHeader $disabled={disabled}>
          {title}
        </StyledAppSettingTableCellLabel>
      </StyledAppSettingTableCell>
      <StyledAppSettingTableCell
        style={{ width: '45.26%' }}
        $disabled={disabled}
      >
        <StyledAppSettingInputFieldContainer>
          <StyledAppSettingCheckboxOption>
            {options.map(({ codeName, fullCode }, index) => (
              <StyledAppSettingCheckboxWrapper
                key={fullCode}
                $disabled={disabled}
              >
                <StyledAppSettingCheckboxInput
                  type="checkbox"
                  checked={checked === fullCode ? checked : false}
                  $disabled={disabled}
                  onChange={() => onChange(fullCode)}
                />
                <StyledAppSettingCheckboxIcon
                  $disabled={disabled}
                  $isChecked={checked === fullCode ? checked : false}
                />
                <StyledAppSettingCheckboxLabel
                  onClick={index === 1 ? onChange : undefined}
                  $disabled={disabled}
                >
                  {codeName}
                </StyledAppSettingCheckboxLabel>
              </StyledAppSettingCheckboxWrapper>
            ))}
          </StyledAppSettingCheckboxOption>
        </StyledAppSettingInputFieldContainer>
        <CustomButton
          size="small"
          $state="default"
          text="변경"
          $variant="change"
          disabled={disabled}
          onClick={() =>
            showModal(modalTitleText, modalContentText, 'alert', handleConfirm)
          }
        />
      </StyledAppSettingTableCell>
      <StyledAppSettingTableCell $isOdd style={{ width: '38.94%' }}>
        <StyledAppSettingTableCellLabel $disabled={disabled}>
          *필키요청을 보낼 수 있는 회원의 조건을 설정해 주세요.
        </StyledAppSettingTableCellLabel>
      </StyledAppSettingTableCell>
    </StyledAppSettingTableRow>
  );
};

export default CheckboxOptions;
