/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

export const getVideoActivityCount = () => {
  const { data, fetch } = useFetch({
    url: '/video/activity/count',
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  return { data, fetch };
};
