// Loading.jsx
import SyncLoader from 'react-spinners/SyncLoader';
import { StyledLoadingContainer } from './MyInfo.styles';

const Loading = () => (
  <StyledLoadingContainer>
    <SyncLoader color="#000000" size={15} margin={2} />
  </StyledLoadingContainer>
);

export default Loading;
