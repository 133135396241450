import { useState } from 'react';
import styled from 'styled-components';
import useModal from '../../../../hooks/useModal';

import Modal from '../../../../components/common/Modal/Modal';
import CustomButton from '../../../../components/common/Button/Button';
import RegionalFilter from './RegionalFilter/RegionalFilter';
import RegionCategoryList from './RegionCategoryList/RegionCategoryList';

const Regional = () => {
  const [createButton, setCreateButton] = useState([
    {
      size: '',
      state: '',
      text: '',
      variant: '',
      onClick: () => {},
    },
  ]);

  const { isModalOpen, modalTitle, modalContent, showModal, closeModal } =
    useModal();

  return (
    <StyledRegionalSettingContainer>
      <StyledRegionalSettingContentsWrapper>
        <StyledRegionalSettingTitle>
          지역 카테고리 추가 및 설정
        </StyledRegionalSettingTitle>
        <StyledRegionalSettingContents>
          <RegionCategoryList
            showModal={showModal}
            setCreateButton={setCreateButton}
            closeModal={closeModal}
          />
          <RegionalFilter
            showModal={showModal}
            setCreateButton={setCreateButton}
            closeModal={closeModal}
          />
        </StyledRegionalSettingContents>
      </StyledRegionalSettingContentsWrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={createButton.map(
          ({ size, state, text, variant, onClick }, index) => (
            <CustomButton
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              size={size}
              $state={state}
              text={text}
              $variant={variant}
              onClick={onClick}
            />
          ),
        )}
      />
    </StyledRegionalSettingContainer>
  );
};

export default Regional;

const StyledRegionalSettingContainer = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
  margin-top: 24px;
`;

const StyledRegionalSettingContentsWrapper = styled.div``;

const StyledRegionalSettingTitle = styled.div`
  margin-bottom: 10px;
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

const StyledRegionalSettingContents = styled.div`
  display: flex;
  gap: 20px;
`;
