// 회원관리 > 게시물 조회 컴포넌트.
import React from 'react';
import CustomButton from '../../../../components/common/Button/Button';
import SearchFilter from './SearchFilter';

import {
  StyledUserSearchFilterHeader,
  StyledUserSearchFilterContents,
  StyledUserSearchFilterFooter,
} from './UserSearchFilter.styles';

const UserSearchFilter = () => {
  const searchFilter = React.useRef(null);

  const resetFilters = () => {
    searchFilter.current?.resetFilters();
  };

  const setFilters = () => {
    searchFilter.current?.setFilters();
  };

  const handleResetClick = () => {
    resetFilters();
  };

  const handleSetFilter = () => {
    setFilters();
  };

  return (
    <>
      <StyledUserSearchFilterHeader>회원 조회</StyledUserSearchFilterHeader>
      <StyledUserSearchFilterContents>
        <SearchFilter ref={searchFilter} />
      </StyledUserSearchFilterContents>
      <StyledUserSearchFilterFooter>
        <CustomButton
          size="medium"
          $state="default"
          text="초기화"
          $variant="reset"
          onClick={handleResetClick}
        />
        <CustomButton
          size="medium"
          $state="default"
          text="검색"
          $variant="search"
          onClick={handleSetFilter}
        />
      </StyledUserSearchFilterFooter>
    </>
  );
};

export default UserSearchFilter;
