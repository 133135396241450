import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

export const getAlarmList = ({ query, isInitialRequest = true, onSuccess }) => {
  const { data, fetch } = useFetch({
    url: `/support/alarm/list?currentPage=${query.currentPage}&pageSize=${query.pageSize}&sortKey=${query.sortColumn}&sort=${query.sortDirection}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, [
    query.currentPage,
    query.pageSize,
    query.sortColumn,
    query.sortDirection,
  ]);

  return { data, fetch };
};

export const getSupportAlarm = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/support/alarm/${query}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const postSupportAlarm = ({ query, onSuccess }) => {
  const { data, fetch } = useFetch({
    url: `/support/alarm?${query}`,
    method: 'post',
    onSuccess,
  });

  return { data, fetch };
};

export const putSupportAlarm = ({ query, onSuccess, onFailure }) => {
  const { data, fetch } = useFetch({
    url: `/support/alarm?${query}`,
    method: 'put',
    onSuccess,
    onFailure,
  });

  return { data, fetch };
};
export const delteSupportAlarm = ({ query, onSuccess, onFailure }) => {
  const { data, fetch } = useFetch({
    url: `/support/alarm?deleteType=${query}`,
    method: 'delete',
    onSuccess,
    onFailure,
  });

  return { data, fetch };
};
