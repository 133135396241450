import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

export const getSupportInquiryTab = ({
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: '/support/inquiry/tab',
    method: 'get',
    onSuccess: (value) => onSuccess(value),
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const getSupportInquiryList = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/support/inquiry/list?isExcel=${query.isExcel}&currentPage=${query.currentPage}&sortKey=${query.sortColumn}&sort=${query.sortDirection}&pageSize=${query.pageSize}&reportSearchType=${query.activeTab}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, [
    query.currentPage,
    query.sortColumn,
    query.sortDirection,
    query.pageSize,
    query.activeTab,
  ]);

  return { data, fetch };
};

export const getSupportInquiryDeltail = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/support/inquiry/detail/${query}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const getSupportFaqList = ({ isInitialRequest = true, onSuccess }) => {
  const { data, fetch } = useFetch({
    url: '/support/faq/list',
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const postSupportFaqSort = () => {
  const { fetch } = useFetch({
    url: '/support/faq/sort',
    method: 'post',
  });

  return { fetch };
};

export const deleteSupportFaq = ({ query, onSuccess }) => {
  const { fetch } = useFetch({
    url: `/support/faq/${query}`,
    method: 'delete',
    onSuccess,
  });

  return { fetch };
};
export const deleteSupportFaqCategory = ({ query, onSuccess }) => {
  const { fetch } = useFetch({
    url: `/support/faq/category/${query}`,
    method: 'delete',
    onSuccess,
  });

  return { fetch };
};

export const getSupportFaqCategory = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/support/faq/category/${query}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const getSupportFaqDetail = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/support/faq/detail/${query}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const postSupportFaqCategory = ({ query, onSuccess }) => {
  const { fetch } = useFetch({
    url: `/support/faq/category?categoryName=${query.titleValue}&isActive=${
      query.selectedStatus === '게시'
    }&description=${query.content}`,
    method: 'post',
    onSuccess,
  });

  return { fetch };
};

export const putSupportFaqCategory = ({ query, onSuccess }) => {
  const { fetch } = useFetch({
    url: `/support/faq/category?categoryId=${query.focusId}&categoryName=${
      query.titleValue
    }&isActive=${query.selectedStatus === '게시'}`,
    method: 'put',
    onSuccess,
  });

  return { fetch };
};

export const postSupportFaq = ({ query, onSuccess }) => {
  const { fetch } = useFetch({
    url: `/support/faq?category=${query.focusId}&title=${
      query.titleValue
    }&contents=${query.content}&isActive=${query.selectedStatus === '게시'}`,
    method: 'post',
    onSuccess,
  });

  return { fetch };
};

export const putSupportFaq = ({ query, onSuccess }) => {
  const { fetch } = useFetch({
    url: `/support/faq?faqId=${query.focusId}&title=${
      query.titleValue
    }&contents=${query.content}&isActive=${query.selectedStatus === '게시'}`,
    method: 'put',
    onSuccess,
  });

  return { fetch };
};

export const putSupportInquirySetail = ({ query, onSuccess }) => {
  const { isLoading, fetch } = useFetch({
    url: `/support/inquiry/detail/${query.targetId}?reportStatus=${query.selectedReportStatus}`,
    method: 'put',
    onSuccess,
  });

  return { isLoading, fetch };
};
