import { useSearchParams } from 'react-router-dom';

const useParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangeParams = ({ key, value }) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  return { searchParams, setSearchParams, handleChangeParams };
};

export default useParams;
