import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  StyledDatePickerContainer,
  StyledDatePickerButtons,
  StyledApplyButton,
  StyledCancelButton,
} from './CustomDatePicker.styles';

const CustomDatePicker = ({
  selectedDate,
  onChange,
  onCancel,
  onConfirm,
  disabled,
}) => {
  const handleDateChange = (date) => {
    onChange(date);
  };

  return (
    <StyledDatePickerContainer>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        inline
        locale={ko}
        disabled={disabled}
      />
      <StyledDatePickerButtons>
        <StyledCancelButton onClick={onCancel} disabled={disabled}>
          닫기
        </StyledCancelButton>
        <StyledApplyButton onClick={onConfirm} disabled={disabled}>
          적용하기
        </StyledApplyButton>
      </StyledDatePickerButtons>
    </StyledDatePickerContainer>
  );
};

export default CustomDatePicker;
