/* eslint-disable no-nested-ternary */
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageHeader from '../../../../components/common/PageHeader';
import CustomButton from '../../../../components/common/Button/Button';
import Modal from '../../../../components/common/Modal/Modal';

import useModal from '../../../../hooks/useModal';

import {
  formatGroupCode,
  formatGroupFullCode,
  formatDateTime,
  formatDataHHMMSS,
} from '../../../../utils/formatDate';
import { getSupportAlarm, putSupportAlarm } from '../../../../apis/alarm';
import AlarmTemplate from './components/AlarmTemplate';

const AlarmDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const viewTargetList = formatGroupCode(groupCodeList, 7021);
  const {
    isModalOpen,
    modalTitle,
    modalVariant,
    modalContent,
    handleConfirm,
    showModal,
    closeModal,
  } = useModal();

  const [fetchData, setFetchData] = useState('');

  const [alarmData, setAlarmData] = useState();
  const { id } = useParams();

  getSupportAlarm({
    query: id,
    onSuccess: (value) => {
      const endTime = !!value.endDate && formatDataHHMMSS(value.endDate);
      const startTime = !!value.startDate && formatDataHHMMSS(value.startDate);

      const viewType = [
        formatGroupFullCode(groupCodeList, value.viewType)?.codeName,
      ];

      setAlarmData({ ...value, viewType, endTime, startTime });
    },
  });

  const { fetch: fetchPutSupportAlarm } = putSupportAlarm({
    query: fetchData.queryString,
    onSuccess: () => {
      showModal('저장 완료', '입력하신 내용이 저장되었습니다.', 'confirm');
    },
    onFailure: () => {
      showModal('오류', '변경에 실패하였습니다. 다시 시도해주세요.', 'alert');
    },
  });

  useEffect(() => {
    const { formData, queryString } = fetchData;
    if (!queryString) return;
    fetchPutSupportAlarm(formData);
  }, [fetchData]);

  if (!alarmData) {
    return <div>공지사항을 찾을 수 없습니다.</div>;
  }

  const handleCancel = () => {
    navigate(`/CustomerService/Alarm${location.search}`, { replace: true });
  };

  const handleCheckCancel = () =>
    showModal(
      '수정을 취소 하시겠습니까?',
      '나가기 클릭시 입력하신 모든 내용이 삭제됩니다.',
      'checkExit',
      () => handleCancel(),
    );

  const handleSave = (data) => {
    if (
      data.title.trim() === '' ||
      data.shortContents.trim() === '' ||
      !data.startDate ||
      !data.startTime
    ) {
      showModal('내용을 입력해주세요', '', 'input');
    } else {
      setFetchData(handleCreateFetchData(data));
    }
  };

  const handleCreateFetchData = (value) => {
    const {
      viewType,
      viewTarget,
      title,
      shortContents,
      startDate,
      startTime,
      isPush,
      noticeId,
      fileUrl,
    } = value;

    const alarmBoardId = id;
    const alarmBoardIdQuery = `alarmBoardId=${alarmBoardId}`;

    const viewTypeCode = viewType[0] === '팝업' ? 702001 : 702002;
    const viewTypeQuery = `viewType=${viewTypeCode}`;

    const viewTargetCode = viewTargetList.codes.find(
      ({ codeName }) =>
        codeName.replace(/ /g, '') === viewTarget.replace(/ /g, ''),
    ).fullCode;
    const viewTargetQuery = `viewTarget=${viewTargetCode}`;

    const titleQuery = `title=${title}`;

    const shortContentsQuery = `shortContents=${shortContents}`;

    const startDateQuery = `startDate=${formatDateTime(startDate, startTime)}`;

    const isPushQuery = `isPush=${isPush}`;

    const noticeIdQuery = `noticeId=${noticeId}`;

    const queryString = [
      alarmBoardIdQuery,
      viewTypeQuery,
      viewTargetQuery,
      titleQuery,
      shortContentsQuery,
      startDateQuery,
      isPushQuery,
      noticeIdQuery,
    ]
      .filter(Boolean)
      .join('&');

    const formData = new FormData();

    formData.append('file', fileUrl);

    return { queryString, formData };
  };

  return (
    <StyledPostManagementContainer>
      <div>
        <PageHeader title="알림 상세" returnPage={handleCheckCancel} />
        <StyledPostManagementWrapper>
          <AlarmTemplate
            alarmData={alarmData}
            isEditable
            onCancel={handleCheckCancel}
            onSave={handleSave}
          />
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            title={modalTitle}
            content={<div style={{ textAlign: 'center' }}>{modalContent}</div>}
            actions={
              modalVariant === 'confirm' ? (
                <CustomButton
                  size="large"
                  $state="default"
                  text="확인"
                  $variant="confirm"
                  onClick={() => {
                    closeModal();
                    handleCancel();
                  }}
                />
              ) : modalVariant === 'alert' ? (
                <CustomButton
                  size="large"
                  $state="default"
                  text="확인"
                  $variant="confirm"
                  onClick={closeModal}
                />
              ) : modalVariant === 'checkExit' ? (
                <>
                  <CustomButton
                    size="large"
                    $state="default"
                    text="취소"
                    $variant="cancel"
                    onClick={closeModal}
                  />
                  <CustomButton
                    size="large"
                    $state="default"
                    $variant="alert"
                    text="나가기"
                    onClick={handleConfirm}
                  />
                </>
              ) : modalVariant === 'checkCreate' ? (
                <>
                  <CustomButton
                    size="large"
                    $state="default"
                    text="취소"
                    $variant="cancel"
                    onClick={closeModal}
                  />
                  <CustomButton
                    size="large"
                    $state="default"
                    $variant="confirm"
                    text="확인"
                    onClick={handleConfirm}
                  />
                </>
              ) : (
                <CustomButton
                  size="large"
                  $state="default"
                  text="확인"
                  $variant="alert"
                  onClick={closeModal}
                />
              )
            }
          />
        </StyledPostManagementWrapper>
      </div>
    </StyledPostManagementContainer>
  );
};
export default AlarmDetail;

const StyledPostManagementContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledPostManagementWrapper = styled.div`
  min-width: 926px;
  width: auto;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;
