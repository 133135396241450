/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
// 회원 관리 > 회원 상세 관리 테이블
import { useState, useCallback, useMemo, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import useModal from '../../../../../../hooks/useModal.js';

import Pagination from '../../../../../../components/common/Pagination/Pagination.jsx';
import Modal from '../../../../../../components/common/Modal/Modal.jsx';
import Dropdown from '../../../../../../components/common/DropDown/DropDown.jsx';
import CustomButton from '../../../../../../components/common/Button/Button.jsx';

import PublishedVideoListTable from './PublishedVideoListTable.jsx';
import PublishedVideoListTableTitle from './PublishedVideoListTableTitle.jsx';
import VideoDetailModal from '../../../../../../components/common/VideoDetailModal/VideoDetailModal.jsx';

import { PAGE_SIZE_OPTIONS } from '../../../../../../constants/constants.js';

import {
  StyledPublishedVideoListContainer,
  StyledPublishedVideoListWrapper,
  StyledPublishedVideoListListWrapper,
  StyledPublishedVideoListTableTitleContainer,
  StyledPublishedVideoListButtonGroupWrapper,
  StyledPublishedVideoListBlackBtn,
  StyledPublishedVideoCategoryText,
} from './PublishedVideoList.styles.js';

import {
  formatDateHHMM,
  formatEndTime,
  formatNumber,
  formatYYYYMMDDHHMMSS,
} from '../../../../../../utils/formatDate.js';
import CommentVideoDetailModal from '../../../../../../components/common/CommentVideoDetailModal/CommentVideoDetailModal.jsx';
import xlsxDownload from '../../../../../../utils/xlsxDownload.js';
import {
  getUserDetailVideos,
  getVideoDetailCommenmtVideo,
} from '../../../../../../apis/userManagement/index.js';
import { getVideoDetailMainVideo } from '../../../../../../apis/video/index.js';

const PublishedVideoList = ({ userId }) => {
  const [selectedVideo, setSelectedVideo] = useState({
    videoId: '',
    category: '',
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const pageSize = parseInt(searchParams.get('size')) || PAGE_SIZE_OPTIONS[0];
  const [selectedVideoData, setSelectedVideoData] = useState(null);
  const [detailVideos, setDetailVideos] = useState([]);
  const [selectedVideoIds, setSelectedVideoIds] = useState([]);

  const [isVideoDetailModalOpen, setIsVideoDetailModalOpen] = useState('');

  const [sortColumn, setSortColumn] = useState('createdAtSort');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [isExcel, setIsExcel] = useState(false);

  const handleSetSearchParams = ({ key, value }) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const handleSetSeatchParamsList = (list) => {
    const newSearchParams = new URLSearchParams(searchParams);
    list.forEach(({ key, value }) => newSearchParams.set(key, value));
    setSearchParams(newSearchParams);
  };
  const columnsLabel = useMemo(
    () => ({
      createdAtSort: '게시일자',
      state: '상태',
      category: '구분',
      title: '영상 타이틀',
      timeline: '길이',
      commentVideoCountSort: '영상댓글',
      feelkyRequestCount: '필키요청',
      likeCountSort: '좋아요',
      viewCountSort: '조회수',
      reportCountSort: '신고',
    }),
    [],
  );

  const columnsKey = useMemo(
    () => [
      {
        key: 'createdAtSort',
        width: '180px',
        sortable: true,
      },
      { key: 'state', width: '55px' },
      { key: 'category', width: '55px' },
      { key: 'title', width: '32.5%' },
      {
        key: 'timeline',
        width: '80px',
      },
      {
        key: 'commentVideoCountSort',
        width: '110px',
        sortable: true,
      },
      {
        key: 'feelkyRequestCount',
        width: '110px',
        sortable: true,
      },
      {
        key: 'likeCountSort',
        width: '100px',
        sortable: true,
      },
      {
        key: 'viewCountSort',
        width: '100px',
        sortable: true,
      },
      {
        key: 'reportCountSort',
        width: '80px',
        sortable: true,
      },
    ],
    [],
  );

  const columns = useMemo(
    () =>
      columnsKey.map(({ ...value }) => ({
        label: columnsLabel[value.key],
        ...value,
      })),
    [],
  );

  const fetchUserDetailVideos = getUserDetailVideos({
    userId,
    sortColumn,
    sortDirection,
    onSuccess: (value) => {
      const newData = value.map(({ ...item }) => {
        return {
          ...item,
          id: item.videoId,
          createdAtSort: formatYYYYMMDDHHMMSS(item.createdAt),
          commentVideoCountSort: item.commentCount
            ? formatNumber(item.commentCount)
            : '-',
          feelkyRequestCount: formatNumber(item.feelkyRequestCount),
          likeCountSort: formatNumber(item.likeCount),
          viewCountSort: formatNumber(item.viewCount),
          reportCountSort: formatNumber(item.reportCount),
          timeline: item.timeline ? `${item.timeline}초` : '-',
          category: item.category === 'MAIN' ? '메인' : '댓글',
          state: item.isActiveStatus, // 변경값
        };
      });
      if (isExcel) {
        const excelData = newData
          .filter(({ videoId }) =>
            selectedVideoIds.length ? selectedVideoIds.includes(videoId) : true,
          )
          .map((data) => {
            const mappedData = {};
            Object.keys(columnsLabel).forEach((key) => {
              mappedData[columnsLabel[key]] = data[key];
            });
            return mappedData;
          });

        handleExcelDownload(excelData);
        setIsExcel(false);
      } else {
        setDetailVideos(
          newData.map(({ title, state, ...item }) => ({
            ...item,
            state: (
              <StyledPublishedVideoCategoryText $isActive={state === '게시'}>
                {state}
              </StyledPublishedVideoCategoryText>
            ),
            title: (
              <StyledPublishedVideoListBlackBtn>
                {title}
              </StyledPublishedVideoListBlackBtn>
            ),
          })),
        );
      }
    },
  });

  const handleExcelDownload = (data) => {
    xlsxDownload(data, '영상 목록', '영상 목록 리스트');
    showModal('엑셀 다운로드가 완료되었습니다.', '', 'confirm');
  };

  const { fetch: fetchGetVideoDetailMainVideo } = getVideoDetailMainVideo({
    query: selectedVideo.videoId,
    onSuccess: (value) => {
      const { ...data } = value;
      const formatedEndTime = formatEndTime(data.videoEndTime);

      setSelectedVideoData({
        ...data,
        timeline: `${data.timeline}초`,
        meetingLocation: data.meetingLocation || '-',
        meetingCount: data.meetingCount ? `${data.meetingCount}명` : '-',
        meetingTime: data.meetingTime
          ? `${formatDateHHMM(new Date(data.meetingTime))}`
          : '-',
        tags: data.tags || '-',
        isActive: `${data.isActive ? '게시중' : '게시 종료'} ${
          formatedEndTime
            ? `( ${formatedEndTime.hour}시간${formatedEndTime.second}분 후 종료 )`
            : '( 종료 )'
        }`,
        createdAt: formatYYYYMMDDHHMMSS(data.createdAt),
      });
    },
  });

  const fetchGetVideoDetailCommenmtVideo = getVideoDetailCommenmtVideo(
    selectedVideo.videoId,
    {
      onSuccess: (value) => {
        const { createdAt, isActive, videoEndTime, ...data } = value;

        const formatedEndTime = formatEndTime(videoEndTime);

        setSelectedVideoData({
          ...data,
          timeline: `${data.timeline}초`,
          createdAt: formatYYYYMMDDHHMMSS(createdAt),
          isActive: `${isActive ? '게시중' : '게시 종료'} ${
            formatedEndTime
              ? `( ${formatedEndTime.hour}시간${formatedEndTime.second}분 후 종료 )`
              : '( 종료 )'
          }`,
        });
      },
    },
  );

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  const handleSort = useCallback(
    (column) => {
      if (column === sortColumn) {
        setSortDirection((prevDirection) =>
          prevDirection === 'ASC' ? 'DESC' : 'ASC',
        );
      } else {
        setSortColumn(column);
        setSortDirection('ASC');
      }
    },
    [sortColumn],
  );

  const handleRowClick = useCallback(({ videoId, category }) => {
    const categoryValue = category === '메인' ? 'MAIN' : 'COMMENT';

    setSelectedVideo({
      videoId,
      category: categoryValue,
    });
    setIsVideoDetailModalOpen(categoryValue);
  }, []);

  const closeVideoDetailModal = useCallback(() => {
    setSelectedVideoData(null);
    setIsVideoDetailModalOpen(false);
  }, []);

  const handleDownloadClick = useCallback(() => {
    if (selectedVideoIds.length !== 0) setIsExcel(true);
    else
      showModal(
        '전체 다운로드',
        '전체 리스트를 엑셀로 다운로드 하시겠습니까?',
        'excelConfirm',
      );
  }, [selectedVideoIds, showModal]);

  const handleDownloadConfirm = useCallback(() => {
    setSelectedVideoIds([]);
    closeModal();
  }, [selectedVideoIds, closeModal]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageVideoIds = currentPageVideos.map((user) => user.id);
      setSelectedVideoIds(currentPageVideoIds);
    } else {
      setSelectedVideoIds([]);
    }
  };

  const handleSelectVideo = useCallback((user) => {
    setSelectedVideoIds((prevselectedVideoIds) => {
      if (prevselectedVideoIds.includes(user)) {
        return prevselectedVideoIds.filter((id) => id !== user);
      }
      return [...prevselectedVideoIds, user];
    });
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      handleSetSearchParams({ key: 'page', value: page });
      setSelectedVideoIds([]);
    },
    [searchParams],
  );

  const handlePageSizeChange = useCallback(
    (selectedOption) => {
      const selectedSize = PAGE_SIZE_OPTIONS.find(
        (size) => `${size}개씩 보기` === selectedOption,
      );
      if (selectedSize) {
        handleSetSeatchParamsList([
          { key: 'page', value: 1 },
          { key: 'size', value: selectedSize },
        ]);
        setSelectedVideoIds([]);
      }
    },
    [searchParams],
  );

  const currentPageVideos = useMemo(() => {
    return detailVideos.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize,
    );
  }, [detailVideos, currentPage, pageSize]);

  useEffect(() => {
    const { videoId, category } = selectedVideo;
    if (!videoId) return;
    if (category === 'MAIN') fetchGetVideoDetailMainVideo();
    if (category === 'COMMENT') fetchGetVideoDetailCommenmtVideo();
  }, [selectedVideo]);

  const modalActions = useMemo(() => {
    return modalVariant === 'alert' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="다운로드"
          $variant={modalVariant}
          onClick={handleDownloadConfirm}
        />
      </>
    ) : modalVariant === 'excelConfirm' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="다운로드"
          $variant="confirm"
          onClick={() => {
            setIsExcel(true);
            closeModal();
          }}
        />
      </>
    ) : (
      <CustomButton
        size="large"
        $state="default"
        text="확인"
        $variant={modalVariant}
        onClick={closeModal}
      />
    );
  }, [modalVariant, closeModal, handleDownloadConfirm]);

  useEffect(() => {
    if (isExcel) fetchUserDetailVideos();
  }, [isExcel]);
  return (
    <StyledPublishedVideoListContainer>
      <StyledPublishedVideoListWrapper>
        <StyledPublishedVideoListListWrapper>
          <StyledPublishedVideoListTableTitleContainer>
            <PublishedVideoListTableTitle totalVideos={detailVideos.length} />
            <StyledPublishedVideoListButtonGroupWrapper>
              <Dropdown
                options={PAGE_SIZE_OPTIONS.map((size) => `${size}개씩 보기`)}
                value={`${pageSize}개씩 보기`}
                selectedValue={`${pageSize}개씩 보기`}
                onOptionChange={handlePageSizeChange}
              />
              <CustomButton
                size="small"
                $state="default"
                text="엑셀 다운로드"
                $variant="exceldown"
                onClick={handleDownloadClick}
              />
            </StyledPublishedVideoListButtonGroupWrapper>
          </StyledPublishedVideoListTableTitleContainer>
          <PublishedVideoListTable
            keyValue="videoId"
            videos={currentPageVideos}
            columns={columns}
            selectedVideoIds={selectedVideoIds}
            onSelectAll={handleSelectAll}
            onSelectVideo={handleSelectVideo}
            onRowClick={handleRowClick}
            onSort={handleSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(detailVideos.length / pageSize)}
            onPageChange={handlePageChange}
          />
        </StyledPublishedVideoListListWrapper>
      </StyledPublishedVideoListWrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={modalActions}
      />
      <VideoDetailModal
        isOpen={isVideoDetailModalOpen === 'MAIN'}
        onClose={closeVideoDetailModal}
        videoData={selectedVideoData}
      />
      <CommentVideoDetailModal
        isOpen={isVideoDetailModalOpen === 'COMMENT'}
        onClose={closeVideoDetailModal}
        videoData={selectedVideoData}
      />
    </StyledPublishedVideoListContainer>
  );
};

export default PublishedVideoList;
