import {
  StyledNoticeListTableTitleText,
  StyledNoticeListTableTitleTitleWrapper,
} from './NoticeList.styles';

const NoticeListTableTitle = ({ totalUsers }) => {
  return (
    <StyledNoticeListTableTitleTitleWrapper>
      <StyledNoticeListTableTitleText>
        이벤트/공지사항 목록
      </StyledNoticeListTableTitleText>
      <StyledNoticeListTableTitleText>
        {totalUsers}
      </StyledNoticeListTableTitleText>
    </StyledNoticeListTableTitleTitleWrapper>
  );
};

export default NoticeListTableTitle;
