// 대시보드 > 게시물 현황 탭
import { useState } from 'react';

import Chart from '../../../../components/common/Chart/Chart';

import ActivityStats from './ActivityStats';

import {
  StyledMemberStatusFirstWrapper,
  StyledMemberStatusSecondWrapper,
} from './MemberStatus.styles';

import {
  tooltipFormatterForMembership,
  tooltipFormatterForSignup,
  tooltipFormatterForAge,
} from '../../../../utils/tooltipFormatters';
import getDashboardUsersTotal from '../../../../apis/dashbord/getDashboardUsersTotal';
import getDashboardUsersStatus from '../../../../apis/dashbord/getDashboardUsersStatus';
import getDashboardUsersAge from '../../../../apis/dashbord/getDashboardUsersAge';
import { formatNumber } from '../../../../utils/formatDate';
import { DASHBORD_OPTIONS_RENDER_ITEM } from '../../../../constants/constants';

const MemberStatus = () => {
  // 각 차트의 선택된 기간을 독립적으로 관리하는 상태
  const [selectedPeriodForMembership, setSelectedPeriodForMembership] =
    useState(7);
  const [selectedPeriodForSignup, setSelectedPeriodForSignup] = useState(7);

  const usersTotal = getDashboardUsersTotal(selectedPeriodForMembership);

  const usersStatus = getDashboardUsersStatus(selectedPeriodForSignup);

  const usersAge = getDashboardUsersAge();

  // 첫 번째 차트의 기간 선택 핸들러
  const handlePeriodChangeForMembership = (period) => {
    setSelectedPeriodForMembership(period);
  };

  // 두 번째 차트의 기간 선택 핸들러
  const handlePeriodChangeForSignup = (period) => {
    setSelectedPeriodForSignup(period);
  };

  return (
    <>
      <StyledMemberStatusFirstWrapper>
        <Chart
          title="총 회원수 현황"
          subtitle={`누적 회원수 : ${formatNumber(
            usersTotal?.totalUsers || 0,
          )}명`}
          data={
            usersTotal
              ? usersTotal?.userCountByDateRangeList.map(
                  ({ date, totalUsers }) => ({
                    date,
                    value: totalUsers,
                  }),
                )
              : []
          }
          xAxisKey="date"
          yAxisKey="value"
          tooltipFormatter={tooltipFormatterForMembership}
          period={selectedPeriodForMembership}
          onPeriodChange={handlePeriodChangeForMembership}
          showDropdown
          dateFormat="MM.DD"
        />
        <Chart
          title="신규가입 / 탈퇴 현황"
          subtitle={`${DASHBORD_OPTIONS_RENDER_ITEM[selectedPeriodForSignup]} 신규 회원수 : ${usersStatus?.todayNewJoinUserCount}명 / 탈퇴수 : ${usersStatus?.todayNewWithdrawUserCount}명`}
          data={
            usersStatus
              ? usersStatus.userNewUserOutUserDtos.map(
                  ({
                    date,
                    newUserCount,
                    outUserCount,
                    newUserPercentChange,
                    outUserPercentChange,
                  }) => ({
                    date,
                    value: newUserCount,
                    value2: outUserCount,
                    valuePercent: newUserPercentChange,
                    value2Percent: outUserPercentChange,
                  }),
                )
              : []
          }
          xAxisKey="date"
          yAxisKey="value"
          tooltipFormatter={tooltipFormatterForSignup}
          period={selectedPeriodForSignup}
          onPeriodChange={handlePeriodChangeForSignup}
          showDropdown
          dateFormat="MM.DD"
          hasSecondLineColor="#F76659"
        />
      </StyledMemberStatusFirstWrapper>
      <StyledMemberStatusSecondWrapper>
        <ActivityStats />
        <Chart
          isBar
          title="연령대별 사용자 현황"
          data={
            usersAge
              ? usersAge.map(({ age, userCount }) => ({
                  age,
                  value: userCount,
                }))
              : []
          }
          xAxisKey="age"
          yAxisKey="value"
          tooltipFormatter={tooltipFormatterForAge}
          chartHeight={100}
          hideYAxis="true"
          containerStyle={{ height: '174.09px' }}
        />
      </StyledMemberStatusSecondWrapper>
    </>
  );
};

export default MemberStatus;
