import EmptyList from '../../../../components/common/EmptyList/EmptyList';
import TableDataRow from '../../../../components/common/Table/TableDataRow';
import TableHeaderRow from '../../../../components/common/Table/TableHeaderRow';
import { StyledAdminInfoTableWrapper } from './AdminInfo.styles';

const AdminInfoTable = ({
  users,
  columns,
  selectedUserIds,
  onSelectAll,
  onSelectUser,
  onRowClick,
  onSort,
  sortColumn,
  sortDirection,
}) => {
  const isAllSelected =
    users.length > 0 &&
    users.every((user) => selectedUserIds.includes(user.id));

  return (
    <StyledAdminInfoTableWrapper>
      <TableHeaderRow
        columns={columns}
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
        onSort={onSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
      />
      {users.length ? (
        users.map((user) => (
          <TableDataRow
            key={user.id}
            data={user}
            columns={columns}
            onSelect={onSelectUser}
            isSelected={selectedUserIds.includes(user.id)}
            onRowClick={() => onRowClick(user)}
          />
        ))
      ) : (
        <EmptyList title="관리자 목록" />
      )}
    </StyledAdminInfoTableWrapper>
  );
};

export default AdminInfoTable;
