import EmptyList from '../../../../../components/common/EmptyList/EmptyList';
import TableDataRow from '../../../../../components/common/Table/TableDataRow';
import TableHeaderRow from '../../../../../components/common/Table/TableHeaderRow';
import { StyledNoticeListTableWrapper } from './AlarmList.styles';

const AlarmListTable = ({
  posts,
  columns,
  selectedPostIds,
  onSelectAll,
  onSelectPost,
  onRowClick,
  onSort,
  onNoticeTitleClick,
}) => {
  const isAllSelected =
    posts.length > 0 &&
    posts.every((post) => selectedPostIds.includes(post.id));

  return (
    <StyledNoticeListTableWrapper>
      <TableHeaderRow
        columns={columns}
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
        onSort={onSort}
      />
      {posts.length ? (
        posts.map((post) => (
          <TableDataRow
            key={post.id}
            data={post}
            columns={columns}
            onSelect={onSelectPost}
            isSelected={selectedPostIds.includes(post.id)}
            onRowClick={() => onRowClick(post.id)}
            onNoticeTitleClick={onNoticeTitleClick}
          />
        ))
      ) : (
        <EmptyList title="알림 목록" />
      )}
    </StyledNoticeListTableWrapper>
  );
};

export default AlarmListTable;
