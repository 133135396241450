/* eslint-disable no-unused-vars */
import styled from 'styled-components';

import PageHeader from '../../../../components/common/PageHeader';
import CustomButton from '../../../../components/common/Button/Button';
import Modal from '../../../../components/common/Modal/Modal';
import useModal from '../../../../hooks/useModal';

import KpiStats from './KpiStats';
import InquiryAndReportStats from './InquiryAndReportStats';
import xlsxDownload from '../../../../utils/xlsxDownload';

const StyledDashboardHeaderContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const DashboardHeader = () => {
  const { isModalOpen, showModal, closeModal } = useModal();

  const handleLocationChange = (url) => {
    window.open(`${url}`, '_blank');
  };

  const handleFirebaseClick = () => {
    handleLocationChange(process.env.REACT_APP_FIREBASE_URL);
  };

  const handleExcelClick = () => {
    // xlsxDownload(filterDataToDownload);
    showModal();
  };

  const excelActions = (
    <CustomButton
      size="large"
      $state="default"
      text="확인"
      $variant="confirm"
      onClick={closeModal}
    />
  );
  const buttons = [
    {
      size: 'small',
      text: '파이어베이스 이동',
      onClick: handleFirebaseClick,
    },
    {
      size: 'small',
      text: '엑셀 다운로드',
      disabled: true,
      onClick: handleExcelClick,
    },
  ];

  return (
    <>
      <PageHeader title="대시보드" buttons={buttons} />
      <StyledDashboardHeaderContainer>
        <InquiryAndReportStats />
        <KpiStats />
      </StyledDashboardHeaderContainer>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="엑셀 다운로드가 완료되었습니다."
        actions={excelActions}
      />
    </>
  );
};

export default DashboardHeader;
