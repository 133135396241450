import {
  StyledUserListTableTitleTitleWrapper,
  StyledUserListTableTitleText,
} from './UserList.styles';

const UserListTableTitle = ({ totalUsers }) => {
  return (
    <StyledUserListTableTitleTitleWrapper>
      <StyledUserListTableTitleText>회원 목록</StyledUserListTableTitleText>
      <StyledUserListTableTitleText>{totalUsers}</StyledUserListTableTitleText>
    </StyledUserListTableTitleTitleWrapper>
  );
};

export default UserListTableTitle;
