// 내부Reducer
export const initialState = {
  selectedMenuItem: null,
  selectedDropdownItem: null,
  openDropdownItem: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_MENU_ITEM':
      return {
        ...state,
        selectedMenuItem: action.payload,
        openDropdownItem: null,
        selectedDropdownItem: null,
      };
    case 'SET_OPEN_DROPDOWN_ITEM':
      return {
        ...state,
        openDropdownItem: action.payload,
        selectedMenuItem:
          action.payload !== state.openDropdownItem ? action.payload : null,
      };
    case 'SET_SELECTED_DROPDOWN_ITEM':
      return {
        ...state,
        selectedDropdownItem: action.payload,
        selectedMenuItem: null,
      };
    default:
      return state;
  }
};

// 1. 초기 상태(initialState):
//     - `selectedMenuItem`: 현재 선택된 메뉴 항목을 나타내는 상태입니다. 초기값은 `null`입니다.
//     - `selectedDropdownItem`: 현재 선택된 드롭다운 메뉴 항목을 나타내는 상태입니다. 초기값은 `null`입니다.
//     - `openDropdownItem`: 현재 열려 있는 드롭다운 메뉴 항목을 나타내는 상태입니다. 초기값은 `null`입니다.
// 2. 리듀서 함수(reducer):
//     - 리듀서 함수는 현재 상태(state)와 액션(action)을 인자로 받아 새로운 상태를 반환합니다.
//     - `switch` 문을 사용하여 액션의 타입에 따라 다른 상태 업데이트 로직을 수행합니다.
//     - `SET_SELECTED_MENU_ITEM` 액션:
//         - 선택된 메뉴 항목을 업데이트합니다.
//         - `selectedMenuItem`을 `action.payload`로 설정하고, `openDropdownItem`과 `selectedDropdownItem`을 `null`로 초기화합니다.
//         - 이는 메뉴 항목을 선택할 때 드롭다운 메뉴와 관련된 상태를 초기화하는 역할을 합니다.
//     - `SET_OPEN_DROPDOWN_ITEM` 액션:
//         - 열려 있는 드롭다운 메뉴 항목을 업데이트합니다.
//         - `openDropdownItem`을 `action.payload`로 설정합니다.
//         - `selectedMenuItem`을 `action.payload`와 비교하여 설정합니다.
//             - 현재 열려 있는 드롭다운 메뉴 항목과 `action.payload`가 다르면 `selectedMenuItem`을 `action.payload`로 설정하고, 같으면 `selectedMenuItem`을 `null`로 초기화합니다.
//             - 이는 드롭다운 메뉴를 열고 닫을 때 선택된 메뉴 항목을 업데이트하는 역할을 합니다.
//     - `SET_SELECTED_DROPDOWN_ITEM` 액션:
//         - 선택된 드롭다운 메뉴 항목을 업데이트합니다.
//         - `selectedDropdownItem`을 `action.payload`로 설정하고, `selectedMenuItem`을 `null`로 초기화합니다.
//         - 이는 드롭다운 메뉴 항목을 선택할 때 선택된 메뉴 항목을 초기화하는 역할을 합니다.
//     - `default` 케이스:
//         - 매칭되는 액션 타입이 없는 경우 현재 상태를 그대로 반환합니다.

// 이 리듀서 함수는 사이드바 컴포넌트의 상태 관리 로직을 정의하고 있습니다. 각 액션에 따라 상태를 업데이트하고, 새로운 상태를 반환합니다.
// 이를 통해 사이드바의 선택된 메뉴 항목, 열려 있는 드롭다운 메뉴 항목, 선택된 드롭다운 메뉴 항목 등의 상태를 관리할 수 있습니다.
