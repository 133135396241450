/* eslint-disable no-unused-vars */
import {
  StyledInquiriesStatsContainer,
  StyledInquiriesStatsWrapper,
  StyledInquiriesStatsHeader,
  StyledInquiriesStatsTitle,
  StyledInquiriesStatsContent,
  StyledInquiriesStatsBox,
  StyledInquiriesStatsBoxSecond,
  StyledInquiriesStatsItem,
  StyledInquiriesStatsLabel,
  StyledInquiriesStatsValue,
} from './InquiriesStats.styles';

const InquiriesStats = ({ inquiryStats, reportStats }) => {
  const renderStatusItem = (label, value) => (
    <StyledInquiriesStatsItem key={label}>
      <StyledInquiriesStatsLabel>{label}</StyledInquiriesStatsLabel>
      <StyledInquiriesStatsValue>{value}</StyledInquiriesStatsValue>
    </StyledInquiriesStatsItem>
  );

  return (
    <StyledInquiriesStatsContainer>
      {/* <StyledInquiriesStatsWrapper>
        <StyledInquiriesStatsHeader>
          <StyledInquiriesStatsTitle>문의 관리</StyledInquiriesStatsTitle>
        </StyledInquiriesStatsHeader>

        <StyledInquiriesStatsContent>
          <StyledInquiriesStatsBox>
            {renderStatusItem('신규 문의', inquiryStats.new)}
            {renderStatusItem('미처리 문의', inquiryStats.pending)}
            {renderStatusItem('처리 완료된 문의', inquiryStats.completed)}
          </StyledInquiriesStatsBox>
        </StyledInquiriesStatsContent>
      </StyledInquiriesStatsWrapper> */}

      <StyledInquiriesStatsWrapper>
        <StyledInquiriesStatsHeader>
          <StyledInquiriesStatsTitle>신고 관리</StyledInquiriesStatsTitle>
        </StyledInquiriesStatsHeader>

        <StyledInquiriesStatsContent>
          <StyledInquiriesStatsBoxSecond>
            {reportStats.map(({ name, value }) =>
              renderStatusItem(name, value),
            )}
            {/* {renderStatusItem('신규 신고', reportStats.new)}
            {renderStatusItem('미처리 신고', reportStats.pending)}
            {renderStatusItem('처리 완료된 신고', reportStats.completed)} */}
          </StyledInquiriesStatsBoxSecond>
        </StyledInquiriesStatsContent>
      </StyledInquiriesStatsWrapper>
    </StyledInquiriesStatsContainer>
  );
};

export default InquiriesStats;
