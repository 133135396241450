import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import PageHeader from '../../../../components/common/PageHeader';
import UserBasicInfo from './components/UserBasicInfo/UserBasicInfo';
import PublishedVideoList from './components/PublishedVideoList/PublishedVideoList';

const StyledUUserManagementDetailContainer = styled.div``;
const StyledUserManagementDetailWrapper = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;

const UserManagementDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleReturnPageClick = useCallback(() => {
    const fullPath = new URLSearchParams(location.search).get('fullPath');
    const returnPageURI = decodeURIComponent(fullPath).toString();
    navigate(returnPageURI);
  }, []);

  return (
    <StyledUUserManagementDetailContainer>
      <PageHeader
        title="회원 상세 관리"
        returnPage={() => handleReturnPageClick()}
      />
      <StyledUserManagementDetailWrapper>
        <UserBasicInfo userId={id} />
        <PublishedVideoList userId={id} />
      </StyledUserManagementDetailWrapper>
    </StyledUUserManagementDetailContainer>
  );
};

export default UserManagementDetail;
