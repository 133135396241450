/* eslint-disable no-unused-vars */
import {
  StyledModalOverlay,
  StyledModalWrapper,
  StyledModalTitle,
  StyledModalContent,
  StyledModalActions,
} from './Modal.styles';

const Modal = ({ isOpen, onClose, title, content, actions }) => {
  if (!isOpen) return null;

  return (
    <StyledModalOverlay onClick={onClose}>
      <StyledModalWrapper onClick={(e) => e.stopPropagation()}>
        {title && <StyledModalTitle>{title}</StyledModalTitle>}
        {content && <StyledModalContent>{content}</StyledModalContent>}
        {actions && <StyledModalActions>{actions}</StyledModalActions>}
      </StyledModalWrapper>
    </StyledModalOverlay>
  );
};

export default Modal;
