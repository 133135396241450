import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import PageHeader from '../../../../../components/common/PageHeader';

const AdminInfoDetailHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleReturnPageClick = useCallback(() => {
    navigate(`/AccountManagement${location.search}`);
  }, []);

  return <PageHeader title="계정 상세" returnPage={handleReturnPageClick} />;
};

export default AdminInfoDetailHeader;
