import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

export const getVideoDetail = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/video/detail/${query}`,
    method: 'get',
    onSuccess: (value) => onSuccess(value),
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const getVideoDetailCommentVideo = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/video/detail/commentVideo/${query}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const putVideoStatus = ({ query, onSuccess }) => {
  const { fetch } = useFetch({
    url: `/video/status?mainVideoId=${query.postId}&changeVideoEndTime=${query.changeTime}&isActiveStatus=${query.activeStatus}`,
    method: 'put',
    onSuccess: (value) => onSuccess(value),
  });

  return { fetch };
};

export const putVideoCommentStatus = ({ query, onSuccess }) => {
  const { fetch } = useFetch({
    url: `/video/comment/status?commentVideoId=${query.postId}&isActiveStatus=${query.activeStatus}`,
    method: 'put',
    onSuccess: (value) => onSuccess(value),
  });

  return { fetch };
};

export const getVideoDetailMainVideo = ({ query, onSuccess }) => {
  const { fetch } = useFetch({
    url: `/video/detail/mainVideo/${query}`,
    method: 'get',
    onSuccess,
  });

  return { fetch };
};

export const getVideoAddress = ({ isInitialRequest = true, onSuccess }) => {
  const { data, fetch } = useFetch({
    url: '/video/address',
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const postVideo = ({ query, onSuccess, onFailure }) => {
  const { fetch } = useFetch({
    url: `/video?${query}`,
    method: 'post',
    onSuccess,
    onFailure,
  });

  return { fetch };
};

export const postVideoComment = ({ query, onSuccess, onFailure }) => {
  const { fetch } = useFetch({
    url: `/video/comment?${query}`,
    method: 'post',
    onSuccess,
    onFailure,
  });

  return { fetch };
};

export const getVideoDetailLikes = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/video/detail/likes?isExcel=${query.isExcel}&videoId=${query.postId}&videoType=${query.postType}&sortKey=${query.sortColumn}&pageSize=${query.pageSize}&sort=${query.sortDirection}&currentPage=${query.currentPage}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const getVideoDetailFeelkyRequests = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/video/detail/feelkyRequests?isExcel=${query.isExcel}&videoId=${query.postId}&videoType=${query.postType}&sortKey=${query.sortColumn}&pageSize=${query.pageSize}&sort=${query.sortDirection}&currentPage=${query.currentPage}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const getVideoDetailComments = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/video/detail/comments?isExcel=${query.isExcel}&mainVideoId=${query.postId}&sortKey=${query.sortColumn}&pageSize=${query.pageSize}&sort=${query.sortDirection}&currentPage=${query.currentPage}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const deleteVideoDetailLike = ({ onSuccess }) => {
  const { fetch } = useFetch({
    url: '/video/detail/like',
    method: 'delete',
    onSuccess,
  });

  return { fetch };
};

export const getVideoCreator = ({ isInitialRequest = true, onSuccess }) => {
  const { data, fetch } = useFetch({
    url: '/video/creator',
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const patchVideoDetailCommentStatus = ({
  query,
  onSuccess,
  onFailure,
}) => {
  const { fetch, isLoading } = useFetch({
    url: `/video/detail/comment/status/${query.mainVideoId}?status=${query.status}&type=${query.type}`,
    method: 'patch',
    onSuccess,
    onFailure,
  });

  return { fetch, isLoading };
};
