import {
  StyledNoticeListTableTitleText,
  StyledNoticeListTableTitleWrapper,
} from './NoticeList.styles';

const NoticeListTableTitle = ({ totalPosts }) => {
  return (
    <StyledNoticeListTableTitleWrapper>
      <StyledNoticeListTableTitleText>목록</StyledNoticeListTableTitleText>
      <StyledNoticeListTableTitleText>
        {totalPosts}
      </StyledNoticeListTableTitleText>
    </StyledNoticeListTableTitleWrapper>
  );
};

export default NoticeListTableTitle;
