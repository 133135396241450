import SyncLoader from 'react-spinners/SyncLoader';
import { StyledLoadingWrapper } from '../AdminInfoDetail.style';

const AdminInfoDetailLoading = () => {
  return (
    <StyledLoadingWrapper>
      <SyncLoader color="#000000" size={15} margin={2} />
    </StyledLoadingWrapper>
  );
};

export default AdminInfoDetailLoading;
