import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

const getUsers = ({
  currentPage,
  pageSize,
  sortColumn,
  sortDirection,
  isExcel,
  userFilterQuery,
}) => {
  const { data, fetch } = useFetch({
    url: `/users?page=${
      currentPage - 1
    }&size=${pageSize}&${sortColumn}=${sortDirection}&isExcel=${isExcel}${userFilterQuery}`,
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, [currentPage, pageSize, sortColumn, sortDirection, userFilterQuery]);

  useEffect(() => {
    if (isExcel) fetch();
  }, [isExcel]);

  return data;
};

export default getUsers;
