import { useRef, useEffect } from 'react';

import { StyledNoticeTemplateDatePickerWrapper } from './PostTemplate.styles';
import CustomDatePicker from '../../../../components/common/DatePicker/CustomDatePicker';

const NoticeTemplateDayPicker = ({
  startDate,
  onChange,
  onCancel,
  onConfirm,
  showDatePicker,
  setShowDatePicker,
  disabled,
}) => {
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDatePicker, setShowDatePicker]);

  return (
    <StyledNoticeTemplateDatePickerWrapper ref={datePickerRef}>
      {showDatePicker && (
        <CustomDatePicker
          selectedDate={startDate}
          onChange={onChange}
          onCancel={onCancel}
          onConfirm={onConfirm}
          disabled={disabled}
        />
      )}
    </StyledNoticeTemplateDatePickerWrapper>
  );
};

export default NoticeTemplateDayPicker;
