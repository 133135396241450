import styled from 'styled-components';

export const StyledDropDownWrapper = styled.div`
  position: relative;
  display: inline-block;
  ${({ $minWidth }) => $minWidth === '100%' && 'width: 100%;'};
`;

export const StyledDropDownContent = styled.div`
  display: block;
  position: absolute;
  right: 0;
  background-color: white;
  min-width: ${({ $minWidth }) => $minWidth || '120px'};
  max-width: ${({ $minWidth }) => $minWidth || '120px'};
  max-height: ${({ $maxHeight }) => $maxHeight || 'none'};
  overflow-y: auto;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 4px;
  border: 0.5px solid #f1f2f4;
  z-index: 100;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9d9d9d;
    border-radius: 4px;
  }
`;

export const StyledDropDownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  /* color: #262626; */
  color: ${({ $error }) => ($error ? '#f76659' : '#262626')};
  padding: 5px 8px;
  font-size: ${({ $fontSize }) => $fontSize || '14px'};
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 22px;
  /* border: 1px solid #dddfe3; */
  border: 1px solid ${({ $error }) => ($error ? '#f76659' : '#dddfe3')};
  border-radius: 4px;
  cursor: pointer;
  width: 100%;

  min-width: ${({ $minWidth }) => $minWidth || '120px'};
  height: ${({ $height }) => $height || 'auto'};
`;

export const StyledDropDownPopoverCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 6px 6px;
  border-radius: 4px;
  font-size: ${({ $fontSize }) => $fontSize || '14px'};
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
`;

export const StyledDropDownPopoverText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const StyledDropDownCheckIcon = styled.div``;
