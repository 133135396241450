/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';
import {
  LineChart,
  BarChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
} from 'recharts';

import { formatDateDD, formatDateMMDD } from '../../../utils/formatDate';

import Dropdown from '../DropDown/DropDown';

import {
  StyledChartContainer,
  StyledChartWrapper,
  StyledChartHeader,
  StyledChartTitleWrapper,
  StyledChartTitle,
  StyledChartSubtitle,
  StyledChartContent,
  StyledChartTooltipContainer,
} from './Chart.styles';
import {
  CHART_OPTIONS,
  CHART_OPTIONS_RENDER_ITEM,
} from '../../../constants/constants';

const CustomDot = ({ cx, cy, color = '#412CAB' }) => {
  return (
    <circle cx={cx} cy={cy} r={6} stroke={color} strokeWidth={4} fill="white" />
  );
};

const CustomTooltip = ({ active, payload, label, formatter }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload; // 현재 활성화된 데이터 포인트의 정보
    const { value } = payload[0]; // 첫 번째 값 (value)
    const value2 = payload[1]?.value; // 두 번째 값 (value2), 존재하지 않으면 undefined

    const formattedText = formatter(label, value, value2, data); // 추가 정보를 포맷터 함수에 전달

    return (
      <StyledChartTooltipContainer>
        {formattedText.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </StyledChartTooltipContainer>
    );
  }
  return null;
};

const Chart = ({
  title,
  subtitle,
  data,
  xAxisKey,
  yAxisKey,
  tooltipFormatter,
  period,
  onPeriodChange,
  showDropdown,
  dateFormat,
  chartHeight = '100%',
  hideYAxis = false,
  containerStyle,
  isBar = false,
  hasSecondLineColor = '#00CA80',
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // 데이터는 상위 컴포넌트로부터 필터링된 상태로 전달됨을 가정
    setChartData(data);
  }, [data, period]); // period 의존성 추가

  const renderTooltip = (props) => (
    <CustomTooltip
      active={props.active}
      payload={props.payload}
      label={props.label}
      formatter={tooltipFormatter}
    />
  );

  const formatDate = (tickItem) => {
    const date = new Date(tickItem);
    switch (dateFormat) {
      case 'DD':
        return formatDateDD(date);
      case 'MM.DD':
        return formatDateMMDD(date);
      default:
        return tickItem; // 기본적으로는 전달받은 형식을 그대로 사용
    }
  };

  const hasSecondLine = data.length > 0 && 'value2' in data[0];

  return (
    <StyledChartContainer style={containerStyle}>
      <StyledChartWrapper>
        <StyledChartHeader>
          <StyledChartTitleWrapper>
            <StyledChartTitle>{title}</StyledChartTitle>
            <StyledChartSubtitle>{subtitle}</StyledChartSubtitle>
          </StyledChartTitleWrapper>
          {showDropdown && (
            <div style={{ marginLeft: 'auto' }}>
              <Dropdown
                minWidth="100px"
                options={CHART_OPTIONS}
                renderItem={(option) => CHART_OPTIONS_RENDER_ITEM[option]}
                selectedValue={period}
                onOptionChange={(newPeriod) => onPeriodChange(newPeriod)}
              />
            </div>
          )}
        </StyledChartHeader>
        <StyledChartContent>
          <ResponsiveContainer width="100%" height={chartHeight}>
            {!isBar && (
              <LineChart
                data={chartData}
                margin={{ top: 10, right: 20, left: -20 }}
              >
                <XAxis
                  dataKey={xAxisKey}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                  tickFormatter={formatDate}
                  tickMargin={15}
                />
                <YAxis
                  hide={hideYAxis}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                />
                <Tooltip content={renderTooltip} cursor={false} />
                <CartesianGrid
                  strokeDasharray="3 3"
                  horizontal
                  vertical={false}
                />
                <Line
                  type="monotone"
                  dataKey={yAxisKey}
                  stroke="#412CAB"
                  strokeWidth={3}
                  dot={false}
                  activeDot={<CustomDot />}
                />
                {hasSecondLine && (
                  <Line
                    type="monotone"
                    dataKey="value2"
                    stroke={hasSecondLineColor}
                    strokeWidth={3}
                    dot={false}
                    activeDot={<CustomDot color={hasSecondLineColor} />}
                  />
                )}
              </LineChart>
            )}
            {isBar && (
              <BarChart
                data={chartData}
                margin={{ top: 10, right: 20, left: -20, bottom: -10 }}
              >
                <XAxis
                  dataKey={xAxisKey}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                  tickFormatter={formatDate}
                />
                <YAxis
                  hide={hideYAxis}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                />
                <Tooltip content={renderTooltip} />
                <CartesianGrid
                  strokeDasharray="3 3"
                  horizontal
                  vertical={false}
                />
                <CartesianGrid strokeDasharray="3 3" />
                <Bar
                  dataKey={yAxisKey}
                  fill="#412CAB"
                  dot={false}
                  activeDot={<CustomDot />}
                />
              </BarChart>
            )}
          </ResponsiveContainer>
        </StyledChartContent>
      </StyledChartWrapper>
    </StyledChartContainer>
  );
};

export default Chart;

// 1. 필요한 모듈 import:
//     - React의useState`와`useEffect`훅을 사용하기 위해 import합니다.
//     - Recharts 라이브러리에서 필요한 컴포넌트들을 import합니다.
//     - 날짜 포맷팅을 위한 유틸리티 함수들을 import합니다.
//     - 드롭다운 컴포넌트와 스타일 컴포넌트들을 import합니다.
// 2. `CustomDot`컴포넌트:
//     - 차트의 데이터 포인트를 커스텀 스타일로 렌더링하기 위한 컴포넌트입니다.
//     - 원형 모양의 점을 그리며, 크기와 색상을 지정합니다.
// 3. `CustomTooltip`컴포넌트:
//     - 차트의 툴팁을 커스텀 스타일로 렌더링하기 위한 컴포넌트입니다.
//     - 활성화된 데이터 포인트의 정보를 받아와서 포맷팅된 텍스트를 표시합니다.
//     - 포맷팅된 텍스트는 줄바꿈 문자('\n')로 구분되어 있으며, 각 줄을 `<div>` 요소로 렌더링합니다.
// 4. `Chart`컴포넌트:
//     - 차트를 렌더링하는 메인 컴포넌트입니다.
//     - 상위 컴포넌트로부터 차트 데이터, 제목, 부제목, 기간, 포맷터 함수 등의 props를 받아옵니다.
//     - `useState`훅을 사용하여`chartData` 상태를 관리합니다.
//     - `useEffect`훅을 사용하여 전달받은 데이터를 `chartData` 상태에 설정합니다.
//     - `renderTooltip` 함수는 `CustomTooltip` 컴포넌트를 렌더링하며, 툴팁 포맷터 함수를 전달합니다.
//     - `formatDate` 함수는 X축 눈금 레이블의 날짜 형식을 지정된 형식으로 포맷팅합니다.
//     - `ResponsiveContainer` 컴포넌트를 사용하여 차트의 크기를 반응형으로 조정합니다.
//     - `LineChart` 컴포넌트로 선 차트를 그립니다.
//     - X축과 Y축은 `XAxis`와 `YAxis` 컴포넌트로 설정합니다.
//     - 툴팁은 `Tooltip` 컴포넌트로 설정하고, `CustomTooltip`을 사용합니다.
//     - 차트 그리드는 `CartesianGrid` 컴포넌트로 설정합니다.
//     - 데이터 라인은 `Line` 컴포넌트로 그리며, 스타일과 활성 도트 스타일을 지정합니다.
//     - 데이터에 두 번째 값(`value2`)이 있는 경우, 두 번째 라인도 그립니다.
