// KpiStats.jsx
import StatusCard from '../../../../components/common/StatusCard/StatusCard';
import { formatNumber } from '../../../../utils/formatDate';
import getDashboardVideoStatus from '../../../../apis/dashbord/getDashboardVideoStatus';

const RealTimePostStats = () => {
  const videoStatus = getDashboardVideoStatus();

  const formatVideoStatus = (data) => {
    if (!data) return [];
    const { commentVideoCount, mainVideoCount } = Object.fromEntries(
      Object.entries(data).map(([key, count]) => [key, formatNumber(count)]),
    );
    return [
      {
        label: '메인영상 수',
        count: `${mainVideoCount}건`,
      },
      {
        label: '댓글영상 수',
        count: `${commentVideoCount}건`,
      },
    ];
  };

  return (
    <StatusCard
      title="현재 게시 중인 게시물"
      data={formatVideoStatus(videoStatus)}
      isLoading={!videoStatus}
      skeletonCount={2}
    />
  );
};

export default RealTimePostStats;
