/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

const Input = ({ value, onChange, maxLength, ...rest }) => {
  return (
    <StyledCommonInputContainer>
      <StyledInputFieldInput
        defaultValue={value}
        onChange={onChange}
        autoComplete="off"
        maxLength={maxLength}
        {...rest}
      />
      <StyledCountMaxLength>{`${value.length} / ${maxLength}`}</StyledCountMaxLength>
    </StyledCommonInputContainer>
  );
};

export default Input;

const StyledCommonInputContainer = styled.div`
  width: 100%;
  position: relative;
`;

const StyledInputFieldInput = styled.input`
  border: none;
  outline: none;
  border-radius: 0;
  padding: 0;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &::placeholder {
    color: #9d9d9d;
  }
  color: ${({ $state }) => ($state === 'error' ? '#F76659' : '#1e2024')};

  &:focus {
    border-color: #1a1a1a;
    outline: none;
  }

  &::placeholder {
    color: #9d9d9d;
    word-wrap: break-word;
  }
`;

const StyledCountMaxLength = styled.div`
  position: absolute;
  top: 50%;
  color: #9d9d9d;
  transform: translateY(-50%);
  right: 0;
`;
