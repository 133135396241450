/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import PostTemplateForm from './PostTemplateForm';
import { formatDateTime } from '../../../../utils/formatDate';

const PostTemplate = ({
  isPost = false,
  postData,
  onCancel,
  onSave,
  cancelButtonText = '나가기',
  saveButtonText = '수정',
}) => {
  const [isMute, setIsMute] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [videoTitle, setVideoTitle] = useState('');
  const [meetingLocation, setMeetingLocation] = useState('');
  const [meetingCount, setMeetingCount] = useState(0);
  const [meetingTime, setMeetingTime] = useState(null);
  const [tags, setTags] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [videoAddress, setVideoAddress] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [videoPath, setVideoPath] = useState('');
  const [timeline, setTimeline] = useState(0);
  const [videoThumbnail, setVideoThumbnail] = useState('');
  const [latLng, setLatLng] = useState({
    lat: null,
    lng: null,
  });

  const handleChangeIsMute = () => setIsMute((prev) => !prev);

  // const handleSetVideoTarget = (target) => {
  //   setVideoTarget(target);
  // };

  const handleDayChange = (date) => {
    if (startDate && startDate?.getTime() > date.getTime()) return;
    if (
      startDate &&
      startTime &&
      endTime &&
      new Date(formatDateTime(startDate, startTime)).getTime() >
        new Date(formatDateTime(date, endTime)).getTime()
    )
      return;

    setEndDate(date);
  };

  const handleStartDayChange = (date) => {
    if (endDate && endDate?.getTime() < date.getTime()) return;
    if (
      endDate &&
      startTime &&
      endTime &&
      new Date(formatDateTime(endDate, endTime)).getTime() <
        new Date(formatDateTime(date, startTime)).getTime()
    )
      return;

    setStartDate(date);
  };

  // const handdle

  const handleCancel = () => {
    setShowDatePicker(false);
  };

  const handleConfirm = () => {
    setShowDatePicker(false);
  };

  const handleVideoTitle = (event) => setVideoTitle(event.target.value);

  const handleMeetingLocation = (event) =>
    setMeetingLocation(event.target.value);
  const handleMeetingCount = (value) => setMeetingCount(value);
  const handleTags = (value) => setTags(value);

  const handleStartTime = (value) => setStartTime(value);
  const handleEndTime = (value) => setEndTime(value);
  const handleMeetingTime = (value) => setMeetingTime(value);

  const handleIsActive = () => setIsActive((prev) => !prev);

  const handleCreatedByChange = (id) => setCreatedBy(id);

  const handleSetVideoAddress = (value) => setVideoAddress(value);

  const handleVideoThumbnailChange = (event) => {
    const file = event.target.files[0];
    setVideoThumbnail(file);
  };
  const handleVideoThumbnailDelete = () => {
    setVideoThumbnail(null);
  };

  const handleVideoPathChange = (event) => {
    const file = event.target.files[0];
    setVideoPath(file);

    if (file) {
      const url = URL.createObjectURL(file);
      const video = document.createElement('video');

      video.preload = 'metadata';
      video.src = url;

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(url);
        const videoDuration = video.duration.toString().split('.')[0];
        setTimeline(videoDuration);
      };
    }
  };
  const handleVideoPathDelete = () => {
    setVideoPath(null);
    setTimeline(0);
  };

  const handleSavePost = () =>
    onSave({
      isMute,
      endDate,
      endTime,
      startDate,
      startTime,
      videoTitle,
      meetingLocation,
      meetingCount,
      meetingTime,
      tags,
      isActive,
      videoAddress,
      createdBy,
      videoPath,
      videoThumbnail,
      timeline,
      latLng,
    });
  const handleSetLatLng = ({ lat, lng }) => {
    setLatLng({
      lat,
      lng,
    });
  };

  useEffect(() => {
    // if (postData.videoTarget !== undefined)
    //   setVideoTarget(postData.videoTarget);
    // if (postData.videoTime !== undefined) setVideoTime(postData.videoTime);
    if (postData.startTime !== undefined) setStartTime(postData.startTime);
    if (postData.startDate !== undefined) setStartDate(postData.startDate);
    if (postData.endTime !== undefined) setEndTime(postData.endTime);
    if (postData.endDate !== undefined) setEndDate(postData.endDate);
    if (postData.videoTitle !== undefined) setVideoTitle(postData.videoTitle);
    if (postData.meetingLocation !== undefined)
      setMeetingLocation(postData.meetingLocation);
    if (postData.meetingCount !== undefined)
      setMeetingCount(postData.meetingCount);
    if (postData.meetingTime !== undefined)
      setMeetingTime(postData.meetingTime);
    if (postData.videoActive !== undefined) setIsActive(postData.videoActive);
    if (postData.videoAddress !== undefined)
      setVideoAddress(postData.videoAddress);
    if (postData.createdBy !== undefined) setCreatedBy(postData.createdBy);
    if (postData.videoPath !== undefined) setVideoPath(postData.videoPath);
    if (postData.videoThumbnail !== undefined)
      setVideoThumbnail(postData.videoThumbnail);
    if (postData.latLng.lat !== undefined || postData.latLng.lng !== undefined)
      setLatLng({
        lat: postData.latLng.lat,
        lng: postData.latLng.lng,
      });
    if (postData.isMute !== undefined) setIsMute(postData.isMute);
    if (postData.timeline !== undefined) setTimeline(postData.timeline);
  }, [postData]);

  return (
    <PostTemplateForm
      isPost={isPost}
      isMute={isMute}
      handleChangeIsMute={handleChangeIsMute}
      // videoTarget={videoTarget}
      // handleSetVideoTarget={handleSetVideoTarget}
      setShowDatePicker={setShowDatePicker}
      startTime={startTime}
      handleStartTime={handleStartTime}
      showDatePicker={showDatePicker}
      endTime={endTime}
      handleEndTime={handleEndTime}
      endDate={endDate}
      handleDayChange={handleDayChange}
      startDate={startDate}
      handleStartDayChange={handleStartDayChange}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      videoTitle={videoTitle}
      handleVideoTitle={handleVideoTitle}
      meetingLocation={meetingLocation}
      handleMeetingLocation={handleMeetingLocation}
      meetingCount={meetingCount}
      handleMeetingCount={handleMeetingCount}
      meetingTime={meetingTime}
      handleMeetingTime={handleMeetingTime}
      tags={tags}
      handleTags={handleTags}
      isActive={isActive}
      handleIsActive={handleIsActive}
      videoAddress={videoAddress}
      handleSetVideoAddress={handleSetVideoAddress}
      createdBy={createdBy}
      handleCreatedByChange={handleCreatedByChange}
      videoThumbnail={videoThumbnail}
      handleVideoThumbnailChange={handleVideoThumbnailChange}
      handleVideoThumbnailDelete={handleVideoThumbnailDelete}
      videoPath={videoPath}
      timeline={timeline}
      handleVideoPathChange={handleVideoPathChange}
      handleVideoPathDelete={handleVideoPathDelete}
      cancelButtonText={cancelButtonText}
      onCancel={onCancel}
      onSave={handleSavePost}
      saveButtonText={saveButtonText}
      latLng={latLng}
      handleSetLatLng={handleSetLatLng}
    />
  );
};

export default PostTemplate;
