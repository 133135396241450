import {
  StyledReportListTableTitleWrapper,
  StyledReportListTableTitleText,
} from './ReportList.styles';

const ReportListTableTitle = ({ totalUsers }) => {
  return (
    <StyledReportListTableTitleWrapper>
      <StyledReportListTableTitleText>
        전체 신고 목록
      </StyledReportListTableTitleText>
      <StyledReportListTableTitleText>
        {totalUsers}개
      </StyledReportListTableTitleText>
    </StyledReportListTableTitleWrapper>
  );
};

export default ReportListTableTitle;
