/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

export const getVideos = ({ query, isInitialRequest = true, onSuccess }) => {
  const { data, fetch } = useFetch({
    url: `/videos?currentPage=${query.currentPage}&pageSize=${query.pageSize}&sortKey=${query.sortColumn}&sort=${query.sortDirection}${query.userFilterQuery}`,
    method: 'get',
    onSuccess: (value) => onSuccess(value),
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, [
    query.currentPage,
    query.pageSize,
    query.sortColumn,
    query.sortDirection,
    query.userFilterQuery,
  ]);

  return { data, fetch };
};

export const deleteVideos = ({ onSuccess }) => {
  const { fetch } = useFetch({
    url: '/videos',
    method: 'delete',
    onSuccess,
  });

  return { fetch };
};
