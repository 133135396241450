import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

const getDashboardUsersActivityStatus = (query) => {
  const { data, fetch, isLoading } = useFetch({
    url: `/dashboard/users/activity/status?rangeDay=${query}`,
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, [query]);

  if (!data || isLoading) return null;

  return data;
};

export default getDashboardUsersActivityStatus;
