import { useNavigate } from 'react-router-dom';
import {
  StyledNotFoundButtonsWrapper,
  StyledNotFoundContainer,
  StyledNotFoundLogo,
  StyledNotFoundTextWrapper,
  StyledNotFoundTitleWrapper,
  StyledNotFoundWrapper,
} from './NotFound.styles';
import { ReactComponent as LogoIcon } from '../../assets/logo_icon.svg';
import CustomButton from '../../components/common/Button/Button';

const NotFound = () => {
  const navigate = useNavigate();

  const handleClickReturnPage = () => navigate(-1);

  const handleClickMainPage = () => navigate('/DashBoard');

  return (
    <StyledNotFoundContainer>
      <StyledNotFoundWrapper>
        <StyledNotFoundLogo>
          4
          <LogoIcon />4
        </StyledNotFoundLogo>
        <StyledNotFoundTitleWrapper>
          페이지를 찾을 수 없습니다.
        </StyledNotFoundTitleWrapper>
        <StyledNotFoundTextWrapper>
          페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.
          <br />
          입력 하신 주소가 정확한지 다시 한 번 확인해주세요.
        </StyledNotFoundTextWrapper>
        <StyledNotFoundButtonsWrapper>
          <CustomButton
            type="submit"
            size="medium"
            $state="default"
            text="이전 페이지"
            onClick={handleClickReturnPage}
            $variant="logout"
          />
          <CustomButton
            type="submit"
            size="medium"
            $state="default"
            text="메인 페이지"
            onClick={handleClickMainPage}
            $variant="passwordChange"
          />
        </StyledNotFoundButtonsWrapper>
      </StyledNotFoundWrapper>
    </StyledNotFoundContainer>
  );
};

export default NotFound;
