import styled from 'styled-components';

import UserManagementHeader from './components/UserManagementHeader/UserManagementHeader';
import UserSearchFilter from './components/UserSearchFilter/UserSearchFilter';
import UserList from './components/UserList/UserList';

const StyledUserManagementContainer = styled.div``;
const StyledUserManagementWrapper = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;

const StyledUserManagementContentsWrapper = styled(StyledUserManagementWrapper)`
  margin-top: 20px;
`;

const UserManagement = () => {
  return (
    <StyledUserManagementContainer>
      <UserManagementHeader />
      <StyledUserManagementContentsWrapper>
        <UserSearchFilter />
        <UserList />
      </StyledUserManagementContentsWrapper>
    </StyledUserManagementContainer>
  );
};
export default UserManagement;
