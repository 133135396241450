import { combineReducers } from '@reduxjs/toolkit';
import exampleReducer from './redux/exampleSlice';
import authReducer from './redux/authSlice';
import regionCategoryReducer from './redux/regionCategorySlice';
import regionCategoryListReducer from './redux/regionCategoryListSlice';
import userMangementFilterReducer from './redux/userManagementFilterSlice';
import groupCodeReducer from './redux/groupCodeSlice';
import accessPermissionsReducer from './redux/accessPermissionsSlice';
import userReducer from './redux/userSlice';

const rootReducer = combineReducers({
  example: exampleReducer, // example
  auth: authReducer,
  regionCategory: regionCategoryReducer,
  regionCategoryList: regionCategoryListReducer,
  userMangementFilter: userMangementFilterReducer,
  groupCode: groupCodeReducer,
  accessPermissions: accessPermissionsReducer,
  user: userReducer,

  // add reducer
});

export default rootReducer;
