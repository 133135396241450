// validation.js
export const validatePassword = (password, confirmPassword) => {
  if (!password.trim())
    return {
      isValid: false,
      message: '비밀번호를 입력해주세요.',
      variant: 'alert',
    };
  if (!confirmPassword.trim())
    return {
      isValid: false,
      message: '비밀번호 재입력 값을 입력해주세요.',
      variant: 'alert',
    };
  if (password !== confirmPassword)
    return {
      isValid: false,
      message: '비밀번호와 비밀번호 재입력이 일치하지 않습니다.',
      variant: 'alert',
    };

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}[\]|\\:;"'<>,.?/-])[a-zA-Z\d!@#$%^&*()_+={}[\]|\\:;"'<>,.?/-]{8,12}$/;
  if (!passwordRegex.test(password))
    return {
      isValid: false,
      message: '대소문자, 숫자, 특수문자를 모두 포함한 8~12자로 입력해주세요.',
      variant: 'alert',
    };

  return { isValid: true };
};

export const validateEmail = (email) => {
  // 이메일 유효성 검사를 위한 정규식
  const emailRegex =
    /^[a-zA-Z0-9._%+-]+@(?!.*\.\.)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const validateKrName = (name) => {
  const krNameRegex = /^[가-힣]{2,4}$/;
  return krNameRegex.test(name);
};
