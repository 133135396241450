/* eslint-disable import/prefer-default-export */
// StyledComponents.js
import styled from 'styled-components';

// index
export const StyledAccountManagementContents = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  /* border: 1px solid #f1f2f4; */
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
`;
