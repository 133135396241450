import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import CustomButton from '../../../../../components/common/Button/Button';
import Dropdown from '../../../../../components/common/DropDown/DropDown';
import { formatGroupCode } from '../../../../../utils/formatDate';
import { putSupportInquirySetail } from '../../../../../apis/support';

const InquiryDetailModal = ({
  targetId,
  isOpen,
  onSuccess,
  onClose,
  reportData,
}) => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);

  const reportClearOptions = formatGroupCode(groupCodeList, '6010').codes.map(
    ({ fullCode, codeName }) => ({
      fullCode,
      codeName,
    }),
  );

  const [selectedReportStatus, setSecletedReportStatus] = useState('');
  const [isError, setIsError] = useState(false);

  const { isLoading, fetch: fetchPutSupportInquirySetail } =
    putSupportInquirySetail({
      query: {
        targetId,
        selectedReportStatus,
      },
      onSuccess: () => {
        onClose();
        if (onSuccess) onSuccess();
      },
    });

  const handleOptionChange = (value) => {
    if (isError) setIsError(false);
    setSecletedReportStatus(
      reportClearOptions.find(({ codeName }) => codeName === value).fullCode,
    );
  };

  const handleUpdateInquiryDetail = () => {
    if (!selectedReportStatus) setIsError(true);
    else {
      fetchPutSupportInquirySetail();
    }
  };

  const handleCloseModal = () => {
    setIsError(false);
    setSecletedReportStatus('');
    onClose();
  };

  if (!isOpen || !reportData) return null;

  const { list, reportStatus } = reportData;
  const etcReportDatas = list.filter(
    (item) => item.reportCategory === '600908',
  );
  const reportDatas = list.filter((item) => item.reportCategory !== '600908');
  const options = reportClearOptions
    .filter(({ fullCode }) => fullCode !== reportStatus)
    .map(({ codeName }) => codeName);

  return (
    <StyledModalOverlay>
      <StyledModalWrapper>
        <StyledModalTitle>신고처리</StyledModalTitle>
        <StyledModalContent>
          {reportDatas.map(({ message, messageCount }) => (
            <StyledInquiryContent key={message}>
              <div>{message}</div>
              <div>{messageCount}건</div>
            </StyledInquiryContent>
          ))}
          {etcReportDatas.length ? (
            <StyledInquiryEtcContent>
              <StyledInquiryEtcTitle>
                <div>기타</div>
                <div>{etcReportDatas.length}건</div>
              </StyledInquiryEtcTitle>
              <StyledInquiryEtcsWrapper>
                <ul>
                  {etcReportDatas.map(({ message }) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </StyledInquiryEtcsWrapper>
            </StyledInquiryEtcContent>
          ) : null}
          <StyledDropdownContent>
            <Dropdown
              options={options}
              minWidth="100%"
              height="40px"
              value="처리 방법을 선택해주세요."
              error={isError}
              selectedValue="처리 방법을 선택해주세요."
              onOptionChange={handleOptionChange}
            />
          </StyledDropdownContent>
        </StyledModalContent>
        <StyledModalActions>
          <CustomButton
            size="large"
            $state="default"
            text="취소"
            $variant="cancel"
            onClick={handleCloseModal}
          />
          <CustomButton
            size="large"
            $state="default"
            text="확인"
            $variant="confirm"
            disabled={isLoading}
            onClick={handleUpdateInquiryDetail}
          />
        </StyledModalActions>
      </StyledModalWrapper>
    </StyledModalOverlay>
  );
};

export default InquiryDetailModal;

const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledModalWrapper = styled.div`
  width: 480px;
  background-color: white;
  border-radius: 8px;
  z-index: 1001;
  position: relative;
  padding: 34px 24px 24px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledModalTitle = styled.div`
  margin-bottom: 10px;
  color: #262626;
  font-family: 'Pretendard', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
`;

const StyledModalContent = styled.div`
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard', sans-serif;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  word-wrap: 'break-word';
`;

const StyledInquiryContent = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 5px 10px -8px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  border-radius: 4px;
  border: 0.5px solid #dddfe3;
  margin-top: 8px;
`;

const StyledInquiryEtcContent = styled.div`
  background-color: white;
  box-shadow: 0px 5px 10px -8px rgba(0, 0, 0, 0.1);
  padding: 10px 16px 16px 16px;
  border-radius: 4px;
  border: 0.5px solid #dddfe3;
  margin-top: 8px;
`;

const StyledInquiryEtcTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledInquiryEtcsWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #efefef;
  padding: 10px 16px;
  border-radius: 4px;

  li {
    font-size: 14px;
  }
  li:before {
    content: '・';
    font-size: 25px;
    vertical-align: middle;
    line-height: 20px;
  }
`;

const StyledDropdownContent = styled.div`
  margin-top: 24px;
`;

const StyledModalActions = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 12px;
`;
