/* eslint-disable consistent-return */
// AppSetting.jsx
import { useCallback, useMemo, useState } from 'react';

// 공통 컴포넌트 임포트

import CustomButton from '../../../../components/common/Button/Button';
import Modal from '../../../../components/common/Modal/Modal';

// 공통 커스텀 훅 임포트
import useModal from '../../../../hooks/useModal';

//
import CheckboxOptions from './CheckboxOptions';
import InputFields from './InputFields';

import {
  StyledAppSettingContainer,
  StyledAppSettingTitle,
  StyledAppSettingTable,
} from './AppSetting.styles';
import {
  formatDataTotalHHMMSS,
  formatDataHHMMSStoSS,
} from '../../../../utils/formatDate';
import {
  getSystemAppSettings,
  getSystemLocationCateogry,
  patchSystemBonusTime,
  patchSystemDistance,
  patchSystemFeelkyRequestAuthority,
  patchSystemLocationCategory,
  patchSystemNearbyDistance,
  patchSystemReport,
  patchSystemVideoExpoiration,
  patchSystemVideoRec,
} from '../../../../apis/system';
import DropDownOptions from './DropDownOptions';

const AppSetting = () => {
  // TODO: 프론트 작업 시, 데이터 바인딩 참조.
  // recordingTime : 영상 촬영 시간
  // postingTime : 영상 게시 시간
  // commentWriterChecked : 필키요청 권한 체크 상태
  // reportSettings : 신고 영상 자동 숨김 값.
  const [systemId, setSystemId] = useState('');
  const [recordingTimeSettings, setRecordingTimeSettings] = useState({
    hour: '',
    minute: '',
    second: '',
  });
  const [postingTimeSettings, setPostingTimeSettings] = useState({
    hour: '',
    minute: '',
    second: '',
  });
  const [distanceSettings, setDistanceSettings] = useState({
    minDistance: '',
    maxDistance: '',
  });
  const [nearbyDistance, setNearbyDistance] = useState('');
  const [commentWriterChecked, setCommentWriterChecked] = useState('');
  const [reportSettings, setReportSettings] = useState({
    minute: '',
    count: '',
  });
  const [regionalSetting, setRegionalSetting] = useState('');

  const [bounsTimeSettings, setBounsTimeSettings] = useState({
    hour: '',
    minute: '',
    second: '',
  });

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
    handleConfirm,
  } = useModal();

  getSystemAppSettings({
    onSuccess: (data) => {
      const {
        systemId: appSystemId,
        videoRecTimeSec,
        videoExpirationTimeSec,
        minDistance,
        maxDistance,
        feelkyRequestAuthority,
        reportTimeSec,
        reportCount,
        defaultLocationCategory,
        videoBonusTime,
        nearbyDistance: nearbyDistanceValue,
      } = data;
      setSystemId(appSystemId);

      const formatVideoExpirationTimeSec = formatDataTotalHHMMSS(
        videoExpirationTimeSec,
      );
      setPostingTimeSettings({
        hour: formatVideoExpirationTimeSec.hour,
        minute: formatVideoExpirationTimeSec.minute,
        second: formatVideoExpirationTimeSec.second,
      });

      const formatVideoRecTimeSec = formatDataTotalHHMMSS(videoRecTimeSec);
      setRecordingTimeSettings({
        hour: formatVideoRecTimeSec.hour,
        minute: formatVideoRecTimeSec.minute,
        second: formatVideoRecTimeSec.second,
      });

      setDistanceSettings({
        minDistance,
        maxDistance,
      });

      setReportSettings({
        minute: reportTimeSec / 60,
        count: reportCount,
      });

      setCommentWriterChecked(feelkyRequestAuthority);

      // API 수정 필요
      setRegionalSetting(defaultLocationCategory);
      setNearbyDistance(nearbyDistanceValue);

      const formatVideoBonusTime = formatDataTotalHHMMSS(videoBonusTime);
      setBounsTimeSettings({
        hour: formatVideoBonusTime.hour,
        minute: formatVideoBonusTime.minute,
        second: formatVideoBonusTime.second,
      });
      // setExtraTimeSettings({
      //   hour: 1,
      //   minute: 2,
      //   second: 3,
      // });
    },
  });

  const { data: locationCategory } = getSystemLocationCateogry({});
  const locationCategoryOption = useMemo(
    () => locationCategory?.map(({ categoryId }) => categoryId) || [],
    [locationCategory],
  );

  const locationCategoryOptionFormat = useCallback(
    (id) => {
      if (!locationCategory) return `${id}dd`;
      return (
        locationCategory.find(({ categoryId }) => categoryId === id)
          ?.categoryName || id
      );
    },
    [locationCategory],
  );

  const { fetch: fetchPatchSystemVideoRec } = patchSystemVideoRec();

  const { fetch: fetchPatchSystemVideoExpoiration } =
    patchSystemVideoExpoiration();

  const { fetch: fetchPatchSystemDistance } = patchSystemDistance();

  const { fetch: fetchPatchSystemFeelkyRequestAuthority } =
    patchSystemFeelkyRequestAuthority();

  const { fetch: fetchPatchSystemReport } = patchSystemReport();

  const { fetch: fetchPatchSystemLocationCategory } =
    patchSystemLocationCategory();

  const { fetch: fetchPatchSystemNearbyDistance } = patchSystemNearbyDistance();
  const { fetch: fetchPatchSystemBonusTime } = patchSystemBonusTime();

  const handelSetVideoRec = () => {
    const videoRecTimeSec = formatDataHHMMSStoSS(recordingTimeSettings);
    fetchPatchSystemVideoRec({
      systemId,
      videoRecTimeSec,
    });
  };

  const handelSetVideoExpiration = () => {
    const videoRecTimeSec = formatDataHHMMSStoSS(postingTimeSettings);

    fetchPatchSystemVideoExpoiration({
      systemId,
      videoRecTimeSec,
    });
  };

  const handleSetExtraTime = () => {
    const videoBonusTimeSec = formatDataHHMMSStoSS(bounsTimeSettings);
    fetchPatchSystemBonusTime({ systemId, videoBonusTimeSec });
  };

  const handleSetDistance = () => {
    fetchPatchSystemDistance({
      systemId,
      ...distanceSettings,
    });
  };

  const handleSetDistanceAround = () => {
    fetchPatchSystemNearbyDistance({
      systemId,
      nearbyDistance,
    });
  };

  const handleSetRegional = () => {
    fetchPatchSystemLocationCategory({
      systemId,
      locationGroupId: regionalSetting,
    });
  };

  // 정책 잡히면 수정 필요
  const handleSetFeelkyRequestAuthority = () => {
    const feelkyRequestAuthorityCode = commentWriterChecked;

    fetchPatchSystemFeelkyRequestAuthority({
      systemId,
      feelkyRequestAuthorityCode,
    });
  };

  const handleSetReport = () => {
    const { count: reportCount, minute: reportCountTime } = reportSettings;
    fetchPatchSystemReport({
      systemId,
      reportCount,
      reportCountTime: Math.floor(reportCountTime) * 60,
    });
  };

  const handleCheckboxChange = (value) => {
    setCommentWriterChecked(value);
  };

  const handleInputChange = (value, settingsKey, subKey) => {
    if (settingsKey === 'recordingTime') {
      setRecordingTimeSettings((prevSettings) => ({
        ...prevSettings,
        [subKey]: value,
      }));
    } else if (settingsKey === 'postingTime') {
      setPostingTimeSettings((prevSettings) => ({
        ...prevSettings,
        [subKey]: value,
      }));
    } else if (settingsKey === 'reportSettings') {
      setReportSettings((prevSettings) => ({
        ...prevSettings,
        [subKey]: value,
      }));
    } else if (settingsKey === 'distance') {
      setDistanceSettings((prevSettings) => ({
        ...prevSettings,
        [subKey]: value,
      }));
    } else if (settingsKey === 'extraTime') {
      setBounsTimeSettings((prevSettings) => ({
        ...prevSettings,
        [subKey]: value,
      }));
    }
  };

  return (
    <StyledAppSettingContainer>
      <StyledAppSettingTitle>앱 설정 및 변경</StyledAppSettingTitle>
      <StyledAppSettingTable>
        <InputFields
          hour
          minute
          second
          title="영상 촬영 시간"
          settings={recordingTimeSettings}
          onInputChange={(value, subKey) =>
            handleInputChange(value, 'recordingTime', subKey)
          }
          modalTitleText="영상 촬영 시간을 변경 하시겠습니까?"
          modalContentText="모든 사용자에게 즉시 적용됩니다. 그래도 변경하시겠습니까?"
          handleConfirm={handelSetVideoRec}
          showModal={showModal}
        />
        <InputFields
          hour
          minute
          second
          title="영상 게시 시간"
          settings={postingTimeSettings}
          onInputChange={(value, subKey) =>
            handleInputChange(value, 'postingTime', subKey)
          }
          modalTitleText="영상 게시 시간을 변경 하시겠습니까?"
          modalContentText="모든 사용자에게 즉시 적용됩니다. 그래도 변경하시겠습니까?"
          handleConfirm={handelSetVideoExpiration}
          showModal={showModal}
        />
        <InputFields
          title="최소/최대 거리"
          settings={distanceSettings}
          onInputChange={(value, subKey) =>
            handleInputChange(value, 'distance', subKey)
          }
          modalTitleText="영상 게시 시간을 변경 하시겠습니까?"
          modalContentText="모든 사용자에게 즉시 적용됩니다. 그래도 변경하시겠습니까?"
          handleConfirm={handleSetDistance}
          showModal={showModal}
        />
        <InputFields
          title="'내 주변' 영역"
          settings={nearbyDistance}
          onInputChange={(value) => setNearbyDistance(value)}
          modalTitleText="영상 게시 시간을 변경 하시겠습니까?"
          modalContentText="모든 사용자에게 즉시 적용됩니다. 그래도 변경하시겠습니까?"
          handleConfirm={handleSetDistanceAround}
          showModal={showModal}
        />
        <DropDownOptions
          title="위치탭 초기값"
          settings={regionalSetting}
          options={locationCategoryOption}
          optionFormat={locationCategoryOptionFormat}
          onInputChange={(value) => setRegionalSetting(value)}
          modalTitleText="필키요청 권한을 변경 하시겠습니까?"
          modalContentText="모든 사용자에게 즉시 적용됩니다. 그래도 변경하시겠습니까?"
          handleConfirm={handleSetRegional}
          showModal={showModal}
        />
        <InputFields
          minute
          title="신고 영상 자동 숨김"
          settings={reportSettings}
          onInputChange={(value, subKey) =>
            handleInputChange(value, 'reportSettings', subKey)
          }
          modalTitleText="신고 영상 자동 숨김 세팅값을 변경 하시겠습니까?"
          modalContentText="모든 사용자에게 즉시 적용됩니다. 그래도 변경하시겠습니까?"
          handleConfirm={handleSetReport}
          showModal={showModal}
        />
        <InputFields
          hour
          minute
          second
          title="추가 시간"
          settings={bounsTimeSettings}
          onInputChange={(value, subKey) =>
            handleInputChange(value, 'extraTime', subKey)
          }
          modalTitleText="영상 게시 시간을 변경 하시겠습니까?"
          modalContentText="모든 사용자에게 즉시 적용됩니다. 그래도 변경하시겠습니까?"
          handleConfirm={handleSetExtraTime}
          showModal={showModal}
          disabled
        />
        <CheckboxOptions
          title="필키요청 권한"
          checked={commentWriterChecked}
          onChange={handleCheckboxChange}
          modalTitleText="필키요청 권한을 변경 하시겠습니까?"
          modalContentText="모든 사용자에게 즉시 적용됩니다. 그래도 변경하시겠습니까?"
          handleConfirm={handleSetFeelkyRequestAuthority}
          showModal={showModal}
          disabled
        />
      </StyledAppSettingTable>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        variant={modalVariant}
        actions={
          <>
            <CustomButton
              size="large"
              $state="default"
              text="취소"
              $variant="cancel"
              onClick={closeModal}
            />
            <CustomButton
              size="large"
              $state="default"
              text="변경하기"
              $variant="alert"
              onClick={() => {
                handleConfirm();
                closeModal();
              }}
            />
          </>
        }
      />
    </StyledAppSettingContainer>
  );
};

export default AppSetting;
