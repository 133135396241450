import styled from 'styled-components';

export const TextBase = styled.div`
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 500;
  word-wrap: break-word;
`;

export const StyledUserStatsSection = styled.div`
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  /* border: 1px #f1f2f4 solid; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledUserStatsHeader = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* justify-content: center; */
  /* align-items: flex-start; */
  gap: 10px;
`;

export const StyledUserStatsTitle = styled(TextBase)`
  color: #1e2024;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`;

export const StyledUserStatsContent = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const StyledUserStatsTotalUsersItem = styled.div`
  min-width: 21.56%;
  padding: 14px 24px;
  background: rgba(233, 232, 255, 0.6);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const StyledUserStatsItem = styled.div`
  height: 69px;
  padding: 14px 0;
  background: #f5f5f5;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;

  &:nth-child(2) {
    min-width: 211px; // 두 번째 항목에 최소 너비 464px 적용
  }
  &:nth-child(3) {
    min-width: 464px; // 두 번째 항목에 최소 너비 464px 적용
  }
`;

export const StyledUserStatsItemSection = styled.div`
  min-width: 93.8px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-grow: 1;

  &:not(:last-child) {
    border-right: 1px solid #d9d9d9;
  }
`;

export const StyledUserStatsItemLabel = styled(TextBase)`
  color: #9d9d9d;
  font-size: 11px;
  line-height: 14.3px;
`;

export const StyledUserStatsItemValue = styled(TextBase)`
  color: black;
  font-size: 16px;
  line-height: 20.8px;
`;

export const StyledUserStatsReloadButton = styled.div`
  width: 149px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px 0px 8px;
  gap: 4px;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
  opacity: 0px;

  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  color: #9d9d9d;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #262626;
  }
`;
