import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

export const getSystemAppSettings = ({
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: '/system/app/settings',
    method: 'get',
    onSuccess: (value) => onSuccess(value),
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const patchSystemVideoRec = () => {
  const { fetch } = useFetch({
    url: '/system/videoRec',
    method: 'patch',
  });

  return { fetch };
};

export const patchSystemVideoExpoiration = () => {
  const { fetch } = useFetch({
    url: '/system/videoExpiration',
    method: 'patch',
  });

  return { fetch };
};

export const patchSystemDistance = () => {
  const { fetch } = useFetch({
    url: '/system/distance',
    method: 'patch',
  });

  return { fetch };
};

export const patchSystemFeelkyRequestAuthority = () => {
  const { fetch } = useFetch({
    url: '/system/feelkyRequestAuthority',
    method: 'patch',
  });

  return { fetch };
};

export const patchSystemReport = () => {
  const { fetch } = useFetch({
    url: '/system/report',
    method: 'patch',
  });

  return { fetch };
};

export const getSystemRegionCategory = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { fetch } = useFetch({
    url: `/system/region/category/${query}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { fetch };
};

export const postSystemRegionCategory = () => {
  const { fetch } = useFetch({
    url: '/system/region/category',
    method: 'post',
  });

  return { fetch };
};

export const getSystemRegionCategoryLocation = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { fetch } = useFetch({
    url: `/system/region/category/location?keyword=${query}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { fetch };
};

export const putSystemRegionCategory = () => {
  const { fetch } = useFetch({
    url: '/system/region/category',
    method: 'put',
  });

  return { fetch };
};

export const putSystemRegionCategoryOrder = () => {
  const { fetch } = useFetch({
    url: '/system/region/category/order',
    method: 'put',
  });

  return { fetch };
};

export const deleteSystemRegionCategory = ({ query }) => {
  const { fetch } = useFetch({
    url: `/system/region/category/${query}`,
    method: 'delete',
  });

  return { fetch };
};

export const getSystemServerStatusSettings = ({
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: '/system/serverStatus/settings',
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const patchSystemServerStatus = () => {
  const { fetch } = useFetch({
    url: '/system/serverStatus',
    method: 'patch',
  });

  return { fetch };
};

export const getSystemLocationCateogry = ({
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/system/location-category`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const patchSystemLocationCategory = () => {
  const { fetch } = useFetch({
    url: '/system/location-category',
    method: 'patch',
  });

  return { fetch };
};

export const patchSystemNearbyDistance = () => {
  const { fetch } = useFetch({
    url: '/system/nearby-distance',
    method: 'patch',
  });

  return { fetch };
};

export const patchSystemBonusTime = () => {
  const { fetch } = useFetch({
    url: '/system/bonus-time',
    method: 'patch',
  });

  return { fetch };
};
