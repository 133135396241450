/* eslint-disable react/jsx-props-no-spreading */
import {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CustomButton from '../../../../../../components/common/Button/Button';
import CategoryItem from './CategoryItem';
import useModal from '../../../../../../hooks/useModal';
import Modal from '../../../../../../components/common/Modal/Modal';
import {
  deleteSupportFaq,
  deleteSupportFaqCategory,
  getSupportFaqList,
  postSupportFaqSort,
} from '../../../../../../apis/support';

const Drag = ({ id, index, ...props }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
            <div className="drag-handle" {...provided.dragHandleProps}>
              {props.children}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

const Drop = ({ id, type, ...props }) => {
  return (
    <Droppable droppableId={id} type={type}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.droppableProps} {...props}>
            {props.children}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// CategorySetting 컴포넌트
const Category = forwardRef(
  ({ focusNode, setFocusNode, handleGetCategory }, ref) => {
    const [categories, setCategories] = useState([]);

    const [openedNodes, setOpenedNodes] = useState([]);
    const [focusChangeTitle, setFocusChangeTitle] = useState('');

    const [addingNode, setAddingNode] = useState(false);
    const [addingCategory, setAddingCategory] = useState(false);

    const [deleteId, setDeleteId] = useState();
    const [deleteCategoryId, setDeleteCategoryId] = useState();

    const categorySettingRef = useRef(null);
    const [createButton, setCreateButton] = useState([
      {
        size: '',
        state: '',
        text: '',
        variant: '',
        onClick: () => {},
      },
    ]);

    const { fetch: fetchGetSupportFaqList } = getSupportFaqList({
      onSuccess: (value) => {
        const newCategory = value.map(
          ({ categoryCode, categoryName, list, ...data }) => ({
            categoryCode,
            categoryName,
            ...data,
            list: list.map(({ uuid, title, ...itemData }) => ({
              uuid,
              title,
              ...itemData,
            })),
          }),
        );
        setAddingCategory(false);
        setAddingNode(false);
        setCategories(newCategory);
      },
    });

    const { fetch: fetchPostSupportFaqSort } = postSupportFaqSort();

    const { fetch: fetchDeleteDeleteSupportFaq } = deleteSupportFaq({
      query: deleteId,
      onSuccess: () => handleGetCategory(),
    });

    const { fetch: fetchDeleteDeleteSupportFaqCategory } =
      deleteSupportFaqCategory({
        query: deleteCategoryId,
        onSuccess: () => handleGetCategory(),
      });

    const { isModalOpen, modalTitle, modalContent, showModal, closeModal } =
      useModal();

    // const handleAddClick = () => {
    //   setCategories((prev) => [
    //     {
    //       categoryCode: prev.length.toString(),
    //       title: '',
    //       list: [],
    //     },
    //     ...prev,
    //   ]);
    // };

    const handleAddItem = () => {
      // categoryId에 해당하는 카테고리 찾기
      const categoryIndex = categories.findIndex(
        ({ categoryCode }) => categoryCode === focusNode.id,
      );

      const uuid = `${focusNode.id}:add`;

      const updatedCategories = [...categories];
      updatedCategories[categoryIndex].list.push({
        uuid,
        title: '',
      });

      // setAddingNode((prev) => {
      //   return { ...prev, [focusNode.id]: uuid };
      // });
      setAddingNode(true);
      setCategories(updatedCategories);
      handleOpenNodes(focusNode.id);
      setFocusNode({
        target: 'item',
        id: uuid,
      });
    };

    const handleAddCategory = () => {
      const uuid = 'add';
      const addCategory = {
        categoryCode: uuid,
        categoryName: '',
        list: [],
      };
      setAddingCategory(true);
      setCategories((prev) => [...prev, addCategory]);
      setFocusNode({
        target: 'node',
        id: uuid,
      });
    };

    const updateOrder = () => {
      const updateCategory = categories.map(
        ({ categoryCode, categoryName, list }, index) => ({
          categoryCode,
          categoryName,
          sort: index + 1,
          list: list
            .filter(({ uuid }) => uuid.split(':')[1] !== 'add')
            .map(({ uuid, title }, idx) => ({
              uuid,
              category: categoryCode,
              title,
              sort: idx + 1,
            })),
        }),
      );
      fetchPostSupportFaqSort(updateCategory);
    };

    const handleSaveOrderClick = () => {
      showModal('순서가 저장되었습니다.', '', 'confirm');
      updateOrder();
      setCreateButton([
        {
          size: 'large',
          state: 'default',
          text: '확인',
          variant: 'confirm',
          onClick: () => {
            closeModal();
          },
        },
      ]);
    };

    const handleOpenNodes = (nodeId) => {
      if (openedNodes.includes(nodeId)) return;
      setOpenedNodes([...openedNodes, nodeId]);
    };

    const handleToggle = (nodeId) => {
      if (openedNodes.includes(nodeId)) {
        setOpenedNodes(openedNodes.filter((id) => id !== nodeId));
      } else {
        setOpenedNodes([...openedNodes, nodeId]);
      }
    };

    const handleSetFocus = (target, id) => {
      if (focusNode.id === id) {
        setFocusNode({ target: '', id: '' });
      } else {
        setFocusChangeTitle('');
        setFocusNode({ target, id });
      }
    };

    const handleDragEnd = (result) => {
      const { type, source, destination } = result;

      if (!destination) return;

      const sourceCategoryId = source.droppableId;
      const destinationCategoryId = destination.droppableId;

      if (type === 'droppable-item') {
        if (sourceCategoryId === destinationCategoryId) {
          const sourceCategory = categories.find(
            ({ categoryCode }) => categoryCode === sourceCategoryId,
          );
          const updatedOrder = reorder(
            sourceCategory.list,
            source.index,
            destination.index,
          );

          const updatedCategories = categories.map((category) =>
            category.categoryCode !== sourceCategoryId
              ? category
              : { ...category, list: updatedOrder },
          );

          setCategories(updatedCategories);
        } else {
          const sourceCategory = categories.find(
            ({ categoryCode }) => categoryCode === sourceCategoryId,
          );
          const destinationCategory = categories.find(
            ({ categoryCode }) => categoryCode === destinationCategoryId,
          );

          const sourceItems = Array.from(sourceCategory.list);
          const destinationItems = Array.from(destinationCategory.list);

          const [movedItem] = sourceItems.splice(source.index, 1);
          destinationItems.splice(destination.index, 0, movedItem);

          const updatedCategories = categories.map((category) => {
            if (category.categoryCode === sourceCategoryId) {
              return { ...category, list: sourceItems };
            }
            if (category.categoryCode === destinationCategoryId) {
              return { ...category, list: destinationItems };
            }
            return category;
          });

          setCategories(updatedCategories);
        }
      }

      if (type === 'droppable-category') {
        const updatedCategories = reorder(
          categories,
          source.index,
          destination.index,
        );
        setCategories(updatedCategories);
      }
    };

    const handleDeleteNode = (nodeId) => {
      setDeleteCategoryId(nodeId);
      // setCategories((prevCategories) =>
      //   prevCategories.filter(({ categoryCode }) => categoryCode !== nodeId),
      // );
    };

    const handleDeleteAddCategory = () => {
      setCategories((prev) =>
        prev.filter(({ categoryCode }) => categoryCode !== 'add'),
      );
    };

    const handleDeleteItem = (nodeId, itemId) => {
      const [, isAddItem] = itemId.split(':');

      const categoryIndex = categories.findIndex(
        ({ categoryCode }) => categoryCode === nodeId,
      );

      const updatedCategories = [...categories];
      const category = updatedCategories[categoryIndex];

      const subItemIndex = category.list.findIndex(
        ({ uuid }) => uuid === itemId,
      );

      updatedCategories[categoryIndex].list.splice(subItemIndex, 1);
      if (isAddItem) {
        setAddingNode(false);
        handleResetFocuse();
      }

      setCategories(updatedCategories);
      if (!isAddItem) setDeleteId(itemId);
    };

    const handleResetFocuse = () => {
      setFocusNode({ target: '', id: '' });
    };

    useImperativeHandle(ref, () => ({
      fetchGetSupportFaqList,
    }));

    useEffect(() => {
      if (!deleteId) return;
      fetchDeleteDeleteSupportFaq();
    }, [deleteId]);

    useEffect(() => {
      if (!deleteCategoryId) return;
      fetchDeleteDeleteSupportFaqCategory();
    }, [deleteCategoryId]);

    return (
      <StyledCategorySettingWrapper ref={categorySettingRef}>
        <StyledButtonGroup>
          <CustomButton
            size="medium"
            $state="default"
            $variant="systemChange"
            text="추가"
            disabled={
              // !focusNode.id ||
              // focusNode.id === 'add' ||
              addingNode ||
              addingCategory ||
              focusNode.target === 'item' ||
              // !!addingNode[focusNode.id] ||
              (focusNode.target === 'node' && addingNode) ||
              (focusNode.target === '' && addingCategory) ||
              (focusNode.target === 'node' && focusNode.id === 'add')
            }
            onClick={() => {
              if (!focusNode.id && !addingCategory) handleAddCategory();
              else handleAddItem();
            }}
          />
          <CustomButton
            size="medium"
            $state="default"
            $variant="systemChange"
            text="순서 저장"
            onClick={handleSaveOrderClick}
          />
        </StyledButtonGroup>
        <StyledCategoryWrapper>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Drop id="droppable" type="droppable-category">
              {/* <StyledCategoryNodeWrapper>
                <CategoryItem
                  onCellClick={() => handleSetFocus('node', 'add')}
                  onCellDoubleClick={() => {
                    // 타이틀 변경 상태를 위한
                    // setFocusChangeTitle('add');
                    // setFocusNode({
                    //   target: 'node',
                    //   id: 'add',
                    // });
                  }}
                  isCellSeleted={focusNode.id === 'add'}
                  toggleTitle={focusChangeTitle === 'add'}
                  onTitleSubmit={() => {
                    setFocusChangeTitle('');
                    handleAddClick();
                  }}
                  onTileBluer={() => setFocusChangeTitle('')}
                />
              </StyledCategoryNodeWrapper> */}
              {categories.map(
                (
                  { categoryCode, categoryName, list, isActive },
                  categoryIndex,
                ) => {
                  return (
                    <Drag
                      className="draggable-category"
                      key={categoryCode}
                      id={categoryCode}
                      index={categoryIndex}
                    >
                      <Drop
                        key={categoryCode}
                        id={categoryCode}
                        type="droppable-item"
                      >
                        <StyledCategoryNodeWrapper>
                          <CategoryItem
                            onFolderIconClick={() => {
                              handleToggle(categoryCode);
                            }}
                            folderType={
                              openedNodes.includes(categoryCode)
                                ? 'minus'
                                : 'plus'
                            }
                            onCellClick={() => {
                              handleSetFocus('node', categoryCode);
                            }}
                            onCellDoubleClick={() => {
                              // 타이틀 변경 상태를 위한
                              // setFocusChangeTitle(categoryCode);
                              // setFocusNode({
                              //   target: 'node',
                              //   id: categoryCode,
                              // });
                            }}
                            isCellSeleted={focusNode.id === categoryCode}
                            toggleTitle={focusChangeTitle === categoryCode}
                            onTitleSubmit={() => {
                              setFocusChangeTitle('');
                            }}
                            onTileBluer={() => setFocusChangeTitle('')}
                            titleDefaultValue={categoryName}
                            title={categoryName}
                            subfolderCount={list.length}
                            isActive={isActive}
                            onDelete={() => {
                              showModal(
                                '삭제하시겠습니까?',
                                '삭제 시 다시 복구할 수 없습니다.',
                                'alert',
                              );
                              setCreateButton([
                                {
                                  size: 'large',
                                  state: 'default',
                                  text: '취소',
                                  variant: 'cancel',
                                  onClick: () => closeModal(),
                                },
                                {
                                  size: 'large',
                                  state: 'default',
                                  text: '삭제',
                                  variant: 'alert',
                                  onClick: () => {
                                    if (categoryCode === 'add') {
                                      setAddingCategory(false);
                                      handleDeleteAddCategory();
                                    } else {
                                      handleDeleteNode(categoryCode);
                                    }
                                    closeModal();
                                    handleResetFocuse();
                                  },
                                },
                              ]);
                            }}
                          />
                        </StyledCategoryNodeWrapper>
                        {openedNodes.includes(categoryCode) &&
                          list.map(
                            (
                              { uuid, title, isActive: itemIsActive },
                              index,
                            ) => {
                              return (
                                <Drag
                                  className="draggable"
                                  key={uuid}
                                  id={uuid}
                                  index={index}
                                >
                                  <StyledCategoryItemWrapper>
                                    <CategoryItem
                                      folderType="file"
                                      onCellClick={() =>
                                        handleSetFocus('item', uuid)
                                      }
                                      onCellDoubleClick={() => {
                                        // setFocusChangeTitle(uuid);
                                        // setFocusNode({
                                        //   target: 'item',
                                        //   id: uuid,
                                        // });
                                      }}
                                      isCellSeleted={
                                        focusNode.id === categoryCode ||
                                        focusNode.id === uuid
                                      }
                                      toggleTitle={focusChangeTitle === uuid}
                                      onTitleSubmit={() => {
                                        setFocusChangeTitle('');
                                      }}
                                      onTileBluer={() =>
                                        setFocusChangeTitle('')
                                      }
                                      titleDefaultValue={title}
                                      title={title}
                                      isActive={itemIsActive}
                                      onDelete={() => {
                                        showModal(
                                          '삭제하시겠습니까?',
                                          '삭제 시 다시 복구할 수 없습니다.',
                                          'alert',
                                        );
                                        setCreateButton([
                                          {
                                            size: 'large',
                                            state: 'default',
                                            text: '취소',
                                            variant: 'cancel',
                                            onClick: () => closeModal(),
                                          },
                                          {
                                            size: 'large',
                                            state: 'default',
                                            text: '삭제',
                                            variant: 'alert',
                                            onClick: () => {
                                              handleDeleteItem(
                                                categoryCode,
                                                uuid,
                                              );
                                              closeModal();
                                            },
                                          },
                                        ]);
                                      }}
                                    />
                                  </StyledCategoryItemWrapper>
                                </Drag>
                              );
                            },
                          )}
                      </Drop>
                    </Drag>
                  );
                },
              )}
            </Drop>
          </DragDropContext>
        </StyledCategoryWrapper>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalTitle}
          content={modalContent}
          actions={createButton.map(
            ({ size, state, text, variant, onClick }, index) => (
              <CustomButton
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                size={size}
                $state={state}
                text={text}
                $variant={variant}
                onClick={onClick}
              />
            ),
          )}
        />
      </StyledCategorySettingWrapper>
    );
  },
);

export default Category;

const StyledCategorySettingWrapper = styled.div`
  min-width: 327px;
  height: 550px;
  padding: 20px 24px 20px 20px;
  background: #e9e9e9;
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9d9d9d;
    border-radius: 4px;
  }
`;

const StyledButtonGroup = styled.div`
  align-self: stretch;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 6px;
  margin-bottom: 20px;
`;

const StyledCategoryWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledCategoryNodeWrapper = styled.div`
  padding-bottom: 10px;
`;

const StyledCategoryItemWrapper = styled.div`
  margin-left: 38px;
  padding-bottom: 10px;
`;
