import {
  StyledInputFieldWrapper,
  StyledInputFieldLabel,
  StyledInputFieldInput,
  StyledInputFieldErrorMessage,
} from './InputField.styles';

const InputField = ({
  frameClassName,
  multiline,
  size,
  $state,
  text,
  type,
  value,
  onChange,
  onBlur,
  onFocus,
  errorMessage,
  placeholder,
}) => {
  return (
    <StyledInputFieldWrapper className={frameClassName}>
      <StyledInputFieldLabel>{text}</StyledInputFieldLabel>
      <StyledInputFieldInput
        as={multiline ? 'textarea' : 'input'}
        size={size}
        $state={$state}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
      />
      {errorMessage && (
        <StyledInputFieldErrorMessage>
          {errorMessage}
        </StyledInputFieldErrorMessage>
      )}
    </StyledInputFieldWrapper>
  );
};

export default InputField;
