import styled from 'styled-components';

export const StyledInquiriesStatsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledInquiriesStatsWrapper = styled.div`
  width: 50%;
  height: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  /* border: 1px #f1f2f4 solid; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledInquiriesStatsHeader = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledInquiriesStatsTitle = styled.div`
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

export const StyledInquiriesStatsContent = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const StyledInquiriesStatsBox = styled.div`
  /* min-width: 380px; */
  width: 100%;
  height: 69px;
  padding: 14px 0;
  background: #f5f5f5;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledInquiriesStatsBoxSecond = styled(StyledInquiriesStatsBox)`
  /* min-width: 496px; */
  width: 100%;
`;

export const StyledInquiriesStatsItem = styled.div`
  flex: 1;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  &:not(:last-child) {
    border-right: 1px #d9d9d9 solid;
  }
`;

export const StyledInquiriesStatsLabel = styled.div`
  text-align: center;
  color: #9d9d9d;
  font-size: 11px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 14.3px;
  word-wrap: break-word;
`;

export const StyledInquiriesStatsValue = styled.div`
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 20.8px;
  word-wrap: break-word;
`;
