/* eslint-disable react/jsx-props-no-spreading */
import {
  StyledCommonInputContainer,
  StyledCountMaxLength,
  StyledInputFieldInput,
} from './input.styles';

const CommonInput = ({ value, onChange, maxLength, ...rest }) => {
  return (
    <StyledCommonInputContainer>
      <StyledInputFieldInput
        defaultValue={value}
        onChange={onChange}
        autoComplete="off"
        maxLength={maxLength}
        {...rest}
      />
      <StyledCountMaxLength>{`${value.length} / ${maxLength}`}</StyledCountMaxLength>
    </StyledCommonInputContainer>
  );
};

export default CommonInput;
