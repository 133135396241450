export const USER_LEVEL = 1001;

export const USER_GENDER = 1003;

export const VIEW_TARGET = 7021;

export const VIDEO_TYPE = 4001;

export const VIDEO_STATUS = 4601;

export const VIDEO_ACTIVE_STATUS = 460100;

export const VIDEO_UNACTIVE_STATUS = 460101;

export const VIDEO_HIDDEN_STATUS = 460103;

export const BOARD_TYPE = 7001;

export const BOARD_TYPE_NOTICE = 700101;
export const BOARD_TYPE_POPUP = 700105;

export const BOARD_TYPE_BANNER = 700107;

export const ACTIVE_STATUS = 7201;
export const ACTIVE_HIDDEN_STATUS = 720102;

// export const ACTIVE_STATUS
