import {
  StyledCheckboxContainer,
  StyledCheckbox,
  StyledCheckboxChecked,
  StyledCheckboxUnchecked,
  StyledCheckboxIcon,
  StyledCheckboxLabel,
  StyledLabel,
  StyledCheckboxWrapper,
} from './UserSearchFilter.styles';

const Checkbox = ({ label, options, value, handleChange }) => (
  <>
    <StyledLabel>{label}</StyledLabel>
    <StyledCheckboxWrapper>
      {options.map((option) => (
        <StyledCheckboxContainer
          key={option.value}
          onClick={() => handleChange(option.value)}
        >
          <StyledCheckbox>
            {value?.[option.value] ? (
              <StyledCheckboxChecked>
                <StyledCheckboxIcon />
              </StyledCheckboxChecked>
            ) : (
              <StyledCheckboxUnchecked />
            )}
          </StyledCheckbox>

          <StyledCheckboxLabel>{option.label}</StyledCheckboxLabel>
        </StyledCheckboxContainer>
      ))}
    </StyledCheckboxWrapper>
  </>
);

export default Checkbox;
