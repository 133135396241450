import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Sidebar from '../common/Sidebar/Sidebar';
import TopBar from '../common/Topbar/Topbar';
import getUserMy from '../../apis/user/getUserMy';
import { setUser } from '../../store/redux/userSlice';

const sidebarWidth = '280px';

const Layout = () => {
  const dispatch = useDispatch();

  const user = getUserMy();

  if (!user) return null;

  dispatch(setUser(user));

  return (
    <>
      <TopBar user={user} />
      <Sidebar width={sidebarWidth} />
      <StyledLayoutWrapper>
        <StyledLayoutContentWrapper>
          <StyledLayoutMainContent>
            <StyledLayoutSetting>
              <Outlet />
            </StyledLayoutSetting>
          </StyledLayoutMainContent>
        </StyledLayoutContentWrapper>
      </StyledLayoutWrapper>
    </>
  );
};

const StyledLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const StyledLayoutContentWrapper = styled.div`
  display: flex;
  flex: 1;
  padding-top: 64px;
`;

const StyledLayoutMainContent = styled.div`
  flex: 1;
  padding: 20px 42px 20px 32px;
  margin-left: ${sidebarWidth};
  background-color: #e9e9e9;
`;

const StyledLayoutSetting = styled.div`
  max-width: 1360px;
  min-width: 960px;
  width: 100%;
  margin: 0 auto;
`;

export default Layout;
