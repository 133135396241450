/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { BiLogoQuora } from 'react-icons/bi';
import Dropdown from '../../../../components/common/DropDown/DropDown';
import Checkbox from './Checkbox';
import UserTypeFilter from './UserTypeFilter';
import JoinPeriod from './JoinPeriod';

import {
  StyledSearchFilterHeader,
  StyledSearchFilterInputContainer,
  StyledSearchFilterSearchBar,
  StyledSearchFilterSearchInput,
  StyledSearchFilterSearchIcon,
  StyledSearchFilterContainer,
  StyledSearchFilterAgeDropdownContainer,
  StyledSearchFilterBody,
  StyledLabel,
  StyledSearchFilterFooter,
  StyledUserCelebFilterConatiner,
} from './UserSearchFilter.styles';
import { formatDateYYYYMMDD } from '../../../../utils/formatDate';
import {
  resetMangementFilterQuery,
  setFilterQuery,
} from '../../../../store/redux/userManagementFilterSlice';
import { getGroupCode } from '../../../../utils/groupCode';
import {
  USER_GENDER,
  USER_LEVEL,
  VIEW_TARGET,
} from '../../../../constants/groupCode';
import { AGE_RANGE_OPTIONS } from '../../../../constants/constants';

const useCheckBox = ({ groupCode, setInitValue, isCheckedList = [] }) => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);

  const groupCodeValue = getGroupCode(groupCodeList, groupCode).codes;

  const initValue = setInitValue
    ? setInitValue(groupCodeValue)
    : groupCodeValue;

  const stateInit = initValue.reduce((acc, obj) => {
    acc[obj.fullCode] = isCheckedList.includes(obj.fullCode);
    return acc;
  }, {});

  const resetState = initValue.reduce((acc, obj) => {
    acc[obj.fullCode] = false;
    return acc;
  }, {});

  const option = initValue.map(({ fullCode, codeName }) => ({
    value: fullCode,
    label: codeName,
  }));

  const [state, setState] = useState(stateInit);

  return { resetState, stateInit, option, state, setState };
};

const FILTER_KEYS = {
  searchTitle: 'searchTitle',
  genders: 'genders',
  minAge: 'minAge',
  maxAge: 'maxAge',
  startDate: 'startDate',
  endDate: 'endDate',
  userTypes: 'userTypes',
  authenticationMarks: 'authenticationMarks',
};

const SearchFilter = forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterParams = new URLSearchParams(searchParams.get('filter'));

  const initGenders = filterParams.getAll(FILTER_KEYS.genders);
  const initUserTypes = filterParams.getAll(FILTER_KEYS.userTypes);
  const initCelebs = filterParams.getAll(FILTER_KEYS.authenticationMarks);
  const initSearchTitle = filterParams.get(FILTER_KEYS.searchTitle) || '';
  const initAge = {
    maxAge: filterParams.get(FILTER_KEYS.maxAge),
    minAge: filterParams.get(FILTER_KEYS.minAge),
  };
  const initStartDate = filterParams.get(FILTER_KEYS.startDate);
  const initEndDate = filterParams.get(FILTER_KEYS.endDate);

  const firstDate = new Date();
  const currentDate = new Date();

  const {
    resetState: userTypeValueReset,
    stateInit: userTypeValueInit,
    option: userTypeOptions,
    state: userTypeValue,
    setState: setUserTypeValue,
  } = useCheckBox({
    groupCode: USER_LEVEL,
    setInitValue: (value) => value.filter(({ code }) => Number(code) < 10),
    isCheckedList: initUserTypes,
  });

  const {
    resetState: genderValueReset,
    stateInit: genderValueInit,
    option: genderFilterOptions,
    state: genderValue,
    setState: setGenderValue,
  } = useCheckBox({
    groupCode: USER_GENDER,
    isCheckedList: initGenders,
  });

  const {
    resetState: celebValueReset,
    stateInit: celebValueInit,
    option: celebValueOptions,
    state: celebValue,
    setState: setCelebValue,
  } = useCheckBox({ groupCode: VIEW_TARGET, isCheckedList: initCelebs });

  const [searchInput, setSearchInput] = useState('');

  const [selectedAgeRange, setSelectedAgeRange] = useState('전체');
  const [isAllPeriod, setIsAllPeriod] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(firstDate);
  const [endDate, setEndDate] = useState(currentDate);

  const handleSetSearchParamsList = (paramsList) => {
    const newSearchParams = new URLSearchParams();
    if (paramsList) newSearchParams.set('filter', paramsList);
    else newSearchParams.delete('filter');
    setSearchParams(newSearchParams);
  };

  const extractAgeRange = (ageRangeString) => {
    const matches = ageRangeString.match(/(\d+) - (\d+)세/);
    if (matches && matches.length === 3) {
      const minAge = parseInt(matches[1]);
      const maxAge = parseInt(matches[2]);
      return { minAge, maxAge };
    }
    return null;
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleCheckboxChange = (value) => {
    setGenderValue((prevValue) => ({
      ...prevValue,
      [value]: !prevValue?.[value],
    }));
  };

  const handleCelebCheckBoxChange = (value) => {
    setCelebValue((prevValue) => ({
      ...prevValue,
      [value]: !prevValue?.[value],
    }));
  };

  const handleDropdownChange = (option) => {
    setSelectedAgeRange(option);
  };

  const toggleAllPeriod = () => {
    setIsAllPeriod(true);
    setShowDatePicker(false);
  };

  const handleDirectSettingClick = () => {
    setIsAllPeriod(false);
    setShowDatePicker(true);
  };

  const handleDateChange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
  };

  const handleConfirm = () => {
    setShowDatePicker(false);
  };

  const handleUserTypeChange = (value) => {
    setUserTypeValue((prevValue) => ({
      ...prevValue,
      [value]: !prevValue[value],
    }));
  };

  const resetFilters = () => {
    setSearchInput('');
    setGenderValue(genderValueReset);
    setSelectedAgeRange('전체');
    setIsAllPeriod(true);
    setShowDatePicker(false);
    setStartDate(firstDate);
    setEndDate(currentDate);
    setUserTypeValue(userTypeValueReset);
    setCelebValue(celebValueReset);
  };

  useEffect(() => {
    setSearchInput(initSearchTitle);
    setGenderValue(genderValueInit);
    setSelectedAgeRange(
      initAge.maxAge && initAge.minAge
        ? `${initAge.minAge} - ${initAge.maxAge}세`
        : '전체',
    );
    setShowDatePicker(false);
    setUserTypeValue(userTypeValueInit);
    setCelebValue(celebValueInit);

    if (initStartDate && initStartDate) {
      setIsAllPeriod(false);
      setStartDate(new Date(initStartDate));
      setEndDate(new Date(initEndDate));
    }
  }, []);

  const setFilters = () => {
    const isSearchTitle = searchInput && `searchTitle=${searchInput}`;

    const genders = Object.keys(genderValue).filter((key) => genderValue[key]);
    const isGenders = genders?.map((gender) => `genders=${gender}`).join('&');

    const ageRange = extractAgeRange(selectedAgeRange);

    const isMinAge = ageRange && `minAge=${ageRange.minAge}`;
    const isMaxAge = ageRange && `maxAge=${ageRange.maxAge}`;

    const isStartDate =
      !isAllPeriod &&
      `startDate=${formatDateYYYYMMDD(startDate, '-')}T00:00:00`;
    const isEndDate =
      !isAllPeriod && `endDate=${formatDateYYYYMMDD(endDate, '-')}T00:00:00`;

    const userTypes = Object.keys(userTypeValue).filter(
      (key) => userTypeValue[key],
    );

    const isUserTypes = userTypes?.map((type) => `userTypes=${type}`).join('&');

    const celebValues = Object.keys(celebValue).filter(
      (key) => celebValue[key],
    );

    const isCelebValues = celebValues
      ?.map((value) => `authenticationMarks=${value}`)
      .join('&');

    const queryString = [
      isSearchTitle,
      isGenders,
      isMinAge,
      isMaxAge,
      isStartDate,
      isEndDate,
      isUserTypes,
      isCelebValues,
    ]
      .filter(Boolean)
      .join('&');

    handleSetSearchParamsList(queryString);
  };

  useImperativeHandle(ref, () => ({
    resetFilters,
    setFilters,
  }));

  useEffect(() => {
    dispatch(resetMangementFilterQuery());
  }, []);
  return (
    <>
      <StyledSearchFilterHeader>
        <StyledSearchFilterInputContainer>
          <StyledLabel>검색분류</StyledLabel>

          <StyledSearchFilterSearchBar>
            <StyledSearchFilterSearchIcon />

            <StyledSearchFilterSearchInput
              placeholder="닉네임이나 연락처를 입력해 주세요."
              value={searchInput}
              onChange={handleSearchChange}
            />
          </StyledSearchFilterSearchBar>
        </StyledSearchFilterInputContainer>

        <StyledSearchFilterContainer>
          <Checkbox
            label="성별"
            options={genderFilterOptions}
            handleChange={handleCheckboxChange}
            value={genderValue}
          />
        </StyledSearchFilterContainer>

        <StyledSearchFilterAgeDropdownContainer>
          <StyledLabel>나이대</StyledLabel>

          <Dropdown
            options={AGE_RANGE_OPTIONS}
            selectedValue={selectedAgeRange}
            onOptionChange={handleDropdownChange}
          />
        </StyledSearchFilterAgeDropdownContainer>
      </StyledSearchFilterHeader>

      <StyledSearchFilterBody>
        <JoinPeriod
          isAllPeriod={isAllPeriod}
          showDatePicker={showDatePicker}
          startDate={startDate}
          endDate={endDate}
          toggleAllPeriod={toggleAllPeriod}
          handleDirectSettingClick={handleDirectSettingClick}
          handleDateChange={handleDateChange}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
        <StyledUserCelebFilterConatiner>
          <Checkbox
            label="인증마크"
            options={celebValueOptions}
            handleChange={handleCelebCheckBoxChange}
            value={celebValue}
          />
        </StyledUserCelebFilterConatiner>
      </StyledSearchFilterBody>
      <StyledSearchFilterFooter>
        <UserTypeFilter
          userTypeValue={userTypeValue}
          userTypeOptions={userTypeOptions}
          handleUserTypeChange={handleUserTypeChange}
        />
      </StyledSearchFilterFooter>
    </>
  );
});

export default SearchFilter;
