/* eslint-disable no-unused-vars */
import styled, { keyframes } from 'styled-components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BiSolidMap } from 'react-icons/bi';
import { PiCaretUpBold } from 'react-icons/pi';
import { StyledRadioLabel, StyledRadioWrapper } from './PostTemplate.styles';
import PostTemplateRadioBtn from './PostTemplateRadioBtn';
import { formatDateTime, formatEndTime } from '../../../../utils/formatDate';

const PopupNotification = ({
  videoTitle,
  selectedVideo,
  selectedThumbnail,
  endDate,
  endTime,
  meetingLocation,
  meetingCount,
  meetingTime,
  tags,
  nickname,
  videoAddress,
  tagRequired,
}) => {
  const [viewType, setViewType] = useState('video');
  const [openTag, setOpentTag] = useState(false);

  const videoRef = useRef(null);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [isMouseMove, setIsMouseMove] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  useEffect(() => {
    const videoElement = videoRef.current;
    const isVideo = viewType === 'video';

    const handleMouseEnter = () => setIsMouseMove(true);
    const handleMouseLeave = () => setIsMouseMove(false);

    const handelMousePlay = () => setIsPlay(false);
    const handelMousPause = () => setIsPlay(true);

    if (videoElement && isVideo) {
      videoElement.addEventListener('mouseenter', handleMouseEnter);
      videoElement.addEventListener('mouseleave', handleMouseLeave);
      videoElement.addEventListener('play', handelMousePlay);
      videoElement.addEventListener('pause', handelMousPause);
    }

    return () => {
      if (videoElement && isVideo) {
        videoElement.removeEventListener('mouseenter', handleMouseEnter);
        videoElement.removeEventListener('mouseleave', handleMouseLeave);
        videoElement.removeEventListener('play', handelMousePlay);
        videoElement.removeEventListener('pause', handelMousPause);
      }
    };
  }, [selectedVideo, viewType]);

  useEffect(() => {
    setControlsVisible(isPlay);
    if (!isPlay) setControlsVisible(isMouseMove);
  }, [isMouseMove, isPlay]);

  useEffect(() => {
    setOpentTag(tagRequired);
  }, [tagRequired]);

  const endTimeTitle = useMemo(() => {
    if (!endDate || !endTime) return '';
    console.log(formatEndTime(formatDateTime(endDate, endTime)));
    const time = formatEndTime(formatDateTime(endDate, endTime));
    console.log(time);
    return `종료까지 ${time.hour}시간 ${time.minute}분 남음`;
  }, [endDate, endTime]);

  //   const controlsTimeout = useRef(null);

  return (
    <PopupNotificationContainer>
      <PopupNotificationWrapper>
        <PopupNotificationTitle>
          게시물 예시 화면
          <StyledRadioOption>
            <StyledRadioWrapper onClick={() => setViewType('video')}>
              <PostTemplateRadioBtn checked={viewType === 'video'} />
              <StyledRadioLabel>비디오</StyledRadioLabel>
            </StyledRadioWrapper>
            <StyledRadioWrapper onClick={() => setViewType('thumbnail')}>
              <PostTemplateRadioBtn checked={viewType === 'thumbnail'} />
              <StyledRadioLabel>썸네일</StyledRadioLabel>
            </StyledRadioWrapper>
          </StyledRadioOption>
        </PopupNotificationTitle>
        <PopupNotificationContent>
          {viewType === 'video' && selectedVideo && (
            <StyledVideo
              ref={videoRef}
              controls
              autoPlay
              loop
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            >
              <source
                src={selectedVideo.url || URL.createObjectURL(selectedVideo)}
              />
            </StyledVideo>
          )}
          {viewType === 'thumbnail' && selectedThumbnail && (
            <img
              src={
                selectedThumbnail.url || URL.createObjectURL(selectedThumbnail)
              }
              alt="Uploaded"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          )}
          <PostInfoWrapper
            $openController={viewType === 'video' && controlsVisible}
          >
            {viewType === 'video' && (
              <>
                <AppearDiv>
                  <AnimatedContent>
                    <PostLocationInfoWrapper>
                      <PostLocationInfo style={{ width: '70px' }}>
                        <BiSolidMap size="12px" />
                        <div>0m 이내</div>
                      </PostLocationInfo>
                      {videoAddress && (
                        <PostLocationInfo style={{ width: 'auto' }}>
                          <PostLocationGradient>
                            {videoAddress}
                          </PostLocationGradient>
                        </PostLocationInfo>
                      )}
                    </PostLocationInfoWrapper>
                    <StyledPostTitle
                      onClick={() =>
                        tagRequired && setOpentTag((prev) => !prev)
                      }
                    >
                      <div>{videoTitle || '영상 타이틀'}</div>
                    </StyledPostTitle>
                  </AnimatedContent>
                </AppearDiv>
                <StyledNicname>
                  <div>@ {nickname}</div>
                  <div>{endTimeTitle}</div>
                </StyledNicname>
              </>
            )}
          </PostInfoWrapper>
        </PopupNotificationContent>
        {/* <PopupNotificationTextWrapper>
          <PopupNotificationText>닫기</PopupNotificationText>
        </PopupNotificationTextWrapper> */}
      </PopupNotificationWrapper>
    </PopupNotificationContainer>
  );
};

export default PopupNotification;

const slideUp = keyframes`
  from {
    transform: translateY(65px);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
      transform: translateY(0);
    }
    to {
      transform: translateY(65px);
  }
`;

const AppearDiv = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const AnimatedContent = styled.div`
  width: 100%;
  height: 100%;

  animation-duration: 0.5s;
`;

const PopupNotificationContainer = styled.div`
  height: 462px;
  padding: 30px 20px 20px 20px;
  background: #e9e9e9;
  border-radius: 8px;
  gap: 30px;
  pointer-events: auto;
`;

const PopupNotificationWrapper = styled.div`
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const PopupNotificationTitle = styled.div`
  width: 100%;
  height: 16px;
  color: #9d9d9d;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 9px;
  word-wrap: break-word;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledRadioOption = styled.div`
  display: flex;
  gap: 5px;
`;

const PopupNotificationContent = styled.div`
  flex: 1;
  margin-top: 12px;
  width: 100%;
  position: relative;
  /* height: 350px; */
  background: #c6c4ff;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; // 추가
`;

const NoDataStyled = styled.div`
  color: #9d9d9d;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 9px;
`;

const PostLocationInfoWrapper = styled.div`
  display: flex;

  height: 20px;
  gap: 7px;
`;

const PostLocationInfo = styled.div`
  width: auto;
  /* padding: 5px 15px 5px 5px; */
  padding: 0 10px;
  border-radius: 30px;
  background: #00000080;
  color: #94fecf;
  font-family: Pretendard;
  font-size: 9px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PostLocationGradient = styled.div`
  background: linear-gradient(to right, #bcffe0, #c6c4ff);

  color: transparent;
  -webkit-background-clip: text;
`;

const StyledVideo = styled.video``;

const PostInfoWrapper = styled.div`
  background: red;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.47) 63.7%
  );
  width: 100%;
  padding: 0 10px;
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  transition: 0.5s;
`;

const ArrowIcon = styled.div`
  transform: rotate(${({ $isOpen }) => ($isOpen ? 180 : 0)}deg);
  transition: 0.5s;
`;

const StyledPostTitle = styled.div`
  height: 30px;
  color: #ffffff;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledNicname = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 10px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 9px;
`;

const StyledTagsWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #ffffff4d;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 5px;
`;

const StyledTag = styled.div`
  border: 0.5px solid #ffffff4d;
  gap: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 10px;
  font-family: 'Pretendard';
  font-weight: 300;
  background: #0000004d;
`;
