// src/components/common/Table/TableHeaderRow.jsx
import {
  StyledTableHeader,
  StyledTableHeaderRow,
  StyledTableCheckboxWrapper,
  StyledTableHeaderCell,
  StyledTableText,
  StyledTableCheckboxInput,
  StyledTableCheckboxIcon,
  StyledTableSortButton,
} from './Table.styles';

const TableHeaderRow = ({
  columns,
  onSelectAll,
  isAllSelected,
  onSort,
  sortColumn,
  sortDirection,
}) => (
  <StyledTableHeader>
    <StyledTableHeaderRow>
      {onSelectAll && onSelectAll === 'empty' ? (
        <StyledTableCheckboxWrapper />
      ) : (
        <StyledTableCheckboxWrapper onClick={onSelectAll}>
          <StyledTableCheckboxInput
            type="checkbox"
            checked={isAllSelected}
            readOnly
          />
          <StyledTableCheckboxIcon
            $isChecked={isAllSelected}
            onClick={onSelectAll}
          />
        </StyledTableCheckboxWrapper>
      )}
      {columns.map(({ key, label, width, $flex, sortable }) => (
        <StyledTableHeaderCell key={label} width={width} $flex={$flex}>
          <StyledTableText
            $header
            $sorted={key === sortColumn}
            $sortDirection={sortDirection}
          >
            {label}{' '}
            {sortable && (
              <StyledTableSortButton
                onClick={() => onSort(key)}
                $sorted={key === sortColumn}
                $sortDirection={sortDirection}
              />
            )}
          </StyledTableText>
        </StyledTableHeaderCell>
      ))}
    </StyledTableHeaderRow>
  </StyledTableHeader>
);

export default TableHeaderRow;
/*  
소팅 용법
const columns = [
  { key: 'name', label: '이름', sortable: true },
  { key: 'age', label: '나이', sortable: true },
  { key: 'email', label: '이메일' },
];

const MyTable = () => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // ...

  return (
    <Table>
      <TableHeaderRow
        columns={columns}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={handleSort}
      />
      </Table>
    );
  };

*/
