/* eslint-disable react/no-array-index-key */
import styled from 'styled-components';
import { formatNumber } from '../../../../utils/formatDate';
import { getVideoActivityCount } from '../../../../apis/postManagement';
import StatusHeader from '../../../../components/common/StatusHeadr';

const PostStats = () => {
  const formatVideoActivityCount = (data) => {
    const videoStatusData = data
      ? [
          {
            label: '메인영상 수',
            value: `${formatNumber(data.mainVideoCount)}개`,
          },
          {
            label: '영상댓글 수',
            value: `${formatNumber(data.commentVideoCount)}개`,
          },
        ]
      : [];

    const locationStatusData = data
      ? data.videoLocationCountList.map(
          ({ locationName, locationVideoCount }) => ({
            label: locationName,
            value: `${formatNumber(locationVideoCount)}개`,
          }),
        )
      : [];

    return { videoStatusData, locationStatusData };
  };

  const { data: videoActivityCount, fetch: fetchGetVideoActivityCount } =
    getVideoActivityCount();

  const { videoStatusData, locationStatusData } =
    formatVideoActivityCount(videoActivityCount);

  return (
    <StyledPostStatsContainer>
      <StatusHeader
        title="게시물 현황"
        onReload={() => fetchGetVideoActivityCount()}
      />

      <StyledPostStatsContent>
        <StyledPostStatsStatusBox>
          {videoStatusData?.map((item, index) => (
            <StyledPostStatsStatusItem key={index}>
              <StyledPostStatsStatusLabel>
                {item.label}
              </StyledPostStatsStatusLabel>
              <StyledPostStatsStatusValue>
                {item.value}
              </StyledPostStatsStatusValue>
            </StyledPostStatsStatusItem>
          ))}
        </StyledPostStatsStatusBox>

        <StyledPostStatsStatusBoxSecond>
          {locationStatusData?.map((item, index) => (
            <StyledPostStatsStatusItem key={index}>
              <StyledPostStatsStatusLabel>
                {item.label}
              </StyledPostStatsStatusLabel>

              <StyledPostStatsStatusValue>
                {item.value}
              </StyledPostStatsStatusValue>
            </StyledPostStatsStatusItem>
          ))}
        </StyledPostStatsStatusBoxSecond>
      </StyledPostStatsContent>
    </StyledPostStatsContainer>
  );
};

export default PostStats;

const StyledPostStatsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

const StyledPostStatsContent = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const StyledPostStatsStatusBox = styled.div`
  min-width: 380px;
  width: 100%;
  height: 69px;
  padding: 14px 0;
  background: #f5f5f5;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledPostStatsStatusBoxSecond = styled(StyledPostStatsStatusBox)`
  min-width: 496px;
  width: 100%;
`;

const StyledPostStatsStatusItem = styled.div`
  flex: 1;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  &:not(:last-child) {
    border-right: 1px #d9d9d9 solid;
  }
`;

const StyledPostStatsStatusLabel = styled.div`
  max-width: 90px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #9d9d9d;
  font-size: 11px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 14.3px;
  word-wrap: break-word;
`;

const StyledPostStatsStatusValue = styled.div`
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 20.8px;
  word-wrap: break-word;
`;
