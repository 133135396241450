/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import PageHeader from '../../../../components/common/PageHeader';
import CustomButton from '../../../../components/common/Button/Button';
import Modal from '../../../../components/common/Modal/Modal';

import useModal from '../../../../hooks/useModal';

import { formatDateTime, formatGroupCode } from '../../../../utils/formatDate';
import { postSupportAlarm } from '../../../../apis/alarm';
import AlarmTemplate from './components/AlarmTemplate';

const CreateAlarm = () => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const viewTargetList = formatGroupCode(groupCodeList, 7021);

  const location = useLocation();
  const navigate = useNavigate();
  const defaultAlarmData = {
    viewType: ['알림'],
    viewTarget: '회원',
    title: '',
    shortContents: '',
    startDate: null,
    startTime: null,
    isPush: false,
    fileUrl: '',
    noticeId: '',
    noticeTitle: '',
  };
  const [alarmData, setAlarmData] = useState(defaultAlarmData);
  const [fetchData, setFetchData] = useState('');
  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
    handleConfirm,
  } = useModal();

  const [isEditable] = useState(true); // Always editable for creation

  const { fetch: fetchPostSupportAlarm } = postSupportAlarm({
    query: fetchData.queryString,
    onSuccess: () => {
      showModal('등록 완료', '새 공지사항이 등록되었습니다.', 'confirm');
    },
  });

  const handleResetConfirm = () => {
    setAlarmData(defaultAlarmData);
    closeModal();
  };

  const handleReset = () => {
    showModal(
      '초기화 하시겠습니까?',
      '초기화 하시면 입력하신 정보들을 삭제됩니다.',
      'alert',
    );
  };

  useEffect(() => {
    const { formData, queryString } = fetchData;
    if (!queryString) return;
    fetchPostSupportAlarm(formData);
  }, [fetchData]);

  const handleRegister = (data) => {
    if (
      data.title.trim() === '' ||
      data.shortContents.trim() === '' ||
      !data.startDate ||
      !data.startTime
    ) {
      showModal('내용을 입력해주세요', '', 'input');
    } else {
      showModal(
        '새 알림을 생성하시겠습니까?',
        '작성하신 내용으로 새 알림이 생성됩니다.',
        'checkCreate',
        () => setFetchData(handleCreateFetchData(data)),
      );
    }
  };

  const handleCreateFetchData = (value) => {
    const {
      viewType,
      viewTarget,
      title,
      shortContents,
      startDate,
      startTime,
      isPush,
      noticeId,
      fileUrl,
    } = value;
    const viewTypeCode = viewType[0] === '팝업' ? 702001 : 702002;

    const viewTypeQuery = `viewType=${viewTypeCode}`;

    const viewTargetCode = viewTargetList.codes.find(
      ({ codeName }) =>
        codeName.replace(/ /g, '') === viewTarget.replace(/ /g, ''),
    ).fullCode;
    const viewTargetQuery = `viewTarget=${viewTargetCode}`;

    const titleQuery = `title=${title}`;

    const shortContentsQuery = `shortContents=${shortContents}`;

    const startDateQuery = `startDate=${formatDateTime(startDate, startTime)}`;

    const isPushQuery = `isPush=${isPush}`;

    const noticeIdQuery = `noticeId=${noticeId}`;

    const queryString = [
      viewTypeQuery,
      viewTargetQuery,
      titleQuery,
      shortContentsQuery,
      startDateQuery,
      isPushQuery,
      noticeIdQuery,
    ]
      .filter(Boolean)
      .join('&');

    const formData = new FormData();

    formData.append('file', fileUrl);

    return { queryString, formData };
  };

  const handleRegisterConfirm = () => {
    navigate(`/CustomerService/Alarm${location.search}`, { replace: true });
  };

  return (
    <StyledManagementContainer>
      <div>
        <PageHeader title="알림 생성" returnPage={handleRegisterConfirm} />
        <StyledManagementWrapper>
          <AlarmTemplate
            alarmData={alarmData}
            isEditable={isEditable}
            onCancel={handleReset}
            onSave={handleRegister}
            cancelButtonText="초기화"
            saveButtonText="등록"
          />
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            title={modalTitle}
            content={<div style={{ textAlign: 'center' }}>{modalContent}</div>}
            actions={
              modalVariant === 'alert' ? (
                <>
                  <CustomButton
                    size="large"
                    $state="default"
                    text="취소"
                    $variant="cancel"
                    onClick={closeModal}
                  />
                  <CustomButton
                    size="large"
                    $state="default"
                    text="초기화"
                    $variant="alert"
                    onClick={handleResetConfirm}
                  />
                </>
              ) : modalVariant === 'checkCreate' ? (
                <>
                  <CustomButton
                    size="large"
                    $state="default"
                    text="취소"
                    $variant="cancel"
                    onClick={closeModal}
                  />
                  <CustomButton
                    size="large"
                    $state="default"
                    $variant="confirm"
                    text="확인"
                    onClick={handleConfirm}
                  />
                </>
              ) : modalVariant === 'confirm' ? (
                <CustomButton
                  size="large"
                  $state="default"
                  $variant="confirm"
                  text="확인"
                  onClick={handleRegisterConfirm}
                />
              ) : (
                <CustomButton
                  size="large"
                  $state="default"
                  text="확인"
                  $variant="alert"
                  onClick={closeModal}
                />
              )
            }
          />
        </StyledManagementWrapper>
      </div>
    </StyledManagementContainer>
  );
};

export default CreateAlarm;

const StyledManagementContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledManagementWrapper = styled.div`
  min-width: 926px;
  width: auto;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;
