import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDateRangePicker.css';

import {
  StyledDatePickerContainer,
  StyledDatePickerButtons,
  StyledApplyButton,
  StyledCancelButton,
} from './CustomDateRangePicker.styles';

const CustomDateRangePicker = ({
  startDate,
  endDate,
  onChange,
  onCancel,
  onConfirm,
  selectsRange,
  disabled,
}) => {
  const handleDateChange = (dates) => {
    if (selectsRange) {
      onChange(dates);
    } else {
      onChange(dates[0], dates[1]);
    }
  };

  return (
    <StyledDatePickerContainer
      $selectOneDate={startDate?.toString() === endDate?.toString()}
    >
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange={selectsRange}
        inline
        monthsShown={2}
        locale={ko}
        disabled={disabled}
      />
      <StyledDatePickerButtons>
        <StyledCancelButton onClick={onCancel} disabled={disabled}>
          닫기
        </StyledCancelButton>
        <StyledApplyButton onClick={onConfirm} disabled={disabled}>
          적용하기
        </StyledApplyButton>
      </StyledDatePickerButtons>
    </StyledDatePickerContainer>
  );
};

export default CustomDateRangePicker;

// 1. 필요한 모듈 import:
//     - 'date-fns/locale'에서 한국어 로케일(ko)을 import합니다.
//     - 'react-datepicker' 라이브러리에서 DatePicker 컴포넌트를 import합니다.
//     - 'react-datepicker'의 CSS 파일을 import합니다.
//     - 커스텀 스타일을 위한 CSS 파일('./CustomDateRangePicker.css')을 import합니다.
//     - 스타일 컴포넌트들을 import합니다.
// 2. CustomDateRangePicker 컴포넌트:
//     - 상위 컴포넌트로부터
//       시작날짜(startDate),
//       종료날짜(endDate),
//       변경이벤트핸들러(onChange),
//       취소이벤트핸들러(onCancel),
//       확인이벤트핸들러(onConfirm),
//       범위선택여부(selectsRange),
//       비활성화여부(disabled)를 props로 받아옵니다.

//     - handleDateChange 함수는 날짜 변경 이벤트를 처리합니다. selectsRange가 true인 경우 onChange 핸들러에 선택된 날짜 배열을 전달하고, false인 경우 시작 날짜와 종료 날짜를 개별적으로 전달합니다.
//     - StyledDatePickerContainer 컴포넌트로 감싸진 DatePicker 컴포넌트를 렌더링합니다.
//     - DatePicker 컴포넌트의 주요 props:
//         - selected: 선택된 시작 날짜
//         - onChange: 날짜 변경 이벤트 핸들러(handleDateChange 함수)
//         - startDate: 시작 날짜
//         - endDate: 종료 날짜
//         - selectsRange: 범위 선택 여부
//         - inline: 인라인 모드(달력이 항상 표시됨)
//         - monthsShown: 한 번에 표시할 달의 개수(2개)
//         - locale: 한국어 로케일 설정
//         - disabled: 비활성화 여부
//     - StyledDatePickerButtons 컴포넌트로 감싸진 버튼들을 렌더링합니다.
//         - StyledCancelButton: 취소 버튼, onCancel 이벤트 핸들러와 disabled 속성이 전달됩니다.
//         - StyledApplyButton: 적용 버튼, onConfirm 이벤트 핸들러와 disabled 속성이 전달됩니다.

// 이 커스텀 날짜 범위 선택기 컴포넌트는 사용자가 시작 날짜와 종료 날짜를 선택할 수 있는 달력을 제공합니다. 선택한 날짜 범위는 상위 컴포넌트에 전달되며,
// 취소 및 확인 버튼을 통해 사용자의 액션을 처리할 수 있습니다. 컴포넌트의 스타일은 별도의 스타일 컴포넌트를 사용하여 커스터마이징할 수 있습니다.
