// 회원관리 > 게시물 조회 컴포넌트.
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import CustomButton from '../../../../components/common/Button/Button';
import SearchFilter from './SearchFilter';

import {
  StyledPostSearchFilterHeader,
  StyledPostSearchFilterContents,
  StyledPostSearchFilterFooter,
} from './PostSearchFilter.styles';

const PostSearchFilter = () => {
  const handleResetClick = () => {
    resetFilters();
  };

  const handleSetFilter = () => {
    setFilters();
  };

  const resetFilters = () => {
    searchFilter.current?.resetFilters();
  };

  const setFilters = () => {
    searchFilter.current?.setFilters();
  };

  const searchFilter = React.useRef(null);

  return (
    <>
      <StyledPostSearchFilterHeader>게시물 조회</StyledPostSearchFilterHeader>
      <StyledPostSearchFilterContents>
        <SearchFilter ref={searchFilter} />
      </StyledPostSearchFilterContents>
      <StyledPostSearchFilterFooter>
        <CustomButton
          size="medium"
          $state="default"
          text="초기화"
          $variant="reset"
          onClick={handleResetClick}
        />
        <CustomButton
          size="medium"
          $state="default"
          text="검색"
          $variant="search"
          onClick={handleSetFilter}
        />
      </StyledPostSearchFilterFooter>
    </>
  );
};

export default PostSearchFilter;
