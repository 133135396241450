/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { PiCheckBold } from 'react-icons/pi';

// AppSetting
export const StyledAppSettingContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  /* border: 1px #f1f2f4 solid; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
`;

export const StyledAppSettingTitle = styled.div`
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
`;

export const StyledAppSettingTable = styled.div`
  align-self: stretch;
  /* height: 200px; */
  border-radius: 8px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  border: 1px #d9d9d9 solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

// Table 공통
export const StyledAppSettingTableRow = styled.div`
  align-self: stretch;
  flex: 1 1 0;
  background: ${(props) => (props.$isHeader ? '#E9E9E9' : 'white')};
  border-bottom: 1px #d9d9d9 solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`;

export const StyledAppSettingTableCell = styled.div`
  align-self: stretch;
  padding: 6px 12px;
  background: ${(props) =>
    props.$disabled
      ? '#F5F5F5'
      : props.$isHeader
      ? '#E9E9E9'
      : props.$isOdd
      ? '#F5F5F5'
      : 'white'};
  border-right: 1px #d9d9d9 solid;
  display: flex;
  justify-content: ${(props) =>
    props.$isHeader ? 'flex-start' : 'space-between'};
  align-items: center;
  gap: 12px;

  &:last-child {
    border: none;
  }
`;

export const StyledAppSettingTableCellLabel = styled.div`
  color: ${(props) =>
    props.$disabled ? '#9D9D9D' : props.$isHeader ? '#434343' : '#555555'};
  font-size: ${(props) => (props.$isHeader ? '13px' : '12px')};
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
`;

export const StyledAppSettingInputFieldContainer = styled.div`
  flex: 1 1 0;
  height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const StyledAppSettingInputField = styled.div`
  width: 50px;
  height: 28px;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAppSettingInputFieldText = styled.input`
  pointer-events: ${({ $disabled }) => $disabled && 'none'};
  width: 100%;
  padding: 0 10px;
  color: ${(props) => (props.value ? 'black' : '#9d9d9d')};
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  border: none;
  outline: none;
  background: transparent;
  &::placeholder {
    color: #9d9d9d;
    text-align: center;
  }
`;

export const StyledAppSettingInputFixedText = styled.div`
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  position: relative;
  right: 10px;
`;

export const StyledAppSettingInputFieldWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

export const StyledAppSettingInputFieldLabel = styled.div`
  color: ${({ $disabled }) => ($disabled ? '#9D9D9D' : 'black')};
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
`;

// Checkbox
export const StyledAppSettingCheckboxOption = styled.div`
  height: 17px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const StyledAppSettingCheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export const StyledAppSettingCheckboxInput = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  padding: 0;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  outline: none;
  pointer-events: ${({ $disabled }) => $disabled && 'none'};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  &:checked {
    background-color: ${({ $disabled }) => ($disabled ? '#C4C4C4' : '#412cab')};
    border-color: ${({ $disabled }) => ($disabled ? '#C4C4C4' : '#412cab')};
  }
`;

export const StyledAppSettingCheckboxIcon = styled(PiCheckBold)`
  width: 16px;
  height: 16px;
  position: absolute;
  color: #fff;
  font-size: 16px;
  visibility: ${({ $isChecked }) => ($isChecked ? 'visible' : 'hidden')};
  pointer-events: none;
`;

export const StyledAppSettingCheckboxLabel = styled.div`
  text-align: center;
  color: ${({ $disabled }) => ($disabled ? '#9e9e9e' : 'black')};
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 16.9px;
`;

export const StyledAppSettingDropDownContainer = styled.div``;
