// Shutdown.styles.js
import styled from 'styled-components';

export const StyledShutdownContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  /* border: 1px #f1f2f4 solid; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledShutdownHeader = styled.div`
  margin-bottom: 10px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledShutdownTitle = styled.div`
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

export const StyledShutdownTable = styled.div`
  align-self: stretch;
  border-radius: 8px;
  border: 1px #d9d9d9 solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const StyledShutdownTableRow = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledShutdownTableCell = styled.div`
  height: 50px;
  align-self: stretch;
  padding: 6px 12px;
  background: ${({ $isHeader }) => ($isHeader ? '#e9e9e9' : 'none')};
  border-right: 1px #d9d9d9 solid;
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Pretendard';
  display: flex;
  justify-content: ${({ $isHeader }) =>
    $isHeader ? 'flex-start' : 'space-between'};
  align-items: center;
  gap: 8px;
  width: ${({ $isHeader }) => ($isHeader ? '120px' : 'auto')};
  &:last-child {
    border-right: none;
    min-width: 205px;
  }
  &:first-child {
    border-left: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

export const StyledShutdownDropdownWrapper = styled.div`
  position: relative;
`;

export const StyledShutdownInputField = styled.div`
  position: relative;
  height: 30px;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const StyledShutdownInputFieldText = styled.div`
  min-width: 87px;
  color: #1e2024;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

export const StyledShutdownCheckboxOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  border-radius: 50%;
`;

export const StyledShutdownCheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

export const StyledShutdownCheckbox = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
`;

export const StyledShutdownCheckedBackground = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  background: #412cab;
  border-radius: 50%;
`;

export const StyledShutdownCheckedIcon = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 50%;
`;

export const StyledShutdownUncheckedBackground = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
`;

export const StyledShutdownCheckboxLabel = styled.div`
  text-align: center;
  color: black;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 16.9px;
  word-wrap: break-word;
`;

export const StyledShutdownButton = styled.div`
  width: 120px;
  height: 32px;
  padding: 8px;
  background: #412cab;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: white;
  font-size: 14px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

export const StyledShutdownDatePickerWrapper = styled.div`
  position: relative;
  top: 2px;
  left: 120px;
`;
