import { useNavigate } from 'react-router-dom';
import {
  StyledVideoDetailModalWrapper,
  StyledVideoDetailModalHeader,
  StyledVideoDetailModalTitle,
  StyledVideoDetailModalCloseButton,
  StyledVideoDetailModalCloseButtonVector,
  StyledVideoDetailModalContent,
  StyledVideoDetailModalThumbnailWrapper,
  StyledVideoDetailModalInfoWrapper,
  StyledVideoDetailModalInfoTable,
  StyledVideoDetailModalInfoRow,
  StyledVideoDetailModalInfoHeader,
  StyledVideoDetailModalInfoCell,
  StyledVideoDetailModalStatsTable,
  StyledVideoDetailModalStatsHeader,
  StyledVideoDetailModalStatsHeaderCell,
  StyledVideoDetailModalStatsRow,
  StyledVideoDetailModalStatsCell,
  StyledVideoDetailModalOverlay,
  StyledVideoDetailModalThumbnailImage,
  StyledVideoPlayer,
} from './CommentVideoDetailModal.style';
import CustomButton from '../Button/Button';

const CommentVideoDetailModal = ({ isOpen, onClose, videoData }) => {
  const navigate = useNavigate();

  if (!isOpen || !videoData) return null;

  const {
    videoThumbnail,
    videoPath,
    timeline,
    createdAt,
    videoTitle,
    isActive,
    videoAddress,
    nickname,
    videoId,
    viewCount,
    likeCount,
    recvFeelkyRequestCount,
    reportCount,
  } = videoData;

  const handleSearchDetail = () => {
    onClose();
    navigate(`/PostManagement/PostManagementDetail/${videoId}?type=댓글`);
  };

  return (
    <StyledVideoDetailModalOverlay onClick={onClose}>
      <StyledVideoDetailModalWrapper onClick={(e) => e.stopPropagation()}>
        <StyledVideoDetailModalHeader>
          <StyledVideoDetailModalTitle>영상 상세</StyledVideoDetailModalTitle>
          <StyledVideoDetailModalCloseButton onClick={onClose}>
            <StyledVideoDetailModalCloseButtonVector />
          </StyledVideoDetailModalCloseButton>
        </StyledVideoDetailModalHeader>
        <StyledVideoDetailModalContent>
          <StyledVideoDetailModalThumbnailWrapper>
            {videoPath ? (
              <StyledVideoPlayer controls>
                <source src={videoPath} type="video/mp4" />
                Your browser does not support the video tag.
              </StyledVideoPlayer>
            ) : (
              <StyledVideoDetailModalThumbnailImage
                src={videoThumbnail}
                alt="Video Thumbnail"
              />
            )}
          </StyledVideoDetailModalThumbnailWrapper>
          <StyledVideoDetailModalInfoWrapper>
            <StyledVideoDetailModalInfoTable>
              <StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoHeader>
                  영상 구분
                </StyledVideoDetailModalInfoHeader>
                <StyledVideoDetailModalInfoCell>
                  댓글영상
                </StyledVideoDetailModalInfoCell>
                <StyledVideoDetailModalInfoHeader>
                  영상 길이
                </StyledVideoDetailModalInfoHeader>
                <StyledVideoDetailModalInfoCell>
                  {timeline}
                </StyledVideoDetailModalInfoCell>
              </StyledVideoDetailModalInfoRow>
              <StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoHeader>
                  업로드 일시
                </StyledVideoDetailModalInfoHeader>
                <StyledVideoDetailModalInfoCell>
                  {createdAt}
                </StyledVideoDetailModalInfoCell>
              </StyledVideoDetailModalInfoRow>
              <StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoHeader>
                  타이틀
                </StyledVideoDetailModalInfoHeader>
                <StyledVideoDetailModalInfoCell>
                  {videoTitle}
                </StyledVideoDetailModalInfoCell>
              </StyledVideoDetailModalInfoRow>
              <StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoHeader>
                  게시 상태
                </StyledVideoDetailModalInfoHeader>
                <StyledVideoDetailModalInfoCell>
                  {isActive}
                </StyledVideoDetailModalInfoCell>
              </StyledVideoDetailModalInfoRow>
              <StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoHeader>
                  게시 지역
                </StyledVideoDetailModalInfoHeader>
                <StyledVideoDetailModalInfoCell>
                  {videoAddress}
                </StyledVideoDetailModalInfoCell>
              </StyledVideoDetailModalInfoRow>
              <StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoHeader>
                  게시자 닉네임
                </StyledVideoDetailModalInfoHeader>
                <StyledVideoDetailModalInfoCell>
                  {nickname}
                </StyledVideoDetailModalInfoCell>
              </StyledVideoDetailModalInfoRow>
            </StyledVideoDetailModalInfoTable>
            <StyledVideoDetailModalStatsTable>
              <StyledVideoDetailModalStatsHeader>
                <StyledVideoDetailModalStatsHeaderCell>
                  조회수
                </StyledVideoDetailModalStatsHeaderCell>
                <StyledVideoDetailModalStatsHeaderCell>
                  좋아요
                </StyledVideoDetailModalStatsHeaderCell>

                <StyledVideoDetailModalStatsHeaderCell>
                  필키요청
                </StyledVideoDetailModalStatsHeaderCell>
                <StyledVideoDetailModalStatsHeaderCell>
                  신고
                </StyledVideoDetailModalStatsHeaderCell>
              </StyledVideoDetailModalStatsHeader>
              <StyledVideoDetailModalStatsRow>
                <StyledVideoDetailModalStatsCell>
                  {viewCount}
                </StyledVideoDetailModalStatsCell>
                <StyledVideoDetailModalStatsCell>
                  {likeCount}
                </StyledVideoDetailModalStatsCell>
                <StyledVideoDetailModalStatsCell>
                  {recvFeelkyRequestCount}
                </StyledVideoDetailModalStatsCell>
                <StyledVideoDetailModalStatsCell>
                  {reportCount}
                </StyledVideoDetailModalStatsCell>
              </StyledVideoDetailModalStatsRow>
            </StyledVideoDetailModalStatsTable>
            <CustomButton
              size="medium"
              $state="default"
              text="자세히 보기"
              $variant="search"
              onClick={handleSearchDetail}
            />
          </StyledVideoDetailModalInfoWrapper>
        </StyledVideoDetailModalContent>
      </StyledVideoDetailModalWrapper>
    </StyledVideoDetailModalOverlay>
  );
};

export default CommentVideoDetailModal;
