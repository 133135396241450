import {
  StyledRadioBtnContainer,
  StyledSelectedRadioBackground,
  StyledSelectedRadioIcon,
  StyledUnSelectedRadioBackground,
} from '../NoticeTemplate.styles';

const NoticeTemplateRadioBtn = ({ checked }) => {
  return (
    <StyledRadioBtnContainer>
      {checked ? (
        <>
          <StyledSelectedRadioBackground />
          <StyledSelectedRadioIcon />
        </>
      ) : (
        <StyledUnSelectedRadioBackground />
      )}
    </StyledRadioBtnContainer>
  );
};

export default NoticeTemplateRadioBtn;
