/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable-next-line import/named */
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import Dropdown from '../../../../../components/common/DropDown/DropDown';
import CustomButton from '../../../../../components/common/Button/Button';

import {
  StyledContainer,
  StyledTable,
  StyledDropdownWrapper,
  StyledInputField,
  StyledInputFieldWithHeight,
  StyledInputFieldText,
  StyledRadioOption,
  StyledRadioWrapper,
  StyledRadioLabel,
  StyledCheckbox,
  StyledCheckboxInput,
  StyledCheckboxIcon,
  StyledCheckboxLabel,
  StyledFilterInputFieldText,
  StyledFilterTextArea,
  StyledButtonGroup,
  StyledDayPickContainer,
} from '../NoticeTemplate.styles';

import PopupNotification from './PopupNotification';
import PushNotification from './PushNotification';

import NoticeTemplateRadioBtn from './NoticeTemplateRadioBtn';
import NoticeTemplateTableCell from './NoticeTemplateTableCell';
import NoticeTemplateRow from './NoticeTemplateRow';
import NoticeTemplateDatePicker from './NoticeTemplateDatePicker';
import NoticeTemplateFileUpload from './NoticeTemplateFileUpload';

import { formatDateYYYYMMDD } from '../../../../../utils/formatDate';
import NoticeTemplateDayPicker from './NoticeTemplateDayPicker';
import { getGroupCode } from '../../../../../utils/groupCode';
import {
  ACTIVE_STATUS,
  BOARD_TYPE,
  // eslint-disable-next-line import/named
  BOARD_TYPE_BANNER,
  BOARD_TYPE_POPUP,
} from '../../../../../constants/groupCode';
import { StyledTimeInputFiled } from '../../../../PostManagement/subPages/PostTemplate/PostTemplate.styles';
import NoticeNotification from './NoticeNotification';

const NoticeTemplateForm = ({
  boardType,
  handleChnageBoardType,
  isActiveStatus,
  handleChangeIsActiveStatus,
  handleStartDayChange,
  handleDayChange,
  endTime,
  handleEndTime,
  startTime,
  handleStartTime,
  selectedTarget,
  setSelectedTarget,
  selectedStartTime,
  setSelectedStartTime,
  selectedEndTime,
  setSelectedEndTime,
  viewType,
  handleChangeViewType,
  startDate,
  endDate,
  handleDateChange,
  handleCancel,
  handleConfirm,
  showDatePicker,
  isCelebChecked,
  handleCelebCheckboxChange,
  selectedFile,
  handleFileChange,
  handleFileDelete,
  title,
  handleTitleChange,
  handleSummaryChange,
  content,
  handleContentChange,
  setShowDatePicker,
  onCancel,
  onSave,
  cancelButtonText,
  saveButtonText,
  isEditable,
  summaryTitle,
  hanldeDayChange,
  popUpFile,
  handlePopUpFileChange,
  handlePopUpFileDelete,
  bannerFile,
  handleBannerFileChange,
  handleBannerFileDelete,
}) => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const activeStatusOptions = getGroupCode(
    groupCodeList,
    ACTIVE_STATUS,
  ).codes.filter(({ codeName }) => codeName !== '삭제');
  const boardTypeOptions = getGroupCode(groupCodeList, BOARD_TYPE).codes;

  const isPopup = useMemo(() => boardType == BOARD_TYPE_POPUP, [boardType]);
  const isBanner = useMemo(() => boardType == BOARD_TYPE_BANNER, [boardType]);

  const options = ['모든 회원', '샐럽 회원'];
  const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const period = hour < 12 ? '오전' : '오후';
        let displayHour = hour % 12;
        displayHour = displayHour === 0 ? 12 : displayHour; // 0시를 12시로 변환
        const formattedMinute = String(minute).padStart(2, '0');
        timeSlots.push(`${period} ${displayHour}:${formattedMinute}`);
      }
    }
    return timeSlots;
  };

  const parseTimeString = (timeStr) => {
    const regex = /(오전|오후) (\d{1,2}):(\d{2})/;
    const match = timeStr.match(regex);

    if (!match) {
      throw new Error('Invalid time format');
    }

    const period = match[1];
    let hour = parseInt(match[2], 10);
    const minute = parseInt(match[3], 10);

    if (period === '오전') {
      if (hour === 12) {
        hour = 0;
      }
    } else if (hour !== 12) {
      hour += 12;
    }

    return { hour, minute };
  };

  const formatTimeObject = (timeObj) => {
    if (!timeObj) return null;

    let { hour } = timeObj;
    const minute = String(timeObj.minute).padStart(2, '0');
    let period = '오전';

    if (hour >= 12) {
      period = '오후';
      if (hour > 12) {
        hour -= 12;
      }
    } else if (hour === 0) {
      hour = 12;
    }

    const formattedHour = String(hour).padStart(2, '0');

    return `${period} ${formattedHour}:${minute}`;
  };

  const timeOptions = generateTimeSlots();

  function removeAfter(arr, value) {
    const index = arr.indexOf(value);

    if (index !== -1) {
      return arr.slice(0, index + 1);
    }

    return arr;
  }

  const removeBefore = (arr, value) => {
    const index = arr.indexOf(value);

    if (index !== -1) {
      return arr.slice(index);
    }

    return arr;
  };

  const startTimeOptions = useMemo(() => {
    if (!endTime || startDate.toString() != endDate.toString())
      return timeOptions;
    return removeAfter(timeOptions, formatTimeObject(endTime));
  }, [endTime, startDate, endDate]);

  const endTimeOptions = useMemo(() => {
    if (!endTime || startDate.toString() != endDate.toString())
      return timeOptions;
    return removeBefore(timeOptions, formatTimeObject(startTime));
  }, [startTime, startDate, endDate]);

  const hourOptions = Array.from({ length: 24 }, (_, i) =>
    String(i).padStart(2, '0'),
  );
  const minuteOption = ['00', '15', '30'];

  const handleChangeStartTime = (value) => {
    handleStartTime(parseTimeString(value));
  };

  const handleChangeEndTime = (value) => {
    handleEndTime(parseTimeString(value));
  };

  return (
    <>
      <StyledContainer>
        <StyledTable>
          <NoticeTemplateRow>
            <div style={{ display: 'flex' }}>
              <NoticeTemplateTableCell isHeader isFirstRow isRequired>
                게시 상태
              </NoticeTemplateTableCell>
              <NoticeTemplateTableCell minWidth="50%">
                <StyledRadioOption>
                  {activeStatusOptions.map(({ fullCode, codeName }) => (
                    <StyledRadioWrapper
                      key={fullCode}
                      onClick={() => handleChangeIsActiveStatus(fullCode)}
                    >
                      <NoticeTemplateRadioBtn
                        checked={fullCode == isActiveStatus}
                      />
                      <StyledRadioLabel>{codeName}</StyledRadioLabel>
                    </StyledRadioWrapper>
                  ))}
                </StyledRadioOption>
                {/* <StyledRadioOption>
                <StyledRadioWrapper
                  onClick={() => handleChangeIsActive(true)}
                  // $disabled={!isActive}
                >
                  <NoticeTemplateRadioBtn checked={isActive} />
                  <StyledRadioLabel>게시</StyledRadioLabel>
                </StyledRadioWrapper>
                <StyledRadioWrapper
                  onClick={() => handleChangeIsActive(false)}
                  // $disabled={!isActive}
                >
                  <NoticeTemplateRadioBtn checked={!isActive} />
                  <StyledRadioLabel>숨김</StyledRadioLabel>
                </StyledRadioWrapper>
              </StyledRadioOption> */}
              </NoticeTemplateTableCell>
              <NoticeTemplateTableCell isHeader>
                공지 대상
              </NoticeTemplateTableCell>
              <NoticeTemplateTableCell minWidth="167px">
                <StyledDropdownWrapper>
                  모든 회원
                  {/* <Dropdown
                  options={options}
                  selectedValue={selectedTarget}
                  onOptionChange={(option) =>
                    isEditable && setSelectedTarget(option)
                  }
                  minWidth="143px"
                  maxHeight="100px"
                  height="28px"
                  fontSize="13px"
                  disabled={!isEditable}
                /> */}
                </StyledDropdownWrapper>
              </NoticeTemplateTableCell>
            </div>
          </NoticeTemplateRow>
          <NoticeTemplateRow>
            <NoticeTemplateTableCell isHeader isRequired>
              공지 구분
            </NoticeTemplateTableCell>
            <NoticeTemplateTableCell>
              <StyledRadioOption>
                {boardTypeOptions.map(({ fullCode, codeName }) => (
                  <StyledRadioWrapper
                    key={fullCode}
                    onClick={() => handleChnageBoardType(fullCode)}
                  >
                    <NoticeTemplateRadioBtn checked={fullCode == boardType} />
                    <StyledRadioLabel>{codeName}</StyledRadioLabel>
                  </StyledRadioWrapper>
                ))}
              </StyledRadioOption>
            </NoticeTemplateTableCell>
          </NoticeTemplateRow>

          <NoticeTemplateRow>
            <NoticeTemplateTableCell isHeader isRequired>
              공지 기간
            </NoticeTemplateTableCell>
            <NoticeTemplateTableCell>
              <div>
                <StyledInputField
                  width="100px"
                  height="28px"
                  onClick={() => setShowDatePicker('startDate')}
                >
                  <StyledInputFieldText>
                    {startDate ? (
                      formatDateYYYYMMDD(startDate)
                    ) : (
                      <div style={{ color: '#9D9D9D' }}>시작일</div>
                    )}
                  </StyledInputFieldText>
                </StyledInputField>
                <StyledDayPickContainer>
                  {showDatePicker === 'startDate' && (
                    <NoticeTemplateDayPicker
                      startDate={startDate}
                      onChange={handleStartDayChange}
                      onCancel={handleCancel}
                      onConfirm={handleConfirm}
                      setShowDatePicker={setShowDatePicker}
                      showDatePicker={showDatePicker}
                    />
                  )}
                </StyledDayPickContainer>
              </div>
              <StyledTimeInputFiled>
                <Dropdown
                  options={startTimeOptions}
                  selectedValue={
                    formatTimeObject(startTime) || (
                      <div style={{ color: '#9D9D9D' }}>시작 시간</div>
                    )
                  }
                  onOptionChange={handleChangeStartTime}
                  minWidth="105px"
                  maxHeight="100px"
                  height="28px"
                  fontSize="13px"
                />
              </StyledTimeInputFiled>
              ~
              <div>
                <StyledInputField
                  width="100px"
                  height="28px"
                  onClick={() => setShowDatePicker('endDate')}
                >
                  <StyledInputFieldText>
                    {endDate ? (
                      formatDateYYYYMMDD(endDate)
                    ) : (
                      <div style={{ color: '#9D9D9D' }}>종료일</div>
                    )}
                  </StyledInputFieldText>
                </StyledInputField>
                <StyledDayPickContainer>
                  {showDatePicker === 'endDate' && (
                    <NoticeTemplateDayPicker
                      startDate={endDate}
                      onChange={handleDayChange}
                      onCancel={handleCancel}
                      onConfirm={handleConfirm}
                      setShowDatePicker={setShowDatePicker}
                      showDatePicker={showDatePicker}
                    />
                  )}
                </StyledDayPickContainer>
              </div>
              <StyledTimeInputFiled>
                <Dropdown
                  options={endTimeOptions}
                  selectedValue={
                    formatTimeObject(endTime) || (
                      <div style={{ color: '#9D9D9D' }}>종료 시간</div>
                    )
                  }
                  onOptionChange={handleChangeEndTime}
                  minWidth="105px"
                  maxHeight="100px"
                  height="28px"
                  fontSize="13px"
                />
              </StyledTimeInputFiled>
            </NoticeTemplateTableCell>
          </NoticeTemplateRow>

          <NoticeTemplateRow>
            <NoticeTemplateTableCell isHeader isRequired height="46px">
              타이틀
            </NoticeTemplateTableCell>
            <NoticeTemplateTableCell height="46px">
              <StyledInputField width="594px">
                <StyledFilterInputFieldText
                  value={title}
                  onChange={handleTitleChange}
                  placeholder="타이틀을 입력하여 주세요."
                  disabled={!isEditable}
                  maxLength={30}
                />
              </StyledInputField>
            </NoticeTemplateTableCell>
          </NoticeTemplateRow>
          <NoticeTemplateRow>
            <NoticeTemplateTableCell
              isHeader
              height={isPopup ? '237px' : '284px'}
              isAlignStart
              isRequired
            >
              내용
            </NoticeTemplateTableCell>
            <NoticeTemplateTableCell height={isPopup ? '237px' : '284px'}>
              <StyledInputFieldWithHeight width="594px">
                <StyledFilterTextArea
                  value={content}
                  onChange={handleContentChange}
                  placeholder="내용을 입력해주세요."
                  disabled={!isEditable}
                  maxLength={300}
                />
              </StyledInputFieldWithHeight>
            </NoticeTemplateTableCell>
          </NoticeTemplateRow>
          {isPopup && (
            <NoticeTemplateRow>
              <NoticeTemplateTableCell
                isLastRow
                isHeader
                height="46px"
                isRequired
              >
                팝업 이미지
              </NoticeTemplateTableCell>
              <NoticeTemplateFileUpload
                height="46px"
                selectedFile={popUpFile}
                onFileChange={handlePopUpFileChange}
                onFileDelete={handlePopUpFileDelete}
                fileAspectRatio={{ widthAspectRatio: 4, heightAspectRatio: 1 }}
              />
            </NoticeTemplateRow>
          )}
          {isBanner && (
            <NoticeTemplateRow>
              <NoticeTemplateTableCell
                isLastRow
                isHeader
                height="46px"
                isRequired
              >
                배너 이미지
              </NoticeTemplateTableCell>
              <NoticeTemplateFileUpload
                height="46px"
                selectedFile={bannerFile}
                onFileChange={handleBannerFileChange}
                onFileDelete={handleBannerFileDelete}
                fileAspectRatio={{ widthAspectRatio: 4, heightAspectRatio: 1 }}
              />
            </NoticeTemplateRow>
          )}
          <NoticeTemplateRow>
            <NoticeTemplateTableCell isLastRow isHeader height="46px">
              상세 이미지
            </NoticeTemplateTableCell>
            <NoticeTemplateFileUpload
              height="46px"
              selectedFile={selectedFile}
              onFileChange={handleFileChange}
              onFileDelete={handleFileDelete}
            />
          </NoticeTemplateRow>
        </StyledTable>
        <NoticeNotification
          isPush={isPopup}
          isBanner={isBanner}
          title={title}
          popUpFile={popUpFile}
          bannerFile={bannerFile}
          content={content}
          selectedFile={selectedFile}
          startDate={startDate}
          startTime={formatTimeObject(startTime) || ''}
          endDate={endDate}
          endTime={formatTimeObject(endTime) || ''}
        />
        {/* <PushNotification
          title={title}
          content={summaryTitle}
          selectedFile={selectedFile}
        /> */}
        {/* {viewType.includes('알림') ? (
          isCelebChecked && (
            <PushNotification
              title={title}
              content={summaryTitle}
              selectedFile={selectedFile}
            />
          )
        ) : (
          <PopupNotification selectedFile={selectedFile} />
        )} */}
      </StyledContainer>
      <StyledButtonGroup>
        {isEditable && (
          <>
            <CustomButton
              size="medium"
              $state="default"
              $variant="systemReset"
              text={cancelButtonText}
              onClick={onCancel}
            />
            <CustomButton
              size="medium"
              $state="default"
              $variant="systemSave"
              text={saveButtonText}
              onClick={onSave}
            />
          </>
        )}
      </StyledButtonGroup>
    </>
  );
};

export default NoticeTemplateForm;
