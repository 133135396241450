/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';
import { PiListDashesBold } from 'react-icons/pi';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../../../components/common/Button/Button';
import {
  StyledRegionCategoryList,
  StyledButtonGroup,
  StyledCategoryList,
  StyledCategoryItem,
  StyledCategoryText,
  StyledTextWrapper,
  StyledPrimaryText,
  StyledXIcon,
} from './RegionCategoryList.styles';
import {
  regionCategoryReset,
  selectCategory,
  setAddMode,
  setCategoryName,
} from '../../../../../store/redux/regionCategorySlice';
import {
  addRegionCategoryList,
  asyncGetRegionCategoryList,
  deleteRegionCategoryList,
  updateRegionCategoryOrderList,
} from '../../../../../store/redux/regionCategoryListSlice';

import {
  deleteSystemRegionCategory,
  putSystemRegionCategoryOrder,
} from '../../../../../apis/system';

const RegionCategoryList = ({ showModal, setCreateButton, closeModal }) => {
  const dispatch = useDispatch();
  const selectCategoryId = useSelector(
    (state) => state.regionCategory.categoryId,
  );
  const regionCategoryList = useSelector(
    (state) => state.regionCategoryList.regionCategoryList,
  );
  const initialSort = useSelector(
    (state) => state.regionCategoryList.initialSort,
  );

  // useEffect(() => {
  //   console.log(regionCategoryList);
  // }, [regionCategoryList]);

  const isAdding = useSelector((state) => state.regionCategory.isAdding);
  const [isDeleteCateGoryId, setIsDeleteCateGoryId] = useState('');

  const { fetch: fetchPutSystemRegionCategoryOrder } =
    putSystemRegionCategoryOrder();

  const { fetch: fetchDeleteSystemRegionCategory } = deleteSystemRegionCategory(
    {
      query: isDeleteCateGoryId,
    },
  );

  const [isHover, setIsHover] = useState(null);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleAddCategory = () => {
    const ADD_CATEGORY_ID = 'ADD_CATEGORY_ID';

    dispatch(addRegionCategoryList());
    dispatch(selectCategory(ADD_CATEGORY_ID));
    dispatch(setAddMode(ADD_CATEGORY_ID));
  };

  const deleteCategoryDelete = (deleteCategoryId) => {
    // 삭제 카테고리가 추가중인 카테고리라면 추가중 상태값을 지우기 위한 if문
    // => 추가 카테고리를 삭제해도 추가하기 버튼 disable이 풀리지 않아서
    if (deleteCategoryId === isAdding) dispatch(setAddMode(null));

    // 삭제 카테고리가 현제 선택된 카테고리라면 선택된 카테고리 값을 지우기 위한 i문
    // => 지역 필터 컴포넌트의 데이터 초기화가 되지 않고 남아 있어서
    if (deleteCategoryId === selectCategoryId) dispatch(selectCategory(null));
    dispatch(deleteRegionCategoryList(deleteCategoryId));
    setIsDeleteCateGoryId(deleteCategoryId);
  };

  const handleCategoryDelete = (deleteCategoryId) => {
    showModal(
      '삭제 하시겠습니까?',
      '초기화시 입력하신 모든 내용이 삭제됩니다.',
      'reset',
    );
    setCreateButton([
      {
        size: 'large',
        state: 'default',
        text: '취소',
        variant: 'cancel',
        onClick: () => {
          closeModal();
        },
      },
      {
        size: 'large',
        state: 'default',
        text: '확인',
        variant: 'alert',
        onClick: () => {
          deleteCategoryDelete(deleteCategoryId);
          closeModal();
        },
      },
    ]);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(
      regionCategoryList,
      result.source.index,
      result.destination.index,
    );

    dispatch(updateRegionCategoryOrderList(updatedItems));
  };

  const onDragStart = (data) => {
    const [id, name] = data.draggableId.split(' ');

    dispatch(selectCategory(id));
    dispatch(setCategoryName(name));
  };

  const handleSaveOrder = () => {
    showModal('순서 저장', '현재 순서대로 저장하시겠습니까?', 'confirm');
    setCreateButton([
      {
        size: 'large',
        state: 'default',
        variant: 'confirm',
        text: '확인',
        onClick: () => {
          const newList = regionCategoryList.map(({ categoryId }, index) => ({
            categoryId,
            sort: initialSort + index,
          }));
          fetchPutSystemRegionCategoryOrder(newList);
          closeModal();
        },
      },
    ]);
  };

  useEffect(() => {
    if (!isDeleteCateGoryId) return;
    fetchDeleteSystemRegionCategory(isDeleteCateGoryId);
  }, [isDeleteCateGoryId]);

  useEffect(() => {
    dispatch(regionCategoryReset());
    dispatch(asyncGetRegionCategoryList());
  }, []);

  return (
    <StyledRegionCategoryList>
      <StyledButtonGroup>
        <CustomButton
          size="medium"
          $state="default"
          $variant="systemChange"
          text="추가"
          onClick={handleAddCategory}
          disabled={isAdding}
        />
        <CustomButton
          size="medium"
          $state="default"
          $variant="systemChange"
          text="순서 저장"
          onClick={handleSaveOrder}
          disabled={isAdding}
        />
      </StyledButtonGroup>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <StyledCategoryList
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {regionCategoryList.map(({ categoryId, categoryName }, index) => (
                <Draggable
                  key={categoryId}
                  draggableId={`${categoryId} ${categoryName}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <StyledCategoryItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        border: `1px solid ${
                          snapshot.isDragging ||
                          categoryId === selectCategoryId ||
                          categoryId === isHover
                            ? 'black'
                            : 'white'
                        }`,
                        ...provided.draggableProps.style,
                      }}
                      onMouseEnter={() => setIsHover(categoryId)}
                      onMouseLeave={() => setIsHover(null)}
                      onClick={() => {
                        dispatch(selectCategory(categoryId));
                        dispatch(setCategoryName(categoryName));
                      }}
                    >
                      <StyledCategoryText>
                        <StyledTextWrapper>
                          <PiListDashesBold
                            size={20}
                            color={
                              snapshot.isDragging ||
                              categoryId === selectCategoryId ||
                              categoryId === isHover
                                ? '#555555'
                                : '#9D9D9D'
                            }
                          />

                          <StyledPrimaryText $isPlaceholder={!categoryName}>
                            {categoryName || '카테고리명'}
                          </StyledPrimaryText>
                        </StyledTextWrapper>
                        <StyledXIcon
                          color={
                            snapshot.isDragging ||
                            categoryId === selectCategoryId ||
                            categoryId === isHover
                              ? '#555555'
                              : '#9D9D9D'
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCategoryDelete(categoryId);
                          }}
                        />
                      </StyledCategoryText>
                    </StyledCategoryItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </StyledCategoryList>
          )}
        </Droppable>
      </DragDropContext>
    </StyledRegionCategoryList>
  );
};

export default RegionCategoryList;

// RegionCategoryList 설명

// 1. 컴포넌트 Props:
//    - `categories`: 카테고리 목록 데이터를 담고 있는 배열입니다.
//    - `onCategoryClick`: 카테고리 항목을 클릭할 때 호출되는 콜백 함수입니다.
//    - `onCategoryDelete`: 카테고리 항목을 삭제할 때 호출되는 콜백 함수입니다.
//    - `onDragEnd`: 드래그 앤 드롭 동작이 끝났을 때 호출되는 콜백 함수입니다.
//    - `onAddCategory`: 새로운 카테고리를 추가할 때 호출되는 콜백 함수입니다.
//    - `onSaveOrder`: 카테고리 순서를 저장할 때 호출되는 콜백 함수입니다.
//    - `isAddingCategory`: 현재 카테고리 추가 중인지 여부를 나타내는 boolean 값입니다.

// 2. 상태 관리:
//    - `selectedCategory` 상태는 현재 선택된 카테고리를 저장합니다.
//    - `setSelectedCategory` 함수는 선택된 카테고리를 업데이트합니다.

// 3. 이벤트 핸들러:
//    - `handleCategoryClick`: 카테고리 항목을 클릭할 때 호출되며, 선택된 카테고리를 업데이트하고 `onCategoryClick` 콜백 함수를 호출합니다.
//    - `handleCategoryDelete`: 카테고리 항목을 삭제할 때 호출되며, `onCategoryDelete` 콜백 함수를 호출합니다.
//    - `handleAddCategory`: 새로운 카테고리를 추가할 때 호출되며, `onAddCategory` 콜백 함수를 호출합니다.

// 4. 렌더링:
//    - `StyledRegionCategoryList` 컴포넌트로 전체 카테고리 목록을 감쌉니다.
//    - `StyledButtonGroup` 컴포넌트 내부에 "추가" 버튼과 "순서 저장" 버튼을 렌더링합니다.
//      - "추가" 버튼은 `handleAddCategory` 함수를 호출하여 새로운 카테고리를 추가합니다.
//      - "순서 저장" 버튼은 `onSaveOrder` 콜백 함수를 호출하여 카테고리 순서를 저장합니다.
//    - `DragDropContext` 컴포넌트로 드래그 앤 드롭 기능을 제공하며, `onDragEnd` 콜백 함수를 전달합니다.
//    - `Droppable` 컴포넌트로 드롭 가능한 영역을 정의하며, `droppableId`는 "categories"로 설정됩니다.
//    - `Draggable` 컴포넌트로 각 카테고리 항목을 드래그 가능하게 만듭니다.
//      - `key`와 `draggableId`는 카테고리의 고유 식별자로 설정됩니다.
//      - `index`는 카테고리의 순서를 나타냅니다.
//    - 각 카테고리 항목은 `StyledCategoryItem` 컴포넌트로 렌더링되며, 선택 여부와 플레이스홀더 여부에 따라 스타일이 적용됩니다.
//    - 카테고리 텍스트는 `StyledCategoryText` 컴포넌트로 감싸져 있으며, 아이콘과 카테고리명을 포함합니다.
//    - 카테고리 삭제 아이콘은 `StyledXIcon` 컴포넌트로 렌더링되며, 클릭 시 `handleCategoryDelete` 함수를 호출합니다.

// 이 컴포넌트는 다음과 같이 동작합니다:

// 1. 컴포넌트가 렌더링될 때, `categories` prop으로 전달된 카테고리 목록을 기반으로 각 카테고리 항목을 렌더링합니다.
// 2. 사용자가 "추가" 버튼을 클릭하면 `handleAddCategory` 함수가 호출되어 `onAddCategory` 콜백 함수를 통해 새로운 카테고리를 추가합니다.
// 3. 사용자가 "순서 저장" 버튼을 클릭하면 `onSaveOrder` 콜백 함수가 호출되어 카테고리 순서를 저장합니다.
// 4. 사용자가 카테고리 항목을 클릭하면 `handleCategoryClick` 함수가 호출되어 선택된 카테고리를 업데이트하고 `onCategoryClick` 콜백 함수를 호출합니다.
// 5. 사용자가 카테고리 삭제 아이콘을 클릭하면 `handleCategoryDelete` 함수가 호출되어 `onCategoryDelete` 콜백 함수를 통해 해당 카테고리를 삭제합니다.
// 6. 사용자가 카테고리 항목을 드래그하여 순서를 변경하면 `onDragEnd` 콜백 함수가 호출되어 변경된 순서를 처리할 수 있습니다.
