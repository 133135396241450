/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
// src/router/PublicRoutes.jsx
import { Navigate, Route, Outlet } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Login from '../pages/Login/Login';
import Dashboard from '../pages/Dashboard/Dashboard';
import AccountManagement from '../pages/AccountManagement/AccountManagement';
import Inquiries from '../pages/CustomerService/Inquiries/Inquiries';
import Faq from '../pages/CustomerService/FAQ/faq';
import PostManagement from '../pages/PostManagement/PostManagement';
import SystemManagement from '../pages/SystemManagement/SystemManagement';
import UserManagement from '../pages/UserManagement/UserManagement';
import AdminUserDetailPage from '../pages/AccountManagement/subPages/AdminInfoDetail/AdminInfoDetail';
import AddNewAdmin from '../pages/AccountManagement/subPages/AddNewAdmin/AddNewAdmin';
import UserManagementDetail from '../pages/UserManagement/subPages/UserManagementDetail/UserManagementDetail';
import PostManagementDetail from '../pages/PostManagement/subPages/PostManagementDetail';
import TokenManager from '../api/tokenManger';
import { asyncGetGroupCodeList } from '../store/redux/groupCodeSlice';
import { asyncGetAccessPermissionsList } from '../store/redux/accessPermissionsSlice';
import PostManagementCreatePost from '../pages/PostManagement/subPages/PostManagementCreatePost';
import Spinner from '../components/common/Spinner/Spinner';
import MyInfoPage from '../pages/MyInfo/MyInfo';
import PostManagementCreateCommentVideo from '../pages/PostManagement/subPages/PostManagementCreateCommentVideo';
import Alarm from '../pages/CustomerService/Alarm/Alarm';
import AlarmDetail from '../pages/CustomerService/Alarm/subPages/AlarmDetail';
import CreateAlarm from '../pages/CustomerService/Alarm/subPages/CreateAlarm';
import ConectNoticeId from '../pages/CustomerService/Alarm/subPages/ConectNoticeId';
import Notice from '../pages/CustomerService/Notice/Notice';
import NoticeDetail from '../pages/CustomerService/Notice/subPages/NoticeDetail';
import CreateNotices from '../pages/CustomerService/Notice/subPages/CreateNotices';

// TODO: 추가 페이지 라우터 설정 여기서 해주세요!
const privateRoutes = [
  { path: '/myinfo', element: <MyInfoPage /> },
  { path: '/dashboard', element: <Dashboard />, title: '대시보드' },
  {
    path: '/accountmanagement/*',
    element: <AccountManagement />,
    title: '계정관리',
    children: [
      {
        path: 'userdetail',
        element: <AdminUserDetailPage />,
      },
      {
        path: 'addnewadmin',
        element: <AddNewAdmin />,
      },
    ],
  },
  {
    path: '/postmanagement/*',
    element: <PostManagement />,
    title: '게시물관리',
    children: [
      {
        path: 'postmanagementdetail/:id',
        element: <PostManagementDetail />,
      },
      {
        path: 'postmanagementdetail/:id/createcommentvideo',
        element: <PostManagementCreateCommentVideo />,
      },
      {
        path: 'createpost',
        element: <PostManagementCreatePost />,
      },
      {
        path: 'addnewadmin',
        element: <AddNewAdmin />,
      },
    ],
  },
  {
    path: '/systemmanagement',
    element: <SystemManagement />,
    title: '시스템관리',
  },
  {
    path: '/usermanagement/*',
    element: <UserManagement />,
    title: '회원관리',
    children: [
      {
        path: 'usermanagementdetail/:id',
        element: <UserManagementDetail />,
      },
    ],
  },
  {
    path: '/customerservice/*',
    element: <Outlet />,
    title: '고객센터',
    children: [
      { path: 'faq', element: <Faq /> },
      { path: 'inquiries', element: <Inquiries /> },
      { path: 'alarm', element: <Alarm /> },
      { path: 'alarm/:id', element: <AlarmDetail /> },
      { path: 'alarm/createalarm', element: <CreateAlarm /> },
      { path: 'alarm/:id/conectnoticeid/:key', element: <ConectNoticeId /> },
      {
        path: 'alarm/createalarm/conectnoticeid/:id',
        element: <ConectNoticeId />,
      },
      {
        path: 'notices',
        element: <Notice />,
      },
      { path: 'notices/:id', element: <NoticeDetail /> },
      { path: 'notices/createnotices', element: <CreateNotices /> },
    ],
  },
];

const publicRoutes = [
  {
    path: '/',
    element: <Login />,
  },
];

const ProtectRoute = ({ path }) => {
  const tokenManager = new TokenManager();

  const dispatch = useDispatch();
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const groupCodeListIsLoading = useSelector(
    (state) => state.groupCode.isLoading,
  );
  const accessPermissionsList = useSelector(
    (state) => state.accessPermissions.accessPermissionsList,
  );
  const accessPermissionsListIsLoading = useSelector(
    (state) => state.accessPermissions.isLoading,
  );

  useEffect(() => {
    if (!groupCodeList.length) dispatch(asyncGetGroupCodeList());
    dispatch(asyncGetAccessPermissionsList());
  }, [tokenManager.userId]);

  if (
    !groupCodeList.length ||
    !accessPermissionsList.length ||
    groupCodeListIsLoading ||
    accessPermissionsListIsLoading
  )
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: 'scale(2)',
        }}
      >
        <Spinner />
      </div>
    );

  if (
    !createAccesRoutes(accessPermissionsList).includes(
      path.replace(/[/*]/g, ''),
    ) ||
    !groupCodeList.length
  )
    return <Navigate to="/dashboard" />;
  return <Outlet />;
};

const createAccesRoutes = (route) => {
  const activeFeatures = route?.filter(({ isActive }) => isActive);
  const accessRoutes = [
    'myinfo',
    ...activeFeatures?.map((feature) => feature.routerPath),
  ];

  return accessRoutes;
};

export const createPublicRoutes = () => {
  return publicRoutes.map((route) => {
    return <Route key={route.path} path={route.path} element={route.element} />;
  });
};

export const createPrivateRoutes = () => {
  return privateRoutes.map((route) => {
    if (route.children) {
      return (
        <Route
          key={route.path}
          path={route.path}
          element={<ProtectRoute path={route.path} />}
        >
          <Route index element={route.element} />
          {route.children.map((child) => (
            <Route key={child.path} path={child.path} element={child.element} />
          ))}
        </Route>
      );
    }
    return (
      <Route
        key={route.path}
        path={route.path}
        element={<ProtectRoute path={route.path} />}
      >
        <Route index element={route.element} />
      </Route>
    );
  });
};
