import styled from 'styled-components';
import notificationIcon from '../../../../../assets/notification-icon.png';

const PushNotification = ({ title, content, selectedFile }) => {
  return (
    <PushNotificationContainer>
      <CollapsedNotification>
        <Label>푸시 알림 접힌 화면</Label>
        <NotificationBox>
          <NotificationIcon src={notificationIcon} alt="Notification Icon" />
          <Wrapper>
            <TitleWrapper>
              <Title>{title || '공지타이틀'}</Title>
              <Time>지금</Time>
            </TitleWrapper>
            <ContentsWrapper>
              <Content>
                {content ||
                  '필키를 이용하는 고객님들께 보내는 공지 내용입니다.'}
              </Content>
              <CollapsedNotificationContentBox>
                {selectedFile && (
                  <img
                    src={selectedFile.url || URL.createObjectURL(selectedFile)}
                    alt="Uploaded"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </CollapsedNotificationContentBox>
            </ContentsWrapper>
          </Wrapper>
        </NotificationBox>
      </CollapsedNotification>

      <ExpandedNotification>
        <Label>푸시 알림 펼친 화면</Label>
        <ExpandedNotificationBox>
          <NotificationIcon src={notificationIcon} alt="Notification Icon" />
          <Wrapper>
            <TitleWrapper>
              <TitleWrapper>
                <Title>{title || '공지타이틀'}</Title>
              </TitleWrapper>
              <Time>지금</Time>
            </TitleWrapper>
            <Content>
              {content || '필키를 이용하는 고객님들께 보내는 공지 내용입니다.'}
            </Content>
          </Wrapper>
        </ExpandedNotificationBox>
        <ExpandedNotificationContent>
          {selectedFile && (
            <img
              src={selectedFile.url || URL.createObjectURL(selectedFile)}
              alt="Uploaded"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          )}
        </ExpandedNotificationContent>
      </ExpandedNotification>
    </PushNotificationContainer>
  );
};

export default PushNotification;

const PushNotificationContainer = styled.div`
  height: 100%;
  padding: 20px;
  background: #e9e9e9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
`;

const CollapsedNotification = styled.div`
  height: 73px;
  position: relative;
`;

const CollapsedNotificationContentBox = styled.div`
  display: flex;
  background: #c6c4ff;
  width: 23px;
  height: 23px;
  border-radius: 3px;
`;

const NotificationBox = styled.div`
  width: 240px;
  height: 52px;
  left: 0;
  top: 21px;
  position: absolute;
  background: white;
  border-radius: 9px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const NotificationIcon = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 6px;
`;

const ExpandedNotification = styled.div`
  width: 240px;
  height: 256px;
`;

const ExpandedNotificationBox = styled.div`
  margin-top: 12px;
  width: 240px;
  height: 55px;
  background: white;
  border-radius: 9px;
  display: flex;
  padding: 10px;
  align-items: center;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const ExpandedNotificationContent = styled.div`
  width: 240px;
  height: 190px;
  background: #c6c4ff;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; // 추가
`;

const Wrapper = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-left: 9px;
  margin-right: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  max-width: 144px;
  color: #434343;
  font-size: 9px;
  font-family: 'Pretendard';
  font-weight: 700;
  line-height: 9px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Time = styled.div`
  width: 18px;
  color: #434343;
  font-size: 8px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 12px;
  word-wrap: break-word;
`;

const Content = styled.div`
  max-width: 144px;
  color: #434343;
  font-size: 9px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 11.7px;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Label = styled.div`
  color: #9d9d9d;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 9px;
  word-wrap: break-word;
`;
