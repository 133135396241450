import {
  StyledNoticeTemplateTableCell,
  StyledNoticeTemplateTableHeaderCell,
} from '../NoticeTemplate.styles';

const NoticeTemplateTableCell = ({
  isHeader,
  isFirstRow,
  isLastRow,
  isRequired,
  height,
  minWidth,
  children,
  isAlignStart,
}) => {
  const CellComponent = isHeader
    ? StyledNoticeTemplateTableHeaderCell
    : StyledNoticeTemplateTableCell;

  return (
    <CellComponent
      $isFirstRow={isFirstRow}
      $isLastRow={isLastRow}
      $height={height}
      $minWidth={minWidth}
      $isAlignStart={isAlignStart}
    >
      {children}
      {isRequired && <span style={{ color: '#F76659' }}>*</span>}
    </CellComponent>
  );
};

export default NoticeTemplateTableCell;
