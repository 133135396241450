/* eslint-disable react/prop-types */
import styled from 'styled-components';
import { FaStar } from 'react-icons/fa';
import { PiCrownSimpleFill } from 'react-icons/pi';
import { adminLevel } from '../../constants/adminLevel';

const StyledBadgeWrapper = styled.div`
  box-sizing: border-box;
  width: ${({ type }) =>
    BADGE_COLORS[type]?.width || BADGE_COLORS.default.width};
  height: 24px; // 높이를 24px로 고정
  display: inline-flex;
  align-items: center;
  justify-content: center; // 내용을 가운데 정렬
  padding: 6px 4px 6px 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: ${({ type }) => BADGE_COLORS[type]?.text || BADGE_COLORS.default.text};
  background-color: ${({ type }) =>
    BADGE_COLORS[type]?.background || BADGE_COLORS.default.background};
`;

const StyledBadgeIconWrapper = styled.div`
  flex-shrink: 0; // 아이콘이 줄어들지 않도록 설정
  margin-right: 4px;
  display: flex;
  align-items: center;
`;

const BADGE_COLORS = {
  default: {
    background: '#FFFFFF', // 기본 배경색
    text: '#000000', // 기본 텍스트색
    label: '서비스운영자', // 기본 라벨
    width: '79px',
  },
  master: {
    background: '#E9E8FF',
    text: '#645DD1',
    label: '마스터',
    width: '64px',
  },
  super: {
    background: '#FFF8DB',
    text: '#FFB200',
    label: '슈퍼관리자',
    width: '82px',
  },
  serviceManager: {
    background: '#E5FAEC',
    text: '#00AD74',
    label: '서비스관리자',
    width: '79px',
  },
  serviceAdmin: {
    background: '#F1F2F4',
    text: '#686B73',
    label: '서비스운영자',
    width: '79px',
  },
};

const Badge = ({ type }) => {
  const level = adminLevel[type] || 'default';

  return (
    <StyledBadgeWrapper type={level}>
      {level === 'super' && (
        <StyledBadgeIconWrapper>
          <PiCrownSimpleFill size="14px" />
        </StyledBadgeIconWrapper>
      )}
      {level === 'master' && (
        <StyledBadgeIconWrapper>
          <FaStar size="14px" />
        </StyledBadgeIconWrapper>
      )}
      {BADGE_COLORS[level].label}
    </StyledBadgeWrapper>
  );
};

export default Badge;
// 용법
//  <BadgeComponent type="master" />
