/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import InputField from '../../../../components/common/InputField/InputField';
import CustomButton from '../../../../components/common/Button/Button';

const DeleteAccountModal = ({
  isLoading,
  closeModal,
  handleDeleteConfirm,
  failedDelte,
  setFailedDelete,
}) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordFocused, setPasswordFocused] = useState(false);

  const handlePasswordBlur = () => {
    if (password.trim() !== '') {
      const passwordRegex =
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,12}$/;
      if (!passwordRegex.test(password)) {
        setPasswordError('특수문자 포함 8~12자로 입력해주세요.');
      } else {
        setPasswordError('');
      }
    } else {
      setPasswordError('');
    }

    setPasswordFocused(false);
  };

  const getPasswordState = () => {
    if (passwordError) {
      return 'error';
    }
    if (passwordFocused) {
      return 'focus';
    }
    return 'default';
  };

  const handleCloseModal = () => {
    setPassword('');
    setPasswordError('');
    setPasswordFocused(false);
    setFailedDelete(false);
    closeModal();
  };

  return (
    <>
      <StyledDeleteAccountModalContainer>
        <StyledDeleteAccountModalSubTitle>
          선택하신 계정 삭제를 위해 <br /> 관리자 비밀번호를 입력해 주세요.
        </StyledDeleteAccountModalSubTitle>
        <StyledDeleteAccountModalInput>
          <InputField
            multiline={false}
            size="large"
            $state={getPasswordState()}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={handlePasswordBlur}
            onFocus={() => setFailedDelete(false)}
            errorMessage={passwordError}
            placeholder="비밀번호를 입력해 주세요"
            autoComplete="off"
          />
        </StyledDeleteAccountModalInput>
      </StyledDeleteAccountModalContainer>
      <StyledDeleteAccountModalButtons>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={handleCloseModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="삭제"
          $variant="alert"
          disabled={isLoading || passwordError || !password}
          onClick={() => handleDeleteConfirm(password)}
        />
      </StyledDeleteAccountModalButtons>
      <StyledInputFieldErrorMessage>
        {failedDelte && '요청에 실패하였습니다.'}
      </StyledInputFieldErrorMessage>
    </>
  );
};

const StyledDeleteAccountModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledDeleteAccountModalSubTitle = styled.div`
  text-align: center;
`;

const StyledDeleteAccountModalInput = styled.div`
  margin: 10px 0;
`;
const StyledDeleteAccountModalButtons = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 12px;
`;
const StyledInputFieldErrorMessage = styled.div`
  color: #f76659;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
`;

export default DeleteAccountModal;
