import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from '../components/Layout/Layout';

import { createPrivateRoutes, createPublicRoutes } from './pages';
import NotFound from '../pages/NotFound/NotFound';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route> {createPublicRoutes()}</Route>
        <Route element={<Layout />}>
          <Route>{createPrivateRoutes()}</Route>
        </Route>
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
