import {
  StyledCommentVideoListTableTitleWrapper,
  StyledCommentVideoListTableTitleText,
} from './CommentVideoList.styles';

const CommentVideoListTableTitle = ({ videoTitle, totalUsers }) => {
  return (
    <StyledCommentVideoListTableTitleWrapper>
      <StyledCommentVideoListTableTitleText>
        {videoTitle} 목록
      </StyledCommentVideoListTableTitleText>
      <StyledCommentVideoListTableTitleText>
        {totalUsers}
      </StyledCommentVideoListTableTitleText>
    </StyledCommentVideoListTableTitleWrapper>
  );
};

export default CommentVideoListTableTitle;
