// 계정 관리 > 내정보 탭
import { useState } from 'react';

import { useSelector } from 'react-redux';
import Badge from '../../../../components/common/Badge';
import CustomButton from '../../../../components/common/Button/Button';
import Modal from '../../../../components/common/Modal/Modal';

import useModal from '../../../../hooks/useModal';

import AccessRangeItem from './AccessRangeItem';
import MyInfoItem from './MyInfoItem';
import Loading from './Loading';

import { validatePassword } from '../../../../utils/validate';

import {
  StyledMyInfoContainer,
  StyledMyInfoLabel,
  StyledMyInfoText,
  StyledMyInfoAccessRangeRow,
  StyledMyInfoAccessRangeValue,
  StyledMyInfoSeparator,
  StyledPasswordChangeContainer,
  StyledPasswordInput,
} from './MyInfo.styles';

import {
  getAccountAccessPermissions,
  patchAccountPassword,
} from '../../../../apis/accountManagement';

const MyInfo = ({ userData }) => {
  const accessPermissionsList = useSelector(
    (value) => value.accessPermissions.accessPermissionsList,
  );

  const [passwordChangeState, setPasswordChangeState] = useState({
    isPasswordChangeMode: false,
    password: '',
    confirmPassword: '',
  });

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  const { fetch: updatePassword } = patchAccountPassword({
    onSuccess: () => {
      showModal('성공', '비밀번호가 성공적으로 변경되었습니다.', 'confirm');
      setPasswordChangeState({
        isPasswordChangeMode: false,
        password: '',
        confirmPassword: '',
      });
    },
    onFailure: () => {
      showModal(
        '오류',
        '비밀번호 변경에 실패하였습니다. 다시 시도해주세요.',
        'alert',
      );
    },
  });

  const accessPermissions = getAccountAccessPermissions(userData?.accountUuid);

  if (!userData || !accessPermissions) {
    return <Loading />;
  }

  // TODO: 아래에 실제 비밀번호 변경 로직 구현하시면 됩니다.
  const handlePasswordChange = async () => {
    const { password, confirmPassword } = passwordChangeState;
    const { isValid, message, variant } = validatePassword(
      password,
      confirmPassword,
    );
    if (!isValid) {
      showModal('오류', message, variant);
      return;
    }

    updatePassword({
      adminUuid: userData.accountUuid,
      password: confirmPassword,
    });
  };

  const passwordChangeActions = (
    <CustomButton
      size="large"
      $state="default"
      text="확인"
      $variant={modalVariant}
      onClick={closeModal}
    />
  );

  const renderPasswordChangeInput = () => (
    <StyledPasswordChangeContainer>
      <StyledPasswordInput
        type="password"
        value={passwordChangeState.password}
        onChange={(e) =>
          setPasswordChangeState((prev) => ({
            ...prev,
            password: e.target.value,
          }))
        }
        placeholder="비밀번호 입력"
      />
      <StyledPasswordInput
        type="password"
        value={passwordChangeState.confirmPassword}
        onChange={(e) =>
          setPasswordChangeState((prev) => ({
            ...prev,
            confirmPassword: e.target.value,
          }))
        }
        placeholder="비밀번호 재입력"
      />
      <CustomButton
        $variant="passwordChangeConfirm"
        $state="default"
        size="small"
        text="변경"
        onClick={handlePasswordChange}
      />
    </StyledPasswordChangeContainer>
  );

  const infoItems = [
    { label: '이름', value: userData.name },
    {
      label: '관리자 권한',
      value: (
        <>
          {/* 추후 수정  필요 */}
          <Badge type={userData.level} />
          {userData.department && (
            <>
              <StyledMyInfoSeparator>|</StyledMyInfoSeparator>
              <div>{userData.department}</div>
            </>
          )}
        </>
      ),
    },
    { label: '이메일', value: userData.emailId },
    {
      label: '비밀번호',
      value:
        userData && passwordChangeState.isPasswordChangeMode ? (
          renderPasswordChangeInput()
        ) : (
          <>
            <CustomButton
              $variant="passwordChange"
              $state="default"
              size="small"
              text="비밀번호 변경"
              onClick={() =>
                setPasswordChangeState((prev) => ({
                  ...prev,
                  isPasswordChangeMode: true,
                }))
              }
            />
            <StyledMyInfoText size="small">
              최종 변경일 : {userData.updatedAt}
            </StyledMyInfoText>
          </>
        ),
    },
    { label: '생성일', value: userData.createdAt },
  ];

  return (
    <StyledMyInfoContainer>
      {infoItems.map((item) => (
        <MyInfoItem
          key={item.label}
          label={item.label}
          value={item.value}
          size={item.size}
          weight={item.weight}
        />
      ))}
      <StyledMyInfoAccessRangeRow>
        <StyledMyInfoLabel>접근 허용 범위</StyledMyInfoLabel>
        <StyledMyInfoAccessRangeValue>
          <AccessRangeItem accessRanges={accessPermissionsList} />
        </StyledMyInfoAccessRangeValue>
      </StyledMyInfoAccessRangeRow>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={passwordChangeActions} // 모달 액션 예시
      />
    </StyledMyInfoContainer>
  );
};

export default MyInfo;
