/* eslint-disable no-unused-vars */
// 로그인페이지
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LogoIcon } from '../../assets/logo_icon.svg';
import { ReactComponent as LogoText } from '../../assets/logo_text.svg';

import InputField from '../../components/common/InputField/InputField.jsx';
import CustomButton from '../../components/common/Button/Button.jsx';

import {
  StyledLoginContainer,
  StyledLoginWrapper,
  StyledLoginTextWrapper,
  StyledLoginFormWrapper,
  StyledLoginForm,
  StyledLoginInputWrapper,
  StyledTopBarLogoWrapper,
  StyledTopBarLogoIcon,
  StyledTopBarLogoText,
  StyledLoginInputErrorMessage,
} from './Login.styles';
import TokenManager from '../../api/tokenManger.js';
import { postAuthLogin } from '../../apis/auth/index.js';

// 더미 유저 정보
// TODO: 더미 부분 나중에 삭제해 주세요!

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [emailError, setEmailError] = useState('');
  // const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState('');
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const navigate = useNavigate();

  // 로그인 체크를 위한 로직입니다.
  useEffect(() => {
    (async () => {
      const tokenManager = new TokenManager();
      if (await tokenManager.checkToken()) window.location = '/DashBoard';
      else tokenManager.deleteToken();
    })();
  }, []);

  const getEmailState = () => {
    if (error) {
      return 'error';
    }
    if (emailFocused) {
      return 'focus';
    }
    return 'default';
  };

  const getPasswordState = () => {
    if (error) {
      return 'error';
    }
    if (passwordFocused) {
      return 'focus';
    }
    return 'default';
  };

  const handleEmailBlur = () => {
    // if (email.trim() !== '') {
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   if (!emailRegex.test(email)) {
    //     setEmailError('잘못된 이메일입니다.');
    //   } else {
    //     setEmailError('');
    //   }
    // } else {
    //   setEmailError('');
    // }
    // setEmailError('');
    setError('');
    setEmailFocused(false);
  };

  const handlePasswordBlur = () => {
    // if (password.trim() !== '') {
    //   const passwordRegex =
    //     /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,12}$/;
    //   if (!passwordRegex.test(password)) {
    //     setPasswordError('특수문자 포함 8~12자로 입력해주세요.');
    //   } else {
    //     setPasswordError('');
    //   }
    // } else {
    //   setPasswordError('');
    // }
    // setPasswordError('');
    setError('');
    setPasswordFocused(false);
  };

  const { fetch: fetchPostAuthLogin } = postAuthLogin({
    onSuccess: (e) => {
      setEmail('');
      setPassword('');
      const tokenManager = new TokenManager();
      tokenManager.setToken(e);
      navigate('/DashBoard');
    },
    onFailure: () => {
      setError('이메일/비밀번호가 일치하지 않습니다.');
      // setEmailError('잘못된 이메일입니다.');
      // setPasswordError('잘못된 비밀번호입니다.');
    },
  });

  // TODO: 나중에 실제 로그인 로직으로 바꿔주세요!
  const handleSubmit = (e) => {
    e.preventDefault();

    if (error || !email || !password) {
      return;
    }

    fetchPostAuthLogin({ id: email, password });
  };

  return (
    <StyledLoginContainer>
      <StyledLoginWrapper>
        <StyledLoginTextWrapper>
          <StyledTopBarLogoWrapper>
            <StyledTopBarLogoIcon>
              <LogoIcon />
            </StyledTopBarLogoIcon>
            <StyledTopBarLogoText>
              <LogoText />
            </StyledTopBarLogoText>
          </StyledTopBarLogoWrapper>
        </StyledLoginTextWrapper>
        <StyledLoginFormWrapper>
          <StyledLoginForm onSubmit={handleSubmit}>
            <StyledLoginInputWrapper>
              <InputField
                multiline={false}
                size="large"
                $state={getEmailState()}
                value={email}
                onChange={(e) => {
                  if (error) setError('');
                  setEmail(e.target.value);
                }}
                // onFocus={() => setEmailFocused(true)}
                onBlur={handleEmailBlur}
                // errorMessage={emailError}
                placeholder="이메일"
                autoComplete="off"
              />
              <InputField
                multiline={false}
                size="large"
                $state={getPasswordState()}
                type="password"
                value={password}
                onChange={(e) => {
                  if (error) setError('');
                  setPassword(e.target.value);
                }}
                // onFocus={() => setPasswordFocused(true)}
                onBlur={handlePasswordBlur}
                // errorMessage={passwordError}
                placeholder="비밀번호"
                autoComplete="off"
              />
            </StyledLoginInputWrapper>
            <CustomButton
              type="submit"
              size="large"
              $state="default"
              text="로그인"
              $variant="login"
              disabled={error}
            />
            <StyledLoginInputErrorMessage>
              {error} &nbsp;
            </StyledLoginInputErrorMessage>
          </StyledLoginForm>
        </StyledLoginFormWrapper>
      </StyledLoginWrapper>
    </StyledLoginContainer>
  );
};

export default LoginPage;
