/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { PiCaretUpBold, PiX } from 'react-icons/pi';
import { StyledTagInputField } from './PostTemplate.styles';

const PostTemplateTagForm = ({ handleTags }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tagLength, setTagLength] = useState(0);
  const [tag, setTag] = useState('');
  const [tags, setTags] = useState([]);
  const [selectTag, setSelectTag] = useState(null);

  const handleInvertIsOpen = () => setIsOpen((prev) => !prev);

  const handleSubmitTag = (e) => {
    e.preventDefault();
    if (!tag) return;

    const newTags =
      selectTag !== null
        ? tags.map((value, index) => (index === selectTag ? tag : value))
        : [...tags, tag];

    const tagLenghtValue = getTagLenght(newTags);
    if (tagLenghtValue > 8) return;
    setTagLength(tagLenghtValue);
    setTags(newTags);

    setTag('');
    setSelectTag(null);
  };

  const handleDeleteTag = (idx) => {
    const newTag = tags.filter((_, index) => index !== idx);
    const tagLenghtValue = getTagLenght(newTag);
    setTagLength(tagLenghtValue);
    setTags(newTag);
  };

  const getTagLenght = (checkTag) => {
    let newTagLength = 0;
    checkTag.forEach((item) => (newTagLength += item.length));
    return newTagLength;
  };

  useEffect(() => {
    if (selectTag === null) return;
    setTag(tags.find((_, index) => index === selectTag));
  }, [selectTag]);

  useEffect(() => {
    handleTags(tags.length ? tags.map((value) => `#${value}`).join(',') : '');
  }, [tags]);

  return (
    <PostTemplateTagFormContainer>
      <PostTemplateTagFormWrapper>
        <PostTemplateTagFormTagsContainer>
          <PostTemplateTagFormTagsPreviewWrapper>
            {tags.length ? (
              <>
                {tags.map((value, idx) => (
                  <PostTemplateTagFormTagsWrapper
                    key={idx}
                    onDoubleClick={() => setSelectTag(idx)}
                  >
                    <div>#{value}</div>
                    <CloseIcon
                      $isSelect={selectTag === idx}
                      size={15}
                      onClick={() => handleDeleteTag(idx)}
                    />
                  </PostTemplateTagFormTagsWrapper>
                ))}
                {tagLength !== 8 && (
                  <form onSubmit={handleSubmitTag}>
                    <PostTemplateTagInputContainer
                      style={{ width: '40px' }}
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                      placeholder="입력칸"
                      type="text"
                    />
                  </form>
                )}
              </>
            ) : (
              <form onSubmit={handleSubmitTag}>
                <PostTemplateTagInputContainer
                  style={{ width: 'auto' }}
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  placeholder="태그를 입력해주세요"
                  type="text"
                />
              </form>
            )}
          </PostTemplateTagFormTagsPreviewWrapper>
          {/* <PostTemplateTagFormTagsPreviewWrapper
            onClick={(e) => e.stopPropagation()}
          >
            {tags.length
              ? tags.map((value, idx) => (
                  <PostTemplateTagFormTagsWrapper
                    $isSelect={selectTag === idx}
                    key={idx}
                    onDoubleClick={() => setSelectTag(idx)}
                  >
                    <div>{value}</div>
                    <CloseIcon
                      $isSelect={selectTag === idx}
                      size={15}
                      onClick={() => handleDeleteTag(idx)}
                    />
                  </PostTemplateTagFormTagsWrapper>
                ))
              : '태그를 입력하여 주세요.'}
          </PostTemplateTagFormTagsPreviewWrapper> */}
          <PostTemplateTagFormCloseButton $isNull={!tagLength}>
            {`${tagLength}/8`}
          </PostTemplateTagFormCloseButton>
          {/* <PostTemplateTagFormCloseButton onClick={() => handleInvertIsOpen()}>
            <ArrowIcon $isOpen={isOpen}>
              <PiCaretUpBold size={15} />
            </ArrowIcon>
          </PostTemplateTagFormCloseButton> */}
        </PostTemplateTagFormTagsContainer>
        <PostTemplateTagFormInputWrapper $isOpen={isOpen}>
          <PostTemplateTagFormInput
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            placeholder="입력칸"
            type="text"
          />
        </PostTemplateTagFormInputWrapper>
      </PostTemplateTagFormWrapper>
    </PostTemplateTagFormContainer>
  );
};

export default PostTemplateTagForm;

const PostTemplateTagFormContainer = styled(StyledTagInputField)`
  position: relative;
  border: none;
  width: 100%;
  height: 100%;
`;

const PostTemplateTagFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  transition: 0.5s;
  background: white;
  border: 1px rgb(217, 217, 217) solid;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PostTemplateTagInputContainer = styled.input`
  border: 0;
  border-radius: 0;
  color: #1e2024;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  padding: 0;
`;

const PostTemplateTagFormTagsContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding-right: 5px;
`;

const PostTemplateTagFormTagsPreviewWrapper = styled.div`
  width: 80%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: scroll;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PostTemplateTagFormTagsWrapper = styled.div`
  /* padding: 5px; */
  padding: 0 8px;
  border-radius: 50px;
  background: #e9e9e9;
  display: flex;
  white-space: nowrap;
  gap: 5px;
  cursor: default;
  align-items: center;
  color: #1e2024;
`;

const CloseIcon = styled(PiX)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  ${({ $isSelect }) => $isSelect && 'pointer-events: none'};
  color: ${({ $isSelect }) => $isSelect && '#6f6f6f'};
  &:hover {
    cursor: pointer;
  }
`;

const PostTemplateTagFormCloseButton = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $isNull }) => $isNull && '#9d9d9d'};
  cursor: pointer;
`;

const ArrowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(${({ $isOpen }) => ($isOpen ? 0 : 180)}deg);
  transition: 0.5s;
`;

const PostTemplateTagFormInputWrapper = styled.div`
  width: 100%;
  height: ${({ $isOpen }) => ($isOpen ? '37.5px' : '0')};
  transition: height 0.5s;
`;

const PostTemplateTagFormInput = styled.input`
  padding: 0 12px;
  height: 100%;

  border-radius: 0;
  border: 0;
  color: #1e2024;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
`;
