/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-catch */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import TokenManager from '../../api/tokenManger';
import API from '../../api';

const initialState = {
  accessPermissionsList: [],
  isLoading: false,
  error: null,
};

export const asyncGetAccessPermissionsList = createAsyncThunk(
  'asyncThunk/asyncGetAccessPermissionsList',
  async () => {
    const tokenManager = new TokenManager();
    try {
      const response = await API(
        `/account/accessPermissions?accountId=${tokenManager.userId}`,
        {
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
          headers: {
            Authorization:
              tokenManager.accessToken && `Bearer ${tokenManager.accessToken}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const accessPermissionsSlice = createSlice({
  name: 'accessPermissions',
  initialState,
  reducers: {
    resetAccessPermissionsList: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetAccessPermissionsList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncGetAccessPermissionsList.fulfilled, (state, action) => {
        state.accessPermissionsList = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncGetAccessPermissionsList.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      });
  },
});
export const { resetAccessPermissionsList } = accessPermissionsSlice.actions;

export default accessPermissionsSlice.reducer;
