/* eslint-disable no-unused-vars */
import {
  StyledAccessRangeItemGridContainer,
  StyledAccessRangeItemWrapper,
  StyledAccessRangeItemContent,
  StyledAccessRangeItemInfo,
  StyledAccessRangeItemIconWrapper,
  StyledAccessRangeItemText,
  StyledAccessRangeItemBadge,
  StyledAccessRangeItemCheckIcon,
} from './AccessRangeItem.styles';
import accessRangeData from './AccessRageItemConstans';

const AccessRangeItem = ({ accessRanges }) => {
  // console.log(accessRanges);
  // const items = accessRangeData.map((range) => ({
  //   ...range,
  //   isAvailable: accessRanges.includes(range.id),
  // }));

  // console.log(items);

  return (
    <StyledAccessRangeItemGridContainer>
      {accessRanges.map((item) => {
        const { featureUuid, title, iconUrl, isActive } = item;

        return (
          <StyledAccessRangeItemWrapper key={featureUuid}>
            <StyledAccessRangeItemContent>
              <StyledAccessRangeItemInfo $isFullWidth={isActive}>
                <StyledAccessRangeItemIconWrapper>
                  <img src={iconUrl} alt={title} />
                </StyledAccessRangeItemIconWrapper>
                <StyledAccessRangeItemText>{title}</StyledAccessRangeItemText>
              </StyledAccessRangeItemInfo>
              <StyledAccessRangeItemBadge $isAvailable={isActive}>
                {isActive && <StyledAccessRangeItemCheckIcon />}
                <div>{isActive ? '사용가능' : '사용불가'}</div>
              </StyledAccessRangeItemBadge>
            </StyledAccessRangeItemContent>
          </StyledAccessRangeItemWrapper>
        );
      })}
    </StyledAccessRangeItemGridContainer>
  );
};

export default AccessRangeItem;
