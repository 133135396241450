/* eslint-disable no-nested-ternary */
// src/components/InputField/InputField.styles.js
import styled from 'styled-components';

export const StyledInputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 335px;
  font-family: 'Pretendard';
  font-weight: 400;
`;

export const StyledInputFieldLabel = styled.label`
  color: var(--neutralsgray-400);
  font-size: 16px;
  font-weight: 500;
`;

export const StyledInputFieldInput = styled.input`
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 6px;
  border: 1px solid
    ${({ $state }) =>
      $state === 'error'
        ? '#F76659'
        : $state === 'focus'
        ? '#1A1A1A'
        : '#DDDFE3'};
  color: ${({ $state }) => ($state === 'error' ? '#F76659' : '#1e2024')};
  font-size: 16px;
  line-height: 24px;

  &:focus {
    border-color: #1a1a1a;
    outline: none;
  }

  &::placeholder {
    color: #9d9d9d;
    word-wrap: break-word;
  }
`;

export const StyledInputFieldErrorMessage = styled.div`
  color: #f76659;
  font-size: 14px;
  margin-top: 8px;
`;
