/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterQuery: '',
};

const userMangementFilterSlice = createSlice({
  name: 'userMangementFilter',
  initialState,
  reducers: {
    setFilterQuery: (state, action) => {
      state.filterQuery = action.payload;
    },
    resetMangementFilterQuery: () => initialState,
  },
});

export const { setFilterQuery, resetMangementFilterQuery } =
  userMangementFilterSlice.actions;
export default userMangementFilterSlice.reducer;
