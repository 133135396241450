/* eslint-disable no-unused-vars */
import { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Dropdown from '../../../../components/common/DropDown/DropDown';
import Checkbox from './Checkbox';
import JoinPeriod from './JoinPeriod';

import {
  StyledSearchFilterHeader,
  StyledSearchFilterInputContainer,
  StyledSearchFilterSearchBar,
  StyledSearchFilterSearchInput,
  StyledSearchFilterSearchIcon,
  StyledSearchFilterContainer,
  StyledSearchFilterRegionDropdownContainer,
  StyledSearchFilterBody,
  StyledLabel,
  StyledPostTypeFilterContainer,
  StyledReportTypeContainer,
  StyledSearchFilterFooter,
  StyledRegionRangeOptionsWrapper,
} from './PostSearchFilter.styles';
import { formatDateYYYYMMDD } from '../../../../utils/formatDate';
import {
  resetMangementFilterQuery,
  setFilterQuery,
} from '../../../../store/redux/userManagementFilterSlice';
import { getVideoAddress } from '../../../../apis/video';
import { getGroupCode } from '../../../../utils/groupCode';
import { VIDEO_TYPE } from '../../../../constants/groupCode';

const useCheckBox = ({ groupCode, setInitValue, isCheckedList = [] }) => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);

  const groupCodeValue =
    groupCode && getGroupCode(groupCodeList, groupCode).codes;

  const initValue = setInitValue
    ? setInitValue(groupCodeValue)
    : groupCodeValue;

  const stateInit = initValue.reduce((acc, obj) => {
    acc[obj.fullCode] = isCheckedList.includes(obj.fullCode);
    return acc;
  }, {});

  const resetState = initValue.reduce((acc, obj) => {
    acc[obj.fullCode] = false;
    return acc;
  }, {});
  const option = initValue.map(({ fullCode, codeName }) => ({
    value: fullCode,
    label: codeName,
  }));

  const [state, setState] = useState(stateInit);

  return { resetState, stateInit, option, state, setState };
};

const FILTER_KEYS = {
  searchKeyword: 'searchKeyword',
  startDate: 'startDate',
  endDate: 'endDate',
  locationId: 'locationId',
  videoType: 'videoType',
  status: 'status',
  hasReport: 'hasReport',
};

const SearchFilter = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const filterParams = new URLSearchParams(searchParams.get('filter'));

  const initVideoType = filterParams.getAll(FILTER_KEYS.videoType);
  const initPostType = filterParams.getAll(FILTER_KEYS.status);
  const initHasReport = filterParams.getAll(FILTER_KEYS.hasReport);
  const initSearchKeyword = filterParams.get(FILTER_KEYS.searchKeyword) || '';
  const initRegionRange = filterParams.get(FILTER_KEYS.locationId);
  const initStartDate = filterParams.get(FILTER_KEYS.startDate);
  const initEndDate = filterParams.get(FILTER_KEYS.endDate);

  const firstDate = new Date();
  const currentDate = new Date();

  const [searchInput, setSearchInput] = useState('');

  const [regionRangeOptions, setRegionRangeOptions] = useState([]);
  const [selectedRegionRange, setSelectedRegionRange] = useState('전체');

  const [isAllPeriod, setIsAllPeriod] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(firstDate);
  const [endDate, setEndDate] = useState(currentDate);

  const {
    resetState: videoTypeReset,
    stateInit: videoTypeValueInit,
    option: videoTypeFilterOptions,
    state: videoTypeValue,
    setState: setVideoTypeValue,
  } = useCheckBox({
    groupCode: VIDEO_TYPE,
    setInitValue: (value) =>
      value.map(({ fullCode, codeName, ...item }) => ({
        fullCode: codeName === '메인영상' ? 'MAIN' : 'COMMENT',
        codeName: codeName.slice(0, 2),
        ...item,
      })),
    isCheckedList: initVideoType,
  });

  const {
    resetState: postTypeReset,
    stateInit: postTypeValueInit,
    option: postTypeFilterOptions,
    state: postTypeValue,
    setState: setPostTypeValue,
  } = useCheckBox({
    groupCode: 4601,
    isCheckedList: initPostType,
  });

  const {
    resetState: reportTypeReset,
    stateInit: reportTypeValueInit,
    option: reportTypeFilterOptions,
    state: reportTypeValue,
    setState: setReportTypeValue,
  } = useCheckBox({
    setInitValue: () => [
      {
        fullCode: 'true',
        codeName: '유',
      },
      {
        fullCode: 'false',
        codeName: '무',
      },
    ],
    isCheckedList: initHasReport,
  });

  const handleSetSearchParamsList = (paramsList) => {
    const newSearchParams = new URLSearchParams();
    if (paramsList) newSearchParams.set('filter', paramsList);
    else newSearchParams.delete('filter');
    setSearchParams(newSearchParams);
  };

  getVideoAddress({
    onSuccess: (value) => {
      setRegionRangeOptions(value);
      setSelectedRegionRange(
        value.find((location) => location.locationGroupId === initRegionRange)
          ?.groupName || '전체',
      );
    },
  });

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleCheckboxChange = (value) => {
    setVideoTypeValue((prevValue) => ({
      ...prevValue,
      [value]: !prevValue?.[value],
    }));
  };

  const handleDropdownChange = (option) => {
    setSelectedRegionRange(option);
  };

  const toggleAllPeriod = () => {
    setIsAllPeriod(true);
    setShowDatePicker(false);
  };

  const handleDirectSettingClick = () => {
    setIsAllPeriod(false);
    setShowDatePicker(true);
  };

  const handleDateChange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
  };

  const handleConfirm = () => {
    setShowDatePicker(false);
  };

  const handleReportTypeChange = (value) => {
    setReportTypeValue((prevValue) => ({
      ...prevValue,
      [value]: !prevValue[value],
    }));
  };

  const handlePostTypeChange = (value) => {
    setPostTypeValue((prevValue) => ({
      ...prevValue,
      [value]: !prevValue[value],
    }));
  };

  const resetFilters = () => {
    setSearchInput('');
    setVideoTypeValue(videoTypeReset);
    setSelectedRegionRange('전체');
    setIsAllPeriod(true);
    setShowDatePicker(false);
    setStartDate(firstDate);
    setEndDate(currentDate);
    setPostTypeValue(postTypeReset);
    setReportTypeValue(reportTypeReset);
  };

  useEffect(() => {
    setSearchInput(initSearchKeyword);
    setShowDatePicker(false);
    setVideoTypeValue(videoTypeValueInit);
    setPostTypeValue(postTypeValueInit);
    setReportTypeValue(reportTypeValueInit);

    if (initStartDate && initStartDate) {
      setIsAllPeriod(false);
      setStartDate(new Date(initStartDate));
      setEndDate(new Date(initEndDate));
    }
  }, []);

  const setFilters = () => {
    const isSearchTitle = searchInput && `searchKeyword=${searchInput}`;

    const isStartDate =
      !isAllPeriod &&
      `startDate=${formatDateYYYYMMDD(startDate, '-')}T00:00:00`;
    const isEndDate =
      !isAllPeriod && `endDate=${formatDateYYYYMMDD(endDate, '-')}T00:00:00`;

    const selectedRegionId = regionRangeOptions?.find(
      (value) => value.groupName === selectedRegionRange,
    )?.locationGroupId;
    const isLocationData =
      !!selectedRegionId && `locationId=${selectedRegionId}`;

    const videoTypes = Object.keys(videoTypeValue).filter(
      (key) => videoTypeValue[key],
    );

    const isVideoTypes = videoTypes
      ?.map((type) => `videoType=${type}`)
      .join('&');

    const postTypes = Object.keys(postTypeValue).filter(
      (key) => postTypeValue[key],
    );

    const isPostTypes = postTypes?.map((type) => `status=${type}`).join('&');

    const reportTypes = Object.keys(reportTypeValue).filter(
      (key) => reportTypeValue[key],
    );

    const isReportTypes = reportTypes
      ?.map((type) => `hasReport=${type}`)
      .join('&');

    const queryString = [
      isSearchTitle,
      isStartDate,
      isEndDate,
      isLocationData,
      isVideoTypes,
      isPostTypes,
      isReportTypes,
    ]
      .filter(Boolean)
      .join('&');

    handleSetSearchParamsList(queryString);
    // dispatch(setFilterQuery(`&${queryString}`));
  };

  useImperativeHandle(ref, () => ({
    resetFilters,
    setFilters,
  }));

  useEffect(() => {
    dispatch(resetMangementFilterQuery());
  }, []);

  return (
    <>
      <StyledSearchFilterHeader>
        <StyledSearchFilterInputContainer>
          <StyledLabel>검색분류</StyledLabel>

          <StyledSearchFilterSearchBar>
            <StyledSearchFilterSearchIcon />

            <StyledSearchFilterSearchInput
              placeholder="영상 타이틀이나 게시자 닉네임을 입력해 주세요."
              maxLength={20}
              value={searchInput}
              onChange={handleSearchChange}
            />
          </StyledSearchFilterSearchBar>
        </StyledSearchFilterInputContainer>

        <StyledSearchFilterContainer>
          <Checkbox
            label="영상 구분"
            options={videoTypeFilterOptions}
            handleChange={handleCheckboxChange}
            value={videoTypeValue}
          />
        </StyledSearchFilterContainer>
      </StyledSearchFilterHeader>

      <StyledSearchFilterBody>
        <JoinPeriod
          isAllPeriod={isAllPeriod}
          showDatePicker={showDatePicker}
          startDate={startDate}
          endDate={endDate}
          toggleAllPeriod={toggleAllPeriod}
          handleDirectSettingClick={handleDirectSettingClick}
          handleDateChange={handleDateChange}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
        <StyledSearchFilterContainer>
          <Checkbox
            label="게시물 상태"
            options={postTypeFilterOptions}
            value={postTypeValue}
            handleChange={handlePostTypeChange}
          />
        </StyledSearchFilterContainer>
      </StyledSearchFilterBody>
      <StyledSearchFilterFooter>
        <StyledSearchFilterInputContainer>
          <StyledLabel>카테고리</StyledLabel>
          <StyledRegionRangeOptionsWrapper>
            <Dropdown
              options={[
                '전체',
                ...regionRangeOptions.map(({ groupName }) => groupName),
              ]}
              minWidth="100%"
              selectedValue={selectedRegionRange}
              onOptionChange={handleDropdownChange}
            />
          </StyledRegionRangeOptionsWrapper>
        </StyledSearchFilterInputContainer>
        <StyledSearchFilterContainer>
          <Checkbox
            label="신고"
            options={reportTypeFilterOptions}
            value={reportTypeValue}
            handleChange={handleReportTypeChange}
          />
        </StyledSearchFilterContainer>
      </StyledSearchFilterFooter>
    </>
  );
});

export default SearchFilter;
