import styled from 'styled-components';

export const StyledAdminInfoDetailContainer = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  /* border: 1px solid #f1f2f4; */
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledAdminInfoDetailContentsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledAdminInfoDetailInfoLabel = styled.div`
  width: 120px;
  font-size: 13px;
  font-weight: 500;
  color: #434343;
`;

export const StyledAdminInfoDetailInfoValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledAdminInfoDetailInfoText = styled.div`
  font-size: ${({ size }) => (size === 'small' ? '13px' : '16px')};
  font-weight: ${({ weight }) => (weight === 'bold' ? '500' : '400')};
  color: #434343;
`;

export const StyledAdminInfoDetailInfoRow = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px;
  border-bottom: 1px solid #e9e9e9;
`;

// eslint-disable-next-line prettier/prettier
export const StyledAdminInfoDetailAccessRow = styled(
  StyledAdminInfoDetailInfoRow,
)`
  height: 210px;
  align-items: flex-start;
  border-bottom: none;
`;

export const StyledAdminInfoDetailAccessValue = styled.div`
  flex: 1;
  display: flex;
  gap: 12px;
`;

export const StyledAdminInfoDetailSeparator = styled.div`
  color: #c4c4c4;
`;

export const StyledPasswordChangeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledPasswordInput = styled.input`
  max-width: 143px;
  padding: 6px 12px;
  border: 1px solid #dddfe3;
  border-radius: 4px;
  font-size: 14px;
`;

export const StyledLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; // 컨테이너가 전체 너비를 차지하도록 설정
  min-height: 50vh; // 컨테이너의 최소 높이 설정, 실제 상황에 맞게 조절 필요
`;

export const StyledInput = styled.input`
  max-width: 143px;
  padding: 6px 12px;
  border: 1px solid #dddfe3;
  border-radius: 4px;
  font-size: 14px;
`;
