import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { formatDateYYYYMMDD } from '../../../../../utils/formatDate';
import { StyledRadioLabel, StyledRadioWrapper } from '../NoticeTemplate.styles';
import PostTemplateRadioBtn from '../../../../PostManagement/subPages/PostTemplate/PostTemplateRadioBtn';

const NoticeNotification = ({
  isPush,
  isBanner,
  title,
  content,
  popUpFile,
  bannerFile,
  selectedFile,
  startDate,
  startTime,
  endDate,
  endTime,
}) => {
  const periodStartDate = formatDateYYYYMMDD(startDate, '. ');
  const periodEndDate = formatDateYYYYMMDD(endDate, '. ');
  const [viewType, setViewType] = useState('detail');

  useEffect(() => {
    if (!isPush) setViewType('detail');
  }, [isPush]);

  useEffect(() => {
    if (!isBanner) setViewType('detail');
  }, [isBanner]);

  return (
    <PushNotificationContainer>
      <PushNotificationTitle>
        <Label>예시 화면</Label>
        {isPush && (
          <StyledRadioOption>
            <StyledRadioWrapper onClick={() => setViewType('popUp')}>
              <PostTemplateRadioBtn checked={viewType === 'popUp'} />
              <StyledRadioLabel>팝업</StyledRadioLabel>
            </StyledRadioWrapper>
            <StyledRadioWrapper onClick={() => setViewType('detail')}>
              <PostTemplateRadioBtn checked={viewType === 'detail'} />
              <StyledRadioLabel>상세내용</StyledRadioLabel>
            </StyledRadioWrapper>
          </StyledRadioOption>
        )}
        {isBanner && (
          <StyledRadioOption>
            <StyledRadioWrapper onClick={() => setViewType('banner')}>
              <PostTemplateRadioBtn checked={viewType === 'banner'} />
              <StyledRadioLabel>배너</StyledRadioLabel>
            </StyledRadioWrapper>
            <StyledRadioWrapper onClick={() => setViewType('detail')}>
              <PostTemplateRadioBtn checked={viewType === 'detail'} />
              <StyledRadioLabel>상세내용</StyledRadioLabel>
            </StyledRadioWrapper>
          </StyledRadioOption>
        )}
      </PushNotificationTitle>
      {viewType === 'popUp' && (
        <PopupNotificationWrapper>
          <PopupNotificationContent>
            {popUpFile && (
              <img
                src={popUpFile.url || URL.createObjectURL(popUpFile)}
                alt="Uploaded"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            )}
          </PopupNotificationContent>
          <PopupNotificationTextWrapper>
            <PopupNotificationText>오늘 하루 보지 않기</PopupNotificationText>
            <PopupNotificationText>닫기</PopupNotificationText>
          </PopupNotificationTextWrapper>
        </PopupNotificationWrapper>
      )}
      {viewType === 'banner' && (
        <BannerNotificationWrapper>
          <BannerNotificationContent>
            {bannerFile && (
              <img
                src={bannerFile.url || URL.createObjectURL(bannerFile)}
                alt="Uploaded"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            )}
          </BannerNotificationContent>
        </BannerNotificationWrapper>
      )}
      {viewType === 'detail' && (
        <ExpandedNotification>
          <NoticeTitle>{title || '타이틀'}</NoticeTitle>
          <NoticePeriod>
            {startTime && `${periodStartDate}  ${startTime}`}
            {endTime && ` ~ ${periodEndDate}  ${endTime}`}
          </NoticePeriod>
          <NoticeFile>
            {selectedFile ? (
              <img
                src={selectedFile.url || URL.createObjectURL(selectedFile)}
                alt="Uploaded"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : (
              <div
                style={{
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                이미지
              </div>
            )}
          </NoticeFile>
          <NoticeContent>{content || '내용을 입력해 주세요.'}</NoticeContent>
        </ExpandedNotification>
      )}
    </PushNotificationContainer>
  );
};

export default NoticeNotification;

const PushNotificationContainer = styled.div`
  height: 503px;
  padding: 20px;
  background: #e9e9e9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
`;

const PushNotificationTitle = styled.div`
  width: 100%;
  height: 16px;
  color: #9d9d9d;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 9px;
  word-wrap: break-word;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  color: #9d9d9d;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 9px;
  word-wrap: break-word;
`;

const ExpandedNotification = styled.div`
  width: 240px;
  height: 100%;
  background: #000000;
  border-radius: 8px;
  padding: 23.5px 16px;
  overflow: auto;
`;

const NoticeTitle = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #ffffff;
`;

const NoticePeriod = styled.div`
  font-family: Pretendard;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #7b7b7b;
  margin-top: 10px;
`;

const NoticeFile = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
  background: #7b7b7b;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #ffffff;
`;

const NoticeContent = styled.p`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-top: 20px;

  color: #ffffff;
`;

const StyledRadioOption = styled.div`
  display: flex;
  gap: 5px;
`;

const PopupNotificationWrapper = styled.div`
  width: 240px;
`;

const PopupNotificationContent = styled.div`
  margin-top: 12px;
  width: 240px;
  height: 180px;
  background: #c6c4ff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; // 추가
`;

const PopupNotificationTextWrapper = styled.div`
  width: 240px;
  height: 30px;
  background: #000000;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
`;

const PopupNotificationText = styled.div`
  display: flex;
  color: white;
  font-size: 12px;
  font-weight: 400;
`;

const BannerNotificationWrapper = styled.div`
  width: 240px;
`;

const BannerNotificationContent = styled.div`
  margin-top: 12px;
  width: 240px;
  height: 60px;
  background: #c6c4ff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; // 추가
`;
