// Shutdown.jsx
import { useState, useRef, useEffect } from 'react';
import Dropdown from '../../../../components/common/DropDown/DropDown';
import CustomDateRangePicker from '../../../../components/common/DateRangePicker/CustomDateRangePicker';
import CustomButton from '../../../../components/common/Button/Button';

import useModal from '../../../../hooks/useModal';
import Modal from '../../../../components/common/Modal/Modal';

import { formatDateYYYYMMDD } from '../../../../utils/formatDate';

import {
  StyledShutdownContainer,
  StyledShutdownHeader,
  StyledShutdownTitle,
  StyledShutdownTable,
  StyledShutdownTableRow,
  StyledShutdownTableCell,
  StyledShutdownDropdownWrapper,
  StyledShutdownInputField,
  StyledShutdownInputFieldText,
  StyledShutdownCheckboxOption,
  StyledShutdownCheckboxWrapper,
  StyledShutdownCheckbox,
  StyledShutdownCheckedBackground,
  StyledShutdownCheckedIcon,
  StyledShutdownUncheckedBackground,
  StyledShutdownCheckboxLabel,
  StyledShutdownDatePickerWrapper,
} from './Shutdown.styles';

const Shutdown = () => {
  const [selectedStartTime, setSelectedStartTime] = useState('오후 02:00');
  const [selectedEndTime, setSelectedEndTime] = useState('오후 02:00');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [selectedReasons, setSelectedReasons] = useState(['앱 점검']);

  const { isModalOpen, modalTitle, modalContent, showModal, closeModal } =
    useModal();

  const datePickerRef = useRef(null);

  // DateRangePicker 관련.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderTimeItem = (option) => <div>{option}</div>;

  const timeOptions = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2);
    const minute = i % 2 === 0 ? '00' : '30';
    const period = hour < 12 ? '오전' : '오후';
    const formattedHour = hour % 12 || 12;
    return `${period} ${formattedHour}:${minute}`;
  });

  const handleDateChange = (dates) => {
    if (dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  // 라디오 버튼 관련
  const handleReasonToggle = (reason) => {
    setSelectedReasons([reason]);
  };

  // 버튼 관련
  const handleCancel = () => {
    setShowDatePicker(false);
  };

  const handleConfirm = () => {
    setShowDatePicker(false);
    console.log(showDatePicker);
  };

  const handleModalConfirm = () => {
    // 셧다운 실행 로직 구현
    closeModal();
  };

  const handleModalCancel = () => {
    closeModal();
  };

  return (
    <StyledShutdownContainer>
      <StyledShutdownHeader>
        <StyledShutdownTitle>강제 셧다운</StyledShutdownTitle>
      </StyledShutdownHeader>

      <StyledShutdownTable>
        <StyledShutdownTableRow>
          <StyledShutdownTableCell $isHeader>
            셧다운 시간
          </StyledShutdownTableCell>

          <StyledShutdownTableCell>
            <StyledShutdownInputField onClick={() => setShowDatePicker(true)}>
              <StyledShutdownInputFieldText>
                {formatDateYYYYMMDD(startDate)}
              </StyledShutdownInputFieldText>
            </StyledShutdownInputField>

            <StyledShutdownDropdownWrapper>
              <Dropdown
                options={timeOptions}
                selectedValue={selectedStartTime}
                onOptionChange={setSelectedStartTime}
                renderItem={renderTimeItem}
                minWidth="100px"
                maxHeight="100px"
                height="30px"
                fontSize="12px"
              />
            </StyledShutdownDropdownWrapper>

            <div>~</div>

            <StyledShutdownInputField onClick={() => setShowDatePicker(true)}>
              <StyledShutdownInputFieldText>
                {formatDateYYYYMMDD(endDate)}
              </StyledShutdownInputFieldText>
            </StyledShutdownInputField>

            <StyledShutdownDropdownWrapper>
              <Dropdown
                options={timeOptions}
                selectedValue={selectedEndTime}
                onOptionChange={setSelectedEndTime}
                renderItem={renderTimeItem}
                minWidth="90px"
                maxHeight="100px"
                height="30px"
                fontSize="12px"
              />
            </StyledShutdownDropdownWrapper>
          </StyledShutdownTableCell>

          <StyledShutdownTableCell $isHeader>
            셧다운 사유 선택
          </StyledShutdownTableCell>

          <StyledShutdownTableCell>
            <StyledShutdownCheckboxOption>
              <StyledShutdownCheckboxWrapper
                onClick={() => handleReasonToggle('앱 점검')}
              >
                <StyledShutdownCheckbox>
                  {selectedReasons.includes('앱 점검') ? (
                    <>
                      <StyledShutdownCheckedBackground />
                      <StyledShutdownCheckedIcon />
                    </>
                  ) : (
                    <StyledShutdownUncheckedBackground />
                  )}
                </StyledShutdownCheckbox>
                <StyledShutdownCheckboxLabel>
                  앱 점검
                </StyledShutdownCheckboxLabel>
              </StyledShutdownCheckboxWrapper>

              <StyledShutdownCheckboxWrapper
                onClick={() => handleReasonToggle('업데이트')}
              >
                <StyledShutdownCheckbox>
                  {selectedReasons.includes('업데이트') ? (
                    <>
                      <StyledShutdownCheckedBackground />
                      <StyledShutdownCheckedIcon />
                    </>
                  ) : (
                    <StyledShutdownUncheckedBackground />
                  )}
                </StyledShutdownCheckbox>
                <StyledShutdownCheckboxLabel>
                  업데이트
                </StyledShutdownCheckboxLabel>
              </StyledShutdownCheckboxWrapper>
            </StyledShutdownCheckboxOption>
          </StyledShutdownTableCell>
        </StyledShutdownTableRow>
        {showDatePicker && (
          <StyledShutdownDatePickerWrapper ref={datePickerRef}>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              onCancel={handleCancel}
              onConfirm={handleConfirm}
              selectsRange
            />
          </StyledShutdownDatePickerWrapper>
        )}
      </StyledShutdownTable>

      <CustomButton
        size="medium"
        $state="default"
        $variant="save"
        text="실행"
        onClick={() =>
          showModal(
            '강제 셧다운을 실행 하시겠습니까?',
            <>
              강제 셧다운 실행 시 사용자에게 앱의 사용이 제한됩니다.
              <br />
              그래도 실행하시겠습니까?
            </>,
            'alert',
          )
        }
      />

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={<div style={{ textAlign: 'center' }}>{modalContent}</div>}
        actions={
          <>
            <CustomButton
              size="large"
              $state="default"
              text="취소"
              $variant="cancel"
              onClick={handleModalCancel}
            />
            <CustomButton
              size="large"
              $state="default"
              text="셧다운 실행하기"
              $variant="alert"
              onClick={handleModalConfirm}
            />
          </>
        }
      />
    </StyledShutdownContainer>
  );
};

export default Shutdown;
