/* eslint-disable import/no-extraneous-dependencies */
import { Provider } from 'react-redux';
import { NavermapsProvider } from 'react-naver-maps';
import Router from './router';
import store from './store';

const App = () => {
  return (
    <Provider store={store}>
      <NavermapsProvider ncpClientId={process.env.REACT_APP_NAVER_CLIENT_ID}>
        <Router />
      </NavermapsProvider>
    </Provider>
  );
};

export default App;
