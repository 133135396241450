import { useState } from 'react';
import StatusCard from '../../../../components/common/StatusCard/StatusCard';
import { formatNumber } from '../../../../utils/formatDate';
import getDashboardUsersActivityStatus from '../../../../apis/dashbord/getDashboardUsersActivityStatus';
import {
  DASHBORD_OPTIONS,
  DASHBORD_OPTIONS_RENDER_ITEM,
} from '../../../../constants/constants';

const ActivityStats = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState(1);

  const usersActivityStatus =
    getDashboardUsersActivityStatus(selectedTimeRange);

  const deletePersent = (value) => {
    return `${parseInt(value.replace('%', ''), 10)}%`;
  };

  const handleOptionChange = (option) => {
    setSelectedTimeRange(option);
  };

  const formatUserActivityData = (data) => {
    if (!data) return [];

    const {
      recentRequestCount,
      requestValueChange,
      requestValueChangePercentage,
      recentAcceptedCount,
      acceptedValueChange,
      acceptedValueChangePercentage,
      recentLikeCount,
      likeValueChange,
      likeValueChangePercentage,
    } = Object.fromEntries(
      Object.entries(data).map(([key, count]) => [key, formatNumber(count)]),
    );

    return [
      {
        label: '필키요청수',
        count: `${recentRequestCount}건`,
        change: deletePersent(requestValueChangePercentage),
        changeValue: requestValueChange,
      },
      {
        label: '필키요청 수락수',
        count: `${recentAcceptedCount}건`,
        change: deletePersent(acceptedValueChangePercentage),
        changeValue: acceptedValueChange,
      },
      {
        label: '좋아요수',
        count: `${recentLikeCount}건`,
        change: deletePersent(likeValueChangePercentage),
        changeValue: likeValueChange,
      },
    ];
  };

  return (
    <StatusCard
      title="활동 현황"
      data={formatUserActivityData(usersActivityStatus)}
      isLoading={!usersActivityStatus}
      timeRangeOptions={DASHBORD_OPTIONS}
      timeRenderItem={(option) => DASHBORD_OPTIONS_RENDER_ITEM[option]}
      selectedTimeRange={selectedTimeRange}
      onTimeRangeChange={handleOptionChange}
      skeletonCount={3}
      arrow
    />
  );
};

export default ActivityStats;
