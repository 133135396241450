import React, { useReducer, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { PiCaretUpBold, PiCaretDownBold } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import { menuItems, dropdownItems } from './SidebarConstants';
import { initialState, reducer } from './SidebarReducer';

import {
  StyledSidebarDrawer,
  StyledSidebarList,
  StyledSidebarListItem,
  StyledSidebarListItemText,
  StyledSidebarListItemIcon,
  StyledSidebarDropdown,
} from './Sidebar.styles';

const Sidebar = ({ width }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const accessPermissionsList = useSelector(
    (value) => value.accessPermissions.accessPermissionsList,
  );
  useEffect(() => {
    const currentPath = location.pathname;

    // 드롭다운 메뉴 항목 선택 상태 유지
    const selectedDropdownItem = dropdownItems.find((item) =>
      currentPath.includes(item.to),
    );
    if (selectedDropdownItem) {
      dispatch({
        type: 'SET_SELECTED_DROPDOWN_ITEM',
        payload: selectedDropdownItem.name,
      });
      const parentMenuItem = menuItems.find((item) => item.hasDropdown);
      dispatch({
        type: 'SET_OPEN_DROPDOWN_ITEM',
        payload: parentMenuItem,
      });
    } else {
      // 일반 메뉴 항목 선택 상태 유지
      const currentMenuItem = menuItems.find((item) =>
        currentPath.includes(item.to),
      );
      dispatch({
        type: 'SET_SELECTED_MENU_ITEM',
        payload: currentMenuItem || null,
      });
    }
  }, [location.pathname]);

  const isMenuItemSelected = useMemo(
    () => (item) => {
      return (
        state.selectedMenuItem === item ||
        (state.openDropdownItem === item && state.selectedDropdownItem !== null)
      );
    },
    [
      state.selectedMenuItem,
      state.openDropdownItem,
      state.selectedDropdownItem,
    ],
  );

  const handleItemClick = (item) => {
    if (item.hasDropdown) {
      dispatch({
        type: 'SET_OPEN_DROPDOWN_ITEM',
        payload: item === state.openDropdownItem ? null : item,
      });
    } else {
      dispatch({ type: 'SET_SELECTED_MENU_ITEM', payload: item });
      if (item.to) {
        navigate(item.to);
      }
    }
  };

  const handleDropdownItemClick = (dropdownItem) => {
    dispatch({
      type: 'SET_SELECTED_DROPDOWN_ITEM',
      payload: dropdownItem.name,
    });
    if (dropdownItem.to) {
      navigate(dropdownItem.to);
    }
  };

  const createAccesRoutes = (route) => {
    const activeFeatures = route?.filter(({ isActive }) => isActive);
    return activeFeatures?.map((feature) => feature.routerPath);
  };

  return (
    <StyledSidebarDrawer width={width}>
      <StyledSidebarList>
        {menuItems.map(
          (item) =>
            createAccesRoutes(accessPermissionsList).includes(item.path) && (
              <React.Fragment key={item.name}>
                <StyledSidebarListItem
                  onClick={() => handleItemClick(item)}
                  selected={isMenuItemSelected(item)}
                >
                  <StyledSidebarListItemIcon>
                    {isMenuItemSelected(item) ? item.iconSelected : item.icon}
                  </StyledSidebarListItemIcon>
                  <StyledSidebarListItemText
                    selected={isMenuItemSelected(item)}
                  >
                    {item.name}
                  </StyledSidebarListItemText>
                  {item.hasDropdown &&
                    (state.openDropdownItem === item ? (
                      <PiCaretUpBold six="16px" color="#412CAB" />
                    ) : (
                      <PiCaretDownBold six="16px" />
                    ))}
                </StyledSidebarListItem>
                {item.hasDropdown && (
                  <StyledSidebarDropdown open={state.openDropdownItem === item}>
                    <StyledSidebarList>
                      {dropdownItems.map((dropdownItem) => (
                        <StyledSidebarListItem
                          key={dropdownItem.name}
                          onClick={() => handleDropdownItemClick(dropdownItem)}
                          selected={
                            state.selectedDropdownItem === dropdownItem.name
                          }
                          data-dropdown="true"
                        >
                          <StyledSidebarListItemText
                            selected={
                              state.selectedDropdownItem === dropdownItem.name
                            }
                            data-dropdown="true"
                          >
                            {dropdownItem.name}
                          </StyledSidebarListItemText>
                        </StyledSidebarListItem>
                      ))}
                    </StyledSidebarList>
                  </StyledSidebarDropdown>
                )}
              </React.Fragment>
            ),
        )}
      </StyledSidebarList>
    </StyledSidebarDrawer>
  );
};

export default Sidebar;

// 1. 필요한 모듈 import:
//     - React의 `useReducer`, `useEffect`, `useMemo` 훅을 사용하기 위해 import합니다.
//     - `react-router-dom`에서 `useNavigate`와 `useLocation` 훅을 import합니다.
//     - 아이콘 컴포넌트인 `PiCaretUpBold`와 `PiCaretDownBold`를 import합니다.
//     - 메뉴 항목과 드롭다운 항목에 대한 상수를 `SidebarConstants.js` 파일에서 import합니다.
//     - 사이드바의 상태 관리를 위한 `initialState`와 `reducer` 함수를 `SidebarReducer.js` 파일에서 import합니다.
//     - 스타일 컴포넌트들을 import합니다.
// 2. Sidebar 컴포넌트:
//     - 상위 컴포넌트로부터 `width` prop을 받아와 사이드바의 너비를 설정합니다.
//     - `useReducer` 훅을 사용하여 사이드바의 상태를 관리합니다. `initialState`와 `reducer` 함수를 사용하여 초기 상태와 상태 업데이트 로직을 정의합니다.
//     - `useNavigate` 훅을 사용하여 페이지 이동을 처리하고, `useLocation` 훅을 사용하여 현재 경로를 가져옵니다.
//     - `useEffect` 훅을 사용하여 컴포넌트가 마운트될 때와 경로가 변경될 때마다 선택된 메뉴 항목과 드롭다운 항목을 업데이트합니다.
//     - `useMemo` 훅을 사용하여 메뉴 항목이 선택되었는지 여부를 판단하는 함수를 최적화합니다.
//     - `handleItemClick` 함수는 메뉴 항목 클릭 시 호출되며, 드롭다운 메뉴를 열거나 닫고 선택된 메뉴 항목을 업데이트합니다. 또한 해당 메뉴 항목의 경로로 페이지를 이동합니다.
//     - `handleDropdownItemClick` 함수는 드롭다운 메뉴 항목 클릭 시 호출되며, 선택된 드롭다운 항목을 업데이트하고 해당 드롭다운 항목의 경로로 페이지를 이동합니다.
//     - `StyledSidebarDrawer` 컴포넌트로 감싸진 사이드바를 렌더링합니다.
//     - `StyledSidebarList` 컴포넌트 내부에서 `menuItems` 배열을 매핑하여 각 메뉴 항목을 렌더링합니다.
//     - 각 메뉴 항목은 `StyledSidebarListItem` 컴포넌트로 렌더링되며, 선택 여부에 따라 스타일이 적용됩니다.
//     - 메뉴 항목에 드롭다운이 있는 경우, 드롭다운 아이콘을 렌더링하고 `StyledSidebarDropdown` 컴포넌트로 드롭다운 메뉴를 렌더링합니다.
//     - 드롭다운 메뉴 내부에서는 `dropdownItems` 배열을 매핑하여 각 드롭다운 항목을 렌더링합니다.
//     - 드롭다운 항목은 `StyledSidebarListItem` 컴포넌트로 렌더링되며, 선택 여부에 따라 스타일이 적용됩니다.
