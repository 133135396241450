import { useState } from 'react';

import { useSelector } from 'react-redux';
import useModal from '../../../../hooks/useModal';
import Modal from '../../../../components/common/Modal/Modal';

import CustomButton from '../../../../components/common/Button/Button';

import {
  StyledServiceStatsContainer,
  StyledServiceStatsHeader,
  StyledServiceStatsTitle,
  StyledServiceStatsTable,
  StyledServiceStatsTableRow,
  StyledServiceStatsTableCell,
  StyledServiceStatsCheckboxOption,
  StyledServiceStatsCheckboxWrapper,
  StyledServiceStatsCheckbox,
  StyledServiceStatsCheckedBackground,
  StyledServiceStatsCheckedIcon,
  StyledServiceStatsUncheckedBackground,
  StyledServiceStatsCheckboxLabel,
  StyledServiceStatsInputField,
  StyledServiceStatsInputFieldText,
} from './ServiceStats.styles';
import { formatGroupFullCode } from '../../../../utils/formatDate';
import {
  getSystemServerStatusSettings,
  patchSystemServerStatus,
} from '../../../../apis/system';

const ServiceStats = () => {
  // TODO: 프론트 작업 시, 데이터 바인딩 참조.
  // selectedOption 운영상태 체크 선택 상태
  // normalDomain : 정상 운영 도메인 인풋 상태값
  // maintenanceDomain : 점검중 도메인 인풋 상태값
  const NormalCode = '980001';
  const MaintenanceCode = '980002';
  const [systemId, setSystemId] = useState('');
  const [selectedOption, setSelectedOption] = useState('정상 운영');
  const [normalDomain, setNormalDomain] = useState('');
  const [maintenanceDomain, setMaintenanceDomain] = useState('');
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);

  const { isModalOpen, modalTitle, modalContent, showModal, closeModal } =
    useModal();
  getSystemServerStatusSettings({
    onSuccess: (data) => {
      const {
        systemId: appSystemId,
        serverStatus,
        mainServerIp,
        subServerIp,
      } = data;

      setSystemId(appSystemId);
      const { foundCode } = formatGroupFullCode(groupCodeList, serverStatus);
      setSelectedOption(foundCode.fullCode);
      setMaintenanceDomain(subServerIp);
      setNormalDomain(mainServerIp);
    },
  });

  const { fetch: fetchPatchSystemServerStatus } = patchSystemServerStatus();

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleModalConfirm = () => {
    // 서비스 운영 상태 변경 로직 구현
    const serverStatus = selectedOption;
    const serverIp =
      serverStatus === NormalCode ? normalDomain : maintenanceDomain;
    fetchPatchSystemServerStatus({
      systemId,
      serverStatus,
      serverIp,
    });
    closeModal();
  };

  const handleModalCancel = () => {
    closeModal();
  };

  return (
    <StyledServiceStatsContainer>
      <StyledServiceStatsHeader>
        <StyledServiceStatsTitle>서비스 운영 상태</StyledServiceStatsTitle>
      </StyledServiceStatsHeader>
      <StyledServiceStatsTable>
        <StyledServiceStatsTableRow>
          <StyledServiceStatsTableCell $isHeader>
            운영 상태
          </StyledServiceStatsTableCell>
          <StyledServiceStatsTableCell>
            <StyledServiceStatsCheckboxOption>
              <StyledServiceStatsCheckboxWrapper
                onClick={() => handleOptionChange(NormalCode)}
              >
                <StyledServiceStatsCheckbox>
                  {selectedOption === NormalCode ? (
                    <>
                      <StyledServiceStatsCheckedBackground />
                      <StyledServiceStatsCheckedIcon />
                    </>
                  ) : (
                    <StyledServiceStatsUncheckedBackground />
                  )}
                </StyledServiceStatsCheckbox>
                <StyledServiceStatsCheckboxLabel>
                  정상 운영
                </StyledServiceStatsCheckboxLabel>
              </StyledServiceStatsCheckboxWrapper>
              <StyledServiceStatsInputField>
                <StyledServiceStatsInputFieldText
                  value={normalDomain}
                  onChange={(e) => setNormalDomain(e.target.value)}
                  placeholder="연결할 도메인을 입력해 주세요."
                />
              </StyledServiceStatsInputField>
            </StyledServiceStatsCheckboxOption>
          </StyledServiceStatsTableCell>
          <StyledServiceStatsTableCell>
            <StyledServiceStatsCheckboxOption>
              <StyledServiceStatsCheckboxWrapper
                onClick={() => handleOptionChange(MaintenanceCode)}
              >
                <StyledServiceStatsCheckbox>
                  {selectedOption === MaintenanceCode ? (
                    <>
                      <StyledServiceStatsCheckedBackground />
                      <StyledServiceStatsCheckedIcon />
                    </>
                  ) : (
                    <StyledServiceStatsUncheckedBackground />
                  )}
                </StyledServiceStatsCheckbox>
                <StyledServiceStatsCheckboxLabel>
                  점검중
                </StyledServiceStatsCheckboxLabel>
              </StyledServiceStatsCheckboxWrapper>
              <StyledServiceStatsInputField>
                <StyledServiceStatsInputFieldText
                  value={maintenanceDomain}
                  onChange={(e) => setMaintenanceDomain(e.target.value)}
                  placeholder="연결할 도메인을 입력해 주세요."
                />
              </StyledServiceStatsInputField>
            </StyledServiceStatsCheckboxOption>
          </StyledServiceStatsTableCell>
        </StyledServiceStatsTableRow>
      </StyledServiceStatsTable>
      <CustomButton
        size="medium"
        $state="default"
        $variant="save"
        text="실행"
        onClick={() =>
          showModal('서비스 운영상태를 변경 하시겠습니까?', '', 'alert')
        }
      />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={<div style={{ textAlign: 'center' }}>{modalContent}</div>}
        actions={
          <>
            <CustomButton
              size="large"
              $state="default"
              text="취소"
              $variant="cancel"
              onClick={handleModalCancel}
            />
            <CustomButton
              size="large"
              $state="default"
              text="실행하기"
              $variant="alert"
              onClick={handleModalConfirm}
            />
          </>
        }
      />
    </StyledServiceStatsContainer>
  );
};

export default ServiceStats;
