// KpiStats.jsx
import { useState } from 'react';
import StatusCard from '../../../../components/common/StatusCard/StatusCard';
import { formatNumber } from '../../../../utils/formatDate';
import getDashboardVideoEtcStatistics from '../../../../apis/dashbord/getDashboardVideoEtcStatistics';
import {
  DASHBORD_OPTIONS,
  DASHBORD_OPTIONS_RENDER_ITEM,
} from '../../../../constants/constants';

const MiscPostStats = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState(1);

  const videoEtcStatistics = getDashboardVideoEtcStatistics(selectedTimeRange);

  const handleOptionChange = (option) => {
    setSelectedTimeRange(option);
  };

  const formatVideoEtcStatistics = (data) => {
    if (!data) return [];
    const { naturalDeleteVideo, manualDeleteVideo } = Object.fromEntries(
      Object.entries(data).map(([key, count]) => [key, formatNumber(count)]),
    );
    return [
      {
        label: '수동 삭제',
        count: `${manualDeleteVideo}건`,
      },
      {
        label: '자연 삭제',
        count: `${naturalDeleteVideo}건`,
      },
      {
        label: '신고 숨김',
        count: `${'-0'}건`,
      },
    ];
  };

  return (
    <StatusCard
      title="게시물 기타 현황"
      data={formatVideoEtcStatistics(videoEtcStatistics)}
      isLoading={!videoEtcStatistics}
      timeRangeOptions={DASHBORD_OPTIONS}
      timeRenderItem={(option) => DASHBORD_OPTIONS_RENDER_ITEM[option]}
      selectedTimeRange={selectedTimeRange}
      onTimeRangeChange={handleOptionChange}
      skeletonCount={3}
    />
  );
};

export default MiscPostStats;
