/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import {
  PiFolderSimple,
  PiFolderSimpleMinus,
  PiFolderSimplePlus,
  PiListDashes,
  PiFileText,
  PiX,
} from 'react-icons/pi';

const getFolderIcon = (type) => {
  switch (type) {
    case 'minus':
      return <PiFolderSimpleMinus style={{ fontSize: '26px' }} />;
    case 'plus':
      return <PiFolderSimplePlus style={{ fontSize: '26px' }} />;
    case 'file':
      return <PiFileText style={{ fontSize: '26px' }} />;
    default:
      return <PiFolderSimple style={{ fontSize: '26px' }} />;
  }
};

const CategoryItem = ({
  onFolderIconClick,
  folderType = 'default',
  onCellClick,
  onCellDoubleClick,
  isCellSeleted,
  toggleTitle,
  onTitleSubmit,
  onTileBluer,
  placeholder = '카테고리명',
  titleDefaultValue,
  title = '카테고리명',
  subfolderCount,
  onDelete,
  isActive = true,
}) => {
  return (
    <Frame>
      <FolderIconContainer
        onClick={onFolderIconClick}
        $isSelected={isCellSeleted}
      >
        {getFolderIcon(folderType)}
      </FolderIconContainer>
      <Cell
        onClick={onCellClick}
        onDoubleClick={onCellDoubleClick}
        $isSelected={isCellSeleted}
        $isActive={isActive}
      >
        <FrameContent>
          <TextContainer>
            <IconContainer>
              <ListIcon $isSelected={isCellSeleted} />
            </IconContainer>
            {toggleTitle ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!e.target[0].value) return;
                  onTitleSubmit();
                }}
              >
                <StyledInput
                  onBlur={onTileBluer}
                  autoFocus
                  type="text"
                  placeholder={placeholder}
                  defaultValue={titleDefaultValue}
                />
              </form>
            ) : (
              <InputTitle $isActive={isActive}>{title}</InputTitle>
            )}
            {subfolderCount > 0 && (
              <SubfolderCount>{subfolderCount}</SubfolderCount>
            )}
          </TextContainer>
          {onDelete && (
            <IconContainer
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <CloseIcon $isSelected={isCellSeleted} />
            </IconContainer>
          )}
        </FrameContent>
      </Cell>
    </Frame>
  );
};

export default CategoryItem;

const Frame = styled.div`
  width: 245px;
  height: 35px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding-bottom: 10px;
`;

const FolderIconContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.$isSelected ? '#555555' : '#9D9D9D')};

  &:hover {
    cursor: pointer;
  }
`;

const Cell = styled.div`
  border: 1px solid
    ${(props) =>
      props.$isActive ? (props.$isSelected ? 'black' : 'white') : '#D9D9D9'};
  width: 211px;
  height: 34px;
  padding: 6px 12px;
  background: ${(props) => (props.$isActive ? 'white' : '#D9D9D9')};
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  &:hover {
    border-color: black;
    cursor: pointer;
  }
`;

const FrameContent = styled.div`
  width: 100%;
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

const InputTitle = styled.div`
  max-width: 135px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Pretendard', sans-serif;
  font-size: 13px;
  color: ${(props) => !props.$isActive && '#9D9D9D'}; // 입력된 텍스트 색상
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
`;

const ListIcon = styled(PiListDashes)`
  font-size: 20px;
  color: ${(props) => (props.$isSelected ? '#555555' : '#9D9D9D')};
`;

const CloseIcon = styled(PiX)`
  font-size: 20px;
  color: ${(props) => (props.$isSelected ? '#555555' : '#9D9D9D')};
  &:hover {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  width: 60px;
  font-family: 'Pretendard', sans-serif;
  font-size: 13px;
  line-height: 22px;

  color: ${(props) =>
    props.$isActive ? '#9D9D9D' : 'black'}; // 입력된 텍스트 색상
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  border-radius: 0;
  border: 1px solid black;
  &::placeholder {
    color: #9d9d9d;
  }
`;

const SubfolderCount = styled.div`
  font-family: 'Pretendard', sans-serif;
  font-size: 13px;
  line-height: 22px;
  color: #4094f7;
  text-decoration: underline;
  margin-left: 4px;
`;
