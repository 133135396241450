import styled from 'styled-components';

export const StyledPostStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const StyledPostStatusWrapper = styled.div`
  flex: 1;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledPostStatusStatsWrapper = styled(StyledPostStatusWrapper)`
  height: 100%;
`;

export const StyledPostStatusChartWrapper = styled(StyledPostStatusWrapper)`
  height: 100%;
`;
