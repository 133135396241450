import {
  StyledNoticeTemplateTableCell,
  StyledNoticeTemplateTableHeaderCell,
} from '../AlarmTemplate.styles';

const AlarmTemplateTableCell = ({
  isHeader,
  isFirstRow,
  isRequired,
  height,
  minWidth,
  children,
  isAlignStart,
}) => {
  const CellComponent = isHeader
    ? StyledNoticeTemplateTableHeaderCell
    : StyledNoticeTemplateTableCell;

  return (
    <CellComponent
      $isFirstRow={isFirstRow}
      $height={height}
      $minWidth={minWidth}
      $isAlignStart={isAlignStart}
    >
      {children}
      {isRequired && <span style={{ color: '#F76659' }}>*</span>}
    </CellComponent>
  );
};

export default AlarmTemplateTableCell;
