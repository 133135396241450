import EmptyList from '../../../../../../components/common/EmptyList/EmptyList';
import TableDataRow from '../../../../../../components/common/Table/TableDataRow';
import TableHeaderRow from '../../../../../../components/common/Table/TableHeaderRow';
import { StyledPublishedVideoListTableWrapper } from './PublishedVideoList.styles';

const PublishedVideoListTable = ({
  keyValue,
  videos,
  columns,
  selectedVideoIds,
  onSelectAll,
  onSelectVideo,
  onRowClick,
  onSort,
  sortColumn,
  sortDirection,
}) => {
  const isAllSelected =
    videos.length > 0 &&
    videos.every((video) => selectedVideoIds.includes(video.id));

  return (
    <StyledPublishedVideoListTableWrapper>
      <TableHeaderRow
        columns={columns}
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
        onSort={onSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
      />
      {videos.length ? (
        videos.map((video) => (
          <TableDataRow
            key={keyValue ? video[keyValue] : video.id}
            data={video}
            columns={columns}
            onSelect={onSelectVideo}
            isSelected={selectedVideoIds.includes(video.id)}
            onRowClick={() => onRowClick(video)}
          />
        ))
      ) : (
        <EmptyList title="영상 목록" />
      )}
    </StyledPublishedVideoListTableWrapper>
  );
};

export default PublishedVideoListTable;
