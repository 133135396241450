/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
// 계정관리 > 관리자 정보 > 관리자 상세
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Badge from '../../../../components/common/Badge';
import CustomButton from '../../../../components/common/Button/Button';
import Modal from '../../../../components/common/Modal/Modal';

import useModal from '../../../../hooks/useModal';

import ChangeAccessItem from './components/ChangeAccessItem';
import AdminInfoDetailHeader from './components/AdminInfoDetailHeader';
import AdminInfoDetailBadgeDropdown from './components/AdminInfoDetailBadgeDropdown';
import AdminInfoDetailItem from './components/AdminInfoDetailItem';
import AdminInfoDetailLoading from './components/AdminInfoDetailLoading';

import { validatePassword } from '../../../../utils/validate';

import {
  StyledAdminInfoDetailContainer,
  StyledAdminInfoDetailContentsWrapper,
  StyledAdminInfoDetailInfoLabel,
  StyledAdminInfoDetailInfoText,
  StyledAdminInfoDetailAccessRow,
  StyledAdminInfoDetailAccessValue,
  StyledAdminInfoDetailSeparator,
  StyledPasswordChangeWrapper,
  StyledPasswordInput,
  StyledInput,
} from './AdminInfoDetail.style';
// import Loading from '../../components/MyInfo/Loading';
import { adminLevelList } from '../../../../constants/adminLevel';
import {
  getAccountAccessPermissions,
  putAccountInfo,
} from '../../../../apis/accountManagement';
import Spinner from '../../../../components/common/Spinner/Spinner';

const AdminUserDetailPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const userData = state?.userData;
  const userInfo = useSelector((state) => state.user.user);

  if (!userData) {
    return (
      <div
        style={{
          width: '100%',
          height: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }

  const [initialUserData, setInitialUserData] = useState(userData);

  // 사용자 이름 수정 관련 상태 추가
  const [userName, setUserName] = useState(userData.name); // 이름으로 수정

  // 관리자 권한 변경 관련 상태 추가
  const [isPositionChangeMode, setIsPositionChangeMode] = useState(false);
  const [selectedType, setSelectedType] = useState(userData.level);
  const [position, setPosition] = useState(userData.department);
  const resetPositionMode = () => {
    setIsPositionChangeMode(false);
    setSelectedType(initialUserData.level);
    setPosition(initialUserData.department);
  };

  // 비밀번호 변경 관련 상태 추가
  const [isPasswordChangeMode, setIsPasswordChangeMode] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const resetPasswordChangeMode = () => {
    setIsPasswordChangeMode(false);
    setPassword('');
    setConfirmPassword('');
  };

  // 접근 허용 범위 변경 관련 상태 추가
  const [accessRanges, setAccessRanges] = useState();

  const accessPermissions = getAccountAccessPermissions(userData?.accountUuid);

  const updateAccountInfo = putAccountInfo({
    onSuccess: () => {
      navigate('/AccountManagement', {
        state: { activeTab: 'adminInfo' },
      });
    },
    onFailure: () => {
      showModal(
        '오류',
        '정보 저장에 실패하였습니다. 다시 시도해주세요.',
        'alert',
      );
    },
  });

  useEffect(() => {
    if (!accessPermissions) return;
    const accessPermissionNames = accessPermissions.map(
      ({ isActive, ...data }) => ({
        ...data,
        isActive: !!isActive,
      }),
    );
    setInitialUserData((prev) => ({
      ...prev,
      accessPermissionNames: accessPermissionNames.filter((item) => {
        return (
          selectedType !== '100993' || item.routerPath !== 'accountmanagement'
        );
      }),
    }));

    setAccessRanges(
      accessPermissionNames.filter((item) => {
        return (
          selectedType !== '100993' || item.routerPath !== 'accountmanagement'
        );
      }),
    );
  }, [accessPermissions]);

  useEffect(() => {
    // 계정관리 설정을 막기 위한 로직 추후 변경 필요
    if (!accessRanges) return;
    if (selectedType === 100993) {
      setAccessRanges((prev) =>
        prev.filter((item) => item.routerPath !== 'accountmanagement'),
      );
    } else if (accessRanges.length !== 6) {
      setAccessRanges((prev) => [...prev, accessPermissions[5]]);
    }
  }, [selectedType]);

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  useEffect(() => {
    setInitialUserData(userData);
  }, []);

  const handleAccessRangeChange = (id) => {
    setAccessRanges((prev) =>
      prev.map((range) =>
        range.featureUuid === id
          ? { ...range, isActive: !range.isActive }
          : range,
      ),
    );
  };

  const handlePasswordConfirm = async () => {
    const { isValid, message, variant } = validatePassword(
      password,
      confirmPassword,
    );
    if (!isValid) {
      showModal('오류', message, variant);
      return;
    }

    try {
      // 비밀번호 변경 로직 구현
      showModal('확인', '비밀번호가 변경 가능합니다.', 'save');
      setNewPassword(password);
      setPassword('');
      setConfirmPassword('');
      setIsPasswordChangeMode(false);
    } catch (error) {
      showModal(
        '오류',
        '비밀번호 변경에 실패하였습니다. 다시 시도해주세요.',
        'alert',
      );
    }
  };

  const handleInfoUpdate = () => {
    const { accountUuid } = userData;

    const accountInfo = {
      changeAccountRoleReq: {
        adminUuid: accountUuid,
        level: selectedType.toString(),
        department: position,
      },
      changeAccountPasswordReq: {
        adminUuid: accountUuid,
        password: newPassword || null,
      },
      changeAccountInfoReq: {
        adminUuid: accountUuid,
        name: userName,
        accessPermissionScopeDto: accessRanges.map(
          ({ featureUuid, isActive }) => ({
            accessPermissionFeatureId: featureUuid,
            isActive,
          }),
        ),
      },
    };

    updateAccountInfo(accountInfo);
  };

  const handleSave = () => {
    const { isValid, message, variant } = validatePassword(
      password,
      confirmPassword,
    );
    if (isPasswordChangeMode && !isValid) {
      showModal('오류', message, variant);
      return;
    }

    showModal('저장 확인', '수정한 내용을 저장하시겠습니까?', 'confirm');
  };

  if (!userData) {
    return <AdminInfoDetailLoading />;
  }

  const infoItems = [
    {
      label: '이름',
      value: (
        <StyledInput
          type="text"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          placeholder="이름 입력"
        />
      ),
    },
    {
      label: '관리자 권한',
      value: isPositionChangeMode ? (
        <>
          <AdminInfoDetailBadgeDropdown
            options={adminLevelList.filter((item) => item > userInfo.role)}
            initialOption={selectedType}
            onOptionChange={(option) => setSelectedType(option)}
            minWidth="143px"
          />
          <StyledInput
            type="text"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            placeholder="담당파트 입력"
          />
          <CustomButton
            $variant="passwordChangeCancel"
            $state="default"
            size="small"
            text="취소"
            onClick={resetPositionMode}
          />
        </>
      ) : (
        <>
          <Badge type={userData.level} />
          {userData.department && (
            <>
              <StyledAdminInfoDetailSeparator>|</StyledAdminInfoDetailSeparator>
              <div>{userData.department}</div>
            </>
          )}
          <CustomButton
            $variant="passwordChange"
            $state="default"
            size="small"
            text="변경"
            onClick={() => setIsPositionChangeMode(true)}
          />
        </>
      ),
    },
    { label: '이메일', value: userData.email },
    {
      label: '비밀번호',
      value: isPasswordChangeMode ? (
        <StyledPasswordChangeWrapper>
          {/* 비밀번호 변경 입력 필드 추가 */}
          <StyledPasswordInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="비밀번호 입력"
          />
          <StyledPasswordInput
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="비밀번호 재입력"
          />
          <CustomButton
            $variant="passwordChangeConfirm"
            $state="default"
            size="small"
            text="확인"
            onClick={handlePasswordConfirm}
          />
          <CustomButton
            $variant="passwordChangeCancel"
            $state="default"
            size="small"
            text="취소"
            onClick={resetPasswordChangeMode}
          />
        </StyledPasswordChangeWrapper>
      ) : (
        <>
          <CustomButton
            $variant="passwordChange"
            $state="default"
            size="small"
            text="비밀번호 변경"
            onClick={() => setIsPasswordChangeMode(true)}
          />
          <StyledAdminInfoDetailInfoText size="small">
            최종 변경일 : {userData.updatedAt}
          </StyledAdminInfoDetailInfoText>
        </>
      ),
    },
    { label: '생성일', value: userData.createdAt },
  ];

  return (
    <div>
      <AdminInfoDetailHeader />
      <StyledAdminInfoDetailContainer>
        <StyledAdminInfoDetailContentsWrapper>
          {infoItems.map((item) => (
            <AdminInfoDetailItem
              key={item.label}
              label={item.label}
              value={item.value}
              size={item.size}
              weight={item.weight}
            />
          ))}
          <StyledAdminInfoDetailAccessRow>
            <StyledAdminInfoDetailInfoLabel>
              접근 허용 범위
            </StyledAdminInfoDetailInfoLabel>
            <StyledAdminInfoDetailAccessValue>
              <ChangeAccessItem
                accessRanges={accessRanges}
                onAccessRangeChange={handleAccessRangeChange}
              />
            </StyledAdminInfoDetailAccessValue>
          </StyledAdminInfoDetailAccessRow>
        </StyledAdminInfoDetailContentsWrapper>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <CustomButton
            $variant="save"
            $state="default"
            size="medium"
            text="저장"
            onClick={handleSave}
            disabled={
              userName === initialUserData.name &&
              selectedType.toString() === initialUserData.level &&
              position === initialUserData.department &&
              !newPassword &&
              JSON.stringify(accessRanges) ===
                JSON.stringify(initialUserData.accessPermissionNames)
            }
          />
        </div>
      </StyledAdminInfoDetailContainer>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={
          modalVariant === 'confirm' ? (
            <>
              <CustomButton
                size="large"
                $state="default"
                text="취소"
                $variant="cancel"
                onClick={closeModal}
              />
              <CustomButton
                size="large"
                $state="default"
                text="확인"
                $variant="confirm"
                onClick={() => {
                  handleInfoUpdate();
                }}
              />
            </>
          ) : modalVariant === 'save' ? (
            <CustomButton
              size="large"
              $state="default"
              text="확인"
              $variant="confirm"
              onClick={closeModal}
            />
          ) : (
            <CustomButton
              size="large"
              $state="default"
              text="확인"
              $variant={modalVariant}
              onClick={closeModal}
            />
          )
        }
      />
    </div>
  );
};

export default AdminUserDetailPage;
