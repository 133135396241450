import styled from 'styled-components';

export const StyledMemberStatusWrapper = styled.div`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  flex-direction: inline-flex;
`;

export const StyledMemberStatusFirstWrapper = styled(StyledMemberStatusWrapper)`
  height: 100%;
`;

export const StyledMemberStatusSecondWrapper = styled(
  StyledMemberStatusWrapper,
)`
  height: 180px;
  margin-top: 20px;
`;
