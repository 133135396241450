import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

export const getUserLevels = () => {
  const { data, fetch } = useFetch({
    url: '/user/levels',
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  return data;
};

export const patchUserDetailLevel = ({ userId, memberType }) => {
  const { fetch } = useFetch({
    url: `/user/detail/${userId}/level?userLevelCode=${memberType}`,
    method: 'patch',
  });

  return fetch;
};

export const patchUserDetailCelb = ({ userId, isCelebChecked }) => {
  const { fetch } = useFetch({
    url: `/user/detail/${userId}/celeb?isActive=${isCelebChecked}`,
    method: 'patch',
  });

  return fetch;
};

export const patchUserNickName = ({ userId }) => {
  const { fetch } = useFetch({
    url: `/user/detail/${userId}/nickname`,
    method: 'patch',
  });

  return fetch;
};

export const getUserDetailVideos = ({
  userId,
  sortColumn,
  sortDirection,
  onSuccess,
}) => {
  const { fetch } = useFetch({
    url: `/user/detail/${userId}/videos?${sortColumn}=${sortDirection}`,
    method: 'get',
    onSuccess: (value) => onSuccess(value),
  });

  useEffect(() => {
    fetch();
  }, [sortColumn, sortDirection]);

  return fetch;
};

export const getVideoDetailCommenmtVideo = (query, { onSuccess }) => {
  const { fetch } = useFetch({
    url: `/video/detail/commentVideo/${query}`,
    method: 'get',
    onSuccess: (value) => onSuccess(value),
  });

  return fetch;
};
