import styled from 'styled-components';

const PopupNotification = ({ selectedFile }) => {
  return (
    <PopupNotificationContainer>
      <PopupNotificationWrapper>
        <PopupNotificationTitle>팝업 예시 화면</PopupNotificationTitle>
        <PopupNotificationContent>
          {selectedFile && (
            <img
              src={selectedFile.url || URL.createObjectURL(selectedFile)}
              alt="Uploaded"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          )}
        </PopupNotificationContent>
        <PopupNotificationTextWrapper>
          <PopupNotificationText>오늘 하루 보지 않기</PopupNotificationText>
          <PopupNotificationText>닫기</PopupNotificationText>
        </PopupNotificationTextWrapper>
      </PopupNotificationWrapper>
    </PopupNotificationContainer>
  );
};

export default PopupNotification;

const PopupNotificationContainer = styled.div`
  height: 100%;
  padding: 30px 20px;
  background: #e9e9e9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
`;

const PopupNotificationWrapper = styled.div`
  width: 240px;
`;

const PopupNotificationTitle = styled.div`
  color: #9d9d9d;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 9px;
  word-wrap: break-word;
`;

const PopupNotificationContent = styled.div`
  margin-top: 12px;
  width: 240px;
  height: 180px;
  background: #c6c4ff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; // 추가
`;

const PopupNotificationTextWrapper = styled.div`
  width: 240px;
  height: 30px;
  background: #000000;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
`;

const PopupNotificationText = styled.div`
  display: flex;
  color: white;
  font-size: 12px;
  font-weight: 400;
`;
