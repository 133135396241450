import styled from 'styled-components';
import { PiCheckBold } from 'react-icons/pi';

export const StyledRegionalFilterContainer = styled.div`
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  overflow-y: auto;
`;

export const StyledRegionalFilterTable = styled.div`
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  border: 1px #d9d9d9 solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledRegionalFilterHeader = styled.div`
  min-width: 140px;
  min-height: 46px;
  align-self: stretch;
  background: white;
  border-bottom: 1px #d9d9d9 solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledRegionalFilterHeaderCell = styled.div`
  min-width: 140px;
  align-self: stretch;
  padding: 9px 12px;
  opacity: 0.9;
  background: #f5f5f5;
  border-right: 1px #d9d9d9 solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export const StyledRegionalFilterHeaderText = styled.div`
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

export const StyledRegionalFilterBodyCell = styled.div`
  flex: 1;
  align-self: stretch;
  padding: 9px 12px;
  background: white;
`;

export const StyledRegionalFilterInputField = styled.div`
  width: 100%;
  height: 30px;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const StyledRegionalFilterInputFieldText = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 0px;
  color: #000000;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &::placeholder {
    color: #9d9d9d;
  }
`;

export const StyledRegionalFilterSearchbar = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledRegionalFilterAreaList = styled.div`
  width: 100%;
  min-width: 413px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px #c4c4c4 solid;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9d9d9d;
    border-radius: 4px;
  }
`;

export const StyledRegionalFilterAreaItem = styled.div`
  height: 32px;
  padding: 8px 12px;
  background: #efefef;
  border-radius: 4px;
  display: flex;
  gap: 10px;
`;

export const StyledRegionalFilterAreaText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

export const StyledRegionalFilterCheckbox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledRegionalFilterCheckboxInput = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  padding: 0;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  &:checked {
    background-color: #412cab;
    border-color: #412cab;
  }
`;

export const StyledRegionalFilterCheckboxIcon = styled(PiCheckBold)`
  width: 16px;
  height: 16px;
  position: absolute;
  color: #fff;
  font-size: 16px;
  visibility: ${({ $isChecked }) => ($isChecked ? 'visible' : 'hidden')};
  pointer-events: none;
`;

export const StyledRegionalFilterButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;
