/* eslint-disable*/

import axios from 'axios';
import { Router } from 'react-router-dom';

const accessToken = 'accessToken';
const refreshToken = 'refreshToken';
const accessTokenExp = 'accessTokenExp';
const refreshTokenExp = 'refreshTokenExp';
const role = 'role';
const userId = 'userId';

class TokenManager {
  constructor() {
    this.initToken();
  }

  initToken() {
    if (typeof window === 'undefined') return;
    this._accessToken = localStorage.getItem(accessToken);
    this._refreshToken = localStorage.getItem(refreshToken);
    this._accessTokenExp = localStorage.getItem(accessTokenExp);
    this._refreshTokenExp = localStorage.getItem(refreshTokenExp);
    this._role = localStorage.getItem(role);
    this._userId = localStorage.getItem(userId);
  }

  setToken(tokens) {
    this._accessToken = tokens.accessToken;
    this._refreshToken = tokens.refreshToken;
    this._accessTokenExp = tokens.accessTokenExp;
    this._refreshTokenExp = tokens.refreshTokenExp;
    this._role = tokens.role;
    this._userId = tokens.userId;

    localStorage.setItem(accessToken, tokens.accessToken);
    localStorage.setItem(refreshToken, tokens.refreshToken);
    localStorage.setItem(accessTokenExp, tokens.accessTokenExp);
    localStorage.setItem(refreshTokenExp, tokens.refreshTokenExp);
    localStorage.setItem(role, tokens.role);
    localStorage.setItem(userId, tokens.userId);
  }

  isSkipUrl() {
    const skipUrls = ['/'];
    return skipUrls.includes(Router.route);
  }

  async checkToken() {
    try {
      await axios.post(
        '/auth/check-token',
        {},
        {
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
          headers: {
            Authorization: this._accessToken && `Bearer ${this._accessToken}`,
          },
        },
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  async reissueToken() {
    try {
      const { data } = await axios.post(
        '/auth/refresh-token',
        {},
        {
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
          headers: {
            'Refresh-Token': this._refreshToken && `${this._refreshToken}`,
          },
        },
      );
      this._accessToken = data.accessToken;
      this._accessTokenExp = data.accessTokenExp;
      localStorage.setItem(accessToken, this._accessToken);
      localStorage.setItem(accessTokenExp, this._accessTokenExp);
    } catch (e) {
      this.deleteToken();
      if (window.location.pathname !== '/') window.location.href = '/';
    }
  }

  deleteToken() {
    localStorage.removeItem(accessToken);
    localStorage.removeItem(refreshToken);
    localStorage.removeItem(accessTokenExp);
    localStorage.removeItem(refreshTokenExp);
    localStorage.removeItem(role);
    localStorage.removeItem(userId);
  }

  get accessToken() {
    return this._accessToken;
  }

  get refreshToken() {
    return this._refreshToken;
  }

  get accessTokenExp() {
    return this._accessTokenExp;
  }

  get refreshTokenExp() {
    return this._refreshTokenExp;
  }

  get role() {
    return this._role;
  }

  get userId() {
    return this._userId;
  }
}

export default TokenManager;
