import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

export const getAccount = () => {
  const { data, fetch } = useFetch({
    url: '/account',
    method: 'get',
  });
  useEffect(() => {
    fetch();
  }, []);
  return data;
};

export const patchAccountPassword = ({ onSuccess, onFailure }) => {
  const { fetch } = useFetch({
    url: '/account/password',
    method: 'patch',
    onSuccess: () => onSuccess(),
    onFailure: () => onFailure(),
  });

  return { fetch };
};

export const getAccountAccessPermissions = (query) => {
  const { data, fetch } = useFetch({
    url: `/account/accessPermissions?accountId=${query}`,
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  return data;
};

export const getAccountAdmins = () => {
  const { data, fetch } = useFetch({
    url: '/account/admins',
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  return { data, fetch };
};

export const deleteAccount = ({ onSuccess, onFailure }) => {
  const { isLoading, fetch } = useFetch({
    url: '/account',
    method: 'delete',
    onSuccess: () => onSuccess(),
    onFailure: () => onFailure(),
  });
  return { isLoading, fetch };
};

export const putAccountInfo = ({ onSuccess, onFailure }) => {
  const { fetch } = useFetch({
    url: '/account/info',
    method: 'put',
    onSuccess: () => onSuccess(),
    onFailure: () => onFailure(),
  });

  return fetch;
};

export const postAccunt = ({ onSuccess, onFailure }) => {
  const { fetch } = useFetch({
    url: '/account',
    method: 'post',
    onSuccess: () => onSuccess(),
    onFailure: () => onFailure(),
  });

  return fetch;
};
