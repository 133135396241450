import CustomButton from '../../../../components/common/Button/Button';
import Dropdown from '../../../../components/common/DropDown/DropDown';
import {
  StyledAppSettingInputFieldContainer,
  StyledAppSettingTableCell,
  StyledAppSettingTableCellLabel,
  StyledAppSettingTableRow,
} from './AppSetting.styles';

const DropDownOptions = ({
  title,
  settings,
  options,
  optionFormat,
  onInputChange,
  modalTitleText,
  modalContentText,
  handleConfirm,
  showModal,
}) => {
  return (
    <StyledAppSettingTableRow>
      <StyledAppSettingTableCell $isHeader style={{ width: '15.80%' }}>
        <StyledAppSettingTableCellLabel $isHeader>
          {title}
        </StyledAppSettingTableCellLabel>
      </StyledAppSettingTableCell>
      <StyledAppSettingTableCell style={{ width: '45.26%' }}>
        <StyledAppSettingInputFieldContainer>
          <Dropdown
            minWidth="100%"
            height="28px"
            maxHeight="70px"
            options={options}
            renderItem={optionFormat}
            value={settings}
            selectedValue={settings}
            onOptionChange={onInputChange}
          />
        </StyledAppSettingInputFieldContainer>
        <CustomButton
          size="small"
          $state="default"
          text="변경"
          $variant="change"
          onClick={() =>
            showModal(modalTitleText, modalContentText, 'alert', handleConfirm)
          }
        />
      </StyledAppSettingTableCell>
      <StyledAppSettingTableCell $isOdd style={{ width: '38.94%' }}>
        <StyledAppSettingTableCellLabel>
          {`*위치 카테고리 ‘내주변' 영역을 설정해 주세요.`}
        </StyledAppSettingTableCellLabel>
      </StyledAppSettingTableCell>
    </StyledAppSettingTableRow>
  );
};

export default DropDownOptions;
