/* eslint-disable consistent-return */
import styled from 'styled-components';
// import { useLocation, useNavigate } from 'react-router-dom';
import { PiX } from 'react-icons/pi';
import NoticeList from './components/NoticeList/NoticeList';

const StyledNoticeListContainer = styled.div``;
const StyledNoticeListWrapper = styled.div`
  max-width: 1080px;
  min-width: 926px;
  width: 100%;
  padding: 30px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;

const StyledNoticeListContentsWrapper = styled(StyledNoticeListWrapper)`
  margin-top: 20px;
`;

const ConectNoticeId = ({
  openModal,
  onClose,
  noticeId,
  handleUpdateNoticeId,
  handleUpdateNoticeTitle,
}) => {
  if (!openModal) return;

  const handleConectNotice = ({ id, data }) => {
    handleUpdateNoticeId(id);
    handleUpdateNoticeTitle(data?.title);
    onClose();
  };

  return (
    <ConectNoticeIdContainer onClick={onClose}>
      <StyledNoticeListContainer onClick={(e) => e.stopPropagation()}>
        <StyledNoticeListContentsWrapper>
          <StyledVideoDetailModalHeader>
            <StyledVideoDetailModalTitle>
              상세 내용 연결
            </StyledVideoDetailModalTitle>
            <StyledVideoDetailModalCloseButton onClick={onClose}>
              <StyledVideoDetailModalCloseButtonVector />
            </StyledVideoDetailModalCloseButton>
          </StyledVideoDetailModalHeader>

          <NoticeList
            noticeId={noticeId}
            handleConectNotice={handleConectNotice}
          />
        </StyledNoticeListContentsWrapper>
      </StyledNoticeListContainer>
    </ConectNoticeIdContainer>
  );
};

export default ConectNoticeId;

const ConectNoticeIdContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledVideoDetailModalHeader = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const StyledVideoDetailModalTitle = styled.div`
  color: #1e2024;
  font-size: 28px;
  font-family: 'Pretendard';
  font-weight: 700;
  line-height: 36px;
  word-wrap: break-word;
`;

const StyledVideoDetailModalCloseButton = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledVideoDetailModalCloseButtonVector = styled(PiX)`
  width: 24px;
  height: 24px;
  color: #686b73;
`;
