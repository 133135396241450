import styled from 'styled-components';
import { PiArrowsDownUp, PiCheckBold } from 'react-icons/pi';

export const StyledTableHeader = styled.div`
  width: 100%;
  background: #efefef;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  /* display: inline-flex; */
  display: flex;
`;

export const StyledTableRow = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 1px #f1f2f4 solid;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
  &:hover {
    background-color: #f6f6ff;
  }
`;

export const StyledTableHeaderRow = styled(StyledTableRow)`
  height: 32px;
  border-bottom: 2px #fff solid;
  display: flex;
  &:hover {
    background-color: inherit; // 호버 효과 제거
  }
`;

export const StyledTableCheckboxWrapper = styled.div`
  margin-left: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const StyledTableCheckboxInput = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  padding: 0px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  &:checked {
    background-color: #412cab;
    border-color: #412cab;
  }
`;

export const StyledTableCheckboxIcon = styled(PiCheckBold)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  visibility: ${({ $isChecked }) => ($isChecked ? 'visible' : 'hidden')};
  cursor: pointer;
`;

export const StyledTableCell = styled.div`
  padding: 4px 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: ${(props) => props.width || 'auto'};
  /* min-width: ${(props) => (props.$flex ? '160px' : 'auto')}; */
  box-sizing: border-box;
  /* flex: ${(props) => (props.$flex ? props.$flex : 'none')}; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
`;

export const StyledTableHeaderCell = styled(StyledTableCell)`
  height: 32px;
  &:hover {
    cursor: default;
  }
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #fff;
    cursor: default;
  }
`;

export const StyledTableText = styled.div`
  color: ${(props) => {
    if (props.$header && props.$sorted) {
      return '#412CAB';
    }
    if (props.$header) {
      return '#434343';
    }
    return '#1a1a1a';
  }};
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const StyledTableCheckbox = styled.input`
  width: 16px;
  height: 16px;
`;

export const StyledTableSortButton = styled(PiArrowsDownUp)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ $sorted }) => ($sorted ? '#412CAB' : 'inherit')};
  font-size: 16px;
  margin-left: 6px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const StyledTableLink = styled.a`
  color: #0070f3;
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &:hover {
    text-decoration: underline;
  }
`;
