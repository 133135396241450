import styled from 'styled-components';

export const StyledInquiriesContainer = styled.div``;

export const StyledInquiriesWrapper = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  /* border: 1px solid #f1f2f4; */
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;

export const StyledInquiriesContentsWrapper = styled(StyledInquiriesWrapper)`
  margin-top: 20px;
`;

export const StyledInquiriesTabWrapper = styled.div`
  width: 100%;
  justify-content: flex-start;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 28px;
`;

export const StyledInquiriesTab = styled.div`
  width: 160px;
  height: 40px;
  padding: 11px 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: ${(props) => (props.$isActive ? '600' : '500')};
  color: ${(props) => (props.$isActive ? '#1A1A1A' : '#9D9D9D')};
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${(props) =>
      props.$isActive ? '#1E2024' : 'transparent'};
  }
`;

export const StyledInquiriesBadge = styled.div`
  width: 24px;
  padding: 0 5px;
  background: ${(props) => (props.$isNew ? '#FFF0F0' : '#E4E4E7')};
  border-radius: 99px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;
`;

export const StyledInquiriesBadgeCount = styled.div`
  text-align: center;
  color: ${(props) => (props.$isNew ? '#F76659' : '#71717A')};
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
`;
