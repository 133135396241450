/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

export const getNoticeList = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `notice/list?currentPage=${query.currentPage}&pageSize=${query.pageSize}&sortKey=${query.sortColumn}&sort=${query.sortDirection}`,
    method: 'get',
    onSuccess,
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, [
    query.currentPage,
    query.pageSize,
    query.sortColumn,
    query.sortDirection,
  ]);

  return { data, fetch };
};

export const postNotice = ({ query, onSuccess }) => {
  const { data, fetch } = useFetch({
    url: `/notice?${query}`,
    method: 'post',
    onSuccess,
  });

  return { data, fetch };
};

export const getNotice = ({ query, isInitialRequest = true, onSuccess }) => {
  const { data, fetch } = useFetch({
    url: `/notice/${query}`,
    method: 'get',
    onSuccess,
  });
  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const putNotice = ({ query, onSuccess, onFailure }) => {
  const { fetch } = useFetch({
    url: `/notice?${query}`,
    method: 'put',
    onSuccess,
    onFailure,
  });

  return { fetch };
};

export const delteNotice = ({ query, onSuccess, onFailure }) => {
  const { data, fetch } = useFetch({
    url: `/notice?deleteType=${query}`,
    method: 'delete',
    onSuccess,
    onFailure,
  });

  return { data, fetch };
};
