const DASHBORD_TABS = [
  {
    title: '회원 현황',
    type: 'member',
  },
  {
    title: '게시물 현황',
    type: 'post',
  },
];

export default DASHBORD_TABS;
