import styled from 'styled-components';

export const StyledAdminInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledAdminInfoWrapper = styled.div`
  width: 100%;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
`;

export const StyledAdminInfoListWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledAdminInfoTableTitleContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  margin-bottom: 10px;
`;

export const StyledAdminInfoButtonGroupWrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: flex;
`;

// AdminInfoTableTitle

export const StyledAdminInfoTableTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledAdminInfoTableTitleText = styled.div`
  color: #262626;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

// AdminInfoTable
export const StyledAdminInfoTableWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  box-sizing: border-box;
`;
