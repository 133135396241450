// CustomDatePicker.styles.js
import styled, { css } from 'styled-components';

export const StyledDatePickerContainer = styled.div`
  top: 100%;
  left: 17.5%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #fff;
  border: 1px #f1f2f4 solid;
  border-radius: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  ${({ $selectOneDate }) =>
    !$selectOneDate &&
    `
.react-datepicker__day--range-end::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10%;
  width: 50%;
  height: 100%;
  background: #e9e8ff;
  z-index: -1;
}

.react-datepicker__day--range-start::before {
  content: '';
  position: absolute;
  top: 0;
  right: -10%;
  width: 50%;
  height: 100%;
  background: #e9e8ff;
  z-index: -1;
}

  `}
`;

export const StyledDatePickerButtons = styled.div`
  min-width: 152px;
  min-height: 40px;
  display: flex;
  justify-content: flex-end;
  margin: 12px 20px;
  gap: 8px;
`;

const disabledStyle = css`
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
`;

export const StyledApplyButton = styled.button`
  width: 100%;
  padding: 5px 10px;
  background-color: #412cab;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-family: 'Pretendard', sans-serif;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    css`
      ${disabledStyle}
    `}
`;

export const StyledCancelButton = styled.button`
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #262626;
  font-size: 14px;
  font-family: 'Pretendard', sans-serif;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  border: 1px solid #dddfe3;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    css`
      ${disabledStyle}
    `}
`;
