// ServiceStats.styles.js
import styled from 'styled-components';

export const StyledServiceStatsContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  /* border: 1px #f1f2f4 solid; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const StyledServiceStatsHeader = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledServiceStatsTitle = styled.div`
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

export const StyledServiceStatsTable = styled.div`
  align-self: stretch;
  border-radius: 8px;
  border: 1px #d9d9d9 solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-collapse: collapse;
`;

export const StyledServiceStatsTableRow = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledServiceStatsTableCell = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  font-family: 'Pretendard';
  align-self: stretch;
  padding: 6px 12px;
  background: ${({ $isHeader }) => ($isHeader ? '#e9e9e9' : 'none')};
  border-left: 1px #d9d9d9 solid;
  display: flex;
  justify-content: ${({ $isHeader }) =>
    $isHeader ? 'flex-start' : 'flex-start'};
  align-items: center;
  gap: 12px;
  width: ${({ $isHeader }) => ($isHeader ? '120px' : 'auto')};
  flex: ${({ $isHeader }) => ($isHeader ? 'none' : '1 1 0')};
  &:first-child {
    border-left: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

export const StyledServiceStatsCheckboxOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex: 1 1 0;
`;

export const StyledServiceStatsCheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

export const StyledServiceStatsCheckbox = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
`;

export const StyledServiceStatsCheckedBackground = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  background: #412cab;
  border-radius: 50%;
`;

export const StyledServiceStatsCheckedIcon = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 50%;
`;

export const StyledServiceStatsUncheckedBackground = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
`;

export const StyledServiceStatsCheckboxLabel = styled.div`
  text-align: center;
  color: black;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 16.9px;
  word-wrap: break-word;
`;

export const StyledServiceStatsInputField = styled.div`
  flex: 1 1 0;
  height: 30px;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px #d9d9d9 solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const StyledServiceStatsInputFieldText = styled.input`
  flex: 1 1 0;
  border: none;
  outline: none;
  color: #1e2024;
  font-size: 13px;
  padding: 0px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &::placeholder {
    color: #9d9d9d;
  }
`;

export const StyledServiceStatsButton = styled.div`
  margin-top: 10px;
  width: 120px;
  height: 32px;
  padding: 8px;
  background: #412cab;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: white;
  font-size: 14px;
  font-family: 'Pretendard';
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;
