import styled from 'styled-components';
import { PiCheckBold } from 'react-icons/pi';

export const StyledAccessRangeItemGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 294px);
  grid-template-rows: repeat(3, 46px);
  grid-gap: 12px;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 1281px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
`;

export const StyledAccessRangeItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 12px;
  border-radius: 4px;
  border: 1px #dddfe3 solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

export const StyledAccessRangeItemContent = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAccessRangeItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  flex: ${({ $isFullWidth }) => ($isFullWidth ? '1 1 0' : 'initial')};
  align-self: ${({ $isFullWidth }) => ($isFullWidth ? 'stretch' : 'initial')};
`;

export const StyledAccessRangeItemIconWrapper = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
  }
`;

export const StyledAccessRangeItemText = styled.div`
  color: #434343;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
`;

export const StyledAccessRangeItemBadge = styled.div`
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: ${({ $isAvailable }) =>
    $isAvailable ? '#E5FAEC' : '#F1F2F4'};
  color: ${({ $isAvailable }) => ($isAvailable ? '#00AD74' : '#686B73')};
`;

export const StyledAccessRangeItemCheckIcon = styled(PiCheckBold)`
  font-size: 10px;
  vertical-align: middle;
`;
