/* eslint-disable import/no-extraneous-dependencies */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Container as MapDiv,
  NaverMap,
  Marker,
  useNavermaps,
} from 'react-naver-maps';

const reverseLatLngToAddress = (inputLatLng, setMarker) => {
  if (!inputLatLng.lat || !inputLatLng.lng) return;

  const navermaps = useNavermaps();

  navermaps.Service.reverseGeocode(
    {
      coords: new navermaps.LatLng(inputLatLng.lat, inputLatLng.lng),
    },
    (status, response) => {
      if (
        status === navermaps.Service.Status.ERROR ||
        response.v2.status.code !== 0
      ) {
        return;
      }
      const address = response.v2.results[0] || response.v2.results[1];
      const city = address.region.area1.name || '';
      const county = address.region.area2.name || '';
      const district = address.region.area3.name || '';

      setMarker({ city, county, district });
    },
  );
};

const reverseAddressToLatLng = (inputAddress, setMarker) => {
  const navermaps = useNavermaps();

  navermaps.Service.geocode({ query: inputAddress }, (status, response) => {
    if (status !== navermaps.Service.Status.OK) {
      return;
    }

    const result = response.v2.addresses[0];
    const coords = new navermaps.LatLng(result.y, result.x);
    setMarker({ lat: coords.lat(), lng: coords.lng() });
  });
};

const Map = ({ marker, setMarker }) => {
  const navermaps = useNavermaps();

  const handleMapClick = (e) => {
    const newMarker = {
      lat: e.coord.lat(),
      lng: e.coord.lng(),
    };
    setMarker(newMarker);
  };

  if (!marker) return <div>Loading...</div>;

  return (
    <NaverMap
      defaultCenter={new navermaps.LatLng(marker.lat, marker.lng)}
      defaultZoom={15}
      onClick={handleMapClick}
    >
      <Marker
        position={new navermaps.LatLng(marker.lat, marker.lng)}
        title={marker.title}
      />
    </NaverMap>
  );
};

const MyMap = forwardRef(
  ({ address, latLng, onSaveMap, setSelectLocation }, ref) => {
    const [marker, setMarker] = useState(
      address && !latLng.lat && !latLng.lng
        ? null
        : {
            lat: latLng.lat,
            lng: latLng.lng,
          },
    );

    const saveMap = () => {
      const { lat, lng } = marker;

      onSaveMap({ lat, lng });
      onSaveAddress();
    };

    const onSaveAddress = () =>
      reverseLatLngToAddress(marker, setSelectLocation);

    useImperativeHandle(ref, () => ({
      saveMap,
    }));

    useEffect(() => {
      if (address && !latLng.lat && !latLng.lng)
        reverseAddressToLatLng(address, setMarker);
    }, []);

    return (
      <MapDiv
        style={{
          width: '100%',
          aspectRatio: 1 / 1,
        }}
      >
        <Map marker={marker} setMarker={setMarker} />
      </MapDiv>
    );
  },
);

export default MyMap;
