import {
  StyledAdminInfoTableTitleWrapper,
  StyledAdminInfoTableTitleText,
} from './AdminInfo.styles';

const AdminInfoTableTitle = ({ totalUsers }) => {
  return (
    <StyledAdminInfoTableTitleWrapper>
      <StyledAdminInfoTableTitleText>
        하위 계정 목록
      </StyledAdminInfoTableTitleText>
      <StyledAdminInfoTableTitleText>
        {totalUsers}명
      </StyledAdminInfoTableTitleText>
    </StyledAdminInfoTableTitleWrapper>
  );
};

export default AdminInfoTableTitle;
