// src/pages/LoginPage/LoginPage.styles.js
import styled from 'styled-components';

export const StyledNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #f9fafa;
`;

export const StyledNotFoundWrapper = styled.div`
  padding: 80px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* background: #000000; */
  gap: 10px;
`;

export const StyledNotFoundLogo = styled.div`
  min-width: 500px;
  height: 100px;
  /* color: #ffffff; */
  font-size: 64px;
  font-family: 'Pretendard';
  font-weight: 800;
  line-height: 44px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  > svg {
    position: relative;
    height: 55px;
    background: #000000;
    padding: 6px;
    border-radius: 50px;
    width: 55px;
  }
`;

export const StyledNotFoundTitleWrapper = styled.div`
  font-size: 28px;
  font-family: 'Pretendard';
  font-weight: 700;
  line-height: 36px;
  word-wrap: break-word;
`;

export const StyledNotFoundTextWrapper = styled.div`
  font-size: 12px;
  font-family: 'Pretendard';
  line-height: 18px;
  word-wrap: break-word;
`;

export const StyledNotFoundButtonsWrapper = styled.div`
  width: 50%;
  height: 80px;
  display: flex;
  align-items: center;
  gap: 30px;
`;
