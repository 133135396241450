import EmptyList from '../../../../components/common/EmptyList/EmptyList';
import TableDataRow from '../../../../components/common/Table/TableDataRow';
import TableHeaderRow from '../../../../components/common/Table/TableHeaderRow';
import { StyledCommentVideoListTableWrapper } from './CommentVideoList.styles';

const CommentVideoListTable = ({
  keyValue,
  users,
  columns,
  selectedUserIds,
  onSelectAll,
  onSelectUser,
  onRowClick,
  onSort,
  onTitleClick,
  onPublisherClick,
  sortColumn,
  sortDirection,
  searchTitle,
}) => {
  const isAllSelected =
    users.length > 0 &&
    users.every((user) =>
      selectedUserIds.includes(keyValue ? user[keyValue] : user.id),
    );

  return (
    <StyledCommentVideoListTableWrapper>
      <TableHeaderRow
        columns={columns}
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
        onSort={onSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
      />
      {users.length ? (
        users.map((user) => (
          <TableDataRow
            key={keyValue ? user[keyValue] : user.id}
            data={user}
            columns={columns}
            onSelect={onSelectUser}
            isSelected={selectedUserIds.includes(
              keyValue ? user[keyValue] : user.id,
            )}
            onRowClick={() => onRowClick(user)}
            onTitleClick={onTitleClick}
            onPublisherClick={onPublisherClick}
          />
        ))
      ) : (
        <EmptyList title={searchTitle} />
      )}
    </StyledCommentVideoListTableWrapper>
  );
};

export default CommentVideoListTable;
