/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
// VideoDetailModal.jsx
import { useNavigate } from 'react-router-dom';
import CustomButton from '../Button/Button';
import {
  StyledVideoDetailModalWrapper,
  StyledVideoDetailModalHeader,
  StyledVideoDetailModalTitle,
  StyledVideoDetailModalCloseButton,
  StyledVideoDetailModalCloseButtonVector,
  StyledVideoDetailModalContent,
  StyledVideoDetailModalThumbnailWrapper,
  StyledVideoDetailModalInfoWrapper,
  StyledVideoDetailModalInfoTable,
  StyledVideoDetailModalInfoRow,
  StyledVideoDetailModalInfoHeader,
  StyledVideoDetailModalInfoCell,
  StyledVideoDetailModalDescriptionCell,
  StyledVideoDetailModalDescriptionRow,
  StyledVideoDetailModalTagCell,
  StyledVideoDetailModalStatsTable,
  StyledVideoDetailModalStatsHeader,
  StyledVideoDetailModalStatsHeaderCell,
  StyledVideoDetailModalStatsRow,
  StyledVideoDetailModalStatsCell,
  StyledVideoDetailModalOverlay,
  StyledVideoDetailModalThumbnailImage,
  StyledVideoPlayer,
  StyledVideoDetailModalDescriptionTable,
  StyledVideoDetailModalInfoLayout,
} from './VideoDetailModal.styles';

const VideoDetailModal = ({ isOpen, onClose, videoData }) => {
  const navigate = useNavigate();

  if (!isOpen || !videoData) return null;

  const {
    videoThumbnail,
    videoPath,
    timeline,
    createdAt,
    videoTitle,
    meetingLocation,
    meetingCount,
    meetingTime,
    isActive,
    tags,
    videoAddress,
    nickname,
    viewCount,
    likeCount,
    videoCommentCount,
    recvFeelkyRequestCount,
    reportCount,
    videoId,
  } = videoData;

  const handleSearchDetail = () => {
    onClose();
    navigate(`/PostManagement/PostManagementDetail/${videoId}?type=메인`);
  };

  return (
    <StyledVideoDetailModalOverlay onClick={onClose}>
      <StyledVideoDetailModalWrapper onClick={(e) => e.stopPropagation()}>
        <StyledVideoDetailModalHeader>
          <StyledVideoDetailModalTitle>영상 상세</StyledVideoDetailModalTitle>
          <StyledVideoDetailModalCloseButton onClick={onClose}>
            <StyledVideoDetailModalCloseButtonVector />
          </StyledVideoDetailModalCloseButton>
        </StyledVideoDetailModalHeader>
        <StyledVideoDetailModalContent>
          <StyledVideoDetailModalThumbnailWrapper>
            {videoPath ? (
              <StyledVideoPlayer controls>
                <source src={videoPath} type="video/mp4" />
                Your browser does not support the video tag.
              </StyledVideoPlayer>
            ) : (
              <StyledVideoDetailModalThumbnailImage
                src={videoThumbnail}
                alt="Video Thumbnail"
              />
            )}
          </StyledVideoDetailModalThumbnailWrapper>
          <StyledVideoDetailModalInfoLayout>
            <StyledVideoDetailModalInfoWrapper>
              <StyledVideoDetailModalInfoTable>
                <StyledVideoDetailModalInfoRow>
                  <StyledVideoDetailModalInfoHeader>
                    영상 구분
                  </StyledVideoDetailModalInfoHeader>
                  <StyledVideoDetailModalInfoCell>
                    메인영상
                  </StyledVideoDetailModalInfoCell>
                  <StyledVideoDetailModalInfoHeader>
                    영상 길이
                  </StyledVideoDetailModalInfoHeader>
                  <StyledVideoDetailModalInfoCell>
                    {timeline}
                  </StyledVideoDetailModalInfoCell>
                </StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoRow>
                  <StyledVideoDetailModalInfoHeader>
                    업로드 일시
                  </StyledVideoDetailModalInfoHeader>
                  <StyledVideoDetailModalInfoCell>
                    {createdAt}
                  </StyledVideoDetailModalInfoCell>
                </StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoRow>
                  <StyledVideoDetailModalInfoHeader>
                    타이틀
                  </StyledVideoDetailModalInfoHeader>
                  <StyledVideoDetailModalInfoCell>
                    {videoTitle}
                  </StyledVideoDetailModalInfoCell>
                </StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoRow>
                  <StyledVideoDetailModalInfoHeader>
                    설명 및 태그
                  </StyledVideoDetailModalInfoHeader>
                  <StyledVideoDetailModalDescriptionTable>
                    <StyledVideoDetailModalDescriptionRow>
                      <StyledVideoDetailModalDescriptionCell>
                        {meetingLocation}
                      </StyledVideoDetailModalDescriptionCell>
                      <StyledVideoDetailModalTagCell>
                        {meetingCount}
                      </StyledVideoDetailModalTagCell>
                    </StyledVideoDetailModalDescriptionRow>
                    <StyledVideoDetailModalDescriptionRow>
                      <StyledVideoDetailModalDescriptionCell>
                        {meetingTime}
                      </StyledVideoDetailModalDescriptionCell>
                      <StyledVideoDetailModalTagCell>
                        {tags}
                      </StyledVideoDetailModalTagCell>
                    </StyledVideoDetailModalDescriptionRow>
                  </StyledVideoDetailModalDescriptionTable>
                </StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoRow>
                  <StyledVideoDetailModalInfoHeader>
                    게시 상태
                  </StyledVideoDetailModalInfoHeader>
                  <StyledVideoDetailModalInfoCell>
                    {isActive}
                  </StyledVideoDetailModalInfoCell>
                </StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoRow>
                  <StyledVideoDetailModalInfoHeader>
                    게시 지역
                  </StyledVideoDetailModalInfoHeader>
                  <StyledVideoDetailModalInfoCell>
                    {videoAddress}
                  </StyledVideoDetailModalInfoCell>
                </StyledVideoDetailModalInfoRow>
                <StyledVideoDetailModalInfoRow>
                  <StyledVideoDetailModalInfoHeader>
                    게시자 닉네임
                  </StyledVideoDetailModalInfoHeader>
                  <StyledVideoDetailModalInfoCell>
                    {nickname}
                  </StyledVideoDetailModalInfoCell>
                </StyledVideoDetailModalInfoRow>
              </StyledVideoDetailModalInfoTable>
              <StyledVideoDetailModalStatsTable>
                <StyledVideoDetailModalStatsHeader>
                  <StyledVideoDetailModalStatsHeaderCell>
                    조회수
                  </StyledVideoDetailModalStatsHeaderCell>
                  <StyledVideoDetailModalStatsHeaderCell>
                    좋아요
                  </StyledVideoDetailModalStatsHeaderCell>
                  <StyledVideoDetailModalStatsHeaderCell>
                    영상댓글
                  </StyledVideoDetailModalStatsHeaderCell>
                  <StyledVideoDetailModalStatsHeaderCell>
                    필키요청
                  </StyledVideoDetailModalStatsHeaderCell>
                  <StyledVideoDetailModalStatsHeaderCell>
                    신고
                  </StyledVideoDetailModalStatsHeaderCell>
                </StyledVideoDetailModalStatsHeader>
                <StyledVideoDetailModalStatsRow>
                  <StyledVideoDetailModalStatsCell>
                    {viewCount}
                  </StyledVideoDetailModalStatsCell>
                  <StyledVideoDetailModalStatsCell>
                    {likeCount}
                  </StyledVideoDetailModalStatsCell>
                  <StyledVideoDetailModalStatsCell>
                    {videoCommentCount}
                  </StyledVideoDetailModalStatsCell>
                  <StyledVideoDetailModalStatsCell>
                    {recvFeelkyRequestCount}
                  </StyledVideoDetailModalStatsCell>
                  <StyledVideoDetailModalStatsCell>
                    {reportCount}
                  </StyledVideoDetailModalStatsCell>
                </StyledVideoDetailModalStatsRow>
              </StyledVideoDetailModalStatsTable>
            </StyledVideoDetailModalInfoWrapper>
            <CustomButton
              size="medium"
              $state="default"
              text="자세히 보기"
              $variant="search"
              onClick={handleSearchDetail}
            />
          </StyledVideoDetailModalInfoLayout>
        </StyledVideoDetailModalContent>
      </StyledVideoDetailModalWrapper>
    </StyledVideoDetailModalOverlay>
  );
};

export default VideoDetailModal;
