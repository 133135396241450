/* eslint-disable consistent-return */
/* eslint-disable radix */
// 계정 관리 > 관리자 정보 탭
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useModal from '../../../../hooks/useModal.js';

import Pagination from '../../../../components/common/Pagination/Pagination.jsx';
import Modal from '../../../../components/common/Modal/Modal';
import Dropdown from '../../../../components/common/DropDown/DropDown';
import CustomButton from '../../../../components/common/Button/Button';
import Badge from '../../../../components/common/Badge.jsx';

import AdminInfoTable from './AdminInfoTable';
import AdminInfoTableTitle from './AdminInfoTableTitle';

// import dummyUserListData from '../../../../data/dummyUserListData';
import {
  PAGE_SIZE_OPTIONS,
  // USER_TYPE_PRIORITY,
} from '../../../../constants/constants.js';

import {
  StyledAdminInfoContainer,
  StyledAdminInfoWrapper,
  StyledAdminInfoListWrapper,
  StyledAdminInfoTableTitleContainer,
  StyledAdminInfoButtonGroupWrapper,
} from './AdminInfo.styles';
import { formatDateYYYYMMDD } from '../../../../utils/formatDate.js';
import {
  deleteAccount,
  getAccountAdmins,
} from '../../../../apis/accountManagement/index.js';
import DeleteAccountModal from './DeleteAccountModal.jsx';

const UserRoleBadge = ({ value }) => {
  return <Badge type={value} />;
};

const AdminInfo = () => {
  // TODO: 나중에 가드 추가해주세요.
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get('page')) || 1;
  const pageSize = parseInt(searchParams.get('size')) || PAGE_SIZE_OPTIONS[0];

  const userInfo = useSelector((state) => state.user.user);

  const [users, setUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');

  const [failedDelte, setFailedDelete] = useState(false);

  const { data: accountAdmins, fetch: getAdmins } = getAccountAdmins();

  const handleSetSearchParams = ({ key, value }) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const handleSetSeatchParamsList = (list) => {
    const newSearchParams = new URLSearchParams(searchParams);
    list.forEach(({ key, value }) => newSearchParams.set(key, value));
    setSearchParams(newSearchParams);
  };

  const processData = (data) => {
    const groupedByLevel = data.reduce((acc, item) => {
      if (!acc[item.level]) {
        acc[item.level] = [];
      }
      acc[item.level].push(item);
      return acc;
    }, {});

    const sortedLevels = Object.keys(groupedByLevel).sort((a, b) => a - b);

    return sortedLevels.flatMap((level) => groupedByLevel[level]);
  };

  useEffect(() => {
    if (!accountAdmins) return;

    setUsers(
      processData(
        accountAdmins
          .filter((item) => item.level > userInfo.role)
          .map((item) => {
            return {
              ...item,
              id: item.accountUuid || '-',
              accessPermissionNames:
                item.accessPermissionNames.join(', ') || '-',
              updatedAt: formatDateYYYYMMDD(new Date(item.updatedAt)) || '-',
              createdAt: formatDateYYYYMMDD(new Date(item.createdAt)) || '-',
            };
          }),
      ),
    );
  }, [accountAdmins]);

  const { isLoading, fetch: fetchDeleteAccount } = deleteAccount({
    onSuccess: () => {
      getAdmins();
      setSelectedUserIds([]);
      closeModal();
      showModal('삭제 완료 되었습니다.', '', 'confirm');
    },
    onFailure: () => {
      setFailedDelete(true);
    },
  });

  const navigate = useNavigate();

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  const handleSort = useCallback(
    (column) => {
      if (column === sortColumn) {
        setSortDirection((prevDirection) =>
          prevDirection === 'asc' ? 'desc' : 'asc',
        );
      } else {
        setSortColumn(column);
        setSortDirection('asc');
      }
    },
    [sortColumn],
  );

  const columns = useMemo(
    () => [
      { key: 'name', label: '이름', width: '10%' },
      {
        key: 'level',
        label: '권한',
        width: '120px',
        component: UserRoleBadge,
      },
      { key: 'email', label: '이메일', width: '15%' },
      {
        key: 'accessPermissionNames',
        label: '접근 허용 범위',
        width: '45%',
      },
      { key: 'department', label: '담당파트', width: '10%' },
      {
        key: 'createdAt',
        label: '계정 생성일',
        width: '130px',
        sortable: true,
      },
    ],
    [],
  );

  const handleRowClick = useCallback(
    (id) => {
      navigate(`/AccountManagement/UserDetail${location.search}`, {
        state: { userData: id },
      });
    },
    [navigate],
  );

  const handleNewAdminClick = () => {
    navigate(`/AccountManagement/AddNewAdmin${location.search}`);
  };

  useEffect(() => {
    getAdmins();
  }, []);

  const handleDeleteClick = useCallback(() => {
    if (selectedUserIds.length === 0) {
      showModal('삭제할 계정을 선택해 주세요.', '', 'confirm');
    } else {
      showModal('삭제 하시겠습니까?', 'alert', 'alert');
    }
  }, [selectedUserIds, showModal]);

  const handleDeleteConfirm = useCallback(
    (password) => {
      // TODO: 실제 계정 삭제 API 호출 및 삭제 로직 구현으로 수정해주세요.
      // 여기
      console.log(password);
      fetchDeleteAccount({
        deleteAccountId: selectedUserIds,
      });
    },
    [selectedUserIds, closeModal, showModal],
  );

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageUserIds = currentPageUsers.map((user) => user.id);
      setSelectedUserIds(currentPageUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const handleSelectUser = useCallback((userId) => {
    setSelectedUserIds((prevSelectedUserIds) => {
      if (prevSelectedUserIds.includes(userId)) {
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
      return [...prevSelectedUserIds, userId];
    });
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      handleSetSearchParams({ key: 'page', value: page });
      setSelectedUserIds([]);
    },
    [searchParams],
  );

  const handlePageSizeChange = useCallback(
    (selectedOption) => {
      const selectedSize = PAGE_SIZE_OPTIONS.find(
        (size) => `${size}개씩 보기` === selectedOption,
      );
      if (selectedSize) {
        handleSetSeatchParamsList([
          { key: 'page', value: 1 },
          { key: 'size', value: selectedSize },
        ]);
        setSelectedUserIds([]);
      }
    },
    [searchParams],
  );

  const sortedUsers = useMemo(() => {
    return [...users].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn])
        return sortDirection === 'desc' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn])
        return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [users, sortColumn, sortDirection]);

  const currentPageUsers = useMemo(() => {
    return processData(sortedUsers).slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize,
    );
  }, [sortedUsers, currentPage, pageSize]);

  const modalContents = useMemo(() => {
    return modalContent === 'alert' ? (
      <DeleteAccountModal
        isLoading={isLoading}
        closeModal={closeModal}
        handleDeleteConfirm={handleDeleteConfirm}
        failedDelte={failedDelte}
        setFailedDelete={setFailedDelete}
      />
    ) : (
      modalContent
    );
  }, [modalContent, failedDelte, isLoading]);

  const modalActions = useMemo(() => {
    return (
      modalVariant !== 'alert' && (
        <CustomButton
          size="large"
          $state="default"
          text="확인"
          $variant={modalVariant}
          onClick={closeModal}
        />
      )
    );
  }, [modalVariant, closeModal]);

  return (
    <StyledAdminInfoContainer>
      <StyledAdminInfoWrapper>
        <StyledAdminInfoListWrapper>
          <StyledAdminInfoTableTitleContainer>
            <AdminInfoTableTitle totalUsers={users.length} />
            <StyledAdminInfoButtonGroupWrapper>
              <Dropdown
                options={PAGE_SIZE_OPTIONS.map((size) => `${size}개씩 보기`)}
                value={`${pageSize}개씩 보기`}
                selectedValue={`${pageSize}개씩 보기`}
                onOptionChange={handlePageSizeChange}
              />
              <CustomButton
                size="small"
                $state="default"
                text="계정 삭제"
                $variant="accountDelete"
                onClick={handleDeleteClick}
              />
              <CustomButton
                size="small"
                $state="default"
                text="계정 생성"
                $variant="accountCreate"
                onClick={handleNewAdminClick}
              />
            </StyledAdminInfoButtonGroupWrapper>
          </StyledAdminInfoTableTitleContainer>
          <AdminInfoTable
            users={currentPageUsers}
            columns={columns}
            selectedUserIds={selectedUserIds}
            onSelectAll={handleSelectAll}
            onSelectUser={handleSelectUser}
            onRowClick={handleRowClick}
            onSort={handleSort}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(users.length / pageSize)}
            onPageChange={handlePageChange}
          />
        </StyledAdminInfoListWrapper>
      </StyledAdminInfoWrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContents}
        actions={modalActions}
      />
    </StyledAdminInfoContainer>
  );
};

export default AdminInfo;
