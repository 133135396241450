/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
// src/pages/CustomerService/Notices/NoticeDetail.jsx
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageHeader from '../../../../components/common/PageHeader';
import CustomButton from '../../../../components/common/Button/Button';
import Modal from '../../../../components/common/Modal/Modal';

import useModal from '../../../../hooks/useModal';

import NoticeTemplate from './components/NoticeTemplate';
import {
  formatGroupCode,
  formatGroupFullCode,
  formatDateYYYYMMDD,
  formatDateTime,
  formatDataHHMMSS,
} from '../../../../utils/formatDate';
import { getNotice, putNotice } from '../../../../apis/notice';

const NoticeDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const viewTargetList = formatGroupCode(groupCodeList, 7021);
  const {
    isModalOpen,
    modalTitle,
    modalVariant,
    modalContent,
    showModal,
    closeModal,
  } = useModal();

  const [fetchData, setFetchData] = useState('');

  const [noticeData, setNoticeData] = useState();
  const { id } = useParams();

  getNotice({
    query: id,
    onSuccess: (value) => {
      const endTime = !!value.endDate && formatDataHHMMSS(value.endDate);
      const startTime = !!value.startDate && formatDataHHMMSS(value.startDate);

      setNoticeData({
        ...value,
        endTime,
        startTime,
      });
    },
  });

  const { fetch: fetchPutNotice } = putNotice({
    query: fetchData.queryString,
    onSuccess: () => {
      showModal('수정한 내용이 저장 되었습니다.', '', 'confirm');
    },
    onFailure: () => {
      showModal('오류', '변경에 실패하였습니다. 다시 시도해주세요.', 'alert');
    },
  });

  useEffect(() => {
    const { formData, queryString } = fetchData;
    if (!queryString) return;
    fetchPutNotice(formData);
  }, [fetchData]);

  // const formatTimeOption = (date) => {
  //   const initDate = new Date(date);
  //   if (initDate.toString() === 'Invalid Date') return date;

  //   let initHour = initDate.getHours();
  //   if (initHour > 12) initHour -= 12;
  //   else if (initHour === 0) initHour += 12;
  //   const initMinute = initDate.getMinutes();
  //   const formatDate = `${initDate.getHours() < 12 ? '오전' : '오후'} ${String(
  //     initHour,
  //   ).padStart(2, '0')}:${String(initMinute).padStart(2, '0')}`;

  //   return formatDate;
  // };

  const formatTotalMinutes = (value) => {
    const [period, time] = value.split(' ');
    const [hour, minute] = time.split(':').map(Number);

    const totalHour = period === '오후' ? hour + 12 : hour;

    return `${String(totalHour).padStart(2, '0')}:${String(minute).padStart(
      2,
      '0',
    )}:00`;
  };

  // const noticeData = dummyNoticeData.find(
  //   (notice) => notice.id === parseInt(id, 10),
  // );

  // useEffect(() => {
  //   getNotice();
  // }, []);

  if (!noticeData) {
    return <div>공지사항을 찾을 수 없습니다.</div>;
  }

  const handleCancel = () => {
    showModal(
      '수정을 취소 하시겠습니까?',
      '나가기 클릭시 입력하신 모든 내용이 삭제됩니다.',
      'returnPage',
    );
  };

  const handleReturnPage = () =>
    navigate(`/CustomerService/Notices${location.search}`, { replace: true });

  const handleSave = (data) => {
    if (
      data.title.trim() === '' ||
      data.contents.trim() === '' ||
      !data.startDate ||
      !data.startTime ||
      !data.endDate ||
      !data.endTime
    ) {
      showModal('내용을 입력해주세요', '', 'input');
    } else {
      setFetchData(handleCreateFetchData(data));
      // showModal('등록 완료', '새 공지사항이 등록되었습니다.', 'confirm');
    }
  };

  const handleCreateFetchData = (value) => {
    const {
      isActiveStatus,
      boardType,
      viewType,
      viewTarget,
      title,
      contents,
      startDate,
      startTime,
      endDate,
      endTime,
      fileUrl,
      popUpFile,
      bannerFile,
    } = value;

    const noticeId = id;
    const noticeIdQuery = `noticeId=${noticeId}`;

    const formData = new FormData();

    formData.append('file', fileUrl);
    formData.append('popUpFile', popUpFile);
    formData.append('bannerFile', bannerFile);

    const isActiveStatusQuery = `isActiveStatus=${isActiveStatus}`;

    const boardTypeQuery = `boardType=${boardType}`;

    const viewTypeCode = viewType[0] === '팝업' ? 702001 : 702002;
    const viewTypeQuery = `viewType=${viewTypeCode}`;

    const viewTargetCode = viewTargetList.codes.find(
      ({ codeName }) =>
        codeName.replace(/ /g, '') === viewTarget.replace(/ /g, ''),
    ).fullCode;
    const viewTargetQuery = `viewTarget=${viewTargetCode}`;

    const titleQuery = `title=${title}`;

    const endDateQuery = `endDate=${formatDateTime(endDate, endTime)}`;
    const startDateQuery = `startDate=${formatDateTime(startDate, startTime)}`;

    const contentsQuery = `contents=${contents}`;

    const queryString = [
      noticeIdQuery,
      viewTypeQuery,
      viewTargetQuery,
      titleQuery,
      contentsQuery,
      startDateQuery,
      endDateQuery,
      isActiveStatusQuery,
      boardTypeQuery,
    ]
      .filter(Boolean)
      .join('&');

    // if (!fileUrl) return { queryString };
    return { queryString, formData };
  };

  return (
    <StyledPostManagementContainer>
      <div>
        <PageHeader title="이벤트/공지사항 상세" />
        <StyledPostManagementWrapper>
          <NoticeTemplate
            noticeData={noticeData}
            isEditable
            onCancel={handleCancel}
            onSave={handleSave}
          />
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            title={modalTitle}
            content={<div style={{ textAlign: 'center' }}>{modalContent}</div>}
            actions={
              modalVariant === 'confirm' ? (
                <CustomButton
                  size="large"
                  $state="default"
                  text="확인"
                  $variant="confirm"
                  onClick={() => {
                    closeModal();
                    handleReturnPage();
                  }}
                />
              ) : modalVariant === 'alert' ? (
                <CustomButton
                  size="large"
                  $state="default"
                  text="확인"
                  $variant="confirm"
                  onClick={closeModal}
                />
              ) : modalVariant === 'returnPage' ? (
                <CustomButton
                  size="large"
                  $state="default"
                  text="확인"
                  $variant="confirm"
                  onClick={handleReturnPage}
                />
              ) : (
                <CustomButton
                  size="large"
                  $state="default"
                  text="확인"
                  $variant="alert"
                  onClick={closeModal}
                />
              )
            }
          />
        </StyledPostManagementWrapper>
      </div>
    </StyledPostManagementContainer>
  );
};
export default NoticeDetail;

const StyledPostManagementContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledPostManagementWrapper = styled.div`
  /* min-width: 926px; */
  width: auto;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  /* border: 1px solid #f1f2f4; */
  /* justify-content: flex-start; */
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
`;
