export const kpiStatsData = {
  1: [
    {
      label: '총 활성 유저수',
      count: '1,800명',
      change: '+5%',
      changeValue: '+0',
    },
    {
      label: '영상댓글 활성율',
      count: '1,800명',
      change: '+5%',
      changeValue: '+0',
    },
    {
      label: '필키요청 성공율',
      count: '1,800명',
      change: '-5%',
      changeValue: '-5',
    },
  ],
  7: [
    {
      label: '총 활성 유저수',
      count: '1,800명',
      change: '+5%',
      changeValue: '+0',
    },
    {
      label: '영상댓글 활성율',
      count: '1,800명',
      change: '+5%',
      changeValue: '+0',
    },
    {
      label: '필키요청 성공율',
      count: '1,800명',
      change: '-5%',
      changeValue: '-5',
    },
  ],
  30: [
    {
      label: '총 활성 유저수',
      count: '5,000명',
      change: '+10%',
      changeValue: '+500',
    },
    {
      label: '영상댓글 활성율',
      count: '4,500명',
      change: '+8%',
      changeValue: '+300',
    },
    {
      label: '필키요청 성공율',
      count: '4,000명',
      change: '-2%',
      changeValue: '-100',
    },
  ],
  60: [
    {
      label: '총 활성 유저수',
      count: '10,000명',
      change: '+15%',
      changeValue: '+1,500',
    },
    {
      label: '영상댓글 활성율',
      count: '9,000명',
      change: '+12%',
      changeValue: '+1,000',
    },
    {
      label: '필키요청 성공율',
      count: '8,500명',
      change: '-1%',
      changeValue: '-100',
    },
  ],
  180: [
    {
      label: '총 활성 유저수',
      count: '50,000명',
      change: '+20%',
      changeValue: '+10,000',
    },
    {
      label: '영상댓글 활성율',
      count: '45,000명',
      change: '+18%',
      changeValue: '+8,000',
    },
    {
      label: '필키요청 성공율',
      count: '40,000명',
      change: '+5%',
      changeValue: '+2,000',
    },
  ],
};

// TODO: API 연동 시 제거해 주세요!
export const InquiryAndReportStatsData = [
  // { label: '신규 문의', count: '1건' },
  // { label: '미처리 문의', count: '0건' },
  { label: '신규 신고', count: '2건' },
  { label: '미처리 신고', count: '0건' },
];
