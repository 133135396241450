/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-catch */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import TokenManager from '../../api/tokenManger';
import API from '../../api';

const initialState = {
  groupCodeList: [],
  isLoading: false,
  error: null,
};

export const asyncGetGroupCodeList = createAsyncThunk(
  'groupCode/asyncGetGroupCodeList',
  async () => {
    const tokenManager = new TokenManager();
    try {
      const response = await API('/group-code', {
        baseURL: process.env.REACT_APP_BASE_URL,
        withCredentials: true,
        headers: {
          Authorization:
            tokenManager.accessToken && `Bearer ${tokenManager.accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const groupCodeSlice = createSlice({
  name: 'groupCode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetGroupCodeList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncGetGroupCodeList.fulfilled, (state, action) => {
        state.groupCodeList = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncGetGroupCodeList.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      });
  },
});

export default groupCodeSlice.reducer;
