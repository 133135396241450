import styled from 'styled-components';
import { PiCheckBold } from 'react-icons/pi';

export const StyledChangeAccessGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 294px);
  grid-template-rows: repeat(3, 46px);
  grid-gap: 12px;
  /* width: 100%; */
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const StyledChangeAccessItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 12px;
  border-radius: 4px;
  border: 1px #dddfe3 solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  cursor: pointer;
`;

export const StyledChangeAccessItemContent = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`;

export const StyledChangeAccessItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  flex: ${({ $isFullWidth }) => ($isFullWidth ? '1 1 0' : 'initial')};
  align-self: ${({ $isFullWidth }) => ($isFullWidth ? 'stretch' : 'initial')};
`;

export const StyledChangeAccessItemIconWrapper = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
  }
`;

export const StyledChangeAccessItemText = styled.div`
  color: #434343;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
`;

export const StyledChangeAccessItemCheckboxWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const StyledChangeAccessItemCheckboxInput = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  padding: 0px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: #412cab;
    border-color: #412cab;
  }
`;

export const StyledChangeAccessItemCheckboxIcon = styled(PiCheckBold)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  visibility: ${({ $isChecked }) => ($isChecked ? 'visible' : 'hidden')};
  cursor: pointer;
`;
