/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import { useCallback, useMemo, useState } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import { PAGE_SIZE_OPTIONS } from '../../../../../../constants/constants';
import {
  StyledNoticeListButtonGroup,
  StyledNoticeListContainer,
  StyledNoticeListContents,
  StyledNoticeListInquiryTitle,
  StyledNoticeListTableTitleWrapper,
  StyledNoticeListWrapper,
} from './NoticeList.styles';
import NoticeListTableTitle from './NoticeListTitle';
import Pagination from '../../../../../../components/common/Pagination/Pagination';
import { getNoticeList } from '../../../../../../apis/notice';
import { formatDateYYMMDD } from '../../../../../../utils/formatDate';
import NoticeListTable from './NoticeListTable';
import CustomButton from '../../../../../../components/common/Button/Button';
import useModal from '../../../../../../hooks/useModal';
import Modal from '../../../../../../components/common/Modal/Modal';

const NoticeList = ({ noticeId, handleConectNotice }) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // console.log(noticeId);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [notice, setNotice] = useState([]);
  const [totalNotice, setTotalNotice] = useState(0);

  const [selectedNoticeId, setSelectedNoticeId] = useState(noticeId || '');
  const [selectedNotice, setSelectedNotice] = useState();

  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('DESC');

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  getNoticeList({
    query: {
      currentPage,
      pageSize,
      sortColumn,
      sortDirection,
    },
    onSuccess: (value) => {
      setNotice(
        value.list.map(({ ...item }) => {
          const startDateValue = formatDateYYMMDD(new Date(item.startDate));
          const endDateValue = item.endDate
            ? formatDateYYMMDD(new Date(item.endDate))
            : '종료';
          return {
            ...item,
            id: item.noticeId,
            alarmPeriod: `${startDateValue} ~ ${endDateValue}`,
            boardStatus: (
              <StyledNoticeListInquiryTitle
                $isInquiry={item.boardStatus !== '게시'}
              >
                {item.boardStatus}
              </StyledNoticeListInquiryTitle>
            ),
          };
        }),
      );
      setTotalNotice(value.totalCount);
    },
  });

  const handleConectClick = () => {
    showModal(
      '공지를 연결하시겠습니까?',
      '선택하신 공지로 상세 내용이 연결됩니다.',
      'conect',
    );
  };

  // const handleSetSearchParams = ({ key, value }) => {
  //   searchParams.set(key, value);
  //   setSearchParams(searchParams);
  // };

  const handleSort = useCallback(
    (column) => {
      if (column === sortColumn) {
        setSortDirection((prevDirection) =>
          prevDirection === 'ASC' ? 'DESC' : 'ASC',
        );
      } else {
        setSortColumn(column);
        setSortDirection('ASC');
      }
    },
    [sortColumn],
  );

  const columnsLabel = useMemo(
    () => ({
      boardStatus: '상태',
      boardType: '구분',
      alarmPeriod: '공지 기간',
      title: '공지 타이틀',
      createdByName: '생성자',
      viewTargetName: '공지 대상',
    }),
    [],
  );

  const columnsKey = useMemo(
    () => [
      { key: 'boardStatus', width: '80px' },
      { key: 'boardType', width: '80px' },
      { key: 'alarmPeriod', width: '180px' },
      { key: 'title', width: '45.60%' },
      { key: 'createdByName', width: '11.29%' },
      { key: 'viewTargetName', width: '11.29%' },
    ],
    [],
  );

  const columns = useMemo(
    () =>
      columnsKey.map(({ ...value }) => ({
        label: columnsLabel[value.key],
        ...value,
      })),
    [],
  );

  // const handleExcelDownload = (data) => {
  //   xlsxDownload(data, '회원 목록', '회원 목록 리스트');
  //   showModal('엑셀 다운로드가 완료되었습니다.', '', 'confirm');
  // };

  // const handleDownloadClick = useCallback(() => {
  //   if (selectedRowIds.length !== 0) setIsExcel(true);
  //   else
  //     showModal(
  //       '전체 다운로드',
  //       '전체 리스트를 엑셀로 다운로드 하시겠습니까?',
  //       'excelConfirm',
  //     );
  // }, [selectedRowIds, showModal]);

  // const handleDownloadConfirm = useCallback(() => {
  //   closeModal();
  // }, [closeModal]);

  // const handleSelectAll = (event) => {
  //   if (event.target.checked) {
  //     const currentPagePostIds = notice.map((post) => post.id);
  //     setSelectedNoticeIds(currentPagePostIds);
  //   } else {
  //     setSelectedNoticeIds([]);
  //   }
  // };

  const handleSelectNotice = useCallback(
    (noticeData) => {
      if (selectedNoticeId === noticeData.id) {
        setSelectedNoticeId('');
        setSelectedNotice(null);
      } else {
        setSelectedNoticeId(noticeData.id);
        setSelectedNotice(noticeData);
      }
      // setSelectedNoticeIds((prevselectedPostIds) => {
      //   if (prevselectedPostIds.includes(postId)) {
      //     return prevselectedPostIds.filter((id) => id !== postId);
      //   }
      //   return [postId];
      // });
    },
    [selectedNoticeId],
  );

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      // handleSetSearchParams({ key: 'page', value: page });
      setSelectedNoticeId('');
      // setSelectedNoticeIds([]);
    },
    [
      // searchParams
    ],
  );

  return (
    <StyledNoticeListContainer>
      <StyledNoticeListWrapper>
        <StyledNoticeListContents>
          <StyledNoticeListTableTitleWrapper>
            <NoticeListTableTitle totalUsers={totalNotice} />
            <StyledNoticeListButtonGroup>
              {/* <Dropdown
                options={PAGE_SIZE_OPTIONS.map((size) => `${size}개씩 보기`)}
                value={`${pageSize}개씩 보기`}
                selectedValue={`${pageSize}개씩 보기`}
                onOptionChange={handlePageSizeChange}
              /> */}
              <CustomButton
                size="small"
                $state="default"
                text="연결"
                $variant="fileSelect"
                onClick={handleConectClick}
              />
            </StyledNoticeListButtonGroup>
          </StyledNoticeListTableTitleWrapper>
          <NoticeListTable
            notices={notice}
            columns={columns}
            selectedNoticeIds={[selectedNoticeId]}
            onSelectNotice={handleSelectNotice}
            onRowClick={handleSelectNotice}
            onSort={handleSort}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalNotice / pageSize)}
            onPageChange={handlePageChange}
          />
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            content={modalContent}
            title={modalTitle}
            actions={
              modalVariant === 'conect' ? (
                <>
                  <CustomButton
                    size="large"
                    $state="default"
                    text="취소"
                    $variant="cancel"
                    onClick={closeModal}
                  />
                  <CustomButton
                    size="large"
                    $state="default"
                    text="확인"
                    $variant="confirm"
                    onClick={() =>
                      handleConectNotice({
                        id: selectedNoticeId,
                        data: selectedNotice,
                      })
                    }
                  />
                </>
              ) : (
                <CustomButton
                  size="large"
                  $state="default"
                  text="확인"
                  $variant="alert"
                  onClick={closeModal}
                />
              )
            }
          />
        </StyledNoticeListContents>
      </StyledNoticeListWrapper>
    </StyledNoticeListContainer>
  );
};

export default NoticeList;
