// KpiStats.jsx

import StatusCard from '../../../../components/common/StatusCard/StatusCard';
import { formatNumber } from '../../../../utils/formatDate';
import getDashboardVideoLoactionStatus from '../../../../apis/dashbord/getDashboardVideoLoactionStatus';

const RealTimeLocationPostStats = () => {
  const videoLoactionStatus = getDashboardVideoLoactionStatus();

  const formatVideoLocationStatus = (data) => {
    if (!data) return [];
    return data.map((locationData) => ({
      label: locationData.locationGroupName,
      count: `${formatNumber(locationData.count)}건`,
    }));
  };

  return (
    <StatusCard
      title="현재 게시 중인 카테고리별 게시물"
      data={formatVideoLocationStatus(videoLoactionStatus?.list)}
      isLoading={!videoLoactionStatus}
      skeletonCount={3}
    />
  );
};

export default RealTimeLocationPostStats;
