// 대시보드 > 회원현황 탭
import { useState } from 'react';

import { tooltipFormatterForNewPosts } from '../../../../utils/tooltipFormatters';

import Chart from '../../../../components/common/Chart/Chart';

import MiscPostStats from './MiscPostStats';
import RealTimeLocationPostStats from './RealTimeLocationPostStats';
import RealTimePostStats from './RealTimePostStats';

import {
  StyledPostStatusContainer,
  StyledPostStatusStatsWrapper,
  StyledPostStatusChartWrapper,
} from './PostStatus.styles';

import getDashboardVideoDailyCount from '../../../../apis/dashbord/getDashboardVideoDailyCount';

const PostStatus = () => {
  const [selectedPeriodForPost, setSelectedPeriodForPost] = useState(7);

  const videoDailyCount = getDashboardVideoDailyCount(selectedPeriodForPost);

  const handlePeriodChangeForPost = (period) => {
    setSelectedPeriodForPost(period);
  };

  return (
    <StyledPostStatusContainer>
      <StyledPostStatusStatsWrapper>
        <RealTimePostStats />
        <RealTimeLocationPostStats />
        <MiscPostStats />
      </StyledPostStatusStatsWrapper>
      <StyledPostStatusChartWrapper>
        <Chart
          title="일별 신규 등록 게시물 수"
          subtitle={`전주 메인영상: ${videoDailyCount?.mainVideoPreviousDayCount}건 / 댓글영상: ${videoDailyCount?.commentVideoPreviousDayCount}건`}
          data={
            videoDailyCount
              ? videoDailyCount.dailyVideoStatsDtoList.map(
                  ({
                    date,
                    newCommentVideoCount,
                    newMainVideoCount,
                    newCommentVideoPercentChange,
                    newMainVideoPercentChange,
                  }) => ({
                    date,
                    value: newMainVideoCount,
                    value2: newCommentVideoCount,
                    valuePercent: newMainVideoPercentChange,
                    value2Percent: newCommentVideoPercentChange,
                  }),
                )
              : []
          }
          xAxisKey="date"
          yAxisKey="value"
          tooltipFormatter={tooltipFormatterForNewPosts}
          period={selectedPeriodForPost}
          onPeriodChange={handlePeriodChangeForPost}
          showDropdown
          dateFormat="MM.DD"
          containerStyle={{ height: '334px' }}
          hasSecondLineColor="#F76659"
        />
      </StyledPostStatusChartWrapper>
    </StyledPostStatusContainer>
  );
};

export default PostStatus;
