/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { PiX } from 'react-icons/pi';

export const StyledRegionCategoryList = styled.div`
  min-width: 289px;
  padding: 20px;
  height: 550px;
  background: #e9e9e9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 20px;
`;

export const StyledButtonGroup = styled.div`
  width: 100%;
  min-width: 152px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 6px;
`;

export const StyledCategoryList = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  /* padding: grid; */
  /* overflow: scroll; */
`;

export const StyledCategoryItem = styled.div`
  /* width: 100%; */
  width: 245px;
  height: 34px;
  padding: 16px;
  margin: 0 0 8px 0;
  background: white;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export const StyledCategoryText = styled.div`
  flex: 1;
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

export const StyledPrimaryText = styled.div`
  max-width: 135px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ $isPlaceholder }) => ($isPlaceholder ? '#9D9D9D' : '#434343')};
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
`;

export const StyledXIcon = styled(PiX)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
