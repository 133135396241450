/* eslint-disable no-unused-vars */
import { useState } from 'react';
import Dropdown from '../../../../../components/common/DropDown/DropDown';
import CustomButton from '../../../../../components/common/Button/Button';

import {
  StyledContainer,
  StyledTable,
  StyledDropdownWrapper,
  StyledInputField,
  StyledInputFieldWithHeight,
  StyledInputFieldText,
  StyledRadioOption,
  StyledRadioWrapper,
  StyledRadioLabel,
  StyledFilterInputFieldText,
  StyledFilterTextArea,
  StyledButtonGroup,
} from '../AlarmTemplate.styles';

import PopupNotification from './PopupNotification';
import PushNotification from './PushNotification';

import AlarmTemplateTableCell from './AlarmTemplateTableCell';

import { formatDateYYYYMMDD } from '../../../../../utils/formatDate';
import ConectNoticeId from '../ConectNoticeId';
import AlarmTemplateDatePicker from './AlarmTemplateDatePicker';
import AlarmTemplateDayPicker from './AlarmTemplateDayPicker';
import AlarmTemplateFileUpload from './AlarmTemplateFileUpload';
import AlarmTemplateRadioBtn from './AlarmTemplateRadioBtn';
import AlarmTemplateRow from './AlarmTemplateRow';
import AlarmTemplateSetLink from './AlarmTemplateSetLink';

const AlarmTemplateForm = ({
  viewType,
  handleChangeViewType,
  viewTarget,
  setViewTarget,
  title,
  handleTitleChange,
  shortContents,
  handleShortContentsChange,
  startDate,
  setStartDate,
  startTime,
  setStartTime,
  endDate,
  setEndDate,
  endTime,
  setEndTime,
  isPush,
  handleChangeIsPush,
  noticeId,
  handleUpdateNoticeId,
  noticeTitle,
  handleUpdateNoticeTitle,
  selectedFile,
  handleFileChange,
  handleFileDelete,
  isEditable,
  onCancel,
  cancelButtonText,
  handleCancel,
  onSave,
  saveButtonText,
  showDatePicker,
  setShowDatePicker,
  hanldeDayChange,
  handleDateChange,
  handleConfirm,
}) => {
  // const options = ['모든 회원', '샐럽 회원'];
  const renderTimeItem = (option) => <div>{option}</div>;

  const timeOptions = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2);
    const minute = i % 2 === 0 ? '00' : '30';
    const period = hour < 12 ? '오전' : '오후';
    const formattedHour = hour % 12 || 12;
    return `${period} ${formattedHour}:${minute}`;
  });
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);

  const formatTimeObject = (timeObj) => {
    if (!timeObj) return null;

    let { hour } = timeObj;
    const minute = String(timeObj.minute).padStart(2, '0');
    let period = '오전';

    if (hour >= 12) {
      period = '오후';
      if (hour > 12) {
        hour -= 12;
      }
    } else if (hour === 0) {
      hour = 12;
    }

    const formattedHour = String(hour).padStart(2, '0');

    return `${period} ${formattedHour}:${minute}`;
  };

  const parseTimeString = (timeStr) => {
    const regex = /(오전|오후) (\d{1,2}):(\d{2})/;
    const match = timeStr.match(regex);

    if (!match) {
      throw new Error('Invalid time format');
    }

    const period = match[1];
    let hour = parseInt(match[2], 10);
    const minute = parseInt(match[3], 10);

    if (period === '오전') {
      if (hour === 12) {
        hour = 0;
      }
    } else if (hour !== 12) {
      hour += 12;
    }

    return { hour, minute };
  };

  const handleChangeEndTime = (value) => {
    setEndTime(parseTimeString(value));
  };

  const handleChangeStartTime = (value) => {
    setStartTime(parseTimeString(value));
  };

  return (
    <>
      <StyledContainer>
        <StyledTable>
          <AlarmTemplateRow>
            <div style={{ display: 'flex' }}>
              <AlarmTemplateTableCell isHeader isFirstRow>
                알림 대상
              </AlarmTemplateTableCell>
              <AlarmTemplateTableCell minWidth="50%">
                <StyledDropdownWrapper>
                  모든 회원
                  {/* <Dropdown
                  options={options}
                  selectedValue={viewTarget}
                  onOptionChange={(option) =>
                    isEditable && setViewTarget(option)
                  }
                  minWidth="143px"
                  maxHeight="100px"
                  height="28px"
                  fontSize="13px"
                /> */}
                </StyledDropdownWrapper>
              </AlarmTemplateTableCell>
              <AlarmTemplateTableCell isHeader isRequired>
                발송 형태
              </AlarmTemplateTableCell>
              <AlarmTemplateTableCell minWidth="219px">
                <StyledRadioOption>
                  <StyledRadioWrapper onClick={() => handleChangeIsPush(true)}>
                    <AlarmTemplateRadioBtn checked={isPush} />
                    <StyledRadioLabel>포함</StyledRadioLabel>
                  </StyledRadioWrapper>
                  <StyledRadioWrapper onClick={() => handleChangeIsPush(false)}>
                    <AlarmTemplateRadioBtn checked={!isPush} />
                    <StyledRadioLabel>미포함</StyledRadioLabel>
                  </StyledRadioWrapper>
                </StyledRadioOption>

                {/* {viewType.includes('알림') && (
                <StyledCheckbox
                  onClick={isEditable ? handleCelebCheckboxChange : undefined}
                >
                  <StyledCheckboxInput
                    type="checkbox"
                    checked={isCelebChecked}
                    readOnly
                    disabled={!isEditable}
                  />
                  <StyledCheckboxIcon $isChecked={isCelebChecked} />
                  <StyledCheckboxLabel>푸시 포함</StyledCheckboxLabel>
                </StyledCheckbox>
              )} */}
              </AlarmTemplateTableCell>
            </div>
          </AlarmTemplateRow>

          <AlarmTemplateRow>
            <AlarmTemplateTableCell isHeader height="40px" isRequired>
              발송 일자
            </AlarmTemplateTableCell>
            <AlarmTemplateTableCell height="40px">
              <StyledInputField
                height="30px"
                onClick={() => isEditable && setShowDatePicker(true)}
              >
                <StyledInputFieldText>
                  {formatDateYYYYMMDD(startDate)}
                </StyledInputFieldText>
              </StyledInputField>
              <StyledDropdownWrapper>
                <Dropdown
                  options={timeOptions}
                  selectedValue={
                    formatTimeObject(startTime) || (
                      <div style={{ color: '#9D9D9D' }}>발송 시간</div>
                    )
                  }
                  onOptionChange={handleChangeStartTime}
                  renderItem={renderTimeItem}
                  minWidth="100px"
                  maxHeight="100px"
                  height="30px"
                  fontSize="12px"
                  disabled={!isEditable}
                />
              </StyledDropdownWrapper>
              {viewType.includes('팝업') && (
                <>
                  <div>~</div>
                  <StyledInputField
                    height="30px"
                    onClick={() => isEditable && setShowDatePicker(true)}
                  >
                    <StyledInputFieldText>
                      {formatDateYYYYMMDD(endDate)}
                    </StyledInputFieldText>
                  </StyledInputField>
                  <StyledDropdownWrapper>
                    <Dropdown
                      options={timeOptions}
                      selectedValue={
                        formatTimeObject(endTime) || (
                          <div style={{ color: '#9D9D9D' }}>종료 시간</div>
                        )
                      }
                      onOptionChange={handleChangeEndTime}
                      renderItem={renderTimeItem}
                      minWidth="90px"
                      maxHeight="100px"
                      height="30px"
                      fontSize="12px"
                      disabled={!isEditable}
                    />
                  </StyledDropdownWrapper>
                </>
              )}
            </AlarmTemplateTableCell>
          </AlarmTemplateRow>

          {viewType.includes('팝업') && showDatePicker && (
            <AlarmTemplateDatePicker
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              onCancel={handleCancel}
              onConfirm={handleConfirm}
              setShowDatePicker={setShowDatePicker}
              showDatePicker={showDatePicker}
            />
          )}
          {viewType.includes('알림') && showDatePicker && (
            <AlarmTemplateDayPicker
              startDate={startDate}
              onChange={hanldeDayChange}
              onCancel={handleCancel}
              onConfirm={handleConfirm}
              setShowDatePicker={setShowDatePicker}
              showDatePicker={showDatePicker}
            />
          )}

          <AlarmTemplateRow>
            <AlarmTemplateTableCell isHeader isRequired>
              타이틀
            </AlarmTemplateTableCell>
            <AlarmTemplateTableCell>
              <StyledInputField width="592px">
                <StyledFilterInputFieldText
                  value={title}
                  onChange={handleTitleChange}
                  placeholder="타이틀을 입력하여 주세요."
                  disabled={!isEditable}
                  maxLength={20}
                />
              </StyledInputField>
            </AlarmTemplateTableCell>
          </AlarmTemplateRow>
          <AlarmTemplateRow>
            <AlarmTemplateTableCell
              isHeader
              height="175px"
              isAlignStart
              isRequired
            >
              요약 설명
            </AlarmTemplateTableCell>
            <AlarmTemplateTableCell height="175px">
              <StyledInputFieldWithHeight width="592px">
                <StyledFilterTextArea
                  value={shortContents}
                  onChange={handleShortContentsChange}
                  placeholder="푸시알림에 표시 될 요약 설명을 입력하여 주세요."
                  disabled={!isEditable}
                  maxLength={50}
                />
              </StyledInputFieldWithHeight>
            </AlarmTemplateTableCell>
          </AlarmTemplateRow>
          <AlarmTemplateRow>
            <AlarmTemplateTableCell isHeader>이미지</AlarmTemplateTableCell>
            <AlarmTemplateFileUpload
              selectedFile={selectedFile}
              onFileChange={isEditable ? handleFileChange : undefined}
              onFileDelete={isEditable ? handleFileDelete : undefined}
              disabled={!isEditable}
            />
          </AlarmTemplateRow>
          <AlarmTemplateRow>
            <AlarmTemplateTableCell isHeader>
              상세 내용 연결
            </AlarmTemplateTableCell>
            <AlarmTemplateSetLink
              noticeTitle={noticeTitle}
              handleOpenModal={handleOpenModal}
              handleDeleteConectedId={() => {
                handleUpdateNoticeId('');
                handleUpdateNoticeTitle('');
              }}
              handleUpdateNoticeId={handleUpdateNoticeId}
            />
          </AlarmTemplateRow>
        </StyledTable>
        <ConectNoticeId
          openModal={openModal}
          onClose={() => setOpenModal(false)}
          noticeId={noticeId}
          handleUpdateNoticeId={handleUpdateNoticeId}
          handleUpdateNoticeTitle={handleUpdateNoticeTitle}
        />
        {viewType.includes('알림') ? (
          <PushNotification
            title={title}
            content={shortContents}
            selectedFile={selectedFile}
          />
        ) : (
          <PopupNotification selectedFile={selectedFile} />
        )}
      </StyledContainer>
      <StyledButtonGroup>
        {isEditable && (
          <>
            <CustomButton
              size="medium"
              $state="default"
              $variant="systemReset"
              text={cancelButtonText}
              onClick={onCancel}
            />
            <CustomButton
              size="medium"
              $state="default"
              $variant="systemSave"
              text={saveButtonText}
              onClick={onSave}
            />
          </>
        )}
      </StyledButtonGroup>
    </>
  );
};

export default AlarmTemplateForm;
