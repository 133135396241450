/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
// 계정 관리 페이지

import AdminInfo from './components/AdminInfo/AdminInfo';

import { StyledAccountManagementContents } from './AccountManagement.styles';
import PageHeader from '../../components/common/PageHeader';

const AccountManagement = () => {
  return (
    <div>
      <PageHeader title="계정관리" />
      <StyledAccountManagementContents>
        <AdminInfo />
      </StyledAccountManagementContents>
    </div>
  );
};

export default AccountManagement;
