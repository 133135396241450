/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import PageHeader from '../../../components/common/PageHeader';
import PostInfo from './PostInfo';
import CommentVideoList from './CommentVideoList/CommentVideoList';

const StyledUUserManagementDetailContainer = styled.div``;
const StyledUserManagementDetailWrapper = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;

const PostManagementDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = new URLSearchParams(location.search);

  const { id } = useParams();
  const type = queryParams.get('type');
  const defaultListType = useMemo(() => {
    return type === '메인' ? 'videoCommentCount' : 'likeCount';
  }, [type]);
  const listType = queryParams.get('listType') || defaultListType;

  const handleSetSearchParams = ({ key, value }) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const setListType = (value) =>
    handleSetSearchParams({ key: 'listType', value });

  // const [listType, setListType] = useState(defaultListType);

  const handleReturnPageClick = useCallback(() => {
    const fullPath = new URLSearchParams(location.search).get('fullPath');
    const returnPageURI = decodeURIComponent(fullPath).toString();

    navigate(returnPageURI);
  }, []);

  return (
    <StyledUUserManagementDetailContainer>
      <PageHeader title="게시물 상세 관리" returnPage={handleReturnPageClick} />
      <StyledUserManagementDetailWrapper>
        <PostInfo postId={id} postType={type} setListType={setListType} />
        <CommentVideoList
          postId={id}
          postType={type === '메인' ? 'MAIN' : 'COMMENT'}
          listType={listType}
        />
      </StyledUserManagementDetailWrapper>
    </StyledUUserManagementDetailContainer>
  );
};

export default PostManagementDetail;
