/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import CustomDatePicker from '../../../components/common/DatePicker/CustomDatePicker';
import Dropdown from '../../../components/common/DropDown/DropDown';
import CustomButton from '../../../components/common/Button/Button';
import useModal from '../../../hooks/useModal';
import Modal from '../../../components/common/Modal/Modal';

import {
  formatDateHHMM,
  formatDateYYYYMMDD,
  formatDateYYYYMMDDWHHMMSS,
  formatEndTime,
  formatYYYYMMDDHHMMSS,
} from '../../../utils/formatDate';
import {
  getVideoDetailCommentVideo,
  getVideoDetail,
  putVideoCommentStatus,
  putVideoStatus,
} from '../../../apis/video';
import {
  VIDEO_ACTIVE_STATUS,
  VIDEO_HIDDEN_STATUS,
  VIDEO_STATUS,
} from '../../../constants/groupCode';
import { getGroupCode } from '../../../utils/groupCode';

const PostInfo = ({ postId, postType, setListType }) => {
  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const activeStatusList = getGroupCode(
    groupCodeList,
    VIDEO_STATUS,
  ).codes.filter(
    ({ fullCode }) =>
      Number(fullCode) === VIDEO_ACTIVE_STATUS ||
      Number(fullCode) === VIDEO_HIDDEN_STATUS,
  );
  const statusOptions = activeStatusList.map(({ codeName }) => codeName);

  const statusOptionsValue = activeStatusList.reduce((acc, item) => {
    acc[Number(item.fullCode)] = item.codeName;
    acc[item.codeName] = Number(item.fullCode);
    return acc;
  }, {});

  const videoStatsForm = {
    viewCount: '조회수',
    likeCount: '좋아요',
    videoCommentCount: '영상댓글',
    recvFeelkyRequestCount: '필키요청',
    reportCount: '신고',
  };

  const [isFix, setIsFix] = useState(false);
  const [detailValue, setDetailValue] = useState({});
  const [activeStatus, setActiveStatus] = useState();
  const [initialActiveStatus, setInitialActiveStatus] = useState();

  const [changeTime, setChaneTime] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedEndTime, setSelectedEndTime] = useState('');

  const [initialDate, setInitialDate] = useState(new Date());
  const [initialEndTime, setInitialEndTime] = useState('');

  const datePickerRef = useRef(null);
  const { fetch: fetchGetVideoDetail } = getVideoDetail({
    query: postId,
    isInitialRequest: false,
    onSuccess: (value) => {
      const {
        isActiveStatus,
        videoEndTime,
        createdAt,
        tags,
        timeline,
        meetingCount,
        meetingTime,
        viewCount,
        likeCount,
        videoCommentCount,
        recvFeelkyRequestCount,
        reportCount,
        meetingLocation,
      } = value;
      setActiveStatus(isActiveStatus);
      setInitialActiveStatus(isActiveStatus);

      setIsFix(false);

      const initDate = new Date(videoEndTime);
      setSelectedDate(initDate);
      setInitialDate(initDate);
      let initHour = initDate.getHours();

      if (initHour > 12) initHour -= 12;
      else if (initHour === 0) initHour += 12;
      const initMinute = initDate.getMinutes();
      const endTime = `${initDate.getHours() < 12 ? '오전' : '오후'} ${String(
        initHour,
      ).padStart(2, '0')}:${String(initMinute).padStart(2, '0')}`;

      setSelectedEndTime(endTime);
      setInitialEndTime(endTime);
      const videoTimeToEndData = formatEndTime(videoEndTime);

      const stats = {
        viewCount,
        likeCount,
        videoCommentCount,
        recvFeelkyRequestCount,
        reportCount,
      };

      const isSelectList = Object.entries({
        likeCount,
        videoCommentCount,
        recvFeelkyRequestCount,
      }).map(([key]) => key);

      setDetailValue({
        ...value,
        createdAt: formatDateYYYYMMDDWHHMMSS(createdAt),
        meetingTime: meetingTime ? `${formatDateHHMM(meetingTime)}` : '-',

        meetingCount: meetingCount ? `${meetingCount}명` : '-',
        tags: tags ? tags.replace(/,#/g, ' #') : '-',
        timeline: `${timeline}초`,
        meetingLocation: meetingLocation || '-',
        videoTimeToEnd: videoTimeToEndData
          ? `${videoTimeToEndData.hour}시간 ${videoTimeToEndData.minute}분 후 종료`
          : '-',
        videoStartTime: (
          <div style={{ color: '#9D9D9D' }}>
            {`( ${formatYYYYMMDDHHMMSS(createdAt)} ${isActiveStatus}됨 )`}
          </div>
        ),
        // videoEndTime: (
        //   <div style={{ color: '#9D9D9D' }}>
        //     {`( ${formatYYYYMMDDHHMMSS(videoEndTime)} ${isActiveStatus}됨 )`}
        //   </div>
        // ),
        isActiveStatus,
        stats: Object.entries(stats).map(([key, item]) => ({
          key,
          name: videoStatsForm[key],
          value: item,
          onClick: isSelectList.includes(key),
        })),
      });
    },
  });

  const { fetch: fetchGetVideoDetailCommentVideo } = getVideoDetailCommentVideo(
    {
      query: postId,
      isInitialRequest: false,
      onSuccess: (value) => {
        const {
          isActiveStatus,
          videoEndTime,
          createdAt,
          timeline,
          viewCount,
          likeCount,
          recvFeelkyRequestCount,
          reportCount,
        } = value;

        setActiveStatus(isActiveStatus);
        setInitialActiveStatus(isActiveStatus);

        const videoTimeToEndData = formatEndTime(videoEndTime);

        const stats = {
          viewCount,
          likeCount,
          recvFeelkyRequestCount,
          reportCount,
        };

        const isSelectList = Object.entries({
          likeCount,
          recvFeelkyRequestCount,
        }).map(([key]) => key);

        setDetailValue({
          ...value,
          createdAt: formatDateYYYYMMDDWHHMMSS(createdAt),
          timeline: `${timeline}초`,
          videoTimeToEnd: videoTimeToEndData
            ? `${videoTimeToEndData.hour}시간 ${videoTimeToEndData.minute}분 후 종료`
            : '-',
          videoStartTime: (
            <div style={{ color: '#9D9D9D' }}>
              {`( ${formatYYYYMMDDHHMMSS(createdAt)} 종료됨 )`}
            </div>
          ),
          stats: Object.entries(stats).map(([key, item]) => ({
            key,
            name: videoStatsForm[key],
            value: item,
            onClick: isSelectList.includes(key),
          })),
        });
      },
    },
  );

  const { fetch: fetchPutVideoStatus } = putVideoStatus({
    query: {
      postId,
      activeStatus: statusOptionsValue[activeStatus],
      changeTime,
    },
    onSuccess: () => fetchGetVideoDetail(),
  });

  const { fetch: fetchPutVideoCommentStatus } = putVideoCommentStatus({
    query: {
      postId,
      activeStatus: statusOptionsValue[activeStatus],
    },
    onSuccess: () => fetchGetVideoDetailCommentVideo(),
  });

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  useEffect(() => {
    setInitialDate(selectedDate);
    setInitialEndTime(selectedEndTime);
    if (postType === '메인') fetchGetVideoDetail();
    else if (postType === '댓글') fetchGetVideoDetailCommentVideo();
  }, [postId, postType, setListType]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChangeListType = (type) => setListType(type);

  const handleDateChange = (date) => {
    if (initialDate.getTime() > date.getTime()) return;
    setSelectedDate(date);
  };

  const handleInputClick = () => {
    setShowDatePicker(true);
  };

  const handleConfirm = () => {
    setShowDatePicker(false);
  };

  const handleChangeIsFix = () => setIsFix((prev) => !prev);

  const handleModalConfirm = () => {
    showModal(
      '변경 내용 저장',
      '현재 변경 내용을 저장하시겠습니까?',
      'confirm',
    );
  };

  const formatTotalMinutes = (value) => {
    const [period, time] = value.split(' ');
    const [hour, minute] = time.split(':').map(Number);

    // 오전과 오후에 따라 시간을 조정합니다.
    let totalMinutes = hour * 60 + minute;
    if (period === '오후' && hour < 12) {
      totalMinutes += 12 * 60; // 오후일 경우 12시간을 더합니다.
    } else if (period === '오전' && hour === 12) {
      totalMinutes -= 12 * 60; // 오전 12시는 0시로 변경합니다.
    }
    return totalMinutes;
  };
  const handleOptionChange = (value) => {
    setSelectedEndTime(value);
  };

  const handleChangeConfirm = () => {
    if (postType === '메인') fetchPutVideoStatus();
    else fetchPutVideoCommentStatus();
    closeModal();
  };
  useEffect(() => {
    if (!selectedEndTime || !selectedDate) return;
    const date = new Date(selectedDate);
    const [period, time] = selectedEndTime.split(' ');

    const [hour, minute] = time.split(':');

    let formattedHour = parseInt(hour, 10);
    if (period === '오후') {
      formattedHour += 12;
    }
    const formattedString = `${String(formattedHour).padStart(
      2,
      '0',
    )}:${minute}:00`;

    setChaneTime(`${formatDateYYYYMMDD(date, '-')}T${formattedString}`);
  }, [selectedEndTime, selectedDate]);

  const renderTimeItem = (option) => <div>{option}</div>;

  const timeOptions =
    initialEndTime &&
    Array.from({ length: 48 }, (_, i) => {
      const hour = Math.floor(i / 2);
      const minute = i % 2 === 0 ? '00' : '30';
      const period = hour < 12 ? '오전' : '오후';
      const formattedHour = hour % 12 || 12;
      return `${period} ${formattedHour}:${minute}`;
    }).filter((value) => {
      const initialToatalMinutes = formatTotalMinutes(initialEndTime);
      const totalMinutes = formatTotalMinutes(value);

      return (
        initialDate.getTime() !== selectedDate.getTime() ||
        totalMinutes > initialToatalMinutes
      );
    });

  const renderStatusButton = (status) => {
    if (status === '게시') {
      return (
        <StyledStatusContainer
          onClick={() => setActiveStatus(status)}
          key={status}
        >
          <StyledRadioButton>
            {activeStatus === status ? (
              <>
                <StyledSelectedBackground />
                <StyledSelectedIcon />
              </>
            ) : (
              <StyledUnselectedBackground />
            )}
          </StyledRadioButton>
          <StyledRadioLabel>{status}</StyledRadioLabel>
        </StyledStatusContainer>
      );
    }

    return (
      <StyledStatusContainer
        onClick={() => setActiveStatus(status)}
        key={status}
      >
        <StyledRadioButton>
          {activeStatus !== '게시' ? (
            <>
              <StyledSelectedBackground />
              <StyledSelectedIcon />
            </>
          ) : (
            <StyledUnselectedBackground />
          )}
        </StyledRadioButton>
        <StyledRadioLabel>{status}</StyledRadioLabel>
      </StyledStatusContainer>
    );
  };

  return (
    <>
      <StyledPostInfoTitle>게시물 기본 정보</StyledPostInfoTitle>

      <StyledPostInfoContainer>
        <StyledPostInfoThumbnailWrapper>
          {detailValue.videoPath ? (
            <StyledVideoPlayer controls>
              <source src={detailValue.videoPath} type="video/mp4" />
              Your browser does not support the video tag.
            </StyledVideoPlayer>
          ) : (
            <StyledVideoThumbnail
              src={detailValue.videoThumbnail}
              alt="Video Thumbnail"
            />
          )}
        </StyledPostInfoThumbnailWrapper>

        <StyledVideoInfoWrapper>
          <StyledVideoInfoTable>
            <StyledVideoInfoRow>
              <StyledVideoInfoHeader $topLeft>영상 구분</StyledVideoInfoHeader>
              <StyledVideoInfoCell>{postType}영상</StyledVideoInfoCell>

              <StyledVideoInfoHeader>영상 길이</StyledVideoInfoHeader>
              <StyledVideoInfoCell>{detailValue.timeline}</StyledVideoInfoCell>
            </StyledVideoInfoRow>

            <StyledVideoInfoRow>
              <StyledVideoInfoHeader>게시 상태</StyledVideoInfoHeader>
              <StyledVideoInfoCell>
                {isFix ? (
                  <>
                    {statusOptions.map((status) => renderStatusButton(status))}
                  </>
                ) : (
                  <>
                    {detailValue.isActiveStatus} {detailValue.videoStartTime}
                  </>
                )}
              </StyledVideoInfoCell>
              {isFix && detailValue.videoType === 'MAIN' ? (
                <>
                  <StyledVideoInfoHeader>종료 일시</StyledVideoInfoHeader>
                  <StyledVideoInfoCell>
                    <StyledDatePickerContainer>
                      <StyledDatePickerInput onClick={handleInputClick}>
                        <StyledDatePickerInputText>
                          {formatDateYYYYMMDD(selectedDate)}
                        </StyledDatePickerInputText>
                      </StyledDatePickerInput>
                      {showDatePicker && (
                        <StyledDatePickerWrapper ref={datePickerRef}>
                          <CustomDatePicker
                            selectedDate={selectedDate}
                            onChange={handleDateChange}
                            onCancel={() => setShowDatePicker(false)}
                            onConfirm={handleConfirm}
                            disabled={false}
                          />
                        </StyledDatePickerWrapper>
                      )}
                    </StyledDatePickerContainer>
                    <StyledShutdownDropdownWrapper>
                      <Dropdown
                        options={timeOptions}
                        selectedValue={selectedEndTime}
                        onOptionChange={handleOptionChange}
                        renderItem={renderTimeItem}
                        minWidth="99px"
                        maxHeight="100px"
                        height="24px"
                        fontSize="12px"
                      />
                    </StyledShutdownDropdownWrapper>
                  </StyledVideoInfoCell>
                </>
              ) : null}
            </StyledVideoInfoRow>

            <StyledVideoInfoRow>
              <StyledVideoInfoHeader>업로드 일시</StyledVideoInfoHeader>
              <StyledVideoInfoCell>{detailValue.createdAt}</StyledVideoInfoCell>
              <StyledVideoInfoHeader>남은 시간</StyledVideoInfoHeader>
              <StyledVideoInfoCell>
                {detailValue.videoTimeToEnd}
              </StyledVideoInfoCell>
            </StyledVideoInfoRow>

            <StyledVideoInfoRow>
              <StyledVideoInfoHeader>타이틀</StyledVideoInfoHeader>
              <StyledVideoInfoCell>
                {detailValue.videoTitle}
              </StyledVideoInfoCell>
            </StyledVideoInfoRow>

            {detailValue.videoType === 'MAIN' && (
              <StyledVideoInfoRow>
                <StyledVideoInfoHeader>설명 및 태그</StyledVideoInfoHeader>
                <StyledVideoDescriptionWrapper>
                  <StyledVideoDescriptionRow>
                    <StyledVideoDescriptionCell>
                      {detailValue.meetingLocation}
                    </StyledVideoDescriptionCell>
                    <StyledVideoTagCell>
                      {detailValue.meetingTime}
                    </StyledVideoTagCell>
                  </StyledVideoDescriptionRow>
                  <StyledVideoDescriptionRow>
                    <StyledVideoDescriptionCell>
                      {detailValue.meetingCount}
                    </StyledVideoDescriptionCell>
                    <StyledVideoTagCell>{detailValue.tags}</StyledVideoTagCell>
                  </StyledVideoDescriptionRow>
                </StyledVideoDescriptionWrapper>
              </StyledVideoInfoRow>
            )}
            <StyledVideoInfoRow>
              <StyledVideoInfoHeader>게시 지역</StyledVideoInfoHeader>
              <StyledVideoInfoCell>
                {detailValue.videoAddress}
              </StyledVideoInfoCell>
            </StyledVideoInfoRow>

            <StyledVideoInfoRow>
              <StyledVideoInfoHeader $bottomLeft>
                게시자 닉네임
              </StyledVideoInfoHeader>
              <StyledVideoInfoCell>{detailValue.nickname}</StyledVideoInfoCell>
            </StyledVideoInfoRow>
          </StyledVideoInfoTable>

          <StyledVideoStatsTable>
            <StyledVideoStatsHeader>
              {detailValue.stats?.map(({ name, key }) => (
                <StyledVideoStatsHeaderCell key={key}>
                  {name}
                </StyledVideoStatsHeaderCell>
              ))}
            </StyledVideoStatsHeader>
            <StyledVideoStatsRow>
              {detailValue.stats?.map(({ value, key, onClick }) => (
                <StyledVideoStatsCell key={key}>
                  {onClick ? (
                    <StyledPublishedVideoListBlueBtn
                      onClick={() => handleChangeListType(key)}
                    >
                      {value}
                    </StyledPublishedVideoListBlueBtn>
                  ) : (
                    value
                  )}
                </StyledVideoStatsCell>
              ))}
            </StyledVideoStatsRow>
          </StyledVideoStatsTable>
          {isFix && (
            <StyledVideoStatsButtonContainer>
              <CustomButton
                size="large"
                $state="default"
                text="나가기"
                $variant="reset"
                onClick={handleChangeIsFix}
              />
              <CustomButton
                size="large"
                $state="default"
                text="변경 내용 저장"
                $variant="search"
                onClick={handleModalConfirm}
                disabled={
                  activeStatus === initialActiveStatus &&
                  selectedDate.getTime() === initialDate.getTime() &&
                  selectedEndTime === initialEndTime
                }
              />
            </StyledVideoStatsButtonContainer>
          )}
          {!isFix && (
            <CustomButton
              size="large"
              $state="default"
              text="수정"
              $variant="search"
              onClick={handleChangeIsFix}
            />
          )}

          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            title={modalTitle}
            content={modalContent}
            actions={
              <CustomButton
                size="large"
                $state="default"
                text="확인"
                $variant={modalVariant}
                onClick={handleChangeConfirm}
              />
            }
          />
        </StyledVideoInfoWrapper>
      </StyledPostInfoContainer>
    </>
  );
};

export default PostInfo;

const StyledPostInfoTitle = styled.div`
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
  margin-bottom: 10px;
`;

const StyledPostInfoContainer = styled.div`
  width: 926px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 40px;
`;

const StyledPostInfoThumbnailWrapper = styled.div`
  background-color: #e9e9e9;
  width: 240px;
  height: 426px;
  border-radius: 8px;
`;

const StyledVideoThumbnail = styled.img`
  width: 240px;
  height: 426px;
  border-radius: 8px;
`;

const StyledVideoInfoWrapper = styled.div`
  width: 100%;
  min-width: 666px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const StyledVideoInfoTable = styled.div`
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: visible;
`;

const StyledVideoInfoRow = styled.div`
  align-self: stretch;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

const StyledVideoInfoHeader = styled.div`
  min-width: 100px;
  align-self: stretch;
  padding: 6px 12px;
  background: #e9e9e9;
  border-right: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  ${({ $topLeft }) =>
    $topLeft &&
    `
    border-top-left-radius: 8px;
  `}

  ${({ $bottomLeft }) =>
    $bottomLeft &&
    `
    border-bottom-left-radius: 8px;
  `}
`;

const StyledVideoInfoCell = styled.div`
  flex: 1;
  align-self: stretch;
  padding: 6px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  position: relative; // 추가
`;

const StyledVideoDescriptionWrapper = styled.div`
  flex: 1;
  height: 68px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledVideoDescriptionRow = styled.div`
  flex: 1;
  border-right: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  &:last-child {
    border-right: none;
  }
`;

const StyledVideoDescriptionCell = styled.div`
  align-self: stretch;
  padding: 6px 12px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

const StyledVideoTagCell = styled.div`
  flex: 1;
  height: 34px;
  padding: 6px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

const StyledVideoStatsTable = styled.div`
  align-self: stretch;
  height: 68px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 12px;
`;

const StyledVideoStatsButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledVideoStatsHeader = styled.div`
  align-self: stretch;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledVideoStatsHeaderCell = styled.div`
  flex: 1;
  align-self: stretch;
  padding: 6px 12px;
  background: #e9e9e9;
  border-right: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &:last-child {
    border-right: none;
  }
`;

const StyledVideoStatsRow = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledVideoStatsCell = styled.div`
  flex: 1;
  height: 34px;
  border-right: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #434343;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  &:last-child {
    border-right: none;
  }
`;

// Radio 버튼
const StyledStatusContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
  cursor: pointer;
`;

const StyledRadioButton = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  cursor: pointer;
`;

const StyledSelectedBackground = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  background: #412cab;
  border-radius: 50%;
`;

const StyledSelectedIcon = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 50%;
`;

const StyledUnselectedBackground = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
`;

const StyledRadioLabel = styled.div`
  text-align: center;
  color: black;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 16.9px;
  word-wrap: break-word;
`;

// datepicker

const StyledDatePickerContainer = styled.div`
  position: relative;
`;

const StyledDatePickerInput = styled.div`
  width: 100px;
  height: 24px;
  padding: 0 12px;
  background: white;
  border-radius: 4px;
  border: 1px #dddfe3 solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  cursor: pointer;
`;

const StyledDatePickerInputText = styled.div`
  color: #1e2024;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

const StyledDatePickerWrapper = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: -113%;
  z-index: 1;
`;

export const StyledShutdownDropdownWrapper = styled.div`
  position: relative;
`;

const StyledVideoPlayer = styled.video`
  width: 240px;
  height: 426px;
  border-radius: 8px;
`;

export const StyledPublishedVideoListBlueBtn = styled.a`
  color: #1e2024;
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  text-decoration: underline;
`;
