import styled from 'styled-components';

export const StyledSidebarDrawer = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  width: ${({ width }) => width || '270px'};
  height: calc(100% - 64px);
  padding-top: 64px;
  padding: 20px 32px;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  /* border-right: 1px #f1f2f4 solid; */
  z-index: 999;
`;

export const StyledSidebarListItemText = styled.span`
  flex-grow: 1;
  color: ${({ selected }) => (selected ? '#412CAB' : '#434343')};
  font-size: 16px;
  font-family: Pretendard;
  font-weight: ${({ selected, 'data-dropdown': dropdown }) => {
    if (selected) {
      return '600';
    }
    if (dropdown === 'true') {
      return '500';
    }
    return '600';
  }};
  line-height: 22px;
`;

export const StyledSidebarListItemIcon = styled.div`
  min-width: 24px;
  height: 24px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* 아이콘 크기 조정 */
`;

export const StyledSidebarListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ 'data-dropdown': dropdown }) =>
    dropdown === 'true' ? '40px' : '54px'};
  padding: ${({ 'data-dropdown': dropdown }) =>
    dropdown === 'true' ? '4px 8px' : '4px 10px 4px 4px'};
  border-radius: 4px;
  justify-content: flex-start;
  cursor: pointer;
  &:hover {
    color: #412cab;
    ${StyledSidebarListItemText} {
      color: #412cab;
      font-weight: 600;
    }
    ${StyledSidebarListItemIcon} {
      color: #412cab;
    }
  }
`;

export const StyledSidebarList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSidebarDropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: ${({ open }) =>
    open ? '200px' : '0'}; /* 열린 상태에서는 200px, 닫힌 상태에서는 0 */
  overflow: hidden;
  transition: max-height 0.3s ease-out; /* 부드러운 애니메이션 효과 */
  background-color: #efefef;
  padding: ${({ open }) =>
    open ? '10px' : '0 10px'}; /* 패딩 조정으로 내용물이 보일 때의 간격 조정 */
`;
