/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PageHeader from '../../../components/common/PageHeader';
import InquiriesStats from './components/InquiriesStats/InquiriesStats';
import ReportList from './components/ReportList/ReportList';
import {
  StyledInquiriesContainer,
  StyledInquiriesContentsWrapper,
  StyledInquiriesTabWrapper,
  StyledInquiriesTab,
  StyledInquiriesBadge,
  StyledInquiriesBadgeCount,
} from './Inquirie.styles';

import { getSupportInquiryTab } from '../../../apis/support';

const TabBadge = ({ count, isNew }) => (
  <StyledInquiriesBadge $isNew={isNew}>
    <StyledInquiriesBadgeCount $isNew={isNew}>
      {count}
    </StyledInquiriesBadgeCount>
  </StyledInquiriesBadge>
);

const Inquiries = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('currentTab') || '';

  const [inquiryTab, setInquiryTab] = useState([]);
  const [reportStatus, setReportStatus] = useState([]);

  getSupportInquiryTab({
    onSuccess: ({ list: value }) => {
      const newList = value
        .filter(({ code }) => code) // code 값이 있는 요소만 필터링
        .map(({ reportCount, tabName }) => ({
          name: tabName,
          value: `${reportCount}개`,
        }));
      setReportStatus(newList);

      setInquiryTab(
        value.map(({ ...item }) => ({
          isTab: item.code === '602001' || item.code === '602002',
          ...item,
        })),
      );
    },
  });

  const handleTabClick = (tab) => {
    const newParams = new URLSearchParams();
    if (tab) newParams.set('currentTab', tab);
    setSearchParams(newParams);
  };

  return (
    <StyledInquiriesContainer>
      <PageHeader title="신고 관리" />
      {/* <InquiriesStats
        inquiryStats={{
          new: `${'0'}개`,
          pending: `${'0'}개`,
          completed: `${'0'}개`,
        }}
        reportStats={reportStatus}
      /> */}
      <StyledInquiriesContentsWrapper>
        <StyledInquiriesTabWrapper>
          {inquiryTab.map(({ code, reportCount, tabName, isTab }) => (
            <StyledInquiriesTab
              key={code}
              $isActive={activeTab === code}
              onClick={() => handleTabClick(code)}
            >
              {tabName}
              {isTab && (
                <TabBadge count={reportCount} isNew={code === '602001'} />
              )}
            </StyledInquiriesTab>
          ))}
        </StyledInquiriesTabWrapper>
        <ReportList activeTab={activeTab} />
      </StyledInquiriesContentsWrapper>
    </StyledInquiriesContainer>
  );
};

export default Inquiries;
