import EmptyList from '../../../../components/common/EmptyList/EmptyList';
import TableDataRow from '../../../../components/common/Table/TableDataRow';
import TableHeaderRow from '../../../../components/common/Table/TableHeaderRow';
import { StyledUserListTableWrapper } from './UserList.styles';

const UserListTable = ({
  keyValue,
  users,
  columns,
  selectedRowIds,
  onSelectAll,
  onSelectRow,
  onRowClick,
  onSort,
  sortColumn,
  sortDirection,
}) => {
  const isAllSelected =
    users.length > 0 && users.every((user) => selectedRowIds.includes(user.id));

  return (
    <StyledUserListTableWrapper>
      <TableHeaderRow
        columns={columns}
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
        onSort={onSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
      />
      {users.length ? (
        users.map((user) => (
          <TableDataRow
            key={keyValue ? user[keyValue] : user.id}
            data={user}
            columns={columns}
            onSelect={onSelectRow}
            isSelected={selectedRowIds.includes(user.id)}
            onRowClick={() => onRowClick(user)}
          />
        ))
      ) : (
        <EmptyList title="회원 목록" />
      )}
    </StyledUserListTableWrapper>
  );
};

export default UserListTable;
