import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

const getDashboardVideoLoactionStatus = () => {
  const { data, fetch } = useFetch({
    url: '/dashboard/video/location/status',
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  return data;
};

export default getDashboardVideoLoactionStatus;
