/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { PiCheckBold, PiMagnifyingGlass } from 'react-icons/pi';

// UserSearchFilter
export const StyledUserSearchFilterHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 10px;
`;

export const StyledUserSearchFilterContents = styled.div`
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: inline-flex;

  > div {
    height: 64px;
  }
`;

export const StyledUserSearchFilterFooter = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
`;

// SearchFilter
export const StyledSearchFilterHeader = styled.div`
  align-self: stretch;
  border-bottom: 1px #d9d9d9 solid;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
`;

export const StyledSearchFilterInputContainer = styled.div`
  width: 50%;
  min-width: 455px;
  height: 64px;
  /* padding: 14px 24px; */
  padding: 14px 22px 14px 20px;
  justify-content: flex-start;
  align-items: center;
  /* gap: 10px; */
  display: flex;
`;

export const StyledSearchFilterSearchBar = styled.div`
  flex: 1 1 0;
  height: 36px;
  padding: 0 12px;
  background: white;
  border-radius: 6px;
  border: 1px #dddfe3 solid;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
`;

export const StyledSearchFilterSearchInput = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  color: #1e2024;

  &::placeholder {
    color: #9d9d9d;
  }
`;

export const StyledSearchFilterSearchIcon = styled(PiMagnifyingGlass)`
  color: #1e2024;
  font-size: 20px;
`;

export const StyledSearchFilterContainer = styled.div`
  flex: 1 1 0;
  min-width: 207px;
  align-self: stretch;
  padding: 14px 24px 14px 20px;
  /* padding: 14px 24px; */
  border-left: 1px #d9d9d9 solid;
  justify-content: flex-start;
  align-items: center;
  /* gap: 16px; */
  display: flex;
`;

export const StyledSearchFilterAgeDropdownContainer = styled.div`
  min-width: 226px;
  flex: 1 1 0;
  /* height: 64px; */
  height: 100%;
  padding: 14px 24px 14px 20px;
  /* padding: 14px 24px; */
  border-left: 1px #d9d9d9 solid;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
`;

export const StyledSearchFilterBody = styled.div`
  align-self: stretch;
  border-bottom: 1px #d9d9d9 solid;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
`;

export const StyledSearchFilterFooter = styled.div`
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
`;
// 공통
export const StyledLabel = styled.div`
  text-align: start;
  color: black;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16.9px;
  width: 65px;
`;

// Checkbox
export const StyledCheckboxWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledCheckboxContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
  cursor: pointer;
`;

export const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  cursor: pointer;
`;

export const StyledCheckboxChecked = styled.div`
  width: 16px;
  height: 16px;
  background: #412cab;
  border-radius: 2px;
  position: absolute;
  cursor: pointer;
`;

export const StyledCheckboxUnchecked = styled.div`
  width: 16px;
  height: 16px;
  background: #f5f5f5;
  border-radius: 2px;
  border: 1px solid #c4c4c4;
  position: absolute;
  cursor: pointer;
`;

export const StyledCheckboxIcon = styled(PiCheckBold)`
  color: white;
  font-size: 16px;
  cursor: pointer;
`;

export const StyledCheckboxLabel = styled.label`
  text-align: center;
  color: black;
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 16.9px;
  word-wrap: break-word;
  white-space: nowrap;
  cursor: pointer;
`;

// joinPeriod
export const StyledJoinPeriodContainer = styled(
  StyledSearchFilterInputContainer,
)`
  position: relative;
`;

export const StyledJoinPeriodWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`;

export const StyledJoinPeriodInput = styled.div`
  min-width: 175px;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  background: ${(props) => (props.$active ? 'none' : 'white')};
  border-radius: 6px;
  border: 1px #dddfe3 solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  cursor: ${(props) => (props.$active ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.$active ? 'none' : 'auto')};
`;

export const StyledJoinPeriodInputText = styled.div`
  color: ${(props) => (props.$active ? '#9e9e9e' : '#1e2024')};
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

export const StyledJoinPeriodToggle = styled.div`
  min-width: 147px;
  height: 36px;
  padding: 4px;
  background: #d9d9d9;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const StyledJoinPeriodToggleButton = styled.div`
  flex: 1 1 0;
  align-self: stretch;
  padding: 7px 10px;
  background: ${(props) => (props.$active ? 'white' : 'transparent')};
  box-shadow: ${(props) =>
    props.$active ? '0px 1px 2px rgba(0, 0, 0, 0.06)' : 'none'};
  border-radius: 4px;
  border: ${(props) => (props.$active ? '0.5px #d1d5db solid' : 'none')};
  justify-content: flex-start;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const StyledJoinPeriodToggleButtonLabel = styled.div`
  flex: 1 1 0;
  text-align: center;
  color: ${(props) => (props.$active ? 'black' : '#374151')};
  font-size: 13px;
  font-family: 'Pretendard';
  font-weight: ${(props) => (props.$active ? '500' : '400')};
  line-height: 14px;
  word-wrap: break-word;
`;

export const StyledUserCelebFilterConatiner = styled(
  StyledSearchFilterContainer,
)``;
export const StyledUserTypeFilterContainer = styled(
  StyledSearchFilterContainer,
)`
  flex: 1 1 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  border: none;
`;

export const StyledDatePickerWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
`;

export const StyledLabelWrapper = styled.div`
  /* min-width: 45px;
  width: 100%; */
`;
