import styled from 'styled-components';
import { PiCheckBold } from 'react-icons/pi';

export const StyledUserBasicInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 40px;
`;

export const StyledUserBasicInfoHeader = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledUserBasicInfoTitle = styled.div`
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

export const StyledUserBasicInfoTable = styled.div`
  align-self: stretch;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 13px;
  line-height: 22px;
  overflow: visible;
`;

export const StyledUserBasicInfoTableRow = styled.div`
  align-self: stretch;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledUserBasicInfoTableCell = styled.div`
  align-self: stretch;
  padding: 6px 12px;
  background: ${({ $header }) => ($header ? '#e9e9e9' : 'none')};
  border-right: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  width: ${({ $header }) => ($header ? '140px' : 'auto')};
  flex: ${({ $header }) => ($header ? '0 0 auto' : '1 1 0')};

  &:last-child {
    border-right: none;
  }

  ${({ $topLeft }) =>
    $topLeft &&
    `
    border-top-left-radius: 8px;
  `}

  ${({ $bottomLeft }) =>
    $bottomLeft &&
    `
    border-bottom-left-radius: 8px;
  `}
`;

export const StyledUserBasicInfoCheckbox = styled.div`
  width: 55px;
  height: 24px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #efefef;
  gap: 6px;
  position: relative;
  cursor: pointer;
`;

export const StyledUserBasicInfoCheckboxInput = styled.input`
  appearance: none;
  width: 14px;
  height: 14px;
  padding: 0px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  &:checked {
    background-color: #412cab;
    border-color: #412cab;
  }
`;

export const StyledUserBasicInfoNicknameContainer = styled.form`
  display: flex;
  width: 100%;
`;

export const StyledUserBasicInfoNicknameInput = styled.div`
  input {
    height: 24px;
    padding: 0;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    padding: 0 10px;
  }
`;

export const StyledUserBasicInfoCheckboxIcon = styled(PiCheckBold)`
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
  visibility: ${({ $isChecked }) => ($isChecked ? 'visible' : 'hidden')};
  cursor: pointer;
`;

export const StyledUserBasicInfoCheckboxLabel = styled.div`
  text-align: center;
  color: black;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 15.6px;
  word-wrap: break-word;
`;

export const StyledSeparator = styled.div`
  display: flex;
  align-items: center;
  & > div {
    position: relative;
    padding: 0 10px;

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 13px;
      background-color: #9d9d9d;
    }
  }
`;

export const StyledBadgeWrapper = styled.div`
  box-sizing: border-box;
  width: 51px;
  height: 24px; // 높이를 24px로 고정s
  display: inline-flex;
  align-items: center;
  justify-content: center; // 내용을 가운데 정렬
  padding: 6px 4px 6px 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #645dd1;
  background: #e9e8ff;
`;

export const StyledBadgeIconWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 4px;
  display: flex;
  align-items: center;
`;
