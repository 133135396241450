import styled from 'styled-components';

import PageHeader from '../../components/common/PageHeader';
import PostStats from './components/PostStats/PostStats';
import PostSearchFilter from './components/PostSearchFilter/PostSearchFilter';
import PostList from './components/PostList/PostList';

const PostManagement = () => {
  return (
    <StyledPostManagementContainer>
      <PageHeader title="게시물 관리" />

      <PostStats />

      <StyledPostManagementContentsWrapper>
        <PostSearchFilter />
        <PostList />
      </StyledPostManagementContentsWrapper>
    </StyledPostManagementContainer>
  );
};
export default PostManagement;

const StyledPostManagementContainer = styled.div``;

const StyledPostManagementWrapper = styled.div`
  min-width: 926px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  justify-content: flex-start;
  display: inline-flex;
  flex-direction: column;
`;

const StyledPostManagementContentsWrapper = styled(StyledPostManagementWrapper)`
  margin-top: 20px;
`;
