import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

export const getUserDetailBasicInfo = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/user/detail/${query}/basicInfo`,
    method: 'get',
    onSuccess: (value) => onSuccess(value),
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};

export const getUserDetailActivity = ({
  query,
  isInitialRequest = true,
  onSuccess,
}) => {
  const { data, fetch } = useFetch({
    url: `/user/detail/${query}/activity`,
    method: 'get',
    onSuccess: (value) => onSuccess(value),
  });

  useEffect(() => {
    if (!isInitialRequest) return;
    fetch();
  }, []);

  return { data, fetch };
};
