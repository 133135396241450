import {
  StyledMyInfoRow,
  StyledMyInfoLabel,
  StyledMyInfoValue,
  StyledMyInfoText,
} from './MyInfo.styles';

const MyInfoItem = ({ label, value, size, weight }) => (
  <StyledMyInfoRow>
    <StyledMyInfoLabel>{label}</StyledMyInfoLabel>
    <StyledMyInfoValue>
      {typeof value === 'string' ? (
        <StyledMyInfoText size={size} weight={weight}>
          {value}
        </StyledMyInfoText>
      ) : (
        value
      )}
    </StyledMyInfoValue>
  </StyledMyInfoRow>
);

export default MyInfoItem;
