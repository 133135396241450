/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useModal from '../../../../hooks/useModal.js';

import Pagination from '../../../../components/common/Pagination/Pagination.jsx';
import Modal from '../../../../components/common/Modal/Modal.jsx';
import Dropdown from '../../../../components/common/DropDown/DropDown.jsx';
import CustomButton from '../../../../components/common/Button/Button.jsx';

import UserListTable from './UserListTable.jsx';
import UserListTableTitle from './UserListTableTitle.jsx';

import { PAGE_SIZE_OPTIONS } from '../../../../constants/constants.js';

import {
  StyledUserListContainer,
  StyledUserListWrapper,
  StyledUserListContents,
  StyledUserListTableTitleWrapper,
  StyledUserListButtonGroup,
  StyledUserListTableAccUploadTitleText,
} from './UserList.styles.js';
import {
  formatDateYYYYMMDD,
  formatGroupFullCode,
  formatNumber,
  formatPhoneNumber,
} from '../../../../utils/formatDate.js';
import xlsxDownload from '../../../../utils/xlsxDownload.js';
import getUsers from '../../../../apis/userManagement/getUsers.js';

const UserList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get('page')) || 1;
  const pageSize = parseInt(searchParams.get('size')) || PAGE_SIZE_OPTIONS[0];
  const [totalUsers, setTotalUsers] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isExcel, setIsExcel] = useState(false);

  const [sortColumn, setSortColumn] = useState('createdAtSort');
  const [sortDirection, setSortDirection] = useState('DESC');

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  const handleSetSearchParams = ({ key, value }) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const handleSetSeatchParamsList = (list) => {
    const newSearchParams = new URLSearchParams(searchParams);
    list.forEach(({ key, value }) => newSearchParams.set(key, value));
    setSearchParams(newSearchParams);
  };

  const handleSort = useCallback(
    (column) => {
      if (column === sortColumn) {
        setSortDirection((prevDirection) =>
          prevDirection === 'ASC' ? 'DESC' : 'ASC',
        );
      } else {
        setSortColumn(column);
        setSortDirection('ASC');
      }
    },
    [sortColumn],
  );

  const columnsLabel = useMemo(
    () => ({
      createdAtSort: '가입일자',
      nickname: '닉네임',
      gender: '성별',
      level: '회원유형',
      phone: '연락처',
      accUploadVideoSort: '누적 업로드 수',
      feelkyRequestSort: '받은 필키요청',
      sentFeelkyRequestSort: '보낸 필키요청',
    }),
    [],
  );

  const columnsKey = useMemo(
    () => [
      {
        key: 'createdAtSort',
        width: '120px',
        sortable: true,
      },
      { key: 'nickname', width: '30%' },
      { key: 'gender', width: '70px' },
      { key: 'level', width: '100px' },
      { key: 'phone', width: '150px' },
      { key: 'accUploadVideoSort', width: '150px', sortable: true },
      {
        key: 'feelkyRequestSort',
        width: '160px',
        sortable: true,
      },
      {
        key: 'sentFeelkyRequestSort',
        width: '160px',
        sortable: true,
      },
    ],
    [],
  );

  const columns = useMemo(
    () =>
      columnsKey.map(({ ...value }) => ({
        label: columnsLabel[value.key],
        ...value,
      })),
    [],
  );

  const handleExcelDownload = (data) => {
    xlsxDownload(data, '회원 목록', '회원 목록 리스트');
    showModal('엑셀 다운로드가 완료되었습니다.', '', 'confirm');
  };

  const handleRowClick = (user) => {
    const fullPath = encodeURIComponent(
      `${location.pathname}${location.search}`,
    );

    navigate(
      `/UserManagement/UserManagementDetail/${user.uuid}?fullPath=${fullPath}`,
    );
  };

  const handleDownloadClick = useCallback(() => {
    if (selectedRowIds.length !== 0) setIsExcel(true);
    else
      showModal(
        '전체 다운로드',
        '전체 리스트를 엑셀로 다운로드 하시겠습니까?',
        'excelConfirm',
      );
  }, [selectedRowIds, showModal]);

  const handleDownloadConfirm = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPageUserIds = filteredUsers.map((user) => user.id);
      setSelectedRowIds(currentPageUserIds);
    } else {
      setSelectedRowIds([]);
    }
  };

  const handleSelectRow = useCallback((userId) => {
    setSelectedRowIds((prevSelectedRowIds) => {
      if (prevSelectedRowIds.includes(userId)) {
        return prevSelectedRowIds.filter((id) => id !== userId);
      }
      return [...prevSelectedRowIds, userId];
    });
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      handleSetSearchParams({ key: 'page', value: page });

      setSelectedRowIds([]);
    },
    [searchParams],
  );

  const handlePageSizeChange = useCallback(
    (selectedOption) => {
      const selectedSize = PAGE_SIZE_OPTIONS.find(
        (size) => `${size}개씩 보기` === selectedOption,
      );
      if (selectedSize) {
        handleSetSeatchParamsList([
          { key: 'page', value: 1 },
          { key: 'size', value: selectedSize },
        ]);
        setSelectedRowIds([]);
      }
    },
    [searchParams],
  );

  const modalActions = useMemo(() => {
    return modalVariant === 'alert' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="다운로드"
          $variant={modalVariant}
          onClick={handleDownloadConfirm}
        />
      </>
    ) : modalVariant === 'excelConfirm' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="다운로드"
          $variant="confirm"
          onClick={() => {
            setIsExcel(true);
            closeModal();
          }}
        />
      </>
    ) : (
      <CustomButton
        size="large"
        $state="default"
        text="확인"
        $variant={modalVariant}
        onClick={closeModal}
      />
    );
  }, [modalVariant, closeModal, handleDownloadConfirm]);

  const users = getUsers({
    currentPage,
    pageSize,
    sortColumn,
    sortDirection,
    isExcel,
    userFilterQuery: `&${searchParams.get('filter')}`,
  });

  useEffect(() => {
    if (!users) return;

    const newData = users.userFilterListDto.map(({ ...item }) => ({
      ...item,
      id: item.uuid,
      createdAtSort: formatDateYYYYMMDD(new Date(item.createdAt)),
      phone: formatPhoneNumber(item.phone),
      feelkyRequestSort: formatNumber(item.receivedFeelkyRequestCount),
      sentFeelkyRequestSort: formatNumber(item.sentFeelkyRequestCount),
      gender: formatGroupFullCode(groupCodeList, item.gender)?.codeName,
      level: formatGroupFullCode(groupCodeList, item.level)
        ?.codeName.split(' ')
        .join(''),
    }));

    if (isExcel) {
      const excelData = newData
        .filter(({ uuid }) =>
          selectedRowIds.length ? selectedRowIds.includes(uuid) : true,
        )
        .map((data) => {
          const mappedData = {};
          Object.keys(columnsLabel).forEach((key) => {
            mappedData[columnsLabel[key]] = data[key];
          });
          return mappedData;
        });
      handleExcelDownload(excelData);
      setIsExcel(false);
    } else {
      setFilteredUsers(
        newData.map(({ ...item }) => ({
          ...item,
          accUploadVideoSort: (
            <StyledUserListTableAccUploadTitleText>
              <p>메인 {item.accVideoUploadCount}</p>
              <div />
              <p>댓글 {item.accVideoCommentUploadCount}</p>
            </StyledUserListTableAccUploadTitleText>
          ),
        })),
      );
      setTotalUsers(users.maxUserCount);
    }
  }, [users]);

  return (
    <StyledUserListContainer>
      <StyledUserListWrapper>
        <StyledUserListContents>
          <StyledUserListTableTitleWrapper>
            <UserListTableTitle totalUsers={totalUsers} />
            <StyledUserListButtonGroup>
              <Dropdown
                options={PAGE_SIZE_OPTIONS.map((size) => `${size}개씩 보기`)}
                value={`${pageSize}개씩 보기`}
                selectedValue={`${pageSize}개씩 보기`}
                onOptionChange={handlePageSizeChange}
              />
              <CustomButton
                size="small"
                $state="default"
                text="엑셀 다운로드"
                $variant="exceldown"
                onClick={handleDownloadClick}
              />
            </StyledUserListButtonGroup>
          </StyledUserListTableTitleWrapper>
          <UserListTable
            keyValue="uuid"
            users={filteredUsers}
            columns={columns}
            selectedRowIds={selectedRowIds}
            onSelectAll={handleSelectAll}
            onSelectRow={handleSelectRow}
            onRowClick={handleRowClick}
            onSort={handleSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalUsers / pageSize)}
            onPageChange={handlePageChange}
          />
        </StyledUserListContents>
      </StyledUserListWrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={modalActions}
      />
    </StyledUserListContainer>
  );
};

export default UserList;
