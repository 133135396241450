import styled from 'styled-components';

const EmptyList = ({ title = '게시물' }) => {
  return (
    <StyledEmptyListWrapper>검색된 {title}이 없습니다.</StyledEmptyListWrapper>
  );
};

export default EmptyList;

const StyledEmptyListWrapper = styled.div`
  height: 150px;
  padding: 20px 0;
  border-bottom: 1px #f1f2f4 solid;
  display: flex;
  justify-content: center;
  color: #9d9d9d;
  align-items: center;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;
