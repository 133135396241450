/* eslint-disable */
import styled from 'styled-components';
import RegionalSetting from './components/RegionalSetting/RegionalSetting';
import Shutdown from './components/Shutdown/Shutdown';
import ServiceStats from './components/ServiceStats/ServiceStats';
import PageHeader from '../../components/common/PageHeader';
import AppSetting from './components/AppSetting/AppSetting';

const SystemManagement = () => {
  return (
    <StyledSystemManagementContainer>
      <PageHeader title="시스템 관리" />
      <AppSetting />
      <RegionalSetting />
      {/* <Shutdown /> */}
      <ServiceStats />
    </StyledSystemManagementContainer>
  );
};

export default SystemManagement;

const StyledSystemManagementContainer = styled.div``;
