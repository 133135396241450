import styled from 'styled-components';

export const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const StyledPaginationPageNumber = styled.button`
  background-color: transparent;
  color: ${(props) => (props.$active ? '#1A1A1A' : '#C4C4C4')};
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  margin: 0 4px;
  cursor: pointer;
`;

export const StyledPaginationPageButton = styled.button`
  background-color: white;
  color: #1a1a1a;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  cursor: pointer;
`;

export const StyledPaginationEllipsisButton = styled.button`
  background-color: transparent;
  color: #c4c4c4;
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  margin: 0 4px;
  cursor: default;
`;
