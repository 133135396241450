/* eslint-disable no-unused-vars */
// Shutdown.jsx
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NoticeTemplateForm from './NoticeTemplateForm';
import {
  formatDateTime,
  formatGroupFullCode,
} from '../../../../../utils/formatDate';
import {
  ACTIVE_HIDDEN_STATUS,
  BOARD_TYPE_NOTICE,
} from '../../../../../constants/groupCode';

const NoticeTemplate = ({
  noticeData,
  isEditable,
  onCancel,
  onSave,
  cancelButtonText = '나가기',
  saveButtonText = '수정',
}) => {
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isActiveStatus, setIsActiveStatus] = useState(ACTIVE_HIDDEN_STATUS);
  const [boardType, setBorderType] = useState('');
  const [selectedTarget, setSelectedTarget] = useState(BOARD_TYPE_NOTICE);
  // const [selectedStartTime, setSelectedStartTime] = useState('오전 12:00');
  // const [selectedEndTime, setSelectedEndTime] = useState('오전 12:00');
  const [viewType, setViewType] = useState(['팝업']);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isCelebChecked, setIsCelebChecked] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [popUpFile, setPopUpFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [summary, setSummary] = useState('');

  useEffect(() => {
    setSelectedTarget(noticeData.viewTarget || '');
    setStartTime(noticeData.startTime || null);
    setEndTime(noticeData.endTime || null);
    setViewType(noticeData.reasons || ['팝업']);
    setStartDate(
      noticeData.startDate ? new Date(noticeData.startDate) : new Date(),
    );
    setEndDate(noticeData.endDate ? new Date(noticeData.endDate) : new Date());
    setTitle(noticeData.title || '');
    setContent(noticeData.contents || '');
    setSelectedFile(
      noticeData.fileUrl
        ? { name: noticeData.originalFileName, url: noticeData.fileUrl }
        : null,
    );

    setIsCelebChecked(noticeData.isPush || false);
    setSummary(noticeData.pushMessage);
    setIsActiveStatus(noticeData.isActiveStatus || ACTIVE_HIDDEN_STATUS);
    setBorderType(noticeData.boardType || BOARD_TYPE_NOTICE);
    setPopUpFile(
      noticeData.popUpFileUrl
        ? {
            name: noticeData.popUpOriginalFileName,
            url: noticeData.popUpFileUrl,
          }
        : null,
    );
    setBannerFile(
      noticeData.bannerFileUrl
        ? {
            name: noticeData.bannerOriginalFileName,
            url: noticeData.bannerFileUrl,
          }
        : null,
    );
  }, [noticeData]);

  const handleStartTime = (value) => setStartTime(value);
  const handleEndTime = (value) => setEndTime(value);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
  };

  // const handlePopUpFileChange = (event, fileAspectRatio) => {
  //   const file = event.target.files[0];
  //   setPopUpFile(file);
  // };

  const handlePopUpFileChange = (event, fileAspectRatio) => {
    const file = event.target.files[0];
    setPopUpFile(file);
  };

  const handlePopUpFileDelete = () => {
    setPopUpFile(null);
  };

  const handleBannerFileChange = (event, fileAspectRatio) => {
    const file = event.target.files[0];
    if (!file) return;

    console.log('Selected file:', file);

    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        console.log('Image loaded');
        const isValidRatio = checkAspectRatio(img, fileAspectRatio);

        if (isValidRatio) {
          console.log('Ratio is within acceptable range');
          setBannerFile(file);
        } else {
          alert(
            `파일의 비율이 ${fileAspectRatio.widthAspectRatio}:${fileAspectRatio.heightAspectRatio}과 맞지 않습니다. 다른 파일을 선택해주세요. (허용 오차: ±10%)`,
          );
        }
      };

      img.onerror = (error) => {
        console.error('Error loading image:', error);
      };
    };

    reader.readAsDataURL(file);
  };

  const checkAspectRatio = (img, fileAspectRatio) => {
    const expectedHeight = img.width / fileAspectRatio.widthAspectRatio;
    const lowerBound = expectedHeight * 0.9;
    const upperBound = expectedHeight * 1.1;
    return img.height >= lowerBound && img.height <= upperBound;
  };

  const handleBannerFileDelete = () => {
    setPopUpFile(null);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const handleSummaryChange = (event) => {
    setSummary(event.target.value);
  };

  const datePickerRef = useRef(null);

  const handleCelebCheckboxChange = () => {
    setIsCelebChecked(!isCelebChecked);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       datePickerRef.current &&
  //       !datePickerRef.current.contains(event.target)
  //     ) {
  //       setShowDatePicker(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  const handleChangeViewType = (reason) => {
    setViewType([reason]);
  };

  const handleDateChange = (dates) => {
    if (dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  const hanldeDayChange = (date) => {
    setStartDate(date);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
  };

  const handleConfirm = () => {
    setShowDatePicker(false);
  };

  const handleChangeIsActiveStatus = (active) => setIsActiveStatus(active);

  const handleChnageBoardType = (type) => setBorderType(type);
  const handleDayChange = (date) => {
    if (startDate && startDate?.getTime() > date.getTime()) return;
    if (
      startDate &&
      startTime &&
      endTime &&
      new Date(formatDateTime(startDate, startTime)).getTime() >
        new Date(formatDateTime(date, endTime)).getTime()
    )
      return;

    setEndDate(date);
  };

  const handleStartDayChange = (date) => {
    if (endDate && endDate?.getTime() < date.getTime()) return;
    if (
      endDate &&
      startTime &&
      endTime &&
      new Date(formatDateTime(endDate, endTime)).getTime() <
        new Date(formatDateTime(date, startTime)).getTime()
    )
      return;

    setStartDate(date);
  };

  return (
    <NoticeTemplateForm
      startTime={startTime}
      handleStartTime={handleStartTime}
      endTime={endTime}
      handleEndTime={handleEndTime}
      endDate={endDate}
      handleDayChange={handleDayChange}
      startDate={startDate}
      handleStartDayChange={handleStartDayChange}
      boardType={boardType}
      handleChnageBoardType={handleChnageBoardType}
      isActiveStatus={isActiveStatus}
      handleChangeIsActiveStatus={handleChangeIsActiveStatus}
      selectedTarget={selectedTarget}
      setSelectedTarget={setSelectedTarget}
      // selectedStartTime={selectedStartTime}
      // setSelectedStartTime={setSelectedStartTime}
      // selectedEndTime={selectedEndTime}
      // setSelectedEndTime={setSelectedEndTime}
      viewType={viewType}
      handleChangeViewType={handleChangeViewType}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      handleDateChange={handleDateChange}
      showDatePicker={showDatePicker}
      isCelebChecked={isCelebChecked}
      handleCelebCheckboxChange={handleCelebCheckboxChange}
      selectedFile={selectedFile}
      handleFileChange={handleFileChange}
      handleFileDelete={handleFileDelete}
      title={title}
      handleTitleChange={handleTitleChange}
      handleSummaryChange={handleSummaryChange}
      content={content}
      handleContentChange={handleContentChange}
      setShowDatePicker={setShowDatePicker}
      isEditable={isEditable}
      onCancel={onCancel}
      popUpFile={popUpFile}
      handlePopUpFileChange={handlePopUpFileChange}
      handlePopUpFileDelete={handlePopUpFileDelete}
      bannerFile={bannerFile}
      handleBannerFileChange={handleBannerFileChange}
      handleBannerFileDelete={handleBannerFileDelete}
      summaryTitle={summary}
      onSave={() =>
        onSave({
          viewTarget: selectedTarget,
          boardType,
          title,
          contents: content,
          endDate,
          endTime,
          startDate,
          startTime,
          isActiveStatus,
          viewType,
          fileUrl: selectedFile,
          isPush: isCelebChecked,
          pushMessage: summary,
          popUpFile,
          bannerFile,
        })
      }
      cancelButtonText={cancelButtonText}
      saveButtonText={saveButtonText}
      hanldeDayChange={hanldeDayChange}
    />
  );
};

export default NoticeTemplate;
