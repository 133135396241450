import styled from 'styled-components';
import { PiCaretLeftBold } from 'react-icons/pi';
import CustomButton from './Button/Button';

const StyledPageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  height: 36px; // 버튼이 없을 경우에도 높이를 유지하기 위해 추가
`;

const StyledPageHeaderTitleSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const StyledPageHeaderTitle = styled.div`
  color: #1e2024;
  font-size: 22px;
  font-family: Pretendard, sans-serif;
  font-weight: 700;
  line-height: 36px;
`;

const StyledPageHeaderButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const StyledPageReturnIcon = styled.div`
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PageHeader = ({ title, returnPage, buttons = [] }) => {
  return (
    <StyledPageHeaderContainer>
      <StyledPageHeaderTitleSection>
        {returnPage && (
          <StyledPageReturnIcon onClick={returnPage}>
            <PiCaretLeftBold size="28px" />
          </StyledPageReturnIcon>
        )}
        <StyledPageHeaderTitle>{title}</StyledPageHeaderTitle>
      </StyledPageHeaderTitleSection>
      <StyledPageHeaderButtonSection>
        {buttons.map((button) => (
          <CustomButton
            key={button.text}
            size={button.size}
            $state="default"
            text={button.text}
            $variant="default"
            onClick={button.onClick}
            disabled={button.disabled}
          />
        ))}
      </StyledPageHeaderButtonSection>
    </StyledPageHeaderContainer>
  );
};

export default PageHeader;
