/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';

const getSupportInquiryCount = () => {
  const [data, setData] = useState([]);
  const { fetch, isLoading } = useFetch({
    url: '/support/inquiry/count',
    method: 'get',
    onSuccess: (value) =>
      setData([
        {
          label: '신규 신고',
          count: `${value.newReportCount}건`,
        },
        {
          label: '미처리 신고',
          count: `${value.unresolvedReportCount}건`,
        },
      ]),
  });

  useEffect(() => {
    fetch();
  }, []);

  return { data, isLoading };
};

export default getSupportInquiryCount;
