import {
  StyledCheckboxContainer,
  StyledCheckbox,
  StyledCheckboxChecked,
  StyledCheckboxUnchecked,
  StyledCheckboxIcon,
  StyledCheckboxLabel,
  StyledLabel,
  StyledCheckBoxWrapper,
} from './PostSearchFilter.styles';

const Checkbox = ({ label, options, value, handleChange }) => (
  <>
    <StyledLabel>{label}</StyledLabel>
    <StyledCheckBoxWrapper>
      {options.map((option) => (
        <StyledCheckboxContainer key={option.value}>
          <StyledCheckbox onClick={() => handleChange(option.value)}>
            {value?.[option.value] ? (
              <StyledCheckboxChecked>
                <StyledCheckboxIcon />
              </StyledCheckboxChecked>
            ) : (
              <StyledCheckboxUnchecked />
            )}
          </StyledCheckbox>

          <StyledCheckboxLabel onClick={() => handleChange(option.value)}>
            {option.label}
          </StyledCheckboxLabel>
        </StyledCheckboxContainer>
      ))}
    </StyledCheckBoxWrapper>
  </>
);

export default Checkbox;
