import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';

const getDashboardVideoEtcStatistics = (query) => {
  const { data, fetch } = useFetch({
    url: `/dashboard/video/etc/statistics?rangeDay=${query}`,
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);
  return data;
};

export default getDashboardVideoEtcStatistics;
