/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useModal from '../../../../hooks/useModal.js';

import Pagination from '../../../../components/common/Pagination/Pagination.jsx';
import Modal from '../../../../components/common/Modal/Modal.jsx';
import Dropdown from '../../../../components/common/DropDown/DropDown.jsx';
import CustomButton from '../../../../components/common/Button/Button.jsx';

import PostListTable from './PostListTable';
import PostListTableTitle from './PostListTableTitle';

import { PAGE_SIZE_OPTIONS } from '../../../../constants/constants.js';

import {
  StyledPostListContainer,
  StyledPostListWrapper,
  StyledPostListListWrapper,
  StyledPostListTableTitleContainer,
  StyledPostListButtonGroupWrapper,
  StyledPublishedVideoListBlackBtn,
} from './PostList.styles';
import {
  formatBirthDate,
  formatDateYYYYMMDDWHHMMSS,
  formatGroupFullCode,
  formatPhoneNumber,
  formatYYYYMMDDHHMMSS,
} from '../../../../utils/formatDate.js';
import UserDetailModal from '../../../CustomerService/Inquiries/components/ReportList/UserDetailModal.jsx';
import xlsxDownload from '../../../../utils/xlsxDownload.js';
import {
  getUserDetailActivity,
  getUserDetailBasicInfo,
} from '../../../../apis/user/index.js';
import { getVideos } from '../../../../apis/videos/index.js';
import {
  getVideoDetailCommentVideo,
  getVideoDetailMainVideo,
} from '../../../../apis/video/index.js';

const PostList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const groupCodeList = useSelector((state) => state.groupCode.groupCodeList);

  const currentPage = parseInt(searchParams.get('page')) || 1;
  const pageSize = parseInt(searchParams.get('size')) || PAGE_SIZE_OPTIONS[0];
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedPostIds, setSelectedPostIds] = useState([]);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filteredPosts, setFilteredPosts] = useState([]);

  const [openModal, setOpenModal] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [modalData, setModalData] = useState(null);
  const [isExcel, setIsExcel] = useState(false);

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    closeModal,
  } = useModal();

  const handleSetSearchParams = ({ key, value }) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const handleSetSeatchParamsList = (list) => {
    const newSearchParams = new URLSearchParams(searchParams);
    list.forEach(({ key, value }) => newSearchParams.set(key, value));
    setSearchParams(newSearchParams);
  };

  const handleSort = useCallback(
    (column) => {
      if (column === sortColumn) {
        setSortDirection((prevDirection) =>
          prevDirection === 'ASC' ? 'DESC' : 'ASC',
        );
      } else {
        setSortColumn(column);
        setSortDirection('ASC');
      }
    },
    [sortColumn],
  );

  const columnsLabel = useMemo(
    () => ({
      state: '상태',
      createdAt: '게시일자',
      videoType: '구분',
      videoTitle: '영상 타이틀',
      userName: '게시자',
      videoCommentCount: '영상댓글',
      recvFeelkyRequestCount: '필키요청',
      likeCount: '좋아요',
      viewCount: '조회수',
      reportCount: '신고',
      videoAddress: '위치',
    }),
    [],
  );

  const columnsKey = useMemo(
    () => [
      { key: 'state', width: '50px' },
      {
        key: 'createdAt',
        width: '170px',
        sortable: true,
      },
      { key: 'videoType', width: '50px' },
      { key: 'videoTitle', width: '17.5%' },
      { key: 'userName', width: '120px' },
      {
        key: 'videoCommentCount',
        width: '100px',
        sortable: true,
      },
      {
        key: 'recvFeelkyRequestCount',

        width: '100px',
        sortable: true,
      },
      { key: 'likeCount', width: '85px', sortable: true },
      { key: 'viewCount', width: '85px', sortable: true },
      { key: 'reportCount', width: '75px', sortable: true },
      { key: 'videoAddress', width: '18%' },
    ],
    [],
  );

  const columns = useMemo(
    () =>
      columnsKey.map(({ ...value }) => ({
        label: columnsLabel[value.key],
        ...value,
      })),
    [],
  );

  const { fetch: fetchGetDetailMainVideo } = getVideoDetailMainVideo({
    query: selectedId,
    onSuccess: (value) => setModalData(value),
  });

  const { fetch: fetchGetVideoDetailCommentVideo } = getVideoDetailCommentVideo(
    {
      query: selectedId,
      isInitialRequest: false,
      onSuccess: (value) => setModalData(value),
    },
  );

  const { fetch: fetchGetUserDetailBasicInfo } = getUserDetailBasicInfo({
    query: selectedId,
    isInitialRequest: false,
    onSuccess: (value) => {
      const { gender, birthDate, createdAt, phone, ...data } = value;
      const basic = {
        ...data,
        createdAt: formatDateYYYYMMDDWHHMMSS(createdAt),
        birthDate: formatBirthDate(birthDate),
        phone: formatPhoneNumber(phone),
        gender: formatGroupFullCode(groupCodeList, gender).codeName,
      };
      setModalData((prev) => ({ ...prev, basic }));
    },
  });

  const { fetch: fetchGetUserDetailActivity } = getUserDetailActivity({
    query: selectedId,
    isInitialRequest: false,
    onSuccess: (value) => {
      const { blockedUserCount, recvUserVideoReportCount, ...data } = value;
      const activity = {
        ...data,
        blockedUserCount: `${blockedUserCount}회`,
        recvUserVideoReportCount: `${recvUserVideoReportCount}회`,
      };
      setModalData((prev) => ({ ...prev, activity }));
    },
  });

  const { fetch: fetchGetVideos } = getVideos({
    query: {
      currentPage,
      pageSize,
      sortColumn,
      sortDirection,
      userFilterQuery: `&${searchParams.get('filter')}`,
    },
    onSuccess: (value) => {
      const newData = value.allPublishedVideoDto.map(
        ({ createdAt, ...item }) => {
          return {
            ...item,
            id: item.videoId,
            state: item.isActiveStatus,
            createdAt: formatYYYYMMDDHHMMSS(createdAt),
          };
        },
      );
      if (isExcel) {
        const excelData = newData
          .filter(({ videoId }) =>
            selectedPostIds.length ? selectedPostIds.includes(videoId) : true,
          )
          .map((data) => {
            const mappedData = {};
            Object.keys(columnsLabel).forEach((key) => {
              mappedData[columnsLabel[key]] = data[key];
            });
            return mappedData;
          });

        handleExcelDownload(excelData);
        setIsExcel(false);
      } else {
        setFilteredPosts(
          newData.map(({ userId, userName, state, ...item }) => ({
            ...item,
            userName: (
              <StyledPublishedVideoListBlackBtn
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenModal(userId, 'USER');
                }}
              >
                {userName}
              </StyledPublishedVideoListBlackBtn>
            ),
            state: (
              <div style={{ color: `${state !== '게시' && '#F76659'}` }}>
                {state}
              </div>
            ),
          })),
        );
        setTotalUsers(value.totalCount);
      }
    },
  });

  const handleExcelDownload = (data) => {
    xlsxDownload(data, '게시물 목록', '게시물 목록 리스트');
    showModal('엑셀 다운로드가 완료되었습니다.', '', 'confirm');
  };

  const handleRowClick = ({ videoId, videoType }) => {
    const fullPath = encodeURIComponent(
      `${location.pathname}${location.search}`,
    );

    navigate(
      `/PostManagement/PostManagementDetail/${videoId}?type=${videoType}&fullPath=${fullPath}`,
    );
  };
  const handleCloseModal = useCallback(() => {
    setModalData(null);
    setOpenModal('');
  }, []);

  const handleOpenModal = useCallback((id, openModalId) => {
    setOpenModal(openModalId);
    setSelectedId(id);
  }, []);

  const handleDownloadClick = useCallback(() => {
    if (selectedPostIds.length !== 0) setIsExcel(true);
    else
      showModal(
        '전체 다운로드',
        '전체 리스트를 엑셀로 다운로드 하시겠습니까?',
        'excelConfirm',
      );
  }, [selectedPostIds, showModal]);

  const handleDownloadConfirm = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPagePostIds = filteredPosts.map((post) => post.id);
      setSelectedPostIds(currentPagePostIds);
    } else {
      setSelectedPostIds([]);
    }
  };

  const handleSelectPost = useCallback((postId) => {
    setSelectedPostIds((prevselectedPostIds) => {
      if (prevselectedPostIds.includes(postId)) {
        return prevselectedPostIds.filter((id) => id !== postId);
      }
      return [...prevselectedPostIds, postId];
    });
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      handleSetSearchParams({ key: 'page', value: page });
      setSelectedPostIds([]);
    },
    [searchParams],
  );

  const handlePageSizeChange = useCallback(
    (selectedOption) => {
      const selectedSize = PAGE_SIZE_OPTIONS.find(
        (size) => `${size}개씩 보기` === selectedOption,
      );
      if (selectedSize) {
        handleSetSeatchParamsList([
          { key: 'page', value: 1 },
          { key: 'size', value: selectedSize },
        ]);
        setSelectedPostIds([]);
      }
    },
    [searchParams],
  );

  const handlePostCreateClick = () => {
    navigate(`/PostManagement/CreatePost`);
  };

  const modalActions = useMemo(() => {
    return modalVariant === 'alert' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="다운로드"
          $variant={modalVariant}
          onClick={handleDownloadConfirm}
        />
      </>
    ) : modalVariant === 'excelConfirm' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="다운로드"
          $variant="confirm"
          onClick={() => {
            setIsExcel(true);
            closeModal();
          }}
        />
      </>
    ) : (
      <CustomButton
        size="large"
        $state="default"
        text="확인"
        $variant={modalVariant}
        onClick={closeModal}
      />
    );
  }, [modalVariant, closeModal, handleDownloadConfirm]);

  useEffect(() => {
    if (!openModal || !selectedId) return;

    if (openModal === 'MAIN') fetchGetDetailMainVideo();
    if (openModal === 'COMMENT') fetchGetVideoDetailCommentVideo();
    if (openModal === 'USER') {
      fetchGetUserDetailBasicInfo();
      fetchGetUserDetailActivity();
    }
  }, [openModal, selectedId]);

  useEffect(() => {
    if (isExcel) fetchGetVideos();
  }, [isExcel]);

  return (
    <StyledPostListContainer>
      <StyledPostListWrapper>
        <StyledPostListListWrapper>
          <StyledPostListTableTitleContainer>
            <PostListTableTitle totalPosts={totalUsers} />
            <StyledPostListButtonGroupWrapper>
              <Dropdown
                options={PAGE_SIZE_OPTIONS.map((size) => `${size}개씩 보기`)}
                value={`${pageSize}개씩 보기`}
                selectedValue={`${pageSize}개씩 보기`}
                onOptionChange={handlePageSizeChange}
              />
              <CustomButton
                size="small"
                $state="default"
                text="엑셀 다운로드"
                $variant="exceldown"
                onClick={handleDownloadClick}
              />
              <CustomButton
                size="small"
                $state="default"
                text="게시물 생성"
                $variant="default"
                onClick={handlePostCreateClick}
              />
            </StyledPostListButtonGroupWrapper>
          </StyledPostListTableTitleContainer>
          <PostListTable
            keyValue="videoId"
            posts={filteredPosts}
            columns={columns}
            selectedPostIds={selectedPostIds}
            onSelectAll={handleSelectAll}
            onSelectPost={handleSelectPost}
            onRowClick={handleRowClick}
            onSort={handleSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalUsers / pageSize)}
            onPageChange={handlePageChange}
          />
        </StyledPostListListWrapper>
      </StyledPostListWrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={modalActions}
      />
      {/* <VideoDetailModal
        isOpen={openModal === 'MAIN'}
        onClose={handleCloseModal}
        videoData={modalData}
      />
      <CommentVideoDetailModal
        isOpen={openModal === 'COMMENT'}
        onClose={handleCloseModal}
        videoData={modalData}
      /> */}
      <UserDetailModal
        userId={selectedId}
        isOpen={openModal === 'USER'}
        onClose={handleCloseModal}
        userData={modalData}
      />
    </StyledPostListContainer>
  );
};

export default PostList;
