import styled from 'styled-components';

export const StyledNoticeListContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledNoticeListWrapper = styled.div`
  width: 100%;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
`;

export const StyledNoticeListContents = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledNoticeListTableTitleWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  margin-bottom: 10px;
`;

export const StyledNoticeListButtonGroup = styled.div`
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: flex;
`;

export const StyledNoticeListInquiryTitle = styled.a`
  color: ${({ $isInquiry }) => ($isInquiry ? '#F76659' : '#1E2024')};
  text-decoration: none;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
`;

// UserListTableTitle

export const StyledNoticeListTableTitleTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledNoticeListTableTitleText = styled.div`
  color: #262626;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

// UserListTable
export const StyledNoticeListTableWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  box-sizing: border-box;
`;

export const StyledNoticeListTableAccUploadTitleText = styled.div`
  width: 125.45px;
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    width: 100%;
    padding-left: 8px;
  }

  > div {
    height: 12px;
    width: 1px;
    background: #d9d9d9;
  }
`;
