import { useNavigate } from 'react-router-dom';

import LogoIcon from '../../../assets/logo_icon.svg';
import LogoText from '../../../assets/logo_text.svg';

import Badge from '../Badge';
import CustomButton from '../Button/Button';
import Modal from '../Modal/Modal';

import useModal from '../../../hooks/useModal';

import {
  GlobalStyle,
  StyledTopBarWrapper,
  StyledTopBarLogoWrapper,
  StyledTopBarLogoIcon,
  StyledTopBarLogoText,
  StyledTopBarProfileWrapper,
  StyledTopBarProfileInfoWrapper,
  StyledTopBarMemberLabel,
  StyledTopBarMemberNameText,
} from './Topbar.styles';
import TokenManager from '../../../api/tokenManger';

const TopBar = ({ user }) => {
  const { role, nickname } = user;

  const navigate = useNavigate();

  const { isModalOpen, showModal, closeModal } = useModal();

  const confirmLogout = () => {
    const tokenManer = new TokenManager();
    tokenManer.deleteToken();
    navigate('/');
  };

  const handleClickLogo = () => {
    navigate('/DashBoard');
    window.location.reload();
  };

  const handleClickName = () => {
    navigate('/MyInfo');
  };

  const logoutActions = (
    <>
      <CustomButton
        size="large"
        $state="default"
        text="취소"
        $variant="cancel"
        onClick={closeModal}
      />
      <CustomButton
        size="large"
        $state="default"
        text="로그아웃"
        $variant="alert"
        onClick={confirmLogout}
      />
    </>
  );

  return (
    <>
      <GlobalStyle />
      <StyledTopBarWrapper>
        <StyledTopBarLogoWrapper onClick={handleClickLogo}>
          <StyledTopBarLogoIcon src={LogoIcon} alt="Logo Icon" />
          <StyledTopBarLogoText src={LogoText} alt="Logo Text" />
        </StyledTopBarLogoWrapper>
        <StyledTopBarProfileWrapper>
          <StyledTopBarProfileInfoWrapper>
            <StyledTopBarMemberLabel>
              <Badge type={role} />
            </StyledTopBarMemberLabel>
            <StyledTopBarMemberNameText onClick={handleClickName}>
              {nickname}님
            </StyledTopBarMemberNameText>
          </StyledTopBarProfileInfoWrapper>
          <CustomButton
            size="small" // CustomButton에 적용할 사이즈
            $state="default" // 버튼 상태 (enabled, disabled 등)
            text="로그아웃" // 버튼에 표시될 텍스트
            $variant="logout" // 버튼 스타일 변형 (primary, secondary 등)
            onClick={showModal}
          />
        </StyledTopBarProfileWrapper>
      </StyledTopBarWrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="로그아웃 하시겠습니까?"
        actions={logoutActions}
      />
    </>
  );
};

export default TopBar;
