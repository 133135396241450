/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

const buttonSizes = {
  small: css`
    height: 32px;
    font-size: 14px;
  `,
  medium: css`
    width: 125px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
  `,
  large: css`
    width: 100%;
    height: 48px;
    font-size: 18px;
  `,
};

const buttonStates = {
  default: css`
    background-color: #1e2024;
    color: white;
    &:hover {
      background-color: #919eab;
      opacity: 0.8;
    }
  `,
  disabled: css`
    background-color: #e0e0e0;
    color: #9e9e9e;
    cursor: not-allowed;
    &:hover {
      background-color: #e0e0e0;
      opacity: 1;
    }
  `,
};

const buttonVariants = {
  default: css`
    background-color: #000000;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
  `,
  login: css`
    background-color: #1e2024;
  `,
  logout: css`
    height: 34px;
    background-color: #000000;
    border: 1px rgba(145, 158, 171, 0.8) solid;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
  `,
  confirm: css`
    background-color: #412cab;
    color: white;
    font-size: 16px;
    width: 212px;
  `,
  cancel: css`
    background-color: transparent;
    color: #262626;
    border: 1px #dddfe3 solid;
    font-size: 16px;
    width: 212px;
  `,
  alert: css`
    background-color: #f76659;
    font-size: 16px;
    width: 212px;
  `,
  passwordChange: css`
    background-color: white;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border: 1px #dddfe3 solid;
    border-radius: 4px;
    height: 34px;
  `,
  passwordChangeConfirm: css`
    width: 64px;
  `,
  accountDelete: css`
    background-color: white;
    color: #262626;
    border: 1px #dddfe3 solid;
    border-radius: 4px;
    font-weight: 500;
    line-height: 14px;
    width: 100px;
  `,
  accountCreate: css`
    border-radius: 4px;
    font-weight: 500;
    line-height: 14px;
    width: 100px;
  `,
  save: css`
    background-color: #412cab;
    width: 120px;
    height: 32px;
    font-weight: 500;
    line-height: 14px;
    border-radius: 4px;
  `,
  reset: css`
    background-color: #434343;
    width: 140px;
    height: 40px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    border-radius: 4px;
  `,
  search: css`
    background-color: #412cab;
    width: 140px;
    height: 40px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    border-radius: 4px;
  `,
  exceldown: css`
    background-color: white;
    color: #262626;
    border: 1px #dddfe3 solid;
    width: 101px;
    height: 34px;
    padding: 0 4px;
    line-height: 14px;
    border-radius: 4px;
  `,
  edit: css`
    margin-left: 10px;
    padding: 8px;
    width: 64px;
    height: 24px;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    color: white;
  `,
  change: css`
    width: 64px;
    height: 28px;
    padding-left: 8px;
    padding-right: 8px;
    background: #1e2024;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    display: inline-flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    color: white;
  `,
  systemReset: css`
    background-color: #434343;
    width: 120px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    border-radius: 4px;
  `,
  systemChange: css`
    width: 73px;
    height: 28px;
    padding-left: 8px;
    padding-right: 8px;
    background: #1e2024;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    display: inline-flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    color: white;
  `,
  systemSave: css`
    width: 120px;
    height: 32px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    background-color: #412cab;
  `,
  fileSelect: css`
    width: 100px;
    height: 28px;
    padding-left: 8px;
    padding-right: 8px;
    background: #1e2024;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    color: white;
  `,
  fileDelete: css`
    width: 87px;
    height: 28px;
    padding-left: 8px;
    padding-right: 8px;
    background: white;
    border-radius: 4px;
    border: 1px #dddfe3 solid;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    color: #1e2024;
  `,
  mediaDelete: css`
    width: 70px;
    height: 28px;
    padding-left: 8px;
    padding-right: 8px;
    background: white;
    border-radius: 4px;
    border: 1px #dddfe3 solid;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    color: #f76659;
  `,
  post: css`
    width: 101px;
    height: 32px;
    background-color: #1a1a1a;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  `,
  delete: css`
    width: 100px;
    height: 32px;
    background-color: #fff;
    color: #1e2024;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    border: 1px #dddfe3 solid;
  `,
  deleteBlack: css`
    background-color: #1e2024;
    color: #fff;
  `,
};

export const StyledButton = styled.button`
  padding: 0 16px;
  border-radius: 6px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  ${({ size }) => buttonSizes[size]}
  ${({ $state }) => buttonStates[$state]}
  ${({ $variant }) => buttonVariants[$variant]}
  ${({ disabled }) =>
    disabled &&
    css`
      ${buttonStates.disabled}
    `}
`;
