import EmptyList from '../../../../../../components/common/EmptyList/EmptyList';
import TableDataRow from '../../../../../../components/common/Table/TableDataRow';
import TableHeaderRow from '../../../../../../components/common/Table/TableHeaderRow';
import { StyledNoticeListTableWrapper } from './NoticeList.styles';

const NoticeListTable = ({
  notices,
  columns,
  selectedNoticeIds,
  onSelectNotice,
  onRowClick,
  onSort,
  onNoticeTitleClick,
}) => {
  const isAllSelected =
    notices.length > 0 &&
    notices.every((post) => selectedNoticeIds.includes(post.id));

  return (
    <StyledNoticeListTableWrapper>
      <TableHeaderRow
        columns={columns}
        onSelectAll="empty"
        isAllSelected={isAllSelected}
        onSort={onSort}
      />
      {notices.length ? (
        notices.map((notice) => (
          <TableDataRow
            key={notice.id}
            data={notice}
            columns={columns}
            onSelect={onSelectNotice}
            isSelected={selectedNoticeIds.includes(notice.id)}
            onRowClick={onRowClick}
            onNoticeTitleClick={onNoticeTitleClick}
          />
        ))
      ) : (
        <EmptyList title="알림 목록" />
      )}
    </StyledNoticeListTableWrapper>
  );
};

export default NoticeListTable;
