// InfoItem.js
import {
  StyledAddNewAdminInfoRow,
  StyledAddNewAdminInfoLabel,
  StyledAddNewAdminInfoValue,
  StyledAddNewAdminInfoText,
} from '../AddNewAdmin.styles';

const AddNewAdminItem = ({ label, value, size, weight }) => (
  <StyledAddNewAdminInfoRow>
    <StyledAddNewAdminInfoLabel>{label}</StyledAddNewAdminInfoLabel>
    <StyledAddNewAdminInfoValue>
      {typeof value === 'string' ? (
        <StyledAddNewAdminInfoText size={size} weight={weight}>
          {value}
        </StyledAddNewAdminInfoText>
      ) : (
        value
      )}
    </StyledAddNewAdminInfoValue>
  </StyledAddNewAdminInfoRow>
);

export default AddNewAdminItem;
