import styled from 'styled-components';

export const StyledPostListContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledPostListWrapper = styled.div`
  width: 100%;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
`;

export const StyledPostListListWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledPostListTableTitleContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  margin-bottom: 10px;
`;

export const StyledPostListButtonGroupWrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: flex;
`;

export const StyledPublishedVideoListBlackBtn = styled.a`
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Pretendard';
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  text-decoration: underline;
`;

// PostListTableTitle

export const StyledPostListTableTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledPostListTableTitleText = styled.div`
  color: #262626;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

// PostListTable
export const StyledPostListTableWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  box-sizing: border-box;
`;
