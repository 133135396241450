/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import Dropdown from '../../../../components/common/DropDown/DropDown';
import { formatDateYYYYMMDD } from '../../../../utils/formatDate';

import {
  StyledButtonGroup,
  StyledContainer,
  StyledDayPickContainer,
  StyledFilterInputFieldText,
  StyledInputField,
  StyledInputFieldText,
  StyledInputsBorderWrapper,
  StyledInputsWrapper,
  StyledIsCommentVideoContainer,
  StyledLatLngWrapper,
  StyledMappWrapper,
  StyledRadioLabel,
  StyledRadioOption,
  StyledRadioWrapper,
  StyledTable,
  StyledTagInputField,
  StyledTimeInputFiled,
} from './PostTemplate.styles';
import NoticeTemplateDayPicker from './PostTemplateDayPicker';
import PostTemplateRadioBtn from './PostTemplateRadioBtn';
import PostTemplateRow from './PostTemplateRow';
import PostTemplateTableCell from './PostTemplateTableCell';
import PostTemplateFileUpload from './PostTemplateFileUpload';
import PopupNotification from './PopupNotification';
import CustomButton from '../../../../components/common/Button/Button';
import Modal from '../../../../components/common/Modal/Modal';
import MyMap from '../../../../components/common/NaverMap/NaverMap';
import PostTemplateTagForm from './PostTemplateTagForm';
import { getVideoCreator } from '../../../../apis/video';
import { getSystemRegionCategoryLocation } from '../../../../apis/system';
import Input from './Input';
import InputNumber from './InputNumber';

const PostTemplateForm = ({
  isPost,
  isMute,
  handleChangeIsMute,
  // videoTarget,
  // handleSetVideoTarget,
  endTime,
  handleEndTime,
  endDate,
  startTime,
  handleStartTime,
  startDate,
  handleStartDayChange,
  showDatePicker,
  // endHour,
  // endMinute,
  // handleEndTimeHour,
  // handleEndTimeMinute,
  setShowDatePicker,
  handleDayChange,
  handleCancel,
  handleConfirm,
  videoTitle,
  handleVideoTitle,
  meetingLocation,
  handleMeetingLocation,
  meetingCount,
  handleMeetingCount,
  meetingTime,
  handleMeetingTime,
  tags,
  handleTags,
  isActive,
  handleIsActive,
  videoAddress,
  handleSetVideoAddress,
  createdBy,
  handleCreatedByChange,
  videoPath,
  timeline,
  handleVideoPathChange,
  handleVideoPathDelete,
  videoThumbnail,
  handleVideoThumbnailChange,
  handleVideoThumbnailDelete,
  cancelButtonText,
  saveButtonText,
  onCancel,
  onSave,
  latLng,
  handleSetLatLng,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [creatorOptions, setCreatorOptions] = useState([]);

  getVideoCreator({
    onSuccess: (value) => {
      setCreatorOptions(value.list);
    },
  });

  // const options = ['일반', '모든', '셀럽'];

  const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const period = hour < 12 ? '오전' : '오후';
        let displayHour = hour % 12;
        displayHour = displayHour === 0 ? 12 : displayHour; // 0시를 12시로 변환
        const formattedMinute = String(minute).padStart(2, '0');
        timeSlots.push(`${period} ${displayHour}:${formattedMinute}`);
      }
    }
    return timeSlots;
  };

  const parseTimeString = (timeStr) => {
    const regex = /(오전|오후) (\d{1,2}):(\d{2})/;
    const match = timeStr.match(regex);

    if (!match) {
      throw new Error('Invalid time format');
    }

    const period = match[1];
    let hour = parseInt(match[2], 10);
    const minute = parseInt(match[3], 10);

    if (period === '오전') {
      if (hour === 12) {
        hour = 0;
      }
    } else if (hour !== 12) {
      hour += 12;
    }

    return { hour, minute };
  };

  const formatTimeObject = (timeObj) => {
    if (!timeObj) return null;

    let { hour } = timeObj;
    const minute = String(timeObj.minute).padStart(2, '0');
    let period = '오전';

    if (hour >= 12) {
      period = '오후';
      if (hour > 12) {
        hour -= 12;
      }
    } else if (hour === 0) {
      hour = 12;
    }

    const formattedHour = String(hour).padStart(2, '0');

    return `${period} ${formattedHour}:${minute}`;
  };

  const timeOptions = generateTimeSlots();

  function removeAfter(arr, value) {
    const index = arr.indexOf(value);

    if (index !== -1) {
      return arr.slice(0, index + 1);
    }

    return arr;
  }

  const removeBefore = (arr, value) => {
    const index = arr.indexOf(value);

    if (index !== -1) {
      return arr.slice(index);
    }

    return arr;
  };

  const startTimeOptions = useMemo(() => {
    if (!endTime) return timeOptions;
    return removeAfter(timeOptions, formatTimeObject(endTime));
  }, [endTime]);

  const endTimeOptions = useMemo(() => {
    if (!startTime) return timeOptions;
    return removeBefore(timeOptions, formatTimeObject(startTime));
  }, [startTime]);

  const hourOptions = Array.from({ length: 24 }, (_, i) =>
    String(i).padStart(2, '0'),
  );
  const minuteOption = ['00', '15', '30'];

  const [locationsList, setLocationsList] = useState([]);

  const inintLocation = {
    city: '',
    county: '',
    district: '',
  };

  const [selectLocation, setSelectLocation] = useState(inintLocation);

  const getAddress = () => {
    const { city, county, district } = selectLocation;

    return `${city} ${county} ${district}`.trim();
  };

  const groupLocations = (locations) => {
    const groupedLocations = {};

    locations.forEach((location) => {
      const parts = location.split(' ');

      if (parts.length >= 3) {
        const province = parts[0];
        const city = parts[1];
        const district = parts.slice(2).join(' ');

        if (!groupedLocations[province]) {
          groupedLocations[province] = {};
        }

        if (!groupedLocations[province][city]) {
          groupedLocations[province][city] = [];
        }

        if (!groupedLocations[province][city].includes(district)) {
          groupedLocations[province][city].push(district);
        }
      }
    });

    return groupedLocations;
  };

  getSystemRegionCategoryLocation({
    query: '',
    onSuccess: (value) => setLocationsList(groupLocations(value)),
  });

  const handleChangeStartTime = (value) => {
    handleStartTime(parseTimeString(value));
  };

  const handleChangeEndTime = (value) => {
    handleEndTime(parseTimeString(value));
  };

  const handleChangeMeetingTime = (value) => {
    handleMeetingTime(parseTimeString(value));
  };

  const handleSetCity = (city) => {
    handleSetLatLng({ lat: null, lng: null });
    setSelectLocation({ city, county: '', district: '' });
  };

  const handleSetCounty = (county) => {
    handleSetLatLng({ lat: null, lng: null });
    setSelectLocation((prev) => ({ ...prev, county, district: '' }));
  };

  const handleSetDistrict = (district) => {
    handleSetLatLng({ lat: null, lng: null });
    setSelectLocation((prev) => ({ ...prev, district }));
  };

  const handlePostCancel = () => {
    onCancel();
    setSelectLocation(inintLocation);
  };

  useEffect(() => {
    if (!selectLocation.district) handleSetVideoAddress('');
    else {
      handleSetVideoAddress(getAddress());
    }
  }, [selectLocation.district]);

  const handleSaveMap = ({ lat, lng }) => {
    handleSetLatLng({ lat, lng });
    closeModal();
  };

  const handleSetMap = () => {
    saveMap();
  };

  const saveMap = () => {
    myMap.current?.saveMap();
  };

  const myMap = React.useRef(null);

  const handleSelectCreatator = (selectNickname) => {
    handleCreatedByChange(
      creatorOptions.find(({ nickname }) => nickname === selectNickname).userId,
    );
  };

  return (
    <>
      <StyledContainer>
        <StyledIsCommentVideoContainer>
          <StyledTable>
            <PostTemplateRow>
              <PostTemplateTableCell isHeader isFirstRow isRequired>
                게시 상태
              </PostTemplateTableCell>
              <PostTemplateTableCell minWidth="200px">
                <StyledRadioOption>
                  <StyledRadioWrapper onClick={handleIsActive}>
                    <PostTemplateRadioBtn checked={isActive} />
                    <StyledRadioLabel>게시</StyledRadioLabel>
                  </StyledRadioWrapper>
                  <StyledRadioWrapper onClick={handleIsActive}>
                    <PostTemplateRadioBtn checked={!isActive} />
                    <StyledRadioLabel>숨김</StyledRadioLabel>
                  </StyledRadioWrapper>
                </StyledRadioOption>
              </PostTemplateTableCell>
              <PostTemplateTableCell isHeader isFirstRow>
                영상 구분
              </PostTemplateTableCell>
              <PostTemplateTableCell minWidth="200px">
                {isPost ? '메인영상' : '영상댓글'}
              </PostTemplateTableCell>
            </PostTemplateRow>
            {isPost && (
              <PostTemplateRow>
                <PostTemplateTableCell isHeader height="40px" isRequired>
                  게시 일시
                </PostTemplateTableCell>
                <PostTemplateTableCell height="40px">
                  {/* <div>
                <StyledInputField
                  width="100px"
                  height="28px"
                  onClick={() => setShowDatePicker('startDate')}
                >
                  <StyledInputFieldText>
                    {startDate ? (
                      formatDateYYYYMMDD(startDate)
                    ) : (
                      <div style={{ color: '#9D9D9D' }}>시작일</div>
                    )}
                  </StyledInputFieldText>
                </StyledInputField>
                <StyledDayPickContainer>
                  {showDatePicker === 'startDate' && (
                    <NoticeTemplateDayPicker
                      startDate={startDate}
                      onChange={handleStartDayChange}
                      onCancel={handleCancel}
                      onConfirm={handleConfirm}
                      setShowDatePicker={setShowDatePicker}
                      showDatePicker={showDatePicker}
                    />
                  )}
                </StyledDayPickContainer>
              </div>
              <StyledTimeInputFiled>
                <Dropdown
                  options={startTimeOptions}
                  selectedValue={
                    formatTimeObject(startTime) || (
                      <div style={{ color: '#9D9D9D' }}>시작 시간</div>
                    )
                  }
                  onOptionChange={handleChangeStartTime}
                  minWidth="105px"
                  maxHeight="100px"
                  height="28px"
                  fontSize="13px"
                />
              </StyledTimeInputFiled>
              ~ */}
                  <div>
                    <StyledInputField
                      width="100px"
                      height="28px"
                      onClick={() => setShowDatePicker('endDate')}
                    >
                      <StyledInputFieldText>
                        {endDate ? (
                          formatDateYYYYMMDD(endDate)
                        ) : (
                          <div style={{ color: '#9D9D9D' }}>종료일</div>
                        )}
                      </StyledInputFieldText>
                    </StyledInputField>
                    <StyledDayPickContainer>
                      {showDatePicker === 'endDate' && (
                        <NoticeTemplateDayPicker
                          startDate={endDate}
                          onChange={handleDayChange}
                          onCancel={handleCancel}
                          onConfirm={handleConfirm}
                          setShowDatePicker={setShowDatePicker}
                          showDatePicker={showDatePicker}
                        />
                      )}
                    </StyledDayPickContainer>
                  </div>
                  <StyledTimeInputFiled>
                    <Dropdown
                      options={endTimeOptions}
                      selectedValue={
                        formatTimeObject(endTime) || (
                          <div style={{ color: '#9D9D9D' }}>종료 시간</div>
                        )
                      }
                      onOptionChange={handleChangeEndTime}
                      minWidth="105px"
                      maxHeight="100px"
                      height="28px"
                      fontSize="13px"
                    />
                  </StyledTimeInputFiled>
                </PostTemplateTableCell>
              </PostTemplateRow>
            )}
            <PostTemplateRow>
              <PostTemplateTableCell isHeader isRequired>
                타이틀
              </PostTemplateTableCell>
              <PostTemplateTableCell>
                <StyledInputField width="462px">
                  <Input
                    value={videoTitle}
                    onChange={handleVideoTitle}
                    placeholder="타이틀을 입력하여 주세요."
                    maxLength={20}
                  />
                </StyledInputField>
              </PostTemplateTableCell>
            </PostTemplateRow>
            {isPost && (
              <PostTemplateRow>
                <PostTemplateTableCell
                  isHeader
                  height="95px"
                  isRequired={
                    meetingLocation ||
                    meetingCount ||
                    meetingTime?.hour ||
                    meetingTime?.minute ||
                    tags
                  }
                >
                  설명 및 태그
                </PostTemplateTableCell>
                <PostTemplateTableCell height="95px" padding="none">
                  <StyledInputsWrapper>
                    <StyledInputsBorderWrapper $borderRight $borderBottom>
                      <StyledInputField>
                        <Input
                          value={meetingLocation}
                          onChange={handleMeetingLocation}
                          placeholder="모임 장소를 입력해 주세요."
                          maxLength={10}
                        />
                      </StyledInputField>
                    </StyledInputsBorderWrapper>
                    <StyledInputsBorderWrapper $borderLeft $borderBottom>
                      <InputNumber
                        value={meetingCount}
                        onChange={handleMeetingCount}
                        min={0}
                        max={999}
                      />
                    </StyledInputsBorderWrapper>
                    <StyledInputsBorderWrapper $borderRight $borderTop>
                      <Dropdown
                        options={timeOptions}
                        selectedValue={
                          formatTimeObject(meetingTime) || (
                            <div style={{ color: '#9D9D9D' }}>
                              모임 시간을 선택해 주세요
                            </div>
                          )
                        }
                        onOptionChange={handleChangeMeetingTime}
                        minWidth="100%"
                        maxHeight="100px"
                        height="28px"
                        fontSize="13px"
                      />
                    </StyledInputsBorderWrapper>
                    <StyledInputsBorderWrapper $borderLeft $borderTop>
                      <PostTemplateTagForm handleTags={handleTags} />
                    </StyledInputsBorderWrapper>
                  </StyledInputsWrapper>
                </PostTemplateTableCell>
              </PostTemplateRow>
            )}
            <PostTemplateRow>
              <PostTemplateTableCell isHeader isRequired>
                음소거 모드
              </PostTemplateTableCell>
              <PostTemplateTableCell minWidth="200px">
                <StyledRadioOption>
                  <StyledRadioWrapper onClick={handleChangeIsMute}>
                    <PostTemplateRadioBtn checked={isMute} />
                    <StyledRadioLabel>켜짐</StyledRadioLabel>
                  </StyledRadioWrapper>
                  <StyledRadioWrapper onClick={handleChangeIsMute}>
                    <PostTemplateRadioBtn checked={!isMute} />
                    <StyledRadioLabel>꺼짐</StyledRadioLabel>
                  </StyledRadioWrapper>
                </StyledRadioOption>
              </PostTemplateTableCell>
              <PostTemplateTableCell isHeader isFirstRow>
                영상 길이
              </PostTemplateTableCell>
              <PostTemplateTableCell minWidth="200px">
                {timeline} 초
              </PostTemplateTableCell>
            </PostTemplateRow>

            <PostTemplateRow>
              <PostTemplateTableCell isHeader isRequired={isPost}>
                게시 지역
              </PostTemplateTableCell>
              <PostTemplateTableCell>
                <Dropdown
                  options={Object.keys(locationsList) || []}
                  selectedValue={selectLocation.city || '시/도 선택'}
                  onOptionChange={handleSetCity}
                  minWidth="125px"
                  maxHeight="120px"
                  height="28px"
                  fontSize="13px"
                />
                {selectLocation.city && (
                  <Dropdown
                    options={Object.keys(locationsList[selectLocation.city])}
                    selectedValue={selectLocation.county || '구/군 선택'}
                    onOptionChange={handleSetCounty}
                    minWidth="120px"
                    maxHeight="120px"
                    height="28px"
                    fontSize="13px"
                  />
                )}
                {selectLocation.county && (
                  <Dropdown
                    options={
                      locationsList[selectLocation.city][selectLocation.county]
                    }
                    selectedValue={selectLocation.district || '읍/면/동 선택'}
                    onOptionChange={handleSetDistrict}
                    minWidth="120px"
                    maxHeight="120px"
                    height="28px"
                    fontSize="13px"
                    // disabled={!isEditable}
                  />
                )}
              </PostTemplateTableCell>
            </PostTemplateRow>
            <PostTemplateRow>
              <PostTemplateTableCell isHeader isRequired={isPost}>
                게시자 좌표
              </PostTemplateTableCell>
              <PostTemplateTableCell>
                <StyledInputField width="100px">
                  <StyledLatLngWrapper $isNull={!latLng.lat}>
                    {latLng.lat || '위도'}
                  </StyledLatLngWrapper>
                </StyledInputField>
                <StyledInputField width="100px">
                  <StyledLatLngWrapper $isNull={!latLng.lng}>
                    {latLng.lng || '경도'}
                  </StyledLatLngWrapper>
                </StyledInputField>
                <CustomButton
                  size="medium"
                  $state="default"
                  text="지도 보기"
                  $variant="fileSelect"
                  disabled={!getAddress()}
                  onClick={showModal}
                />
              </PostTemplateTableCell>
            </PostTemplateRow>
            <PostTemplateRow>
              <PostTemplateTableCell isHeader isRequired>
                게시자 닉네임
              </PostTemplateTableCell>
              <PostTemplateTableCell>
                <Dropdown
                  options={creatorOptions?.map(({ nickname }) => nickname)}
                  selectedValue={
                    creatorOptions?.find(({ userId }) => userId === createdBy)
                      ?.nickname || '게시자'
                  }
                  onOptionChange={handleSelectCreatator}
                  minWidth="90px"
                  maxHeight="80px"
                  height="28px"
                  fontSize="13px"
                />
              </PostTemplateTableCell>
            </PostTemplateRow>

            <PostTemplateRow>
              <PostTemplateTableCell isHeader isRequired>
                영상 파일
              </PostTemplateTableCell>
              <PostTemplateFileUpload
                selectedFile={videoPath}
                onFileChange={handleVideoPathChange}
                onFileDelete={handleVideoPathDelete}
                accept=".mp4"
                fileInfo="-권장 비디오 : 9:16 비율"
              />
            </PostTemplateRow>

            <PostTemplateRow>
              <PostTemplateTableCell isHeader isRequired>
                영상 썸네일
              </PostTemplateTableCell>
              <PostTemplateFileUpload
                selectedFile={videoThumbnail}
                onFileChange={handleVideoThumbnailChange}
                onFileDelete={handleVideoThumbnailDelete}
                accept=".gif, .png, .jpg, .jpeg"
                fileInfo="-권장 이미지 : 9:16 비율"
              />
            </PostTemplateRow>
          </StyledTable>
          {!isPost && (
            <StyledButtonGroup>
              <>
                <CustomButton
                  size="medium"
                  $state="default"
                  $variant="systemReset"
                  text={cancelButtonText}
                  onClick={handlePostCancel}
                />
                <CustomButton
                  size="medium"
                  $state="default"
                  $variant="systemSave"
                  text={saveButtonText}
                  onClick={onSave}
                />
              </>
            </StyledButtonGroup>
          )}
        </StyledIsCommentVideoContainer>
        <PopupNotification
          endDate={endDate}
          endTime={endTime}
          meetingLocation={meetingLocation}
          meetingCount={meetingCount}
          meetingTime={
            meetingTime?.hour &&
            meetingTime?.minute &&
            `${meetingTime.hour} : ${meetingTime.minute} `
          }
          nickname={
            creatorOptions?.find(({ userId }) => userId === createdBy)
              ?.nickname || ''
          }
          tags={tags}
          videoTitle={videoTitle}
          selectedVideo={videoPath}
          selectedThumbnail={videoThumbnail}
          videoAddress={videoAddress}
          tagRequired={
            meetingLocation ||
            meetingCount ||
            meetingTime?.hour ||
            meetingTime?.minute ||
            tags
          }
        />
      </StyledContainer>
      {isPost && (
        <StyledButtonGroup>
          <>
            <CustomButton
              size="medium"
              $state="default"
              $variant="systemReset"
              text={cancelButtonText}
              onClick={handlePostCancel}
            />
            <CustomButton
              size="medium"
              $state="default"
              $variant="systemSave"
              text={saveButtonText}
              onClick={onSave}
            />
          </>
        </StyledButtonGroup>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="맵 선택"
        actions={
          <StyledMappWrapper>
            <MyMap
              ref={myMap}
              latLng={latLng}
              address={getAddress()}
              onSaveMap={handleSaveMap}
              setSelectLocation={setSelectLocation}
            />
            <CustomButton
              size="medium"
              $state="default"
              $variant="systemSave"
              text="저장"
              onClick={handleSetMap}
            />
          </StyledMappWrapper>
        }
      />
    </>
  );
};

export default PostTemplateForm;
