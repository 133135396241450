// 메뉴 아이템 및 드롭다운 메뉴
import {
  PiAddressBook,
  PiAddressBookFill,
  PiChartBar,
  PiChartBarFill,
  PiDevices,
  PiDevicesFill,
  PiHeadset,
  PiHeadsetFill,
  PiMonitorPlay,
  PiMonitorPlayFill,
  PiShieldCheckered,
  PiShieldCheckeredFill,
} from 'react-icons/pi';

export const menuItems = [
  {
    to: '/DashBoard',
    name: '대시보드',
    icon: <PiChartBar />,
    iconSelected: <PiChartBarFill color="#412CAB" />,
    path: 'dashboard',
  },
  {
    to: '/UserManagement',
    name: '회원 관리',
    icon: <PiAddressBook />,
    iconSelected: <PiAddressBookFill color="#412CAB" />,
    path: 'usermanagement',
  },
  {
    to: '/PostManagement',
    name: '게시물 관리',
    icon: <PiMonitorPlay />,
    iconSelected: <PiMonitorPlayFill color="#412CAB" />,
    path: 'postmanagement',
  },
  {
    to: '/SystemManagement',
    name: '시스템 관리',
    icon: <PiDevices />,
    iconSelected: <PiDevicesFill color="#412CAB" />,
    path: 'systemmanagement',
  },
  {
    name: '고객센터',
    icon: <PiHeadset />,
    iconSelected: <PiHeadsetFill color="#412CAB" />,
    hasDropdown: true,
    path: 'customerservice',
  },
  {
    to: '/AccountManagement',
    name: '계정관리',
    icon: <PiShieldCheckered />,
    iconSelected: <PiShieldCheckeredFill color="#412CAB" />,
    path: 'accountmanagement',
  },
];

export const dropdownItems = [
  { to: '/CustomerService/Inquiries', name: '신고 관리' },
  { to: '/CustomerService/Faq', name: '자주 묻는 질문' },
  { to: '/CustomerService/Alarm', name: '알림 관리' },
  { to: '/CustomerService/Notices', name: '이벤트/공지사항' },
];
