/* eslint-disable no-unused-vars */
/* eslint-disable radix */
import { useState, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useModal from '../../../../../hooks/useModal.js';

import { PAGE_SIZE_OPTIONS } from '../../../../../constants/constants.js';

import {
  StyledNoticeListContainer,
  StyledNoticeListWrapper,
  StyledNoticeListListWrapper,
  StyledNoticeListTableTitleContainer,
  StyledNoticeListButtonGroupWrapper,
  StyledPublishedVideoListInquiryButton,
} from './AlarmList.styles.js';
import { formatYYYYMMDDHHMMSS } from '../../../../../utils/formatDate.js';
import {
  delteSupportAlarm,
  getAlarmList,
} from '../../../../../apis/alarm/index.js';
import CustomButton from '../../../../../components/common/Button/Button.jsx';
import Modal from '../../../../../components/common/Modal/Modal.jsx';
import Pagination from '../../../../../components/common/Pagination/Pagination.jsx';
import AlarmListTable from './AlarmListTable.jsx';
import AlarmListTableTitle from './AlarmListTableTitle.jsx';

const AlarmList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get('page')) || 1;
  const pageSize = parseInt(searchParams.get('size')) || PAGE_SIZE_OPTIONS[0];
  const [posts, setPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [selectedPostIds, setSelectedPostIds] = useState([]);

  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('DESC');

  const handleSetSearchParams = ({ key, value }) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const { fetch: fetchGetAlarmList } = getAlarmList({
    query: {
      currentPage,
      pageSize,
      sortColumn,
      sortDirection,
    },
    onSuccess: (value) => {
      setPosts(
        value.list.map(({ ...item }) => {
          const startDateValue = formatYYYYMMDDHHMMSS(new Date(item.startDate));
          // const endDateValue =
          //   item.endDate === '종료'
          //     ? item.endDate
          //     : formatDateYYMMDD(new Date(item.endDate));
          return {
            ...item,
            id: item.alarmBoardId,
            // alarmPeriod: `${startDateValue} ~ ${endDateValue}`,
            alarmPeriod: startDateValue,
            alarmBoardStatus: (
              <StyledPublishedVideoListInquiryButton
                $isInquiry={item.alarmBoardId !== '게시'}
              >
                {item.alarmBoardStatus}
              </StyledPublishedVideoListInquiryButton>
            ),
          };
        }),
      );
      setTotalPosts(value.totalCount);
    },
  });

  const { fetch: fetchDeleteSupportAlarm } = delteSupportAlarm({
    query: selectedPostIds.length ? 'LIST' : 'ALL',
    onSuccess: () => {
      fetchGetAlarmList();
      setSelectedPostIds([]);
    },
  });

  const {
    isModalOpen,
    modalTitle,
    modalContent,
    modalVariant,
    showModal,
    handleConfirm,
    closeModal,
  } = useModal();

  const navigate = useNavigate();
  const location = useLocation();

  const handleCreateNoticeClick = () => {
    navigate(`/CustomerService/Alarm/CreateAlarm${location.search}`);
  };

  const handleSort = useCallback(
    (column) => {
      if (column === sortColumn) {
        setSortDirection((prevDirection) =>
          prevDirection === 'ASC' ? 'DESC' : 'ASC',
        );
      } else {
        setSortColumn(column);
        setSortDirection('ASC');
      }
    },
    [sortColumn],
  );

  const columnsLabel = useMemo(
    () => ({
      viewTypeName: '발송형태',
      alarmBoardStatus: '상태',
      alarmPeriod: '발송 일자',
      title: '알림 타이틀',
      createdByName: '생성자',
      viewTargetName: '알림 대상',
    }),
    [],
  );

  const columnsKey = useMemo(
    () => [
      { key: 'viewTypeName', width: '80px' },
      { key: 'alarmBoardStatus', width: '60px' },
      { key: 'alarmPeriod', width: '180px' },
      { key: 'title', width: '45.60%' },
      { key: 'createdByName', width: '11.29%' },
      { key: 'viewTargetName', width: '11.29%' },
    ],
    [],
  );

  const columns = useMemo(
    () =>
      columnsKey.map(({ ...value }) => ({
        label: columnsLabel[value.key],
        ...value,
      })),
    [],
  );

  const handleRowClick = useCallback(
    (post) => {
      navigate(`/CustomerService/Alarm/${post}${location.search}`);
    },
    [navigate, location],
  );

  const handleDeleteClick = useCallback(() => {
    if (selectedPostIds.length === 0) {
      showModal('전체 알림을 삭제 하시겠습니까?', '', 'alert', () =>
        fetchDeleteSupportAlarm(),
      );
    } else {
      showModal(
        '삭제 하시겠습니까?',
        '선택하신 알림을 삭제됩니다.',
        'alert',
        () =>
          fetchDeleteSupportAlarm({
            alarmBoardIdList: selectedPostIds,
          }),
      );
    }
  }, [selectedPostIds, showModal]);

  const handleDeleteConfirm = useCallback(() => {
    setSelectedPostIds([]);
    closeModal();
    showModal('삭제 완료 되었습니다.', '', 'confirm');
  }, [closeModal, showModal]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const currentPagePostIds = posts.map((post) => post.id);
      setSelectedPostIds(currentPagePostIds);
    } else {
      setSelectedPostIds([]);
    }
  };

  const handleSelectPost = useCallback((postId) => {
    setSelectedPostIds((prevselectedPostIds) => {
      if (prevselectedPostIds.includes(postId)) {
        return prevselectedPostIds.filter((id) => id !== postId);
      }
      return [...prevselectedPostIds, postId];
    });
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      handleSetSearchParams({ key: 'page', value: page });
      setSelectedPostIds([]);
    },
    [searchParams],
  );

  const handlePageSizeChange = useCallback(
    (selectedOption) => {
      const selectedSize = PAGE_SIZE_OPTIONS.find(
        (size) => `${size}개씩 보기` === selectedOption,
      );
      if (selectedSize) {
        handleSetSearchParams({ key: 'page', value: 1 });
        handleSetSearchParams({ key: 'size', value: selectedSize });
        setSelectedPostIds([]);
      }
    },
    [searchParams],
  );

  const modalActions = useMemo(() => {
    return modalVariant === 'alert' ? (
      <>
        <CustomButton
          size="large"
          $state="default"
          text="취소"
          $variant="cancel"
          onClick={closeModal}
        />
        <CustomButton
          size="large"
          $state="default"
          text="삭제"
          $variant={modalVariant}
          onClick={() => {
            handleConfirm();
            closeModal();
          }}
        />
      </>
    ) : (
      <CustomButton
        size="large"
        $state="default"
        text="확인"
        $variant={modalVariant}
        onClick={closeModal}
      />
    );
  }, [modalVariant, closeModal, handleConfirm]);

  return (
    <StyledNoticeListContainer>
      <StyledNoticeListWrapper>
        <StyledNoticeListListWrapper>
          <StyledNoticeListTableTitleContainer>
            <AlarmListTableTitle totalPosts={totalPosts} />
            <StyledNoticeListButtonGroupWrapper>
              {/* <Dropdown
                options={PAGE_SIZE_OPTIONS.map((size) => `${size}개씩 보기`)}
                value={`${pageSize}개씩 보기`}
                selectedValue={`${pageSize}개씩 보기`}
                onOptionChange={handlePageSizeChange}
              /> */}
              <CustomButton
                size="small"
                $state="default"
                text="글쓰기"
                $variant="post"
                onClick={handleCreateNoticeClick}
              />
              <CustomButton
                size="small"
                $state="default"
                text="삭제"
                $variant="delete"
                onClick={handleDeleteClick}
              />
            </StyledNoticeListButtonGroupWrapper>
          </StyledNoticeListTableTitleContainer>
          <AlarmListTable
            posts={posts}
            columns={columns}
            selectedPostIds={selectedPostIds}
            onSelectAll={handleSelectAll}
            onSelectPost={handleSelectPost}
            onRowClick={(id) => handleRowClick(id)}
            onSort={handleSort}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalPosts / pageSize)}
            onPageChange={handlePageChange}
          />
        </StyledNoticeListListWrapper>
      </StyledNoticeListWrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalTitle}
        content={modalContent}
        actions={modalActions}
      />
    </StyledNoticeListContainer>
  );
};

export default AlarmList;
