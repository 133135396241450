import React, { useState } from 'react';
import styled from 'styled-components';
import RegionalFilter from './RegionalFilter/RegionalFilter';
import Category from './Regional/CategorySetting';

const StyledRegionalSettingContainer = styled.div``;

const StyledRegionalSettingContentsWrapper = styled.div``;

const StyledRegionalSettingTitle = styled.div`
  margin-bottom: 10px;
  color: #1e2024;
  font-size: 16px;
  font-family: 'Pretendard';
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
`;

const StyledRegionalSettingContents = styled.div`
  display: flex;
  gap: 20px;
`;

const RegionalSetting = () => {
  const [focusNode, setFocusNode] = useState({ target: '', id: '' });

  const handleGetCategory = () => {
    getCategory();
  };

  const getCategory = () => {
    searchFilter.current?.fetchGetSupportFaqList();
  };

  const searchFilter = React.useRef(null);

  return (
    <StyledRegionalSettingContainer>
      <StyledRegionalSettingContentsWrapper>
        <StyledRegionalSettingTitle>
          카테고리 추가 및 설정
        </StyledRegionalSettingTitle>
        <StyledRegionalSettingContents>
          <Category
            ref={searchFilter}
            focusNode={focusNode}
            setFocusNode={setFocusNode}
            handleGetCategory={handleGetCategory}
          />
          <RegionalFilter
            focusNode={focusNode}
            setFocusNode={setFocusNode}
            handleGetCategory={handleGetCategory}
          />
        </StyledRegionalSettingContents>
      </StyledRegionalSettingContentsWrapper>
    </StyledRegionalSettingContainer>
  );
};

export default RegionalSetting;
